import { defineStore } from "pinia";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import { Token, useAuthStore } from "~~/store/user/auth";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { useDiscountCouponStore } from "../discountCoupon/discountCoupon";

export interface User {
    id: number;
    uuid: string;
    campaignId: number;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    edmSubscription: boolean;
    lastLoginAt: string;
    activated: string;
    createdAt: string;
    activatedAt: string;
    uploadedCodesCount: boolean;
    alreadyWon: boolean;
    deleteAvailable: boolean;
}

interface LoginCredentials {
    campaignId: number;
    email: string;
    password: string;
}

interface UpdatePassword {
    oldPassword: string;
    password: string;
    passwordConfirmation: string;
}

interface ForgotPassword {
    uuid: string | undefined;
    token: string | undefined;
    password: string;
}

export const useLoginRegistrationStore = defineStore("loginRegistration", {
    state: () => {
        return {
            activeComponent: EComponents.REGISTRATION,
            isRegistered: false,
        };
    },
    getters: {
        hashForActiveSection(state) {
            return state.activeComponent === EComponents.LOGIN
                ? "/#belepes"
                : "/#regisztracio";
        },
    },
    actions: {
        /**
         * Set active component
         *
         * @param componentType
         */
        setActiveComponent(
            componentType: EComponents.LOGIN | EComponents.REGISTRATION
        ) {
            this.activeComponent = componentType;
        },

        /**
         * Create new enduser
         *
         * @param param0
         * @param campaignId
         * @returns
         */
        async createEndUser(registrationPayload: any) {
            const campaignDataStore = useCampaignDataStore();

            const { data, error } = await useApiFetch(
                `/endusermanagerms/${campaignDataStore.campaignId}`,
                {
                    key: "registration",
                    method: "POST",
                    body: registrationPayload,
                }
            );

            if (error.value !== undefined) {
                console.error(error);
            }

            return { data, error };
        },

        /**
         * Login user
         *
         * @param loginCredentials
         * @returns
         */
        async loginEndUser(loginCredentials: LoginCredentials) {
            const campaignDataStore = useCampaignDataStore();
            const authStore = useAuthStore();

            loginCredentials.campaignId = parseInt(
                campaignDataStore.campaignId
            );

            const { data, error } = await useApiFetch(
                "/endusermanagerms/auth/base",
                {
                    key: "login",
                    method: "POST",
                    body: loginCredentials,
                }
            );

            if (error.value !== null) {
                console.error(error.value);
            }

            if (data.value) {
                const resToken = data.value;
                const token: Token = {
                    accessToken: resToken.access_token,
                    accessTokenLifetime: resToken.expires_in,
                    refreshToken: resToken.refresh_token,
                };

                await authStore.setToken(token);
                try {
                    if (
                        campaignDataStore.campaignUUId ==
                            "cc601dc3-716a-4cb3-a994-14d3ec111761" ||
                        campaignDataStore.campaignUUId ==
                            "a46bdb28-0c9b-463b-a48e-b2e78acd71b8"
                    ) {
                        const discountCouponStore = useDiscountCouponStore();
                        discountCouponStore.getAvailableDiscountCoupons();
                    }
                } catch (e) {}

                try {
                    await authStore.getMe();
                } catch (error) {
                    console.error(error);
                }
            }

            return { data, error };
        },

        /**
         * Edit the current user profile
         */
        async updateMe(currentUser: any) {
            const authStore = useAuthStore();

            const { data, error } = await useApiFetch(
                "/endusermanagerms/profile",
                {
                    key: "update-user-profile",
                    method: "PUT",
                    body: currentUser,
                    headers: {
                        authorization: `Bearer ${authStore.accessToken}`,
                    },
                }
            );

            await authStore.getMe();

            return { data, error };
        },

        /**
         * Change the current user password
         */
        async updateMePassword(passwords: UpdatePassword) {
            const authStore = useAuthStore();

            const { data, error } = await useApiFetch(
                "/endusermanagerms/profile/change-password",
                {
                    key: "update-user-password",
                    method: "PUT",
                    body: passwords,
                    headers: {
                        authorization: `Bearer ${authStore.accessToken}`,
                    },
                }
            );

            return { data, error };
        },

        /**
         * Forgot password
         *
         * @param defaults
         * @returns
         */
        async forgotPassword(payload: ForgotPassword) {
            const campaignDataStore = useCampaignDataStore();

            const { data, error } = await useApiFetch(
                `/endusermanagerms/${campaignDataStore.campaignId}/reset-password`,
                {
                    key: "forgot-password",
                    method: "PUT",
                    body: payload,
                }
            );

            if (error.value !== undefined) {
                console.error(error.value);
            }

            return data.value;
        },

        /**
         * Delete end user
         *
         * @param param0
         * @param data
         * @returns
         */
        async deleteEndUser() {
            const authStore = useAuthStore();
            console.log("authStore.accessToken", authStore.accessToken);
            const res = await usePiniaFetch(
                "/endusermanagerms/profile/delete",
                {
                    method: "DELETE",
                    headers: {
                        authorization: `Bearer ${authStore.accessToken}`,
                    },
                }
            );
            return res;
        },

        /**
         * Reset password notification
         *
         * @param email
         * @returns
         */
        async resetPasswordNotification(email: string) {
            const campaignDataStore = useCampaignDataStore();

            const { data, error } = await useApiFetch(
                `/endusermanagerms/${campaignDataStore.campaignId}/send-reset-password-notification`,
                {
                    key: "forgot-password",
                    method: "POST",
                    body: { email: email },
                }
            );

            if (error.value !== undefined) {
                console.error(error.value);
            }

            return { data, error };
        },

        /**
         * Activate user account
         *
         * @param email
         * @returns
         */
        async activateAccount(token: string) {
            console.log("User activation token: ", token);

            const { data, error } = await useApiFetch(
                `/endusermanagerms/auth/activate/` + token,
                {
                    key: "activate-user",
                    method: "GET",
                }
            );

            console.log("User activation response: ", data);
            console.log("User activation error: ", error);

            if (data.value) {
                const authStore = useAuthStore();
                const resToken = data.value;
                const token: Token = {
                    accessToken: resToken.access_token,
                    accessTokenLifetime: resToken.expires_in,
                    refreshToken: resToken.refresh_token,
                };

                authStore.setToken(token);

                try {
                    await authStore.getMe();
                } catch (error) {
                    console.error(error);
                }
            }
        },

        /**
         * Reset password
         *
         * @param userUuid
         * @param token
         * @param password
         */
        async resetPassword(userUuid: string, token: string, password: string) {
            const campaignDataStore = useCampaignDataStore();

            const { data, error } = await useApiFetch(
                `/endusermanagerms/${campaignDataStore.campaignId}/reset-password`,
                {
                    key: "forgot-password",
                    method: "PUT",
                    body: {
                        uuid: userUuid,
                        token: token,
                        password: password,
                    },
                }
            );

            if (data.value) {
                const authStore = useAuthStore();
                const resToken = data.value;
                const token: Token = {
                    accessToken: resToken.access_token,
                    accessTokenLifetime: resToken.expires_in,
                    refreshToken: resToken.refresh_token,
                };

                authStore.setToken(token);

                try {
                    await authStore.getMe();
                } catch (error) {
                    console.error(error);
                }
            }

            return { data, error };
        },
    },
});
