<template>
    <div class="d-flex flex-row align-items-center gap-1">
        <template
            v-for="(brandColor, index) in campaignBrandColors"
            :key="index"
        >
            <div
                :class="[
                    'brand-color',
                    { selected: selectValue == brandColor },
                ]"
                :style="{ backgroundColor: brandColor }"
                @click="
                    handleOnClickColor(brandColor);
                    selectValue = brandColor;
                "
            ></div>
        </template>

        <div
            :class="['color-picker-border-helper', { selected: isCustomColor }]"
        >
            <el-color-picker
                v-model="selectValue"
                color-format="hex"
                show-alpha
                @change="handleSelectChange"
            ></el-color-picker>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { emit } from "vue";
import { storeToRefs } from "pinia";
import { useCampaignDataStore } from "@/store/campaign/campaignData";

const campaignDataStore: any = useCampaignDataStore();
const { campaignBrandColors } = storeToRefs(campaignDataStore);

interface Props {
    modelValue: string | number;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue", "change"]);

const selectValue = computed({
    get: () => {
        if (props.modelValue != null) {
            return props.modelValue;
        } else {
            return "";
        }
    },
    set: (value) => {
        if (value != null) {
            emit("update:modelValue", value);
        } else {
            emit("update:modelValue", "");
        }
    },
});

const data = reactive({
    selectedColor: "",
});

function handleSelectChange() {
    emit("change");
}

function handleOnClickColor(color: string) {
    data.selectedColor = color;
    emit("change");
}

const isCustomColor = computed(() => {
    return !campaignBrandColors.value.filter(
        (color: any) => props.modelValue == color
    ).length;
});
</script>

<style lang="scss" scoped>
.brand-color {
    position: relative;
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #dcdfe6;
    cursor: pointer;

    &:before {
        position: absolute;
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 5px solid white;
    }

    &.selected {
        border-color: #3a89a2;
    }
}

.color-picker-border-helper {
    border-radius: 5px;
    border: 1px solid transparent;
    height: 34px;

    &.selected {
        border-color: #3a89a2;
    }
}
</style>
