<template>
    <!-- Dialog -->
    <client-only>
        <el-dialog
            :style="fontStyleObject"
            class="prizific-dialog"
            :modelValue="show"
            :show-close="false"
            @closed="handleOnClosed()"
            destroy-on-close
            append-to-body
            center
        >
            <template #header="{ close }">
                <div
                    @click="close"
                    class="icon-close d-inline-block float-end"
                    :style="closeIconStyleObject"
                >
                    <el-icon :size="32"><CircleClose /></el-icon>
                </div>
            </template>
            <div>
                <div class="text-center">
                    <h3
                        class="text-center px-4 py-2 fw-bold"
                        :style="headingStyleObject"
                        v-html="title"
                    ></h3>
                </div>
                <img v-if="image" :src="image" alt="" />
                <div
                    class="'dialog-body fw-bold'"
                    :style="paragraphStyleObject"
                    v-html="description"
                ></div>
                <div class="text-center">
                    <el-button
                        v-if="footerBtnLeftText"
                        type="info"
                        class="mt-3"
                        :style="leftButtonStyleObject"
                        @click="handleLeftButtonClick()"
                    >
                        {{ footerBtnLeftText }}
                    </el-button>
                    <el-button
                        v-if="footerBtnText"
                        type="primary"
                        class="mt-3"
                        :style="rightButtonStyleObject"
                        @click="handleRightButtonClick()"
                    >
                        {{ footerBtnText }}
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </client-only>
    <!-- Dialog end -->
</template>

<script lang="ts">
import { getStyle } from "../../composables/styleHelper";
import { ElDialog, ElButton, ElIcon } from "element-plus";
import { CircleClose } from "@element-plus/icons-vue";

export default {
    components: {
        ElDialog,
        ElButton,
        ElIcon,
        CircleClose,
    },
    emits: ["closed", "btnClicked", "btnLeftClicked"],
    props: {
        show: Boolean,
        title: String,
        image: String,
        description: String,
        footerBtnText: String,
        footerBtnLeftText: String,
        component: Object,
    },
    data() {
        return {};
    },
    computed: {
        fontStyleObject() {
            return getStyle(this.component?.style, "prizeModalBody");
        },
        closeIconStyleObject() {
            return getStyle(this.component?.style, "prizeModalClose");
        },
        headingStyleObject() {
            return getStyle(this.component?.style, "prizeModalTitle");
        },
        paragraphStyleObject() {
            return getStyle(this.component?.style, "prizeModalDescription");
        },
        leftButtonStyleObject() {
            return getStyle(this.component?.style, "modalLeftButton");
        },
        rightButtonStyleObject() {
            return getStyle(this.component?.style, "modalRightButton");
        },
    },
    methods: {
        handleOnClosed() {
            this.$emit("closed", false);
        },
        handleRightButtonClick() {
            this.$emit("btnClicked", "");
        },
        handleLeftButtonClick() {
            this.$emit("btnLeftClicked", "");
        },
    },
};
</script>

<style lang="scss" scoped>
.prizific-dialog {
    min-width: 320px;
}

.icon-close {
    cursor: pointer;
}
</style>
