export default class DateOfBirth {
    window: Window;
    wrapper: HTMLElement;
    year: HTMLElement;
    month: HTMLElement;
    day: HTMLElement;
    windowWidth: number;
    wrapperWidth: number;
    yearWidth: number;
    monthWidth: number;
    dayWidth: number;
    gap: number = 0;

    constructor(
        window: Window,
        wrapper: HTMLElement,
        year: HTMLElement,
        month: HTMLElement,
        day: HTMLElement
    ) {
        this.window = window;
        this.wrapper = wrapper;
        this.year = year;
        this.month = month;
        this.day = day;

        this.windowWidth = this.window.innerWidth;
        this.wrapperWidth = this.wrapper.offsetWidth;
        this.yearWidth = this.year.offsetWidth;
        this.monthWidth = this.month.offsetWidth;
        this.dayWidth = this.day.offsetWidth;
        this.gap = parseInt(this.window.getComputedStyle(this.wrapper).gap, 10);
    }

    resize() {
        this.windowWidth = this.window.innerWidth;
        this.wrapperWidth = this.wrapper.offsetWidth;
        this.yearWidth = this.year.offsetWidth;
        this.monthWidth = this.month.offsetWidth;
        this.dayWidth = this.day.offsetWidth;
        this.gap = parseInt(this.window.getComputedStyle(this.wrapper).gap, 10);
    }

    init() {
        this.goToYear();

        const yearNodeList: ChildNode[] = [];
        for (var i = 0; i < this.year.childNodes.length; i++) {
            if (this.year.childNodes[i].nodeName == "INPUT") {
                yearNodeList.push(this.year.childNodes[i]);
            }
        }

        if (yearNodeList.length) {
            (yearNodeList[0] as HTMLInputElement).focus();
        }
    }

    goToYear() {
        this.resize();

        const yearCenterPosition = this.windowWidth / 2 - this.yearWidth / 2;
        this.wrapper.style.transform = `translateX(${yearCenterPosition}px)`;
    }

    goToMonth() {
        this.resize();

        const monthCenterPosition =
            this.windowWidth / 2 -
            this.gap -
            (this.yearWidth + this.monthWidth / 2);
        this.wrapper.style.transform = `translateX(${monthCenterPosition}px)`;
    }

    goToDay() {
        this.resize();

        const dayCenterPosition =
            this.windowWidth / 2 -
            this.gap * 2 -
            (this.yearWidth + this.monthWidth + this.dayWidth / 2);
        this.wrapper.style.transform = `translateX(${dayCenterPosition}px)`;
    }
}
