import { defineStore } from "pinia";
import { useCampaignDataStore } from "~/store/campaign/campaignData";
import { usePiniaFetch } from "~~/composables/useApiFetch";
import { useAuthStore } from "~~/store/user/auth";

enum CouponState {
    Used = 'used',
    UnAvailable = 'unavailable',
    Available = 'available'
}



interface CouponType {
    id: string
    type: string
    image: string
    imageWidth: string
    name: string
    description: string
    state: CouponState
}




const base = (): { [key: string]: CouponType } => {
    return {
        foodora: {
            id: "1",
            type: 'foodora',
            image: useCdnUrl("/images/staropramen/foodora.webp"),
            imageWidth: "304",
            name: "foodora",
            description:
                "Minden új Foodora regisztráló számára egy 4000 Ft értékű kupon.",
            state: CouponState.UnAvailable
        },
        grillTarsasag: {
            id: "2",
            type: "grillTarsasag",
            image: useCdnUrl("/images/staropramen/grill_tarsasag.svg"),
            imageWidth: "146",
            name: "Grill Társaság",

            description: "10%-os kedvezmény",
            state: CouponState.UnAvailable
        },
        rsButor: {
            id: "3",
            type: 'rsButor',
            image: useCdnUrl("/images/staropramen/RS_Butor.webp"),
            imageWidth: "120",
            name: "RS Bútor",
            description: "10 000 Ft kedvezmény 100 000 Ft fölötti vásárlás esetén a megjelölt Andante termékekre",
            state: CouponState.UnAvailable
        },
    }
}

export const useDiscountCouponStore = defineStore("discountCouponStore", {
    state: (): { discountCoupons: CouponType[] } => {
        return {
            discountCoupons: []
        }
    },
    getters: {

    },
    actions: {
        async getAvailableDiscountCoupons() {
            const authStore = useAuthStore();
            //If the user is not logged in
            if (!authStore.isAuthenticated) {
                let out: Array<any> = [];
                const foodora = base().foodora;
                out.push(foodora);
                const grillTarsasag = base().grillTarsasag;

                out.push(grillTarsasag);

                const rsButor = base().rsButor;

                out.push(rsButor);

                this.discountCoupons = out;
                return out;
            }
            const res = await usePiniaFetch<{ [key: string]: any }>(
                `/endusermanagerms/profile/not-winner-coupon-available`,
                {
                    method: "GET",
                    //server: false,
                    headers: {
                        authorization: `Bearer ${authStore.accessToken}`,
                    }
                }
            );

            let out: Array<any> = [];
            const foodora = base().foodora;

            foodora.state = res.data.foodora;

            out.push(foodora);

            const grillTarsasag = base().grillTarsasag;
            grillTarsasag.state = res.data.grillTarsasag;

            out.push(grillTarsasag);

            const rsButor = base().rsButor;
            rsButor.state = res.data.rsButor;

            out.push(rsButor);

            this.discountCoupons = out;
        },

        async notWinnerCouponUse(type: string) {

            const authStore = useAuthStore();

            return await usePiniaFetch(
                `/endusermanagerms/profile/not-winner-coupon-use/${type}`,
                {

                    method: "POST",
                    headers: {
                        authorization: `Bearer ${authStore.accessToken}`,
                    },
                }
            );

        }

    },
});
