<template>
    <section id="jatek-menete">
        <div class="container">
            <div id="nyeremenyek" class="pt-5 pb-4">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <div class="py-9">
                            <h2 class="text-center">
                                Szeretnél nyerni is a szavazatoddal?
                            </h2>

                            <div class="row pt-4">
                                <div class="col-12 col-md-4">
                                    <div class="game-step">
                                        <img
                                            width="160"
                                            height="160"
                                            :src="
                                                useCdnUrl(
                                                    '/images/szentkiralyi/game-step-1.svg'
                                                )
                                            "
                                            alt=""
                                        />
                                        <div class="game-step-title">
                                            VÁSÁROLJ!*
                                        </div>
                                        <div class="game-step-description">
                                            Vegyél bármilyen
                                            <strong
                                                >Szentkirályi terméket</strong
                                            >
                                            a megadott időszakban.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="game-step">
                                        <img
                                            width="160"
                                            height="160"
                                            :src="
                                                useCdnUrl(
                                                    '/images/szentkiralyi/game-step-2.svg'
                                                )
                                            "
                                            alt=""
                                        />
                                        <div class="game-step-title">
                                            TÖLTSD FEL A KÓDOT<br />
                                            ÉS SZAVAZZ!
                                        </div>
                                        <div class="game-step-description">
                                            Tölts fel egy
                                            <strong>fotót</strong> a vásárlást
                                            igazoló
                                            <strong>blokkról&nbsp;</strong>
                                            <NuxtLink
                                                :to="
                                                    campaignDataStore.isRegistrationRequired &&
                                                    !authStore.isAuthenticated
                                                        ? useDynamicLink(
                                                              '/#user-consenting'
                                                          )
                                                        : useDynamicLink(
                                                              '/#kodfeltoltes'
                                                          )
                                                "
                                                >itt az oldalon</NuxtLink
                                            >,
                                            <strong
                                                >majd válassz egy
                                                sportolót</strong
                                            >, akit támogatni szeretnél, és
                                            <strong>szavazz</strong> rá.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="game-step">
                                        <img
                                            width="160"
                                            height="160"
                                            :src="
                                                useCdnUrl(
                                                    '/images/szentkiralyi/game-step-3.svg'
                                                )
                                            "
                                            alt=""
                                        />
                                        <div class="game-step-title">
                                            NYERJ!
                                        </div>
                                        <div class="game-step-description">
                                            Ha szerencséd van, tied lehet a
                                            fődíj, egy
                                            <strong>SsangYong Torres</strong>.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row prize">
                                <div
                                    class="col-12 col-md-8 order-2 order-md-1 mt-4 mt-md-auto text-center text-md-start"
                                >
                                    * Vásárolj egy vásárlás alkalmával bármilyen
                                    Magyarországi üzletben bármilyen kiszerelésű
                                    Szentkirályi Ásványvizet. Töltsd fel az
                                    AP-kódot a honlapon keresztül. Szavazz a
                                    kedvenc tehetségedre, hogy egy SsangYong
                                    Torres modelt nyerhess!
                                </div>
                                <div
                                    class="col-12 col-md-4 order-1 order-md-2 text-center text-md-start"
                                >
                                    <img
                                        style="max-width: 302px; width: 100%"
                                        :src="
                                            useCdnUrl(
                                                '/images/szentkiralyi/ssang-yong-torres.webp'
                                            )
                                        "
                                        alt=""
                                    />
                                </div>
                                <div class="col-12 order-3 text-center">
                                    <a
                                        class="btn btn-szentkiralyi mt-5"
                                        href="https://www.ssangyong.hu/autoink/modellek/?caru-showroomModel=torres"
                                        target="_blank"
                                    >
                                        Tudj meg többet az autóról
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <!-- <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only> -->
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import { useAuthStore } from "~~/store/user/auth";

const runtime = useRuntimeConfig();

/**
 * Props interface
 */
interface Props {
    section: IComponent<EComponents> & any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const campaignDataStore = useCampaignDataStore();
const authStore = useAuthStore();
</script>

<style lang="scss" scoped>
h2 {
    font-family: "Cocogoose";
    color: #2a3e86;
    font-size: 40px;
    font-weight: normal;
}

.prize {
    font-family: "Cocogoose";
    font-size: 14px;
    line-height: 24px;
    margin-top: 32px;
    color: #2a3e86;
}

.game-step {
    margin-top: 32px;
    font-family: "Cocogoose";
    color: #2a3e86;
    text-align: center;

    .game-step-title {
        color: #e3007e;
        font-size: 32px;
        line-height: 40px;
        font-weight: bold;
        margin-top: 32px;
    }

    .game-step-description {
        font-size: 20px;
        margin-top: 8px;

        a {
            color: #e3007e;
        }
    }
}

.btn-szentkiralyi {
    font-family: "Montserrat";
    padding: 9px 24px;
    color: white;
    background-color: #e3007e;

    &:hover {
        color: white;
        background-color: #2a3e86;
    }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
    .prize {
        margin-top: 0px;
    }
}

@media (min-width: 992px) {
    .prize {
        margin-top: -48px;
    }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
</style>
