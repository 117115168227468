import { defineStore } from "pinia";
import moment from "moment";
import { EPurchaseType } from "~/enums/Campaign/EPurchaseType";
import { useCampaignDataStore } from "~/store/campaign/campaignData";
import { usePurchasedPromotionProductStore } from "@/store/components/purchasedPromotionProduct/purchasedPromotionProduct";
import { useAuthStore } from "~~/store/user/auth";
import { ECodeUploadErrorType } from "~~/enums/Campaign/ECodeUploadErrorType";

export interface DynamicField {
    id: number;
    campaignUuid: string;
    entryType: string;
    type: string;
    name: string;
    default: string;
    options: string[];
    validation: string[];
    order: number;
}

interface CodeUploadErrorDialog {
    apCodeConflict: boolean;
    aPCodeExhousted: boolean;
    aPCodeShortTimeGate: boolean;
    aPCodeConflictItself: boolean;
    couponCodeNotExist: boolean;
    couponCodeConflict: boolean;
    couponCodeConflictItself: boolean;
    notActivatedUser: boolean;
}

interface CodeUploadSuccessDialog {
    apCodeNotWin: boolean;
    successfulCodeUpload: boolean;
}
interface State {
    codeUploadForm: { [key: string]: any };
    dynamicFiledByType: { [key: string]: DynamicField[] };
    dynamicForm: any;
    dynamicFieldList: Array<any>;
    receiptsImagesRaw: any;
    codeUploadError: CodeUploadErrorDialog;
    codeUploadSuccess: CodeUploadSuccessDialog;
}

export const useCodeUploadStore = defineStore("codeUpload", {
    state: (): State => {
        return {
            codeUploadForm: {
                types: [],
                type: EPurchaseType.AP_CODE,
                apCode: "",
                dateOfPurchase: "",
                timeOfPurchase: "",
                receiptNumber: "",
                invoiceNumber: "",
                couponCode: "",
                submitButtonClicked: false,
                dynamicFormIsLoading: false,
            },
            dynamicFiledByType: {},
            dynamicForm: {} as any,
            dynamicFieldList: [] as Array<any>,
            receiptsImagesRaw: undefined as any,
            codeUploadError: {
                apCodeConflict: false,
                aPCodeExhousted: false,
                aPCodeShortTimeGate: false,
                aPCodeConflictItself: false,
                couponCodeNotExist: false,
                couponCodeConflict: false,
                couponCodeConflictItself: false,
                notActivatedUser: false,
            },
            codeUploadSuccess: {
                apCodeNotWin: false,
                successfulCodeUpload: false,
            },
        };
    },
    getters: {
        getDynamicFiledByType(state) {
            return (type: EPurchaseType): DynamicField[] => {
                return state.dynamicFiledByType[type] ?? [];
            };
        },
        userId() {
            const authStore = useAuthStore();

            return authStore.user ? authStore.user.id : undefined;
        },
        hasDynamicField(state) {
            return (fieldType: string) => {
                return (
                    state.dynamicFieldList.findIndex(
                        (field: any) => field.type === fieldType
                    ) != -1
                );
            };
        },
        dynamicField(state) {
            return (fieldType: string, type?: any) => {
                const campaignDataStore = useCampaignDataStore();
                return (
                    state.dynamicFieldList.find(
                        (field: any) => field.type === fieldType
                    ) ?? null
                );
            };
        },
    },
    actions: {
        /**
         * Code upload
         *
         * @returns
         */
        async codeUpload(codeUploadData: any) {
            const PurchasedPromotionProductStore =
                usePurchasedPromotionProductStore();

            let rawImages, promises: Array<Promise<any>>, images;
            this.codeUploadForm.submitButtonClicked = true;

            if (
                this.hasDynamicField("receipt-image") &&
                this.dynamicField("receipt-image")?.validation.includes(
                    "required"
                ) &&
                !this.receiptsImagesRaw
            ) {
                return;
            } else {
                if (
                    this.dynamicField("receipt-image")?.validation.includes(
                        "required"
                    )
                ) {
                    promises = [];
                    this.receiptsImagesRaw.forEach((formDataPart: any) => {
                        promises.push(this.uploadImage(formDataPart));
                    });
                    rawImages = await Promise.all(promises);
                    images = rawImages.map((res) => {
                        return res.file.filename;
                    });
                    console.log("images", images);
                    this.dynamicForm.receiptImage = images;
                }
            }

            const isApCode = Boolean(
                codeUploadData.type === EPurchaseType.AP_CODE
            );
            const isOnlinePurchase = Boolean(
                codeUploadData.type === EPurchaseType.ONLINE
            );
            const isCouponCode = Boolean(
                codeUploadData.type === EPurchaseType.COUPON_CODE
            );

            const selectedPromotions: any[] = [];
            PurchasedPromotionProductStore.selectedPromoProducts.forEach(
                (item: any) => {
                    selectedPromotions.push({
                        id: item.id,
                        quantity: item.quantity,
                    });
                }
            );

            const timeOfPurchase =
                codeUploadData.timeOfPurchase.substring(0, 2) +
                ":" +
                codeUploadData.timeOfPurchase.substring(
                    3,
                    codeUploadData.timeOfPurchase.length
                );

            const payload: { [key: string]: any } = {
                firstName: codeUploadData.firstName,
                lastName: codeUploadData.lastName,
                email: codeUploadData.email,
                placeOfPurchase: codeUploadData.placeOfPurchase,
                timeOfPurchase: isApCode
                    ? moment(
                          `${codeUploadData.dateOfPurchase} ${timeOfPurchase}`
                      ).format("YYYY-MM-DD HH:mm:ss")
                    : isOnlinePurchase
                    ? moment(`${codeUploadData.dateOfPurchase}`).format(
                          "YYYY-MM-DD"
                      )
                    : "",
                type: codeUploadData.type,
                code: isApCode
                    ? codeUploadData.apCode
                    : isCouponCode
                    ? codeUploadData.couponCode
                    : "",
                edmSubscription: Number(codeUploadData.marketing),
                receiptNumber: !isOnlinePurchase
                    ? codeUploadData.receiptNumber
                    : "",
                invoiceNumber: isOnlinePurchase
                    ? codeUploadData.invoiceNumber
                    : "",
                transactionNumber: !isOnlinePurchase
                    ? codeUploadData.transactionNumber
                    : "",
                navCode: !isOnlinePurchase ? codeUploadData.navCode : "",
                promotionIds: selectedPromotions,
                receiptImage: this.dynamicForm.receiptImage ?? [],
                dynamicFields: {
                    weeklyPrize: codeUploadData.weeklyPrize,
                },
            };

            if (this.userId) {
                payload["userId"] = this.userId;
            }

            const campaignDataStore = useCampaignDataStore();

            return await useApiFetch(
                `endusermanagerms/code/upload-v2/${campaignDataStore.campaignUUId}`,
                {
                    key:
                        "code-upload" +
                        campaignDataStore.campaignUUId +
                        "/" +
                        this.userId +
                        "/" +
                        Math.random(),
                    method: "POST",
                    body: payload,
                }
            ).then((response) => {
                this.codeUploadForm.submitButtonClicked = false;

                return response;
            });
        },

        /**
         * Handle code upload errors
         */
        codeUploadErrorHandle(errorType: ECodeUploadErrorType) {
            if (errorType == ECodeUploadErrorType.AP_CODE_CONFLICT) {
                this.codeUploadError.apCodeConflict = true;
            } else if (errorType == ECodeUploadErrorType.AP_CODE_EXHAUSTED) {
                this.codeUploadError.aPCodeExhousted = true;
            } else if (errorType == ECodeUploadErrorType.NOT_ACTIVATED_USER) {
                this.codeUploadError.notActivatedUser = true;
            } else if (
                errorType == ECodeUploadErrorType.AP_CODE_SHORT_TIME_GATE
            ) {
                this.codeUploadError.aPCodeShortTimeGate = true;
            } else if (
                errorType == ECodeUploadErrorType.AP_CODE_CONFLICT_ITSELF
            ) {
                this.codeUploadError.aPCodeConflictItself = true;
            } else if (
                errorType == ECodeUploadErrorType.COUPON_CODE_NOT_EXISTS
            ) {
                this.codeUploadError.couponCodeNotExist = true;
            } else if (errorType == ECodeUploadErrorType.COUPON_CODE_CONFLICT) {
                this.codeUploadError.couponCodeConflict = true;
            } else if (
                errorType == ECodeUploadErrorType.COUPON_CODE_CONFLICT_ITSELF
            ) {
                this.codeUploadError.couponCodeConflictItself = true;
            }
        },

        async updateReceipts(receipts: any) {
            this.receiptsImagesRaw = receipts;
        },
        async uploadImage(imageFile: any) {
            const campaignDataStore = useCampaignDataStore();
            const campaignUuid = await campaignDataStore.obtainCampaignUUId();
            const campaignId = await campaignDataStore.obtainCampaignId();

            const formData = new FormData();

            formData.append("file", imageFile);
            formData.append("public", 0 as any);
            formData.append("campaignId", campaignId);
            formData.append("campaignUuid", campaignUuid);

            const { data, error } = await useApiFetch(
                `/imagemanagerms/upload`,
                {
                    key: "receipt-image-upload" + Math.random(),
                    method: "POST",
                    body: formData,
                }
            );

            return Promise.resolve(data.value);
        },
        async getDynamicFieldsByType(type: EPurchaseType) {
            const campaignDataStore = useCampaignDataStore();
            this.codeUploadForm.dynamicFormIsLoading = true;

            const res = await usePiniaFetch<{ dynamicFields: DynamicField[] }>(
                `/drawms/dynamic-field/${
                    campaignDataStore.campaignUUId
                }/entryType/${type.toLowerCase()}`,
                {
                    method: "GET",
                }
            );

            this.dynamicFiledByType[type] = res.dynamicFields;
            this.codeUploadForm.dynamicFormIsLoading = false;
        },
        async getDynamicFields() {
            const campaignDataStore = useCampaignDataStore();

            const res = await usePiniaFetch<{ dynamicFields: DynamicField[] }>(
                `/drawms/dynamic-field/${campaignDataStore.campaignUUId}`,
                {
                    method: "GET",
                }
            );

            const desiredOrder = [
                EPurchaseType.AP_CODE,
                EPurchaseType.ONLINE,
                EPurchaseType.COUPON_CODE,
            ];
            let dynamicFields = res.dynamicFields ?? [];

            this.codeUploadForm.types = dynamicFields
                .map((item) => ({
                    label: `${
                        item.entryType.toUpperCase() === EPurchaseType.AP_CODE
                            ? "Személyesen"
                            : item.entryType.toUpperCase() ===
                              EPurchaseType.ONLINE
                            ? "Online"
                            : item.entryType.toUpperCase() ===
                              EPurchaseType.COUPON_CODE
                            ? "Sorsjegy kód"
                            : ""
                    }`,
                    value: item.entryType.toUpperCase(),
                }))
                .filter(
                    (item, index, self) =>
                        index === self.findIndex((t) => t.value === item.value)
                )
                .sort((a: any, b: any) => {
                    return (
                        desiredOrder.indexOf(a.value) -
                        desiredOrder.indexOf(b.value)
                    );
                });

            if (this.codeUploadForm.types?.length) {
                this.codeUploadForm.type = this.codeUploadForm.types[0].value;
            }
        },
        createDynamicFieldFormItems() {
            const typeCounters: any = {};

            const toCamelCase = (formItemName: string) => {
                return formItemName.replace(/-./g, (match) =>
                    match.charAt(1).toUpperCase()
                );
            };

            this.dynamicFieldList.forEach((formItem: any) => {
                const type = toCamelCase(formItem.type);

                if (!typeCounters[type]) {
                    typeCounters[type] = 0;
                }

                const key =
                    typeCounters[type] === 0
                        ? type
                        : `${type}${typeCounters[type]}`;

                if (type === "receiptImage" || type === "purchasedProducts") {
                    this.dynamicForm[key] = [];
                } else {
                    this.dynamicForm[key] = "";
                }

                typeCounters[type]++;
            });

            return this.dynamicForm;
        },
    },
});
