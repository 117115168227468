<template>
    <section>
        <div :style="backgroundStyle">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6 offset-lg-3 pt-5 mb-5">
                        <div :style="shippingAddressFormStyle">
                            <h2 class="fw-900 mt-2" :style="sectionTitleStyle" v-if="section.sectionTitleRaw" v-html="section.sectionTitleRaw">
                            </h2>

                            <div :style="sectionParagraphStyle" v-if="section.sectionParagraphRaw" v-html="section.sectionParagraphRaw">
                            </div>

                            <ShippingAddressForm
                                class="mt-4"
                                :component="section.components"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { InputInstance, UploadFiles } from "element-plus";
import { getStyle } from "../../composables/styleHelper";
import { Camera, Minus } from "@element-plus/icons-vue";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["change", "missedReceipt"]);

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "shippingAddressBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "shippingAddressTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "shippingAddressParagraph");
});
const shippingAddressFormStyle = computed(() => {
    return getStyle(
        props.section.components[0].style,
        "shippingAddressFormBackground"
    );
});
</script>

<style lang="scss"></style>
