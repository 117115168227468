import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";

/**
 * Get component style
 * @param componentStyle
 * @param belongsTo
 * @returns
 */
export const getStyle = (
    componentStyle: Array<any>,
    belongsTo?: string
): any => {
    let style: { [key: string]: string } = {};
    try {
        componentStyle?.forEach((item) => {
            if (item.property === EProperty.FONT_FAMILY && item.value) {
                style[item.property] = `dynamic-font-${item.value}`;
            } else if (
                (item.belongsTo === belongsTo && item.state === "default") ||
                (item.belongsTo === belongsTo && item.state === "hover") ||
                (item.belongsTo === "" && item.state === "default")
            ) {
                style[item.property] = `${item.value}${
                    item.unit ? item.unit : ""
                }`;
            }
        });
    } catch (e) {
        console.error("Error getStyle:", e);
    }

    return style;
};

/**
 * Set HTMLElement style
 * @param {MouseEvent} event
 * @param {Array<any>} style
 */
export const setStyle = (event: MouseEvent, style: Array<any>) => {
    const target = event.target as HTMLElement;

    Object.keys(style).forEach((prop: any) => {
        if (style !== undefined) {
            target.style[prop] = style[prop];
        }
    });
};

/**
 * Get contrast
 * @param hexColor
 * @returns
 */
export const getContrast = (hexColor: string): string => {
    const color = hexColor.replace("#", ""),
        r = parseInt(color.substr(0, 2), 16),
        g = parseInt(color.substr(2, 2), 16),
        b = parseInt(color.substr(4, 2), 16),
        brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness >= 180 ? "black" : "white";
};
