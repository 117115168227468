<template>
    <div class="vote-alert mt-7"
        :class="{
            'minion-color': campaignDataStore.campaignUUId == '118ef046-ee9f-4d37-9062-7788cd336c2d'
        }"
    >
        <div class="icon-bg">
            <img
                :src="useCdnUrl('/images/szentkiralyi/info.svg')"
                alt="Info icon"
            />
        </div>
        <div>{{ props.message }}</div>
    </div>
</template>

<script setup lang="ts">

import { useCampaignDataStore } from "~~/store/campaign/campaignData";
const campaignDataStore = useCampaignDataStore();

interface Props {
    message: string;
}
const props = defineProps<Props>();
</script>

<style lang="scss" scoped>
.vote-alert {
    display: flex;
    align-items: center;
    background-color: #ffc9e6;
    border: 2px solid #e3007e;
    color: #2a3e86;
    width: 100%;
    max-width: 497px;
    padding: 16px 24px;
    border-radius: 16px;
    margin: 0 auto;
    gap: 16px;
    font-size: 14px;
    text-align: left !important;

    .icon-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 48px;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        background-color: #ffffff30;
    }

    &.minion-color{
        background-color: rgb(14, 52, 86);
        border: 0px solid grey;
        color:rgb(255, 255, 255);
        .icon-bg{
            img {
                filter: invert(11%) sepia(2%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(0%);
            }
        }
    }
}
</style>
