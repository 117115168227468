<template>
    <el-form id="shippingAddressForm" ref="shippingAddressFormRef" :style="shippingAddressFormMergedStyle"
        :model="shippingAddressForm" :rules="rules" label-position="top" status-icon @keydown.enter.prevent="submit()">
        <div>
            <el-form-item ref="shippingNameRef" prop="shippingName" :label="inputShippingNameTexts?.label">
                <el-input v-model="shippingAddressForm.shippingName"></el-input>
            </el-form-item>
            <div class="row">
                <div class="col-xs-12 col-lg-4">
                    <el-form-item ref="postcodeRef" prop="postcode" :label="inputPostCodeTexts?.label">
                        <el-input maxlength="4" v-model="shippingAddressForm.postcode"></el-input>
                    </el-form-item>
                </div>
                <div class="col-xs-12 col-lg-8">
                    <el-form-item ref="settlementRef" prop="settlement" :label="inputCityTexts?.label">
                        <el-input v-model="shippingAddressForm.settlement"></el-input>
                    </el-form-item>
                </div>
            </div>
            <el-form-item ref="streetAddressRef" prop="streetAddress" :label="inputStreetAddressTexts?.label">
                <el-input v-model="shippingAddressForm.streetAddress"></el-input>
            </el-form-item>

            <div class="el-form-item position-relative">
                <label class="el-form-item__label float-none">
                    {{ inputPhoneTexts?.label }}
                </label>
                <div>
                    <client-only>
                        <vue-tel-input @input="handleChangeTelInput" @validate="checkPhoneIsValid"
                            v-model="shippingAddressForm.phoneNumber" :validCharactersOnly="true" :inputOptions="{
                                placeholder: 'pl.: 20 123 4567',
                                maxlength: 14,
                            }" defaultCountry="HU" :autoDefaultCountry="false">
                            <template #arrow-icon>
                                <el-icon :size="16">
                                    <ArrowDown />
                                </el-icon>
                            </template>
                        </vue-tel-input>
                    </client-only>
                    <span class="phone-validation">
                        <el-icon v-if="phone.phoneIsValid" class="el-icon-circle-check" :size="18">
                            <CircleCheck />
                        </el-icon>
                        <el-icon v-if="!phone.phoneIsValid" class="el-icon-circle-close" :size="18">
                            <CircleClose />
                        </el-icon>
                    </span>
                </div>
            </div>

            <el-form-item ref="shippingCommentRef" prop="shippingComment" :label="inputMessageTexts?.label">
                <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 16 }"
                    v-model="shippingAddressForm.shippingComment">
                </el-input>
            </el-form-item>
        </div>

        <div class="text-center">
            <el-button type="primary" :class="[
                'submit-btn',
                {
                    'is-disabled':
                        shippingAddressForm.submitBtnClicked ||
                        !shippingAddressFormIsValid,
                },
            ]" :loading="shippingAddressForm.submitBtnClicked" v-on:click="submit()" @keyup.space="submit()">
                Adatok küldése
            </el-button>

            <el-alert style="margin-top: 20px;" v-if="errorMessage !== ''" type="warning">{{ errorMessage }}</el-alert>
        </div>
        <div class="text-center">
            <div v-if="shippingAddressForm.submitBtnClicked && !hasRecipeImage" class="text-danger mt-3">
                Kérjük töltsd fel a blokk képét
            </div>
        </div>
    </el-form>
</template>

<script setup lang="ts">
import {
    ElForm,
    ElFormItem,
    ElInput,
    ElCheckbox,
    FormItemInstance,
} from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { ArrowDown, CircleCheck, CircleClose } from "@element-plus/icons-vue";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { EShippingAddressFormItem } from "~~/enums/PrizificPageBuilder/EShippingAddressFormItem";
import { getStyle } from "../../composables/styleHelper";

import { useShippingFromStore } from "@/store/components/shippingFrom/shippingFrom";

const shippingFromStore = useShippingFromStore();
const router = useRouter();
interface Props {
    component: any;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["change", "submit"]);

const shippingAddressFormRef = ref<FormInstance>();
const shippingNameRef = ref<FormItemInstance>();
const postcodeRef = ref<FormItemInstance>();
const settlementRef = ref<FormItemInstance>();
const streetAddressRef = ref<FormItemInstance>();
const shippingCommentRef = ref<FormItemInstance>();

const phone = reactive({
    shippingPhone: {},
    phoneIsValid: false,
});

const errorMessage = ref('');

const shippingAddressForm = reactive({
    shippingName: "",
    postcode: "",
    settlement: "",
    streetAddress: "",
    phoneNumber: "",
    shippingComment: "",
    submitBtnClicked: false,
});

const rules = reactive<FormRules>({
    shippingName: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "change",
        },
    ],
    postcode: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "change",
        },
        {
            pattern: /^[0-9]{4}$/,
            message: "Hiba az irányítószámban kérjük javítsd",
            trigger: "blur",
        },
    ],
    settlement: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "change",
        },
    ],
    streetAddress: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "change",
        },
    ],
    phoneNumber: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "input",
        },
    ],
});

const route = useRoute();

/**
 * Handle form submit
 */
async function submit() {
    shippingAddressForm.submitBtnClicked = true;
    errorMessage.value = "";
    await shippingAddressFormRef.value?.validate((valid, fields) => {
        if (valid) {
            emit("submit");
            shippingFromStore
                .uploadWinnerData(route.params.id as any, shippingAddressForm)
                .then(() => {
                    shippingAddressForm.submitBtnClicked = false;
                    try {
                        window.location.href = useDynamicLink("/koszonjuk")
                    } catch (e) {
                        console.warn("Some Warn:", e);
                        errorMessage.value = "Nem várt hiba történt!";
                    }
                })
                .catch((err) => {
                    console.log("Error", err);
                    shippingAddressForm.submitBtnClicked = false;
                    errorMessage.value = "Nem várt hiba történt, lehetséges hogy már feltölötted az adataidat!";
                });
        } else {
            console.log("error submit!", fields);
            shippingAddressForm.submitBtnClicked = false;
        }
    });
}

/**
 * Handle tel input change
 */
function handleChangeTelInput(number: any, phoneObject: any) {
    phone.shippingPhone = phoneObject;
}

/**
 * Check phone input is valid
 */
function checkPhoneIsValid(phoneObject: any) {
    phone.phoneIsValid = phoneObject.valid;
}

const hasRecipeImage = computed(() => {
    return shippingFromStore.receiptsImagesRaw;
});

/**
 * Check shipping address form is valid
 */
const shippingAddressFormIsValid = computed(() => {
    let formIsValid = false;
    let fields: any[] = [];

    Object.keys(shippingAddressForm).forEach((formItem: string) => {
        if (formItem == "shippingName") {
            shippingNameRef.value?.validateState !== undefined
                ? shippingNameRef.value?.validateState
                : "";
        } else if (formItem == "postcode") {
            fields.push(
                postcodeRef.value?.validateState !== undefined
                    ? postcodeRef.value?.validateState
                    : ""
            );
        } else if (formItem == "settlement") {
            fields.push(
                settlementRef.value?.validateState !== undefined
                    ? settlementRef.value?.validateState
                    : ""
            );
        } else if (formItem == "streetAddress") {
            fields.push(
                streetAddressRef.value?.validateState !== undefined
                    ? streetAddressRef.value?.validateState
                    : ""
            );
        } else if (formItem == "phoneNumber") {
            fields.push(phone.phoneIsValid ? phone.phoneIsValid : "");
        }
        // else if (formItem == "shippingComment") {
        //     fields.push(
        //         shippingCommentRef.value?.validateState !== undefined
        //             ? shippingCommentRef.value?.validateState
        //             : ""
        //     );
        // }
    });

    if (shippingFromStore.prize?.imageRequiredOnUpload) {
        fields.push(shippingFromStore.receiptsImagesRaw ? true : "");
    }


    const errors = fields.filter((field: any) => {
        return field == "error" || field == "";
    });

    formIsValid = !Boolean(errors.length);

    return formIsValid;
});

// Computed form and form items style
const shippingAddressFormItemLabelStyle = computed(() => {
    return getStyle(props.component[0].style, "shippingAddressFormItemLabel");
});
const shippingAddressFormItemInputStyle = computed(() => {
    return getStyle(props.component[0].style, "shippingAddressFormItemInput");
});
const shippingAddressFormCheckboxStyle = computed(() => {
    return getStyle(props.component[0].style, "shippingAddressFormCheckbox");
});
const shippingAddressFormSubmitButtonStyle = computed(() => {
    return getStyle(
        props.component[0].style,
        "shippingAddressFormSubmitButton"
    );
});
const shippingAddressFormSubmitButtonHoverStyle = computed(() => {
    return getStyle(
        props.component[0].style,
        "shippingAddressFormSubmitButtonHover"
    );
});

const shippingAddressFormMergedStyle = computed(() => {
    return {
        "--shipping-address-form-label-color":
            shippingAddressFormItemLabelStyle.value[EProperty.COLOR],
        "--shipping-address-form-font-size":
            shippingAddressFormItemLabelStyle.value[EProperty.FONT_SIZE],
        "--shipping-address-form-input-border-radius":
            shippingAddressFormItemInputStyle.value[EProperty.BORDER_RADIUS],
        "--shipping-address-form-checkbox-active-color":
            shippingAddressFormCheckboxStyle.value[EProperty.COLOR],
        "--shipping-address-form-checkbox-background-color":
            shippingAddressFormCheckboxStyle.value[EProperty.BACKGROUND_COLOR],
        "--shipping-address-form-checkbox-border-radius":
            shippingAddressFormCheckboxStyle.value[EProperty.BORDER_RADIUS],
        "--shipping-address-form-submit-color":
            shippingAddressFormSubmitButtonStyle.value[EProperty.COLOR],
        "--shipping-address-form-submit-font-size":
            shippingAddressFormSubmitButtonStyle.value[EProperty.FONT_SIZE],
        "--shipping-address-form-submit-background-color":
            shippingAddressFormSubmitButtonStyle.value[
            EProperty.BACKGROUND_COLOR
            ],
        "--shipping-address-form-submit-border-radius":
            shippingAddressFormSubmitButtonStyle.value[EProperty.BORDER_RADIUS],
        "--shipping-address-form-submit-color-hover":
            shippingAddressFormSubmitButtonHoverStyle.value[EProperty.COLOR],
        "--shipping-address-form-submit-background-color-hover":
            shippingAddressFormSubmitButtonHoverStyle.value[
            EProperty.BACKGROUND_COLOR
            ],
    };
});

// Computed form items texts
const inputShippingNameTexts = computed(() => {
    return getFormItemText(props.component[0], EShippingAddressFormItem.NAME);
});
const inputPostCodeTexts = computed(() => {
    return getFormItemText(
        props.component[0],
        EShippingAddressFormItem.ZIP_CODE
    );
});
const inputCityTexts = computed(() => {
    return getFormItemText(props.component[0], EShippingAddressFormItem.CITY);
});
const inputStreetAddressTexts = computed(() => {
    return getFormItemText(props.component[0], EShippingAddressFormItem.STREET);
});
const inputPhoneTexts = computed(() => {
    return getFormItemText(props.component[0], EShippingAddressFormItem.PHONE);
});
const inputMessageTexts = computed(() => {
    return getFormItemText(
        props.component[0],
        EShippingAddressFormItem.MESSAGE_FOR_THE_COURIER
    );
});
</script>

<style lang="scss">
#shippingAddressForm {
    .el-form-item__label {
        color: var(--shipping-address-form-label-color) !important;
    }

    .el-input__wrapper {
        border-radius: var(--shipping-address-form-input-border-radius);
    }

    .el-textarea__inner {
        border-radius: var(--shipping-address-form-input-border-radius);
    }

    .el-input.is-active,
    .el-input__inner:focus {
        border-color: var(--shipping-address-form-input-focus-border-color);
    }

    .el-form-item.is-error .el-input__inner,
    .el-form-item.is-error .el-input__inner:focus,
    .el-form-item.is-error .el-textarea__inner,
    .el-form-item.is-error .el-textarea__inner:focus,
    .el-message-box__input input.invalid,
    .el-message-box__input input.invalid:focus {
        border-color: #e44f60;
    }

    .el-form-item__label::before {
        content: "" !important;
    }

    .el-form-item__error {
        color: white;
        background-color: #e44f60;
        border-radius: 4px;
        padding: 2px 4px;
        margin-top: 5px;
        right: 0;
        left: initial;

        &::before {
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #e44f60;
            width: 0;
            height: 0;
            display: block;
            content: "";
            top: -5px;
            right: 12px;
        }
    }

    .el-input__validateIcon {
        font-size: 18px;

        &.el-icon-circle-check {
            color: #67c23a;
        }

        &.el-icon-circle-close {
            color: #e44f60;
        }
    }

    .submit-btn {
        border: 0;
        white-space: normal;
        word-wrap: break-word;
        color: var(--shipping-address-form-submit-color);
        border-radius: var(--shipping-address-form-submit-border-radius);
        background-color: var(--shipping-address-form-submit-background-color);
        border: var(--shipping-address-form-submit-border-width) solid var(--shipping-address-form-submit-border-color);

        &:hover {
            color: var(--shipping-address-form-submit-color-hover);
            background-color: var(--shipping-address-form-submit-background-color-hover);
            border: var(--shipping-address-form-submit-border-width-hover) solid var(--shipping-address-form-submit-border-color-hover);
        }

        &.is-disabled {
            background: grey !important;
            color: white !important;
        }
    }

    .el-checkbox {
        display: flex;
        align-items: center;
        height: auto !important;
    }

    .el-checkbox__label {
        white-space: normal;
        word-wrap: break-word;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        color: var(--shipping-address-form-label-color) !important;

        p {
            margin-bottom: 0;
        }
    }

    .el-checkbox__inner {
        width: 24px;
        height: 24px;
        border-radius: 0.35rem;

        &::after {
            border-width: 3px;
            width: 5px;
            height: 12px;
            left: 7px;
            top: 2px;
            transition: initial;
            border-color: var(--shipping-address-form-checkbox-color) !important;
        }
    }

    .el-checkbox__input.is-checked+.el-checkbox__label {
        color: var(--shipping-address-form-label-color) !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: var(--shipping-address-form-checkbox-background-color) !important;
        border-color: var(--shipping-address-form-checkbox-background-color) !important;
    }

    .phone-validation {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 4px;
        bottom: 8px;
        z-index: 2;
        display: flex;
        align-items: center;

        .el-icon {
            &.el-icon-circle-check {
                color: #67c23a;
            }

            &.el-icon-circle-close {
                color: #e44f60;
            }
        }
    }

    .vue-phone-number-input {
        position: relative;
        z-index: 9;
        width: 100%;
        border-radius: 10px;

        .input-tel__input,
        .country-selector__input {
            border-color: #dcdfe6 !important;
            box-shadow: initial !important;
        }

        .input-tel__label,
        .country-selector__label {
            color: #0c2340 !important;
        }

        .text-danger {
            display: inline-block;
            color: white !important;
            border-radius: 4px;
            padding: 0 8px;
            font-size: 12px;
            bottom: -30px;
            top: initial;
            right: 0;
            left: initial;

            &::before {
                position: absolute;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid #e44f60;
                width: 0;
                height: 0;
                display: block;
                content: "";
                top: -5px;
                right: 12px;
            }
        }
    }

    .vue-tel-input {
        position: relative;
        z-index: 2;
        border-radius: var(--shipping-address-form-input-border-radius);
        background-color: white;
        border: 0;

        .vti__input {
            height: 38px;
            font-size: 16px;
            background-color: #fdfcf9;
            border-radius: 0 25px 25px 0;
            line-height: 1;
        }

        .vti__dropdown {
            &::before {
                content: "";
                position: absolute;
                width: 1px;
                height: 24px;
                right: 0;
                top: 50%;
                z-index: 2;
                background-color: #0f6f84;
                transform: translateY(-50%);
            }
        }
    }

    .vue-tel-input:focus-within {
        box-shadow: none;
        border-color: #0f6f84;
    }
}
</style>
