<template>
    <section class="vote overflow-hidden">
        <div id="szavazz" class="py-9" style="background-color: #f5d940">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2
                            class="text-center"
                            style="color: #0e3456"
                            v-html="section.sectionTitle"
                        ></h2>
                        <p
                            class="text-center"
                            style="color: #0e3456; font-size: 20px"
                            v-html="section.sectionParagraph"
                        ></p>

                        <VoteListDefault />
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            style="max-width: 1024px"
            width="92%"
            v-model="voteStore.modal"
            :show-close="false"
            destroy-on-close
            append-to-body
            center
        >
            <template #header="{ close }">
                <div
                    @click="close"
                    class="icon-close d-inline-block float-end mb-3"
                    style="color: #0e3456; margin-right: -16px"
                >
                    <el-icon :size="32">
                        <CircleClose />
                    </el-icon>
                </div>
            </template>
            <div>
                <div class="text-center">
                    <h3
                        class="text-center px-4 py-2 fw-bold"
                        style="color: #0e3456"

                        :class="{
                            'minion-color': campaignDataStore.campaignUUId == '118ef046-ee9f-4d37-9062-7788cd336c2d'
                        }"
                    >
                        Köszönjük, hogy szavaztál, várunk vissza holnap is!
                    </h3>
                </div>
            </div>
            <div class="text-center">
                <el-button
                    style="
                        background-color: #e3007e;
                        color: white;
                        border: 0 !important;
                    "
                    type="primary"
                    class="mt-3"
                    @click="voteStore.hideModal"

                    :class="{
                        'minion-color': campaignDataStore.campaignUUId == '118ef046-ee9f-4d37-9062-7788cd336c2d'
                    }"

                >
                    Bezárás
                </el-button>
            </div>
        </el-dialog>

        <!-- Prizific page builder tool -->
        <PrizificPageBuilderTool v-if="editable" :section="section" />
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { CircleClose } from "@element-plus/icons-vue";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import { useVoteStore, TalentsTab } from "~~/store/components/vote/vote";
import { useAuthStore } from "~~/store/user/auth";
import VoteList from "./VoteList.vue";

const runtimeConfig = useRuntimeConfig();
const campaignDataStore: any = useCampaignDataStore();
const voteStore = useVoteStore();
const authStore = useAuthStore();

interface Props {
    section: IComponent<EComponents> | any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

if (authStore.isAuthenticated) {
    voteStore.getVoted();
} else {
    voteStore.votePercentage();
}

onMounted(() => {
    if (
        campaignDataStore.campaignUUId == runtimeConfig.szentkiralyiCampaignUUId
    ) {
        voteStore.getVoted();
        voteStore.votePercentage();
    }

    if (authStore.isAuthenticated) {
        voteStore.getVoted();
    } else {
        voteStore.votePercentage();
    }
});
</script>

<style lang="scss" scoped>

h3.minion-color{
    color:#0e3456 !important;
}

button.minion-color{
    background-color:#0e3456 !important;
    color:white;
    border-radius: 20px;
}

.vote {
    h2 {
        font-family: "HelveticaNeue";
        font-weight: 700;
        font-size: 24px;

        @media (min-width: 576px) {
            font-size: 30px;
        }

        @media (min-width: 768px) {
            font-size: 40px;
        }

        @media (min-width: 992px) {
            font-size: 80px;
        }

        @media (min-width: 1200px) {
            font-size: 90px;
        }

        @media (min-width: 1400px) {
            font-size: 90px;
        }
    }
}
</style>
