import Page from "~/entities/PrizificPageBuilder/Page";

/**
 * Help to wrap a page to setting up
 */
export abstract class SubPageSetup {

    protected abstract page: Page<any>;
    protected name: string = "Unnamed";

    getPage() { }
    /**
     * Return with the  given page components
     */
    getPageComponents() {
        return this.page.getComponents();
    }

    getName() {
        return this.name;
    }

    getPageType() {
        return this.page.getType()
    }
}