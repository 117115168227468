import Page from "~/entities/PrizificPageBuilder/Page";

import { navbar } from "~/entities/pages/shared/sections/Navbar/Navbar";
import { contact } from "~/entities/pages/shared/sections/Contact/Contact";
import { footer } from "~/entities/pages/shared/sections/Footer/Footer";
import { SubPageSetup } from "./SubPageSetup";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { cookieSettings } from "~/entities/pages/shared/sections/CookieSettings/CookieSettings";
import { uploadedCodes } from "~/entities/pages/uploaded-codes/UploadedCodes";

export class DefaultUploadedCodesPageSetup extends SubPageSetup {
    protected page;
    protected name = "Uploaded codes";

    constructor() {
        super();

        this.page = new Page(
            "-",
            "Uploaded codes",
            EComponents.UPLOADED_CODES_PAGE,
            [],
            "",
            [navbar, uploadedCodes, contact, footer, cookieSettings],
            {}
        );
    }
}
