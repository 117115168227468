<template>
    <section id="kedvezmenykuponok">
        <div class="py-10" :style="sectionWrapperStyleObject">
            <div class="container">
                <h2 class="fw-bold text-center">Kedvezménykuponok</h2>
                <p class="text-center">
                    Amennyiben sikeresen feltöltöttél egy kódot, de egyik napi
                    nyereményt sem vitted el, választhatsz egy kedvezményes
                    vásárlásra jogosító kupont az alábbiak közül (minden
                    alkalommal egyet egészen addig, amíg mind a 3 kuponodat fel
                    nem használtad). A választást követően figyeld az e-mail
                    fiókodat, hiszen oda érkezik a kuponkódod és a tájékoztatás
                    a felhasználási feltételekről (keresd “A Staropramenjáték
                    kuponom“ tárgyú levelet).
                </p>

                <div class="row" v-if="discountCouponStore.discountCoupons">
                    <div
                        class="col-12 col-md-4 mt-6"
                        v-for="discountCoupon in discountCouponStore.discountCoupons"
                    >
                        <div
                            class="d-flex flex-column justify-content-center text-center mt-auto"
                        >
                            <div class="mb-3">
                                <img
                                    :style="{
                                        maxWidth: `${discountCoupon.imageWidth}px`,
                                        width: '100%',
                                    }"
                                    :src="discountCoupon.image"
                                    alt=""
                                />
                            </div>
                            <div class="fw-bold">
                                {{ discountCoupon.name }}
                            </div>
                            <div style="min-height: 64px">
                                {{ discountCoupon.description }}
                            </div>

                            <el-button
                                v-if="discountCoupon.state == 'available'"
                                style=""
                                class="d-inline-block mx-auto use-my-coupon"
                                @click="getMyCoupon(discountCoupon.type)"
                            >
                                Kérem a kuponom
                            </el-button>

                            <el-button
                                v-if="discountCoupon.state == 'unavailable'"
                                style=""
                                :disabled="true"
                                class="d-inline-block mx-auto use-my-coupon"
                                @click="getMyCoupon(discountCoupon.type)"
                            >
                                Kérem a kuponom
                            </el-button>

                            <el-button
                                v-if="discountCoupon.state == 'used'"
                                style=""
                                :disabled="true"
                                class="d-inline-block mx-auto use-my-coupon"
                                @click="getMyCoupon(discountCoupon.type)"
                            >
                                Felhasználva
                            </el-button>
                        </div>
                    </div>
                </div>

                <el-dialog
                    style="max-width: 1024px"
                    width="92%"
                    v-model="showModal"
                    :show-close="false"
                    destroy-on-close
                    append-to-body
                    center
                >
                    <template #header="{ close }">
                        <div
                            @click="close"
                            class="icon-close d-inline-block float-end mb-3"
                            style="color: #114634; margin-right: -16px"
                        >
                            <el-icon :size="32">
                                <CircleClose />
                            </el-icon>
                        </div>
                    </template>
                    <div>
                        <div class="text-center">
                            <h3
                                class="text-center px-4 py-2 fw-bold"
                                style="color: #114634"
                            >
                                Kuponkódodat és a kuponoddal kapcsolatos
                                információkat e-mailben elküldtük.
                            </h3>
                        </div>
                    </div>
                    <div class="text-center">
                        <el-button
                            style="
                                background-color: #114634;
                                color: #faf1d1;
                                border: 0 !important;
                            "
                            type="primary"
                            class="mt-3"
                            @click="showModal = false"
                        >
                            Bezárás
                        </el-button>
                    </div>
                </el-dialog>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { ITimeInterval } from "~~/interfaces/PrizificPageBuilder/ITimeInterval";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { IProperty } from "~~/interfaces/PrizificPageBuilder/IProperty";
import DiscountCoupons from "~~/entities/PrizificPageBuilder/sections/DiscountCoupons";
import { CircleClose } from "@element-plus/icons-vue";
import { useDiscountCouponStore } from "~~/store/components/discountCoupon/discountCoupon";

const campaignDataStore = useCampaignDataStore();

/**
 * Props interface
 */
interface Props {
    section: IComponent<EComponents> & any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const showModal = ref<boolean>(false);
const discountCouponStore = useDiscountCouponStore();

onMounted(async () => {
    await discountCouponStore.getAvailableDiscountCoupons();
});

/**
 * Create time section wrapper style object
 * @returns {Object} styleObject
 */
const sectionWrapperStyleObject = computed(() => {
    try {
        return getStyle(props.section.style, "discountCouponsSection");
    } catch (e) {
        console.log("e", e);
    }
    return "";
});

let couponUseHasError = ref<boolean>(false);
const pending = ref<boolean>(false);

async function getMyCoupon(type: string) {
    pending.value = true;

    try {
        const res = await discountCouponStore.notWinnerCouponUse(type);
        console.log("Res", res.data);
        if (res.error) {
            couponUseHasError.value = true;

            return;
        }

        if (res.data) {
            showModal.value = true;
        }

        pending.value = false;
    } catch (e) {
        console.error("Error while using coupon", e);
        pending.value = false;
    }

    await discountCouponStore.getAvailableDiscountCoupons();
}
</script>

<style lang="scss" scoped>
.use-my-coupon {
    border-color: #114634;
    background-color: #114634;
    color: #faf1d1;

    &:disabled {
        border-color: rgb(138, 138, 138);
        background-color: rgb(138, 138, 138);
    }
}
</style>
