<template>
    <div class="toggle-button">
        <div class="d-flex flex-row align-items-center">
            <div
                v-for="(item, index) in props.buttons"
                :key="index"
                class="flex-fill w-100 px-0"
            >
                <div
                    :class="[
                        'toggle-button__item text-center fw-bold',
                        { active: item.value == modelValue },
                    ]"
                    @click="setActiveButton(item.value)"
                >
                    {{ item.label }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
interface Props {
    buttons: Array<any>;
    modelValue: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue"]);

/**
 * Set active button
 */
const setActiveButton = (clickedButton: string) => {
    emit("update:modelValue", clickedButton);
};
</script>

<style lang="scss" scoped>
.toggle-button {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    width: 100%;
    min-height: 30px;
    overflow: hidden;
    border: 2px solid var(--toggle-button-border-color);
    background-color: var(--toggle-button-background-color);
    border-radius: calc(var(--toggle-button-border-radius) + 0.25rem);

    &__item {
        line-height: 30px;
        color: var(--toggle-button-color) !important;
        border-radius: 0;

        &.active {
            color: var(--toggle-button-active-color) !important;
            background-color: var(
                --toggle-button-active-background-color
            ) !important;
        }
    }
}
</style>
