<template>
    <div :style="editPasswordFormStyle">
        <div class="row">
            <div class="col-12 col-md-4 offset-md-4">
                <el-form
                    id="editPasswordForm"
                    ref="editPasswordFormRef"
                    :style="editPasswordFormMergedStyle"
                    :model="editPasswordForm"
                    :rules="rules"
                    label-position="top"
                    @keyup.enter.prevent="submitEditPasswordForm"
                    status-icon
                >
                    <el-form-item prop="oldPassword" label="Régi jelszó">
                        <el-input
                            type="password"
                            v-model="editPasswordForm.oldPassword"
                            show-password
                        />
                    </el-form-item>
                    <el-form-item prop="password" label="Új jelszó">
                        <PasswordInput v-model="editPasswordForm.password" />
                    </el-form-item>

                    <div class="text-center">
                        <el-button
                            class="submit-btn"
                            @click="submitEditPasswordForm"
                            @keyup.space="submitEditPasswordForm"
                            :loading="editPasswordForm.submitButtonClicked"
                        >
                            Mentés
                        </el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    ElForm,
    ElFormItem,
    ElInput,
    ElCheckbox,
    ElNotification,
} from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { useLoginRegistrationStore } from "~~/store/components/loginRegistration/loginRegistration";

interface Props {
    component: any;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["change"]);

const editPasswordFormRef = ref<FormInstance>();
const editPasswordComponent = props.component[0];

const editPasswordForm = reactive({
    oldPassword: "",
    password: "",
    submitButtonClicked: false,
});

const rules = reactive<FormRules>({
    password: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
        {
            required: true,
            pattern: /^(?=.{6,})(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*\d+).*$/,
            message: "Hiba a jelszóba kérjük javítsd",
            trigger: "change",
        },
    ],
});

// Computed form and form items style
const editPasswordFormStyle = computed(() => {
    return getStyle(editPasswordComponent.style, "editPasswordFormBackground");
});
const editPasswordFormItemLabelStyle = computed(() => {
    return getStyle(editPasswordComponent.style, "editPasswordFormItemLabel");
});
const editPasswordFormItemInputStyle = computed(() => {
    return getStyle(editPasswordComponent.style, "editPasswordFormItemInput");
});
const editPasswordFormSubmitButtonStyle = computed(() => {
    return getStyle(
        editPasswordComponent.style,
        "editPasswordFormSubmitButton"
    );
});
const editPasswordFormSubmitButtonHoverStyle = computed(() => {
    return getStyle(
        editPasswordComponent.style,
        "editPasswordFormSubmitButtonHover"
    );
});

const editPasswordFormMergedStyle = computed(() => {
    return {
        "--edit-password-form-label-color":
            editPasswordFormItemLabelStyle.value[EProperty.COLOR],
        "--edit-password-form-font-size":
            editPasswordFormItemLabelStyle.value[EProperty.FONT_SIZE],
        "--edit-password-form-input-border-radius":
            editPasswordFormItemInputStyle.value[EProperty.BORDER_RADIUS],
        "--edit-password-form-submit-color":
            editPasswordFormSubmitButtonStyle.value[EProperty.COLOR],
        "--edit-password-form-submit-font-size":
            editPasswordFormSubmitButtonStyle.value[EProperty.FONT_SIZE],
        "--edit-password-form-submit-background-color":
            editPasswordFormSubmitButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--edit-password-form-submit-border-radius":
            editPasswordFormSubmitButtonStyle.value[EProperty.BORDER_RADIUS],
        "--edit-password-form-submit-color-hover":
            editPasswordFormSubmitButtonHoverStyle.value[EProperty.COLOR],
        "--edit-password-form-submit-background-color-hover":
            editPasswordFormSubmitButtonHoverStyle.value[
                EProperty.BACKGROUND_COLOR
            ],
    };
});

/**
 * Handle edit password form submit
 */
async function submitEditPasswordForm() {
    await editPasswordFormRef.value?.validate(async (valid, fields) => {
        const profileStore = useLoginRegistrationStore();
        if (valid) {
            try {
                const { data, error } = await profileStore.updateMePassword({
                    oldPassword: editPasswordForm.oldPassword,
                    password: editPasswordForm.password,
                    passwordConfirmation: editPasswordForm.password,
                });

                if (error.value !== null) {
                    ElNotification({
                        title: "Sikertelen jelszó módosítás",
                        message: "Sikertelen jelszó módosítás",
                        position: "bottom-left",
                        duration: 5000,
                        type: "error",
                    });
                } else {
                    editPasswordFormRef.value?.resetFields();

                    ElNotification({
                        title: "Sikeres jelszó módosítás",
                        message: "Sikeres jelszó módosítás",
                        position: "bottom-left",
                        duration: 5000,
                        type: "success",
                    });
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            console.log("error submit!", fields);
        }
    });
}
</script>

<style lang="scss">
#editPasswordForm {
    .el-form-item__label {
        color: var(--edit-password-form-label-color) !important;
        font-size: var(--edit-password-form-font-size) !important;
    }

    .el-form-item__label::before {
        content: "" !important;
    }

    .el-input__wrapper {
        border-radius: var(--edit-password-form-input-border-radius);
    }

    .el-form-item__error {
        color: white;
        background-color: #e44f60;
        border-radius: 4px;
        padding: 2px 4px;
        margin-top: 5px;
        right: 0;
        left: initial;

        &::before {
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #e44f60;
            width: 0;
            height: 0;
            display: block;
            content: "";
            top: -5px;
            right: 12px;
        }
    }

    .submit-btn {
        border: 0;
        white-space: normal;
        word-wrap: break-word;
        color: var(--edit-password-form-submit-color);
        border-radius: var(--edit-password-form-submit-border-radius);
        background-color: var(--edit-password-form-submit-background-color);

        &:hover {
            color: var(--edit-password-form-submit-color-hover);
            background-color: var(
                --edit-password-form-submit-background-color-hover
            );
        }

        &.is-disabled {
            background: grey !important;
            color: white !important;
        }
    }
}
</style>
