import FeedbackPageMessage from "~~/entities/PrizificPageBuilder/sections/FeedbackPageMessage";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    feedbackPageMessageFontFamily,
    feedbackPageMessageBackground,
    feedbackPageMessageTitleColor,
    feedbackPageMessageTitleFontSize,
    feedbackPageMessageParagraphColor,
    feedbackPageMessageParagraphFontSize,
} from "./FeedbackPageMessageProperties";

export const winnerFeedbackPageMessage = new FeedbackPageMessage(
    "",
    "Winner",
    [
        feedbackPageMessageFontFamily,
        feedbackPageMessageBackground,
        feedbackPageMessageTitleColor,
        feedbackPageMessageTitleFontSize,
        feedbackPageMessageParagraphColor,
        feedbackPageMessageParagraphFontSize,
    ],
    "",
    [],
    "Nyertél",
    "Nem kell mást tenned, mint igazolni, hogy nyertes regisztrációhoz használt nyugta nálad van, és megadni a szállítási adatok, ahova küldhetjük a nyereményedet.",
    "Nyertél",
    "Nem kell mást tenned, mint igazolni, hogy nyertes regisztrációhoz használt nyugta nálad van, és megadni a szállítási adatok, ahova küldhetjük a nyereményedet."
);
