/// <reference types="@types/google.maps" />

//INFO: this is a trick, we have to use the global Google api, but only available when the scripts are loaded. 
function init(map: any) {
    class USGSOverlayHungary extends google.maps.OverlayView {

        protected holderDiv: any;

        constructor(protected bounds: any, protected srcImage: any, protected map: any) {
            super()
            console.log("Constructor!", this.map);
            this.setMap(this.map);
        }

        onAdd() {
            console.log("google.maps.MapTypeId.ROADMAP",google.maps.MapTypeId.ROADMAP);
            var div = document.createElement('div');
            div.style.borderStyle = 'none';
            div.style.borderWidth = '0px';
            div.style.position = 'absolute';

            // Create the img element and attach it to the div.
            var img = document.createElement('img');
            img.src = this.srcImage;
            img.style.width = '100%';
            img.style.height = '100%';
            img.style.position = 'absolute';
            
            div.appendChild(img);

            this.holderDiv = div;

            // Add the element to the "overlayLayer" pane.
            var panes = this.getPanes();
            console.log("panes", panes);
            if (panes) {
                panes.overlayLayer.appendChild(div);
            }

        }


        draw() {
            // We use the south-west and north-east
            // coordinates of the overlay to peg it to the correct position and size.
            // To do this, we need to retrieve the projection from the overlay.
            var overlayProjection = this.getProjection();

            // Retrieve the south-west and north-east coordinates of this overlay
            // in LatLngs and convert them to pixel coordinates.
            // We'll use these coordinates to resize the div.
            var sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest()) as any;
            var ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast()) as any;

            // Resize the image's div to fit the indicated dimensions.
            var div = this.holderDiv;
            div.style.left = sw.x + 'px';
            div.style.top = ne.y + 'px';
            div.style.width = (ne.x - sw.x) + 'px';
            div.style.height = (sw.y - ne.y) + 'px';
        }

        onRemove() {
            this.holderDiv.parentNode.removeChild(this.holderDiv);
            this.holderDiv = null;
        };
    }

    //USGSOverlayHungary.prototype = new google.maps.OverlayView();

    let holderDiv: any = null
      
    var swBound = new google.maps.LatLng(45.7147975, 15.9761431);
    var neBound = new google.maps.LatLng(48.5847975, 22.9361431);
    var bounds = new google.maps.LatLngBounds(swBound, neBound);

    var srcImage = '/images/map/hungary_in_red.png';

    return new USGSOverlayHungary(bounds, srcImage, map);


}


export default init;