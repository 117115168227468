<template>
    <!-- Code upload -->
    <section id="kodfeltoltes">
        <div class="codeupload py-10" :style="[backgroundStyleObject]">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-lg-10 offset-lg-1">
                        <!-- Code Upload Title -->
                        <h2 class="fw-900 font-italic text-center" :style="sectionTitleStyleObject"
                            v-if="section.sectionTitleRaw" v-html="section.sectionTitleRaw"></h2>
                        <!-- Code Upload Title end -->

                        <!-- Code Upload Description -->
                        <div class="text-center" :style="sectionParagraphStyleObject" v-if="section.sectionParagraphRaw"
                            v-html="section.sectionParagraphRaw"></div>
                        <!-- Code Upload Description end -->
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 col-lg-5 offset-lg-1">
                        <CodeUploadForm :component="section.components" @highlight-change="(value) => (focusedInput = value)
                            " />

                        <p class="fw-bold mt-5" :style="sectionParagraphStyleObject" v-if="
                            campaignUuid ==
                            'a46bdb28-0c9b-463b-a48e-b2e78acd71b8' ||
                            campaignUuid ==
                            'cc601dc3-716a-4cb3-a994-14d3ec111761'
                        ">
                            A vásárlást igazoló blokkod vagy sorsjegyet 2024.
                            10. 31-ig őrizd meg!
                        </p>
                    </div>

                    <div class="col-12 col-lg-4 offset-lg-1">
                        <RecipeImage :component="section.components[0]" :highlight="focusedInput" />
                        <p class="mt-5" :style="sectionParagraphStyleObject" v-if="
                            campaignUuid ==
                            'a46bdb28-0c9b-463b-a48e-b2e78acd71b8' ||
                            campaignUuid ==
                            'cc601dc3-716a-4cb3-a994-14d3ec111761'
                        ">
                            Ha egy képen nem látszik jól a termék megnevezése, és a blokk adatai, külön képekben (max. 5 db) is fel tudod tölteni a blokkod.. Kérjük, ügyelj rá, hogy a képek összeilleszthetők legyenek egy teljes blokká!
                        </p>

                        <p class=" mt-5" :style="sectionParagraphStyleObject" v-if="
                            campaignUuid ==
                            'a46bdb28-0c9b-463b-a48e-b2e78acd71b8' ||
                            campaignUuid ==
                            'cc601dc3-716a-4cb3-a994-14d3ec111761'
                        ">
                            <span class="fw-bold">Tölts fel 4 alkalommal kódot, és indulj az extra
                            nyereményért!</span> Amikor mind a négy Staropramen logó
                            színes lesz, biztos lehetsz benne, hogy indulsz az
                            extra nyereményekért.  
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>

    <!-- Code upload end -->
</template>

<script lang="ts">
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import { getStyle } from "../../composables/styleHelper";

export default {
    props: {
        section: {
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        const runtime = useRuntimeConfig();
        const campaignDataStore = useCampaignDataStore();

        return {
            date: "",
            focusedInput: "",
            campaignUuid: campaignDataStore.campaignUUId,
            imageXs: `url('${useMediaUrl(this.section.images[0]?.src ?? "")}')`,
            imageSm: `url('${useMediaUrl(this.section.images[1]?.src ?? "")}')`,
            imageMd: `url('${useMediaUrl(this.section.images[2]?.src ?? "")}')`,
            imageLg: `url('${useMediaUrl(this.section.images[3]?.src ?? "")}')`,
            imageXl: `url('${useMediaUrl(this.section.images[4]?.src ?? "")}')`,
            imageXxl: `url('${useMediaUrl(
                this.section.images[5]?.src ?? ""
            )}')`,
        };
    },
    computed: {
        /**
         * Get background style
         * @returns {any} object
         */
        backgroundStyleObject(): any {
            return getStyle(this.section.style, "codeUploadBackground");
        },

        /**
         * Get background-image style
         * @returns {any} object
         */
        sectionBackgroundImage() {
            return this.section.images
                ? {
                    backgroundImage: `url(${useMediaUrl(this.section.src)})`,
                }
                : "";
        },

        /**
         * Get section title style
         * @returns {any} object
         */
        sectionTitleStyleObject(): any {
            return getStyle(this.section.style, "codeUploadTitle");
        },

        /**
         * Get section paragraph style
         * @returns {any} object
         */
        sectionParagraphStyleObject(): any {
            return getStyle(this.section.style, "codeUploadParagraph");
        },
    },
};
</script>

<style lang="scss" scoped>
.codeupload {
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}
</style>
