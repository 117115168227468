<template>
    <div class="uploaded-codes-progress">
        <div
            @click="toggleProgStatus()"
            class="d-flex flex-column justify-content-center"
        >
            <div class="logo-wrapper">
                <img
                    class="logo"
                    width="75"
                    height="67"
                    :src="useCdnUrl('/images/staropramen/logo.png')"
                    alt=""
                />
            </div>
            <div
                class="progress-number"
                v-if="uploadedCodeStore.uploadedCodes != undefined"
            >
                {{
                    uploadedCodeStore.uploadedCodes?.length < 5
                        ? uploadedCodeStore.uploadedCodes?.length
                        : uploadedCodeStore.uploadedCodes == undefined
                        ? 0
                        : 4
                }}/4
            </div>
        </div>
        <div class="uploaded-codes-text-wrapper">
            <transition name="fade-slide">
                <div v-if="progressOpen?.isOpen" class="uploaded-codes-text">
                    <template
                        v-if="uploadedCodeStore.uploadedCodes?.length < 4"
                    >
                        Ha négyszer töltöttél fel kódot, és mind a négy kör
                        színes, indulsz az extra nyereményért.
                    </template>
                    <template v-else>
                        Már feltöltöttél 4 alkalommal, így indulsz az extra
                        nyereményért.
                    </template>

                    <div class="progress-circles">
                        <div
                            :class="[
                                'progress-circle',
                                {
                                    active:
                                        uploadedCodeStore.uploadedCodes
                                            ?.length >= 1,
                                },
                            ]"
                        ></div>
                        <div
                            :class="[
                                'progress-circle',
                                {
                                    active:
                                        uploadedCodeStore.uploadedCodes
                                            ?.length >= 2,
                                },
                            ]"
                        ></div>
                        <div
                            :class="[
                                'progress-circle',
                                {
                                    active:
                                        uploadedCodeStore.uploadedCodes
                                            ?.length >= 3,
                                },
                            ]"
                        ></div>
                        <div
                            :class="[
                                'progress-circle',
                                {
                                    active:
                                        uploadedCodeStore.uploadedCodes
                                            ?.length >= 4,
                                },
                            ]"
                        ></div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useUploadedCodesStore } from "~~/store/components/uploadedCodes/uploadedCodes";

const progressOpen = useCookie("progressOpen", {
    default: () => {
        return { isOpen: true };
    },
    watch: true,
});

const uploadedCodeStore = useUploadedCodesStore();

await uploadedCodeStore.getUploadedCodes();

onMounted(() => {
    uploadedCodeStore.getUploadedCodes();
});

function toggleProgStatus() {
    if (progressOpen.value.isOpen == true) {
        progressOpen.value = { isOpen: false };
    } else {
        progressOpen.value = { isOpen: true };
    }
}
</script>

<style lang="scss">
.uploaded-codes-progress {
    z-index: 100;
    position: fixed;
    bottom: 90px;
    left: 16px;

    .logo-wrapper {
        position: relative;
        cursor: pointer;
        z-index: 4;
        width: 93px;
        height: 93px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #114634;
        border: 2px solid #faf1d1;

        .logo {
            display: inline-block;
            width: 75px;
            height: 67px;
        }
    }

    .progress-number {
        position: relative;
        z-index: 5;
        font-size: 20px;
        line-height: 1;
        font-weight: bold;
        padding: 2px 14px;
        color: #faf1d1;
        border-radius: 5px;
        background-color: #114634;
        border: 2px solid #faf1d1;
        text-align: center;
        width: 81px;
        margin: 0 auto;
        margin-top: -12px;
    }

    .uploaded-codes-text-wrapper {
        position: absolute;
        width: 272px;
        height: 94px;
        overflow: hidden;
        top: 7px;
        z-index: 3;
        left: 64px;
        pointer-events: none;

        .uploaded-codes-text {
            position: relative;
            font-size: 14px;
            line-height: 16px;
            color: #faf1d1;
            padding: 8px;
            border-radius: 5px;
            padding-left: 36px;
            padding-right: 39px;
            border: 2px solid #faf1d1;
            background-color: #114634;
        }

        .progress-circles {
            display: flex;
            align-items: center;
            gap: 9px;
            margin-top: 9px;

            .progress-circle {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.3);

                &.active {
                    background-color: #faf1d1;
                }
            }
        }
    }

    @media (min-width: 576px) {
        bottom: 80px;

        .uploaded-codes-text-wrapper {
            width: 525px;
            height: 94px;
            top: 14px;
        }
    }

    @media (min-width: 1200px) {
        bottom: 16px;

        .uploaded-codes-text-wrapper {
            width: 525px;
            height: 94px;
            top: 14px;
        }
    }
}

.fade-slide-enter-active,
.fade-slide-leave-active {
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
    opacity: 0;
    transform: translateX(-600px);
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}
</style>
