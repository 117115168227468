<template>
    <el-form label-position="left" label-width="50%" :model="section">
        <!-- Section title -->
        <el-form-item v-if="section.sectionTitleRaw" label="Section title">
            <QuillEditorComponent
                v-model="section.sectionTitleRaw"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <!-- Section title end -->

        <!-- Section paragraph -->
        <el-form-item
            v-if="
                section.sectionParagraphRaw || section.sectionParagraphRaw == ''
            "
            label="Section paragraph"
        >
            <QuillEditorComponent
                v-model="section.sectionParagraphRaw"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <!-- Section paragraph end -->

        <!-- Section text -->
        <el-form-item
            v-if="section.text || section.text == ''"
            label="Section text"
        >
            <QuillEditorComponent
                v-model="section.textRaw"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <!-- Section text end -->

        <el-form-item
            v-if="section.checkboxText || section.checkboxText == ''"
            label="Section text"
        >
            <QuillEditorComponent
                v-model="section.checkboxTextRaw"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <el-form-item
            v-if="section.checkboxInnerText || section.checkboxInnerText == ''"
            label="Section text"
        >
            <QuillEditorComponent
                v-model="section.checkboxInnerTextRaw"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <el-form-item
            v-if="
                section.promotionRangeText || section.promotionRangeText == ''
            "
            label="Section text"
        >
            <QuillEditorComponent
                v-model="section.promotionRangeTextRaw"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>

        <!-- Section before campaign text -->
        <el-form-item
            v-if="section.beforeCampaignText"
            label="Campaign before start text"
        >
            <QuillEditorComponent
                v-model="section.beforeCampaignTextRaw"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <!-- Section before campaign text end -->

        <!-- Section active campaign text -->
        <el-form-item
            v-if="
                section.activeCampaignText || section.activeCampaignText == ''
            "
            label="Campaign running text"
        >
            <QuillEditorComponent
                v-model="section.activeCampaignTextRaw"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <!-- Section active campaign text end -->

        <!-- Section after campaign text -->
        <el-form-item
            v-if="section.afterCampaignText || section.afterCampaignText == ''"
            label="Campaign end text"
        >
            <QuillEditorComponent
                v-model="section.afterCampaignTextRaw"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <!-- Section after campaign text end -->

        <!-- If Section has source -->
        <el-form-item
            v-if="section.src == '' || section.src?.length"
            label="Background image"
        >
            <ImageUploader
                :public="true"
                v-model="section.src"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <!-- If Section has source end -->

        <!-- If Section has images by breakpoints -->
        <el-form-item
            v-if="section.images"
            v-for="(image, imageIndex) in section.images"
            :key="imageIndex"
            :label="image.imageLabel"
        >
            <ImageUploader
                :public="true"
                v-model="image.src"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <!-- If Section has images by breakpoints end -->

        <!-- Section style properties -->
        <el-form-item
            v-for="(property, propertyIndex) in section.style"
            :key="propertyIndex"
            :label="property.label"
        >
            <FontSelect
                v-if="property.inputType === EInputType.FONT_SELECT"
                v-model="property.value"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
            <el-input
                v-if="property.inputType === EInputType.TEXT"
                v-model="property.value"
                controls-position="right"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
            <el-input-number
                v-if="property.inputType === EInputType.NUMBER"
                v-model="property.value"
                controls-position="right"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
            <ColorPickerSelect
                v-if="property.inputType === EInputType.COLOR_PICKER"
                v-model="property.value"
                @change="
                    inputChangeHandler(section);
                    pageStore.updateProgress(EUpdateProgress.IN_PROGRESS);
                "
            />
        </el-form-item>
        <!-- Section style properties end -->
    </el-form>
</template>

<script lang="ts" setup>
import {
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElColorPicker,
} from "element-plus";
import { EUpdateProgress, usePagesStore } from "@/store/pages";
import { IComponent } from "~/interfaces/PrizificPageBuilder/IComponent";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { EInputType } from "~~/enums/PrizificPageBuilder/EInputType";
import debounce from "lodash.debounce";
import FontSelect from "./FontSelect.vue";
import ColorPickerSelect from "./ColorPickerSelect.vue";

interface Props {
    section: IComponent<EComponents>;
    page?: any;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Use page store
 */
const pageStore = usePagesStore();

/**
 * Handle input on change
 * @param section
 */
const inputChangeHandler = debounce((section: any) => {
    if (section.getId()) {
        pageStore.updateComponent(section);
    } else if (props.page !== undefined) {
        pageStore.createComponent(section, props.page);
    }
}, 2000);
</script>
<style lang="scss"></style>
