import Page from "~/entities/PrizificPageBuilder/Page";
import { SubPageSetup } from "./subPages/SubPageSetup";
import { DefaultHomePageSetup } from "./subPages/DefaultHomePage";
import { DefaultAgeGatePageSetup } from "./subPages/DefaultAgeGatePageSetup";
import { DefaultThankYouPageSetup } from "./subPages/DefaultThankYouPageSetup";
import { DefaultWinnersPageSetup } from "./subPages/DefaultWinnersPageSetup";
import { DefaultWinnerPageSetup } from "./subPages/DefaultWinnerPageSetup";
import { DefaultNotFoundPageSetup } from "./subPages/DefaultNotFoundPageSetup";
import { DefaultEditProfilePageSetup } from "./subPages/DefaultEditProfilePageSetup";
import { DefaultEditPasswordPageSetup } from "./subPages/DefaultEditPasswordPageSetup";
import ASharedComponent from "~~/entities/PrizificPageBuilder/ASharedComponent";
import { navbar } from "~/entities/pages/shared/sections/Navbar/Navbar";
import { DefaultUploadedCodesPageSetup } from "./subPages/DefaultUploadedCodesPageSetup";
import { DefaultDrawPageSetup } from "./subPages/DefaultDrawPageSetup";
import { DefaultNotEnoughOldPageSetup } from "./subPages/DefaultNotEnoughOldPageSetup";

export abstract class PageDescriptor {
    /**
     * Page template id
     */
    protected templateId: string = "";
    /**
     * Contains the sub pages, what will contain this given page
     */
    protected subPages: Array<SubPageSetup> = [];

    protected sharedComponent: Array<ASharedComponent<any>> = [];

    getSubPages() {
        return this.subPages;
    }

    getSharedComponents() {
        return this.sharedComponent;
    }

    templateIdExists(templateId: string) {
        return this.templateId == templateId;
    }

    getTemplateId() {
        return this.templateId;
    }
}

export class DefaultPage extends PageDescriptor {
    protected templateId = "default";

    constructor() {
        super();

        this.subPages = [
            new DefaultAgeGatePageSetup(),
            new DefaultHomePageSetup(),
            new DefaultWinnerPageSetup(),
            new DefaultThankYouPageSetup(),
            new DefaultWinnersPageSetup(),
            new DefaultEditProfilePageSetup(),
            new DefaultEditPasswordPageSetup(),
            new DefaultUploadedCodesPageSetup(),
            new DefaultNotFoundPageSetup(),
            new DefaultDrawPageSetup(),
            new DefaultNotEnoughOldPageSetup(),
        ];

        this.sharedComponent = [navbar];
    }
}
