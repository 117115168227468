<template>
    <div class="position-relative w-100">
        <el-input
            ref="input"
            id="apCode"
            class="ap-code"
            :inputmode="inputMode"
            v-model="apCodeValue"
            @focus="addFirstCharacter()"
        ></el-input>
        <transition name="fade-helper">
            <div
                v-if="
                    apCodeValue.length && apCodeValue.length != data.maxLength
                "
                class="word-limit-text"
            >
                Még
                <span class="word-limit-number">{{
                    data.maxLength - apCodeValue.length
                }}</span>
                karakter
            </div>
        </transition>
        <transition name="fade">
            <div v-if="data.errorMessage" class="el-form-item__error error">
                Túl sok karakter
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { ElInput } from "element-plus";

interface Props {
    modelValue: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue", "focus"]);

const apCodeInputRef = ref();
const data = reactive({
    apCode: "",
    maxLength: 9,
    errorMessage: false,
});

const apCodeValue = computed({
    get: () => {
        if (props.modelValue != null) {
            return props.modelValue;
        } else {
            return "";
        }
    },
    set: (value) => {
        if (value != null) {
            if (value.length > data.maxLength) {
                tooMuchCharacter();
            }

            emit("update:modelValue", value);
        } else {
            emit("update:modelValue", "");
        }
    },
});

const inputMode = computed(() => {
    if (apCodeValue.value.match(/^(\d{1,8})?$/g)) {
        return "text";
    } else if (apCodeValue.value.match(/^[a-zA-Z](\d{1,8})?$/g)) {
        return "numeric";
    }

    return "";
});

function addFirstCharacter() {
    if (!apCodeValue.value) {
        apCodeValue.value = ApCodeInput("a", "");
    }

    emit("focus", "apcode");
}

function tooMuchCharacter() {
    data.errorMessage = true;

    delay(1250).then(() => {
        data.errorMessage = false;
    });
}

function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

watch(
    () => apCodeValue.value,
    (newValue, oldValue) => {
        apCodeValue.value = ApCodeInput(newValue, oldValue);
    }
);
</script>

<style lang="scss" scoped>
.word-limit-text {
    position: absolute;
    left: 50%;
    z-index: 2;
    max-width: 211px;
    min-height: 24px;
    line-height: 1.25;
    border-radius: 1rem;
    padding: 0.25rem 1rem;
    top: calc(0px - 32px);
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid white;
    color: #000 !important;

    .word-limit-number {
        font-weight: 500;
        color: #000 !important;
    }

    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid white;
    }

    &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid white;
    }
}

.fade-helper-enter-active,
.fade-helper-leave-active {
    transition: opacity 0.5s;
}
.fade-helper-enter,
.fade-helper-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
