import { defineStore } from "pinia";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";

interface IUploadedCode {
    id: number;
    code: string;
    type: string;
    userName: string;
    email: string;
    uploadedAt: string;
    timeOfPurchase: string;
    status: string;
    navCode: string;
    isWinner: boolean;
    fullName: string;
    firstname: string;
    lastname: string;
    notifyImmediately: boolean;
    receiptNumber: string;
    transactionNumber: string;
    invoiceNumber: string;
    winnerId: number;
    winnerUuid: string;
    processStatusDeadline: string;
}

interface IPrize {
    id: number,
    imageUrl: string;
    singleImageRepresentationUrl?: string;
    name: string;
    description: string;
    longDescription: string;
    addressRequiredOnUpload: boolean;
    imageRequiredOnUpload: boolean;
}

interface IShippingFormState {
    uploadedCode?: IUploadedCode;
    prize?: IPrize;
    currentSlug?: string;
    uploadedReceipts?: Array<any>;
    receiptsImagesRaw?: any;
}

export const useShippingFromStore = defineStore("shippingFromStore", {
    state: (): IShippingFormState => {
        return {
            uploadedCode: undefined,
            prize: undefined,
            receiptsImagesRaw: undefined,
        };
    },
    getters: {},
    actions: {
        //async collectWinnerData(slug: string) {
        //    this.currentSlug = slug;
        //    await this.getPrizeByUploadedCode(8401).then(()=>{
        //         this.getCodeByWinnerSlug(slug);
        //    })
        //    
        //    
        //    
        //},

        async getCodeByWinnerSlug(
            slug: string
        ): Promise<IUploadedCode | undefined> {
            this.uploadedCode = undefined;



            const res = await usePiniaFetch<{ uploadedCode: IUploadedCode }>(
                `/endusermanagerms/winner/code/${slug}`,
                {
                    //key: "get-code-by-winner-slug" + Math.random(),
                    method: "GET",
                }
            );

            this.uploadedCode = res.uploadedCode;
            return this.uploadedCode
        },

        async getPrizeByUploadedCode(slug: number): Promise<IPrize> {
            this.prize = undefined;
            const prize = await usePiniaFetch<IPrize>(
                `/endusermanagerms/public/prize/${slug}`,
                {
                    method: "POST",
                }
            );

            this.prize = prize;
            return this.prize;
        },
        async setWinnerMissedReceipt(slug: string) {
            const { data, error } = await useApiFetch(
                `/api/endusermanagerms/winner/code/${slug}/missed`,
                {
                    key: "set-winner-missed-receipt",
                    method: "PUT",
                }
            );

            return Promise.resolve(data.value);
        },
        async updateReceipts(receipts: any) {
            console.log("Update event!", receipts);
            this.receiptsImagesRaw = receipts;
        },
        async uploadImage(imageFile: any) {
            console.log("This is it'?");
            if (this.uploadedCode && this.uploadedCode.id) {
                const campaignDataStore = useCampaignDataStore();
                const campaignUuid = await campaignDataStore.obtainCampaignUUId();
                const campaignId = await campaignDataStore.obtainCampaignId();

                const formData = new FormData();

                formData.append("file", imageFile);
                formData.append("public", "0");
                formData.append("campaignId", campaignId);
                formData.append("campaignUuid", campaignUuid);

                console.log("this.uploadedCode.id", this.uploadedCode.id);
                try {
                    const { data, error } = await useApiFetch(`/imagemanagerms/upload`, {
                        key: 'image-upload' + Math.random(),
                        method: "POST",
                        body: formData,
                    });

                    console.log(data, error);

                    if (error.value == null) {
                        console.log("Resolved");
                        return Promise.resolve(data.value.file)
                    }
                    console.log("Rejected");
                    return Promise.reject(error.value);
                } catch (e) {
                    console.error("OOoo-----", e);
                }
                return Promise.reject("Error while upload image");

            }
        },

        async uploadWinnerData(slug: string, shippingData: any) {


            let promises: Array<Promise<any>> = [];

            if (this.receiptsImagesRaw) {
                this.receiptsImagesRaw.forEach((formDataPart: any) => {
                    promises.push(this.uploadImage(formDataPart));
                });
            }

            const rawImages = await Promise.all(promises);

            const images = rawImages.map((res) => {

                return res.filename;
            });


            const sendingData = {
                ...shippingData,
                receiptImages: images,
            };


            const { data, error } = await useApiFetch(
                `/endusermanagerms/public/winners/shipping-details/${slug}`,
                {
                    key: "upload-winner-data",
                    method: "PUT",
                    body: sendingData,
                }
            );

            if (error.value != null) {
                return Promise.reject(error.value);
            }

            return Promise.resolve(data.value);
        },
    },
});
