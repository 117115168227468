<template>
    <div class="code-upload-form" :style="codeUploadFormStyle">
        <el-form
            v-if="hasMoreType"
            class="codeUploadForm"
            :style="codeUploadFormMergedStyle"
            label-position="top"
        >
            <el-form-item :label="inputHowToPurchaseTexts?.label">
                <ToggleButton
                    :buttons="codeUploadStore.codeUploadForm.types"
                    v-model="codeUploadStore.codeUploadForm.type"
                />
            </el-form-item>
        </el-form>

        <CodeUploadFormsApCode
            v-if="codeUploadStore.codeUploadForm.type == EPurchaseType.AP_CODE"
            v-loading="codeUploadStore.codeUploadForm.dynamicFormIsLoading"
            ref="codeUploadFormsApCode"
            :component="props.component"
            :codeUploadFormMergedStyle="codeUploadFormMergedStyle"
        ></CodeUploadFormsApCode>

        <CodeUploadFormsOnline
            v-if="codeUploadStore.codeUploadForm.type == EPurchaseType.ONLINE"
            v-loading="codeUploadStore.codeUploadForm.dynamicFormIsLoading"
            ref="codeUploadFormsOnline"
            :component="props.component"
            :codeUploadFormMergedStyle="codeUploadFormMergedStyle"
        ></CodeUploadFormsOnline>

        <CodeUploadFormsCouponCode
            v-if="
                codeUploadStore.codeUploadForm.type == EPurchaseType.COUPON_CODE
            "
            v-loading="codeUploadStore.codeUploadForm.dynamicFormIsLoading"
            ref="codeUploadFormsCouponCode"
            :component="props.component"
            :codeUploadFormMergedStyle="codeUploadFormMergedStyle"
        ></CodeUploadFormsCouponCode>

        <!-- Successful code upload Dialog -->
        <Dialog
            :show="codeUploadStore.codeUploadSuccess.successfulCodeUpload"
            :title="successfulCodeUploadModal?.title ?? ''"
            :description="successfulCodeUploadModal?.description"
            :footerBtnText="successfulCodeUploadModal?.buttonRight ?? ''"
            :component="component[1]"
            @closed="
                codeUploadStore.codeUploadSuccess.successfulCodeUpload = false
            "
            @btnClicked="resetFields"
        />
        <!-- Successful code upload Dialog end -->
        <!-- Code Not Win Dialog -->
        <Dialog
            :show="codeUploadStore.codeUploadSuccess.apCodeNotWin"
            :title="apCodeNotWinModal?.title"
            :description="apCodeNotWinModal?.description"
            :footerBtnText="apCodeNotWinModal?.rightButton"
            :component="component[1]"
            @closed="codeUploadStore.codeUploadSuccess.apCodeNotWin = false"
            @btnClicked="resetFields"
        />
        <!-- Code Not Win Dialog end -->
        <!-- Ap code conflict Dialog -->
        <Dialog
            :show="codeUploadStore.codeUploadError.apCodeConflict"
            :title="apCodeConflictModal?.title ?? ''"
            :description="apCodeConflictModal?.description"
            :footerBtnText="apCodeConflictModal?.buttonRight ?? ''"
            :component="component[1]"
            @closed="codeUploadStore.codeUploadError.apCodeConflict = false"
            @btnClicked="resetFields"
        />
        <!-- Ap code conflict Dialog end -->
        <!-- Ap code exhousted Dialog -->
        <Dialog
            :show="codeUploadStore.codeUploadError.aPCodeExhousted"
            :title="apCodeExhoustedModal?.title"
            :description="apCodeExhoustedModal?.description"
            :footerBtnText="apCodeExhoustedModal?.buttonRight ?? ''"
            :component="component[1]"
            @closed="codeUploadStore.codeUploadError.aPCodeExhousted = false"
            @btnClicked="resetFields"
        />
        <!-- Ap code exhousted Dialog end -->
        <!-- Ap code short time gate Dialog -->
        <Dialog
            :show="codeUploadStore.codeUploadError.aPCodeShortTimeGate"
            :title="apCodeShortTimeGateModal?.title"
            :description="apCodeShortTimeGateModal?.description"
            :footerBtnText="apCodeShortTimeGateModal?.buttonRight"
            :component="component[1]"
            @closed="
                codeUploadStore.codeUploadError.aPCodeShortTimeGate = false
            "
            @btnClicked="resetFields"
        />
        <!-- Ap code short time gate Dialog end -->
        <!-- Ap code conflict itself Dialog -->
        <Dialog
            :show="codeUploadStore.codeUploadError.aPCodeConflictItself"
            :title="apCodeConflictItselfModal?.title ?? ''"
            :description="apCodeConflictItselfModal?.description"
            :footerBtnText="apCodeConflictItselfModal?.buttonRight ?? ''"
            :component="component[1]"
            @closed="
                codeUploadStore.codeUploadError.aPCodeConflictItself = false
            "
            @btnClicked="resetFields"
        />
        <!-- Ap code conflict itself Dialog end -->
        <!-- Coupon code not exist Dialog -->
        <Dialog
            :show="codeUploadStore.codeUploadError.couponCodeNotExist"
            :title="couponCodeNotExistModal?.title"
            :description="couponCodeNotExistModal?.description"
            :footerBtnText="couponCodeNotExistModal?.buttonRight ?? ''"
            :component="component[1]"
            @closed="codeUploadStore.codeUploadError.couponCodeNotExist = false"
            @btnClicked="resetFields"
        />
        <!-- Coupon code not exist Dialog end -->
        <!-- Coupon code conflict Dialog -->
        <Dialog
            :show="codeUploadStore.codeUploadError.couponCodeConflict"
            :title="couponCodeConflictModal?.title"
            :description="couponCodeConflictModal?.description"
            :footerBtnText="couponCodeConflictModal?.buttonRight ?? ''"
            :component="component[1]"
            @closed="codeUploadStore.codeUploadError.couponCodeConflict = false"
            @btnClicked="resetFields"
        />
        <!-- Coupon code conflict Dialog end -->
        <!-- Coupon code conflict itself Dialog -->
        <Dialog
            :show="codeUploadStore.codeUploadError.couponCodeConflictItself"
            :title="couponCodeConflictItselfModal?.title"
            :description="couponCodeConflictItselfModal?.description"
            :footerBtnText="couponCodeConflictItselfModal?.buttonRight ?? ''"
            :component="component[1]"
            @closed="
                codeUploadStore.codeUploadError.couponCodeConflictItself = false
            "
            @btnClicked="resetFields"
        />
        <!-- Coupon code conflict itself Dialog end -->
        <!-- User has not been activated Dialog -->
        <Dialog
            :show="codeUploadStore.codeUploadError.notActivatedUser"
            title="Kérjük, aktiváld a regisztrációdat"
            description="Kérjük, aktiváld az e-mail címedre küldött linkre kattintva."
            footerBtnText="Bezárás"
            :component="component[1]"
            @closed="codeUploadStore.codeUploadError.notActivatedUser = false"
            @btnClicked="resetFields"
        />
        <!-- User has not been activated Dialog end -->
    </div>
</template>

<script setup lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { useCodeUploadStore } from "@/store/components/codeUpload/codeUpload";
import { getFormItemText } from "../../composables/textHelper";
import { ElForm, ElFormItem } from "element-plus";

import { ECodeUploadFormItem } from "~/enums/PrizificPageBuilder/ECodeUploadFormItem";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";
import { EPurchaseType } from "~/enums/Campaign/EPurchaseType";
import { usePurchasedPromotionProductStore } from "@/store/components/purchasedPromotionProduct/purchasedPromotionProduct";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

const campaignDataStore = useCampaignDataStore();
const codeUploadStore = useCodeUploadStore();

interface Props {
    component: Array<any>;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["highlight-change"]);

const codeUploadFormsApCode = ref();
const codeUploadFormsOnline = ref();
const codeUploadFormsCouponCode = ref();

const codeUploadComponent = props.component[0];
const feedbackModalsComponent = props.component[1];

const focusedInput = ref("");

function resetFields() {
    if (codeUploadFormsApCode.value) {
        codeUploadFormsApCode.value.resetForm();
    } else if (codeUploadFormsOnline.value) {
        codeUploadFormsOnline.value.resetForm();
    } else if (codeUploadFormsCouponCode.value) {
        codeUploadFormsCouponCode.value.resetForm();
    }
}

await codeUploadStore.getDynamicFields();
const hasMoreType = computed(() => {
    return codeUploadStore.codeUploadForm?.types?.length > 1;
});

// Computed form and form items style
const codeUploadFormStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormBackground");
});
const codeUploadFormItemLabelStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormItemLabel");
});
const codeUploadFormItemInputStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormItemInput");
});
const codeUploadFormItemInputPlaceholderStyle = computed(() => {
    return getStyle(
        props.component[0].style,
        "codeUploadFormItemInputPlaceholder"
    );
});
const codeUploadFormItemInputReceiptImageStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormItemReceiptImage");
});
const codeUploadFormSubmitButtonStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormSubmitButton");
});
const codeUploadFormSubmitButtonHoverStyle = computed(() => {
    return getStyle(
        props.component[0].style,
        "codeUploadFormSubmitButtonHover"
    );
});
const codeUploadFormItemToggleButtonStyle = computed(() => {
    return getStyle(props.component[0].style, "codeUploadFormItemToggleButton");
});
const codeUploadFormItemToggleButtonActiveStyle = computed(() => {
    return getStyle(
        props.component[0].style,
        "codeUploadFormItemToggleButtonActive"
    );
});

const codeUploadFormMergedStyle = computed(() => {
    return {
        "--toggle-button-color":
            codeUploadFormItemToggleButtonStyle.value[EProperty.COLOR],
        "--toggle-button-active-color":
            codeUploadFormItemToggleButtonActiveStyle.value[EProperty.COLOR],
        "--toggle-button-active-background-color":
            codeUploadFormItemToggleButtonActiveStyle.value[
                EProperty.BACKGROUND_COLOR
            ],
        "--toggle-button-border-color":
            codeUploadFormItemToggleButtonStyle.value[EProperty.BORDER_COLOR],
        "--toggle-button-background-color":
            codeUploadFormItemToggleButtonStyle.value[
                EProperty.BACKGROUND_COLOR
            ],
        "--toggle-button-border-radius":
            codeUploadFormItemToggleButtonStyle.value[EProperty.BORDER_RADIUS],
        "--code-upload-form-label-color":
            codeUploadFormItemLabelStyle.value[EProperty.COLOR],
        "--code-upload-form-font-size":
            codeUploadFormItemLabelStyle.value[EProperty.FONT_SIZE],
        "--code-upload-form-input-text-color":
            codeUploadFormItemInputStyle.value[EProperty.COLOR] ?? "#606266",
        "--code-upload-form-input-receipt-image-close-icon-color":
            codeUploadFormItemInputReceiptImageStyle.value[EProperty.COLOR] ??
            "black",
        "--code-upload-form-input-background-color":
            codeUploadFormItemInputStyle.value[EProperty.BACKGROUND_COLOR] ??
            "white",
        "--code-upload-form-input-placeholder-color":
            codeUploadFormItemInputPlaceholderStyle.value[EProperty.COLOR] ??
            "#c4c7cf",
        "--code-upload-form-input-border-radius":
            codeUploadFormItemInputStyle.value[EProperty.BORDER_RADIUS],
        "--code-upload-form-input-border-color":
            codeUploadFormItemInputStyle.value[EProperty.BORDER_COLOR],
        "--code-upload-form-input-border-width":
            codeUploadFormItemInputStyle.value[EProperty.BORDER_WIDTH],
        "--code-upload-form-input-padding":
            codeUploadFormItemInputStyle.value[EProperty.PADDING],
        "--code-upload-form-input-height":
            codeUploadFormItemInputStyle.value[EProperty.HEIGHT],
        "--code-upload-form-input-font-size":
            codeUploadFormItemInputStyle.value[EProperty.FONT_SIZE],
        "--code-upload-checkbox-label-color":
            codeUploadFormItemLabelStyle.value[EProperty.COLOR],
        "--code-upload-checkbox-label-bg-color":
            codeUploadFormItemLabelStyle.value[EProperty.COLOR],
        "--code-upload-form-submit-color":
            codeUploadFormSubmitButtonStyle.value[EProperty.COLOR],
        "--code-upload-form-submit-font-size":
            codeUploadFormSubmitButtonStyle.value[EProperty.FONT_SIZE],
        "--code-upload-form-submit-padding":
            codeUploadFormSubmitButtonStyle.value[EProperty.PADDING],
        "--code-upload-form-submit-background-color":
            codeUploadFormSubmitButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--code-upload-form-submit-border-width":
            codeUploadFormSubmitButtonStyle.value[EProperty.BORDER_WIDTH],
        "--code-upload-form-submit-border-color":
            codeUploadFormSubmitButtonStyle.value[EProperty.BORDER_COLOR],
        "--code-upload-form-submit-border-radius":
            codeUploadFormSubmitButtonStyle.value[EProperty.BORDER_RADIUS],
        "--code-upload-form-submit-color-hover":
            codeUploadFormSubmitButtonHoverStyle.value[EProperty.COLOR],
        "--code-upload-form-submit-background-color-hover":
            codeUploadFormSubmitButtonHoverStyle.value[
                EProperty.BACKGROUND_COLOR
            ],
        "--code-upload-form-submit-border-width-hover":
            codeUploadFormSubmitButtonHoverStyle.value[EProperty.BORDER_WIDTH],
        "--code-upload-form-submit-border-color-hover":
            codeUploadFormSubmitButtonHoverStyle.value[EProperty.BORDER_COLOR],
    };
});

// Computed form items texts
const inputHowToPurchaseTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.HOW_TO_PURCHASE
    );
});

// Computed feedback modals text
const successfulCodeUploadModal = computed(() => {
    return getGroupText(props.component[1], EModal.SUCCESSFULCODEUPLOAD);
});
const apCodeNotWinModal = computed(() => {
    return getGroupText(props.component[1], EModal.APCODENOTWIN);
});
const apCodeConflictModal = computed(() => {
    return getGroupText(props.component[1], EModal.APCODECONFLICT);
});
const apCodeExhoustedModal = computed(() => {
    return getGroupText(props.component[1], EModal.APCODEEXHAUSTED);
});
const apCodeShortTimeGateModal = computed(() => {
    return getGroupText(props.component[1], EModal.APCODESHORTTIMEGATE);
});
const apCodeConflictItselfModal = computed(() => {
    return getGroupText(props.component[1], EModal.APCODECONFLICTITSELF);
});
const couponCodeNotExistModal = computed(() => {
    return getGroupText(props.component[1], EModal.COUPONCODENOTEXIST);
});
const couponCodeConflictModal = computed(() => {
    return getGroupText(props.component[1], EModal.COUPONCODECONFLICT);
});
const couponCodeConflictItselfModal = computed(() => {
    return getGroupText(props.component[1], EModal.COUPONCODECONFLICTITSELF);
});

/**
 * Watch focused input value if change then emit
 */
watch(
    () => focusedInput.value,
    (newVal, oldVal) => {
        if (newVal != oldVal) {
            emit("highlight-change", newVal);
        }
    }
);

watch(
    () => codeUploadStore.codeUploadForm.type,
    () => {
        resetFields();
    }
);
</script>

<style lang="scss">
.code-upload-form {
    .el-loading-mask {
        background-color: transparent !important;

        .el-loading-spinner .path {
            stroke: #0f6f84 !important;
        }
    }
}
</style>
