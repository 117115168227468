<template>
    <!-- Game steps -->
    <client-only>
        <section class="map-section pt-7 pb-7" :style="[wrapperStyleObject]">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <h2
                            class="position-relative d-block fw-900 font-italic pb-2 text-center"
                            :style="titleWrapperStyleObject"
                            v-html="section.sectionTitleRaw"
                        ></h2>
                        <div
                            class="mt-5 text-center"
                            :style="paragraphWrapperStyleObject"
                            v-html="section.sectionParagraphRaw"
                        ></div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <GoogleMap
                            ref="mapRef"
                            class="mt-5"
                            apiKey="AIzaSyC1-ZB2FgD4v_BrDfMBNt1ZF5wAv_a3BC0"
                            style="width: 100%; height: 600px"
                            :center="pennyMarketCenter"
                            :zoom="zoomLevel"
                            :styles="mapStyle"
                            :backgroundColor="'#fff'"
                            :mapTypeId="'roadmap'"
                            :disableDefaultUI="true"
                            :fullscreenControl="false"
                            :mapTypeControl="false"
                        >
                            <!-- <MarkerCluster> -->
                            <CustomMarker
                                v-for="pennyMarket in pennyMarkets"
                                :options="{
                                    position: pennyMarket.mapCoordinates,
                                    anchorPoint: 'BOTTOM_CENTER',
                                }"
                            >
                                <div style="text-align: center">
                                    <img
                                        :src="
                                            useImageMediaUrl(
                                                imagePin,
                                                ImageFormat.WEBP
                                            )
                                        "
                                        width="25"
                                        height="35"
                                        style="margin-top: 0"
                                    />
                                    <div
                                        style="
                                            font-size: 14px;
                                            padding: 4px 4px;
                                            border-radius: 8px;
                                            background-color: #d9d9d9;
                                            margin-top: 8px;
                                        "
                                    >
                                        {{ pennyMarket.mapText }}
                                    </div>
                                </div>
                            </CustomMarker>
                            <!-- </MarkerCluster> -->
                        </GoogleMap>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="d-flex mt-6 pt-6">
                        <img
                            width="307"
                            height="192"
                            class="ms-auto img-fluid"
                            style="max-height: 192px"
                            :src="useCdnUrl('/images/penny/penny-car.png')"
                            alt=""
                        />
                    </div>
                    <div
                        class="d-flex flex-wrap pt-6 mt-6 pb-6"
                        style="gap: 16px 62px"
                    >
                        <div
                            v-for="pennyMarket in pennyMarkets"
                            class="map-button d-flex align-items-center user-select-none"
                            role="button"
                            @click="goTo(pennyMarket.mapCoordinates)"
                        >
                            <div class="date">
                                {{ pennyMarket.year }}<br />
                                {{ pennyMarket.monthWithDay }}
                            </div>
                            <div class="d-flex flex-column">
                                <div class="city">
                                    {{ pennyMarket.city }}
                                </div>
                                <div class="address">
                                    {{ pennyMarket.address }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Prizific page builder tool -->
            <client-only>
                <PrizificPageBuilderTool v-if="editable" :section="section" />
            </client-only>
            <!-- Prizific page builder tool end -->
        </section>
    </client-only>
    <!-- Game steps end -->
</template>

<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { GoogleMap, CustomMarker, MarkerCluster } from "vue3-google-map";

import initOverlay from "./USGSOverlayHungary.js";

/**
 * Props interface
 */
interface Props {
    section: IComponent<EComponents> & any;
    editable: boolean;
}

const mapStyle = ref([
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "landscape",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "road",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
    },
    {
        elementType: "labels",
        stylers: [{ visibility: "off" }],
    },
    {},
]);

const zoomLevel = ref(7);
/**
 * Define props
 */
const props = defineProps<Props>();

const mapRef = ref<any>(null);
const active = ref({ lat: 47.4847975, lng: 19.2361431 });

watch([() => mapRef.value?.ready, active], ([ready, active]) => {
    if (!ready) return;

    try {
        mapRef.value.map.panTo(active);
        initOverlay(mapRef.value.map);

        if (document.body.clientWidth < 400) {
            zoomLevel.value = 6;
        } else if (document.body.clientWidth < 800) {
            zoomLevel.value = 5;
        } else if (document.body.clientWidth < 1500) {
            zoomLevel.value = 7;
        }
        console.log("document.body.clientWidth", document.body.clientWidth);
    } catch (e) {
        console.error(e);
    }
});

function goTo(mapCoordinates: any) {
    active.value = mapCoordinates;
}

const pennyMarketCenter = { lat: 47.4847975, lng: 19.2361431 };

const pennyMarkets = ref<any[]>([
    {
        year: "2024",
        monthWithDay: "09.26.",
        city: "Budapest",
        address: "Pesti út 16, 1173",
        mapText: "09.26. - Budapest, Pesti út",
        mapCoordinates: { lat: 47.4847862, lng: 19.2332599 },
    },
    {
        year: "2024",
        monthWithDay: "10.09.",
        city: "Nyíregyháza",
        address: "László u. 10, 4400",
        mapText: "10.09. - Nyíregyháza, László u.",
        mapCoordinates: { lat: 47.957392, lng: 21.7256663 },
    },
    {
        year: "2024",
        monthWithDay: "09.30.",
        city: "Kecskemét",
        address: "Kodály Zoltán tér 8, 6000",
        mapText: "09.30. - Kecskemét, Kodály tér",
        mapCoordinates: { lat: 46.9143186, lng: 19.6850003 },
    },
    {
        year: "2024",
        monthWithDay: "10.11.",
        city: "Veszprém",
        address: "Dózsa György tér 1, 8200",
        mapText: "10.11. - Veszprém, Dózsa tér",
        mapCoordinates: { lat: 47.1021411, lng: 17.8980716 },
    },
    {
        year: "2024",
        monthWithDay: "10.02.",
        city: "Debrecen",
        address: "Sámsoni út 99, 4033",
        mapText: "10.02. - Debrecen, Sámsoni út",
        mapCoordinates: { lat: 47.5473387, lng: 21.6551214 },
    },
    {
        year: "2024",
        monthWithDay: "10.15.",
        city: "Nagykanizsa",
        address: "Kalmár u. 8, 8800",
        mapText: "10.15. - Nagykanizsa, Kalmár u.",
        mapCoordinates: { lat: 46.4584144, lng: 16.9802098 },
    },
    {
        year: "2024",
        monthWithDay: "10.04.",
        city: "Eger",
        address: "II. Rákóczi Ferenc u. 96, 3300",
        mapText: "10.04. - Eger, II. Rákóczi F. út",
        mapCoordinates: { lat: 47.9224944, lng: 20.3673658 },
    },

    {
        year: "2024",
        monthWithDay: "10.17.",
        city: "Győr",
        address: "Gerence út 29-31, 9024",
        mapText: "10.17. - Győr, Gerence út",
        mapCoordinates: { lat: 47.6664348, lng: 17.636798 },
    },

    {
        year: "2024",
        monthWithDay: "10.07.",
        city: "Budapest",
        address: "Margó Tivadar u. 31, 1181",
        mapText: "10.07. - Budapest, Margó",
        mapCoordinates: { lat: 47.4451287, lng: 19.1662723 },
    },
    {
        year: "2024",
        monthWithDay: "10.22.",
        city: "Budapest",
        address: "Kossuth Lajos u. 69, 1211",
        mapText: "10.22. - Budapest, Csepel",
        mapCoordinates: { lat: 47.4287231, lng: 19.0672382 },
    },
]);

/**
 * Create wrapper inline style object
 * @returns {Object} styleObject
 */
const wrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "wrapper");
});

const imageXs = `url('${useMediaUrl(props.section.images[0]?.src)}')`;
const imageSm = `url('${useMediaUrl(props.section.images[1]?.src)}')`;
const imageMd = `url('${useMediaUrl(props.section.images[2]?.src)}')`;
const imageLg = `url('${useMediaUrl(props.section.images[3]?.src)}')`;
const imageXl = `url('${useMediaUrl(props.section.images[4]?.src)}')`;
const imageXxl = `url('${useMediaUrl(props.section.images[5]?.src)}')`;

/**
 * Create title inline style object
 * @returns {Object} styleObject
 */
const titleWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "title");
});

/**
 * Create paragraph inline style object
 * @returns {Object} styleObject
 */
const paragraphWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "paragraph");
});

const imagePin = computed(() => {
    return props.section.components[0]?.src;
});
</script>

<style lang="scss">
.map-section {
    min-height: 56px;
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}

.map-button {
    width: 100%;
    color: #ce2029;
    padding: 20px 24px 20px 48px;
    border-radius: 10px;
    border: 2px solid #ce2029;

    .date {
        font-size: 20px;
        line-height: 22px;
        font-weight: bold;
        padding-right: 48px;
        margin-right: 32px;
        border-right: 1px solid #ce2029;
    }

    .city {
        font-size: 20px;
        line-height: 22px;
        font-weight: 500;
    }

    .address {
        font-size: 20px;
        line-height: 22px;
        font-weight: 400;
    }

    @media (min-width: 992px) {
        width: calc(50% - 31px);
    }
}
</style>
