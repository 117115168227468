import { defineStore } from "pinia";
import { useCampaignDataStore } from "../campaign/campaignData";

import { DefaultPage } from "./DefaultPageDescriptior";
import { IComponent } from "~/interfaces/PrizificPageBuilder/IComponent";
import { usePagesStore } from "../pages";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

enum PageType {
    HOME_PAGE = "home-page",
    ACTIVATE_REGISTRATION_PAGE = "activate-registration-page",
    THANK_YOU_PAGE = "thank-you-page",
    UPLOADED_CODES_PAGE = "uploaded-codes-page",
    WE_ARE_SORRY_PAGE = "we-are-sorry-page",
    NEW_PASSWORD_PAGE = "new-password-page",
    EDIT_PASSWORD_PAGE = "edit-password-page",
    EDIT_PROFILE_PAGE = "edit-profile-page",
    WINNERS_PAGE = "winners-page",
    WINNER_PAGE = "winner-page",
    AGE_GATE_PAGE = "age-gate-page",
    DRAW_PAGE = "draw-page",
}

class SetupLog {
    public errors: Array<string> = [];
    public completed: boolean = false;
    public completedTasks: number = 0;

    constructor(
        public name: string,
        public pageType: string,
        public tasks: number
    ) {}
    getName() {
        return this.name;
    }
    completeTask() {
        this.completedTasks = this.completedTasks + 1;
    }

    markAsCompleted() {
        this.completed = true;
        this.completedTasks = this.tasks;
    }
    getCompletedTasks() {
        return this.completedTasks;
    }
    getProgressPercentage() {
        if (this.tasks == 0) {
            return 100;
        }
        return Math.ceil((this.completedTasks / this.tasks) * 100);
    }

    isCompleted() {
        return this.completed;
    }

    pushError(error: string) {
        this.errors.push(error);
    }

    getErrors() {
        return this.errors;
    }
}

interface SetupPageState {
    inProgress: boolean;
    pageSetupCompleted: boolean;
    setupLogs: { [key: string]: SetupLog };
}

export const useSetupPagesStore = defineStore("setupPage", {
    state: (): SetupPageState => {
        return {
            inProgress: false,
            pageSetupCompleted: false,
            setupLogs: {},
        };
    },
    getters: {},
    actions: {
        async setupPage() {
            this.inProgress = true;
            const { $axios } = useNuxtApp();
            // console.log(JSON.stringify(navbar));
            const campaignDataStore = useCampaignDataStore();

            const campaignUuid = campaignDataStore.campaignUUId;

            const defaultPage = new DefaultPage();

            const sharedComponents = defaultPage.getSharedComponents();

            const sharedComponentPromises = defaultPage
                .getSharedComponents()
                .map(async (sharedComponent) => {
                    const setupLog = new SetupLog(
                        "Shared Component",
                        "shared-component",
                        sharedComponents.length
                    );

                    this.setupLogs["shared-component"] = setupLog;

                    //const sharedComponentId = await this.saveSharedComponent(sharedComponent.getType(), sharedComponent.toPayload(), campaignUuid);
                    const sharedComponentId =
                        await this.recursiveSaveSharedComponent(
                            sharedComponent,
                            campaignUuid
                        );
                    sharedComponent.setSharedComponentId(sharedComponentId);
                    setupLog.completeTask();
                });

            await Promise.all(sharedComponentPromises);

            const pageSetup = defaultPage.getSubPages().map(async (subPage) => {
                const pageComponents = subPage.getPageComponents();

                const setupLog = new SetupLog(
                    subPage.getName(),
                    subPage.getPageType(),
                    pageComponents.length
                );

                this.setupLogs[subPage.getPageType()] = setupLog;

                try {
                    const { data, error } = await useApiFetch(
                        `/pagebuilderms/page/setup/${campaignUuid}`,
                        {
                            method: "POST",
                            key: "page-key-" + subPage.getPageType(),
                            body: {
                                name: campaignDataStore.campaignName,
                                type: subPage.getPageType(),
                            },
                        }
                    );

                    //console.log("Page Created--------", data.value, error.value);
                    const pageId = data.value.page.id;
                    //console.log("pageId-----", pageId);

                    for (let i: any = 0; i < pageComponents.length; i++) {
                        //console.log(
                        //    "Save page component",
                        //    pageComponents[i].getType()
                        //);

                        const saved =
                            await this.recursiveGetPayloadOfAllComponent(
                                pageComponents[i],
                                null,
                                campaignUuid,
                                pageId
                            );
                        //this.setupLogs[subPage.getPageType()].completedTasks = this.setupLogs[subPage.getPageType()].completedTasks + 1;

                        this.setupLogs[subPage.getPageType()].completeTask();

                        //console.log(
                        //    "Is Saved",
                        //    i,
                        //    pageComponents[i].getType(),
                        //    saved
                        //);
                    }
                } catch (e: any) {
                    console.log("E", e);
                    this.setupLogs[subPage.getPageType()].errors.push(e);
                }
                setupLog.markAsCompleted();
            });

            await Promise.all(pageSetup);

            const pageStore = usePagesStore();
            await pageStore.getPageFromServer(EComponents.HOME_PAGE);
            this.pageSetupCompleted = true;
            this.inProgress = false;
        },
        /**
         * Iterating recursively to all page component to save into database
         *
         * This is saving the given component and sub components, send data to backend server in a proper way
         */
        async recursiveGetPayloadOfAllComponent(
            comp: IComponent<any>,
            parentId: string | number | null,
            campaignUuid: string,
            pageId: string | number,
            order?: any
        ) {
            try {
                let payload = {};
                if (comp.isSharedComponent()) {
                    console.log("Shared component------>", comp.getType());
                    payload = { data: { isSharedComponent: true } };
                } else {
                    payload = comp.toPayload();
                }

                const sharedComponentId = comp.getSharedComponentId();
                const componentId = await this.saveComponent(
                    payload,
                    parentId,
                    pageId,
                    campaignUuid,
                    order,
                    sharedComponentId ?? undefined
                );

                if (comp.isSharedComponent()) {
                    return Promise.resolve(componentId);
                }
                const subComps = comp.getComponents();

                for (let i: any = 0; i < subComps.length; i++) {
                    await this.recursiveGetPayloadOfAllComponent(
                        subComps[i],
                        componentId,
                        campaignUuid,
                        pageId
                    );
                }

                return Promise.resolve(componentId);
            } catch (e) {
                console.log("comp----", comp, e);
                return false;
            }

            // return true;
        },
        /**
         * Save the given component and return with the d
         * @param payload
         * @param parentId
         * @param pageId
         * @param campaignUuid
         * @returns
         */
        async saveComponent(
            payload: any,
            parentId: any,
            pageId: string | number,
            campaignUuid: string,
            order?: any,
            sharedComponentId?: string | number
        ) {
            const body: { [key: string]: any } = {
                data: payload,
                parentId: parentId,
                previousComponentId: order?.previousComponentId
                    ? order.previousComponentId
                    : null,
                nextComponentId: order?.nextComponentId
                    ? order.nextComponentId
                    : null,
            };

            if (sharedComponentId) {
                body.sharedComponentId = sharedComponentId;
            }

            const { data, error } = await useApiFetch(
                `/pagebuilderms/page/${campaignUuid}/component/${pageId}`,
                {
                    method: "POST",
                    body: body,
                }
            );

            //console.log("saveComponent error--->: ", error.value);
            //console.log("saveComponent data: ", data.value);
            return data.value.component.id;
        },

        /**
         * Save the given component and return with the d
         * @param payload
         * @param parentId
         * @param pageId
         * @param campaignUuid
         * @returns
         */
        async saveSharedComponent(
            type: any,
            payload: any,
            campaignUuid: string,
            parentId?: number
        ) {
            const { data, error } = await useApiFetch(
                `/pagebuilderms/page/${campaignUuid}/shared-component`,
                {
                    method: "POST",
                    key: "save-shared-component-" + type,
                    body: {
                        data: payload,
                        parentId: parentId,
                    },
                }
            );

            console.log("save Shared Component error--->: ", error.value);
            console.log("save Shared Component data: ", data.value);
            return data.value.component.id;
        },

        async recursiveSaveSharedComponent(
            sharedComponent: IComponent<any>,
            campaignUuid: string,
            parentId?: number
        ) {
            const sharedComponentId = await this.saveSharedComponent(
                sharedComponent.getType(),
                sharedComponent.toPayload(),
                campaignUuid,
                parentId
            );

            const subComps = sharedComponent.getComponents();

            for (let i: any = 0; i < subComps.length; i++) {
                await this.recursiveSaveSharedComponent(
                    subComps[i],
                    campaignUuid,
                    sharedComponentId
                );
            }
            return sharedComponentId;
        },
    },
});
