<template>
    <section id="user-consenting">
        <div id="login" class="login py-10" :style="backgroundStyle">
            <div class="container">
                <div class="col-12 col-md-6 ms-auto me-auto">
                    <h2
                        class="text-center mb-5"
                        :style="sectionTitleStyle"
                        v-html="section.sectionTitle"
                    ></h2>
                    <div
                        class="description text-center mb-5"
                        :style="sectionParagraphStyle"
                        v-html="section.sectionParagraph"
                    ></div>

                    <SwipeButton
                        class="mb-4"
                        :style="loginToggleButtonMergedStyle"
                        v-model="loginPage.selected"
                        :buttons="toggleButtonTypes"
                    />

                    <LoginForm :component="section.components" />

                    <!-- <div
                        class="text-center mt-2"
                        :style="sectionParagraphStyle"
                    >
                        Még nem regisztráltál?
                        <el-link
                            class="fw-bold"
                            :style="sectionLinkStyle"
                            @click="handleRegistrationButtonClick"
                            :underline="false"
                        >
                            Regisztrálj itt
                        </el-link>
                    </div> -->
                </div>
                <div class="col-12 col-md-6"></div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { useLoginRegistrationStore } from "~~/store/components/loginRegistration/loginRegistration";
import { getStyle } from "../../composables/styleHelper";
import { replaceEmail } from "../../composables/replaceEmail";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

const loginRegistrationStore: any = useLoginRegistrationStore();

interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const toggleButtonTypes = [
    {
        name: "Regisztráció",
        value: "registration",
    },
    {
        name: "Bejelentkezés",
        value: "login",
    },
];

const loginPage = reactive({
    selected: "login",
});

const imageXs = `url('${useMediaUrl(props.section.images[5]?.src)}')`;
const imageSm = `url('${useMediaUrl(props.section.images[4]?.src)}')`;
const imageMd = `url('${useMediaUrl(props.section.images[3]?.src)}')`;
const imageLg = `url('${useMediaUrl(props.section.images[2]?.src)}')`;
const imageXl = `url('${useMediaUrl(props.section.images[1]?.src)}')`;
const imageXxl = `url('${useMediaUrl(props.section.images[0]?.src)}')`;

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "loginBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "loginTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "loginParagraph");
});
const sectionLinkStyle = computed(() => {
    return {
        "--login-registration-link-color":
            sectionParagraphStyle.value[EProperty.COLOR],
    };
});
const loginToggleButtonStyle = computed(() => {
    return getStyle(props.section.style ?? [], "loginToggleButton");
});
const loginToggleButtonActiveStyle = computed(() => {
    return getStyle(props.section.style ?? [], "loginToggleButtonActive");
});
const loginToggleButtonMergedStyle = computed(() => {
    return {
        "--toggle-button-color": loginToggleButtonStyle.value[EProperty.COLOR],
        "--toggle-button-active-color":
            loginToggleButtonActiveStyle.value[EProperty.COLOR],
        "--toggle-button-active-background-color":
            loginToggleButtonActiveStyle.value[EProperty.BACKGROUND_COLOR],
        "--toggle-button-border-color":
            loginToggleButtonStyle.value[EProperty.BORDER_COLOR],
        "--toggle-button-background-color":
            loginToggleButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--toggle-button-border-radius":
            loginToggleButtonStyle.value[EProperty.BORDER_RADIUS],
    };
});

watch(
    () => loginPage.selected,
    (newVal) => {
        if (newVal == "registration") {
            loginRegistrationStore.setActiveComponent(EComponents.REGISTRATION);
        }
    }
);
</script>

<style lang="scss">
.login {
    background-image: v-bind("imageXs");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}

.el-link {
    color: var(--login-registration-link-color) !important;

    &:hover {
        color: var(--login-registration-link-color) !important;
    }
}
</style>
