<template>
    <div
        ref="prizificPageBuilderTool"
        :class="[
            'prizific-page-builder-tool',
            { opened: prizificPageBuilderToolData.opened },
            {
                'd-none': !campaignDataStore.showPageBuilderControls,
            },
        ]"
    >
        <!-- Settings button -->
        <client-only>
            <el-tooltip
                :content="`Edit ${sectionTitle}`"
                effect="prizific"
                placement="bottom"
                :disabled="prizificPageBuilderToolData.show"
            >
                <div
                    :class="[
                        'prizific-page-builder-tool__open',
                        { active: prizificPageBuilderToolData.show },
                    ]"
                    @click="
                        prizificPageBuilderToolData.show =
                            !prizificPageBuilderToolData.show
                    "
                >
                    <span class="material-icons">settings</span>
                </div>
            </el-tooltip>
        </client-only>
        <!-- Settings button end -->

        <Transition
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
            v-on:before-enter="beforeEnter"
            v-on:after-leave="afterLeave"
        >
            <div
                v-if="prizificPageBuilderToolData.show"
                class="prizific-page-builder-tool__popup"
            >
                <div class="d-flex align-items-center fs-5 mb-5 lh-1">
                    Edit {{ sectionTitle }}
                    <PrizificPageBuilderUpdateProgress />
                </div>

                <!-- Section params -->
                <div v-if="hasSectionParams" class="mb-3 small text-black-50">
                    Section paramaters:
                </div>
                <el-collapse
                    v-model="prizificPageBuilderToolData.collapsedParams"
                >
                    <el-collapse-item
                        :title="sectionTitle.toUpperCase()"
                        :name="section.title"
                    >
                        <PrizificPageBuilderToolSection
                            :section="section"
                            :page="props.page"
                        />
                    </el-collapse-item>
                </el-collapse>
                <!-- Section params end -->

                <!-- Section components -->
                <div
                    v-if="hasSectionComponents"
                    class="mt-5 mb-3 small text-black-50"
                >
                    Section components:
                </div>

                <PrizificPageBuilderToolComponent
                    v-if="hasSectionComponents"
                    :section="section"
                    :componentComponents="section.getComponents()"
                    :addableListItem="
                        hasSection(section, EComponents.GAME_STEPS) ||
                        hasSection(section, EComponents.YOUTUBE_VIDEO)
                    "
                />
                <!-- Section components end -->
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { ElIcon } from "element-plus";
import { Loading, Check, Close } from "@element-plus/icons-vue";
import { usePagesStore, EUpdateProgress } from "@/store/pages";
import { onClickOutside } from "@vueuse/core";
import { ElCollapse, ElCollapseItem, ElTooltip } from "element-plus";
import { IComponent } from "~/interfaces/PrizificPageBuilder/IComponent";
import { ISection } from "~~/interfaces/PrizificPageBuilder/ISection";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { useCampaignDataStore } from "@/store/campaign/campaignData";

/**
 * Use page store
 */
const pageStore = usePagesStore();
const campaignDataStore: any = useCampaignDataStore();

interface Props {
    page?: any;
    section: IComponent<EComponents> | any;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const prizificPageBuilderTool = ref(null);

const prizificPageBuilderToolData = reactive({
    show: false,
    collapsedParams: [],
    collapsedComponents: [],
    opened: false,
});

onMounted(() => {
    onClickOutside(prizificPageBuilderTool, (event: any) => {
        closeIfClickOutside(event);
    });
});

const hasSectionParams = computed(() => {
    return Object.keys(props.section.style).length;
});

const hasSectionComponents = computed(() => {
    return props.section?.getComponents()?.length;
});

const sectionTitle = computed(() => {
    return props.section?.title;
});

/**
 * If click outside PPB Tool then close PPB Tool
 * We need to checking color-picker because its outer in PPB Tool wrapper
 */
function closeIfClickOutside(event: any) {
    const elColorRegExp = new RegExp("el-color");
    const elInputInnerRegExp = new RegExp("el-input__inner");

    if (
        !elColorRegExp.test(event.target.className) &&
        !elInputInnerRegExp.test(event.target.className) &&
        event.target.className !== ""
    ) {
        prizificPageBuilderToolData.show = false;
    }
}

/**
 * Helper function to fade animation
 * We need for smooth animation
 */
function beforeEnter() {
    prizificPageBuilderToolData.opened = !prizificPageBuilderToolData.opened;
}

/**
 * Helper function to fade animation
 * We need for smooth animation
 */
function afterLeave() {
    prizificPageBuilderToolData.opened = !prizificPageBuilderToolData.opened;
}

/**
 * Helper function
 *
 * @param section
 * @param sectionType
 */
function hasSection(
    section: IComponent<EComponents> | any,
    sectionType: EComponents
) {
    if (section.type == sectionType) {
        return true;
    } else {
        return false;
    }
}
</script>

<style lang="scss" scoped>
.prizific-page-builder-tool {
    position: absolute;
    top: calc(50% - 20px);
    z-index: 2;
    right: 32px;
    display: flex;
    min-width: 40px;
    max-width: 40px;
    border-radius: 50%;
    flex-direction: column;
    font-family: "Poppins" !important;

    &.opened {
        border-radius: 0;
        min-width: 420px;
        max-width: 720px;
        z-index: 3;
    }

    &__open {
        display: flex;
        width: 40px;
        height: 40px;
        cursor: pointer;
        color: #6e6e73;
        user-select: none;
        margin-left: auto;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: #ffffffb3;
        backdrop-filter: saturate(50%) blur(8px);
        box-shadow: 2px 4px 12px rgb(0 0 0 / 14%);
        transition: color 0.2s ease-in-out, transform 0.25s ease-in-out;

        &.active {
            color: black;
            transform: rotate(120deg);
        }

        &:hover {
            color: black;
        }
    }

    &__popup {
        position: relative;
        padding: 30px;
        margin-top: 10px;
        white-space: normal;
        border-radius: 18px;
        background-color: #ffffff;
        backdrop-filter: saturate(50%) blur(8px);
        box-shadow: 2px 4px 12px rgb(0 0 0 / 14%);
        max-height: 560px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: #f1f1f1;
        }

        &::before {
            position: absolute;
            content: "";
            right: 12px;
            top: -7px;
            border-style: solid;
            border-width: 0px 8px 8px 8px;
            border-color: transparent transparent #ffffff transparent;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
