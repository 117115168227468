<template>
    <section>
        <div
            class="py-5 overflow-hidden contact-us-wrapper"
            :style="[backgroundStyleObject]"
        >
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 text-center">
                        <h2
                            class="d-inline-block fw-bold text-uppercase px-4"
                            :style="titleStyleObject"
                            v-if="section.sectionTitleRaw"
                            v-html="section.sectionTitleRaw"
                        ></h2>
                        <br />
                        <div
                            class="d-block"
                            :style="paragraphStyleObject"
                            v-if="section.sectionParagraphRaw"
                            v-html="section.sectionParagraphRaw"
                        ></div>
                        <a
                            class="d-inline-block fw-bold text-decoration-none text-uppercase mt-0 mb-5 mb-md-0 mt-md-6"
                            :style="buttonStyleObject"
                            :href="`mailto:${campaign.contactEmail}`"
                            v-html="contactButtonTextRaw"
                        ></a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { getStyle } from "../../composables/styleHelper";
export default {
    props: {
        section: {
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        const campaignDataStore = useCampaignDataStore();
        const runtime = useRuntimeConfig();

        return {
            campaign: {
                contactEmail: campaignDataStore.campaignData.contactEmail,
            },
            imageXs: `url('${useMediaUrl(this.section.images[0]?.src ?? "")}')`,
            imageSm: `url('${useMediaUrl(this.section.images[1]?.src ?? "")}')`,
            imageMd: `url('${useMediaUrl(this.section.images[2]?.src ?? "")}')`,
            imageLg: `url('${useMediaUrl(this.section.images[3]?.src ?? "")}')`,
            imageXl: `url('${useMediaUrl(this.section.images[4]?.src ?? "")}')`,
            imageXxl: `url('${useMediaUrl(
                this.section.images[5]?.src ?? ""
            )}')`,
        };
    },
    computed: {
        /**
         * Get background style
         * @returns {any} object
         */
        backgroundStyleObject(): any {
            return getStyle(this.section?.style ?? [], "contactBackground");
        },

        /**
         * Get background-image style
         * @returns {any} object
         */
        sectionBackgroundImage() {
            return this.section?.src
                ? {
                      backgroundImage: `url(${useMediaUrl(
                          this.section?.src ?? ""
                      )})`,
                  }
                : "";
        },

        /**
         * Get title style
         * @returns {any} object
         */
        titleStyleObject(): any {
            return getStyle(this.section?.style ?? "", "contactTitle");
        },

        /**
         * Get paragraph style
         * @returns {any} object
         */
        paragraphStyleObject(): any {
            return getStyle(this.section?.style ?? "", "contactParagraph");
        },

        /**
         * Get button style
         * @returns {any} object
         */
        buttonStyleObject(): any {
            return getStyle(
                this.section.components[0]?.style ?? [],
                "contactButton"
            );
        },

        /**
         * Get button text
         * @returns {string}
         */
        contactButtonText() {
            return this.section.components[0]?.text ?? "";
        },
        contactButtonTextRaw() {
            return this.section.components[0]?.textRaw ?? "";
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-us-wrapper {
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}
</style>
