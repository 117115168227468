<template>
    <div class="prizific-responsive-view-control">
        <div
            v-if="campaignDataStore.isPageBuilder"
            class="prizific-responsive-view-control__panel"
        >
            <template
                v-for="(responsiveViewType, index) in responsiveView.types"
            >
                <button
                    @click="setResponsiveView(responsiveViewType.size)"
                    :class="[
                        'btn btn-dark',
                        { 'ms-auto': index == 0 },
                        {
                            active:
                                responsiveViewType.size ==
                                responsiveView.selected,
                        },
                    ]"
                >
                    <img
                        class="mt-auto ms-auto me-auto img-fluid"
                        :src="responsiveViewType.icon"
                    />
                    <div class="ms-auto me-auto">
                        {{ responsiveViewType.name }}
                    </div>
                </button>
            </template>
            <button
                :class="[
                    'btn btn-dark ms-auto me-4',
                    { active: campaignDataStore.showPageBuilderControls },
                ]"
                @click="togglePageBuilderControls"
            >
                <span class="material-icons-outlined"> construction </span>
                <div class="ms-auto me-auto">
                    <template v-if="campaignDataStore.showPageBuilderControls">
                        Show
                    </template>
                    <template v-else> Hide </template>
                </div>
            </button>
        </div>
        <div
            class="prizific-responsive-view-control__body"
            :style="{ width: responsiveView.selected }"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useCampaignDataStore } from "~~/store/campaign/campaignData";

const campaignDataStore = useCampaignDataStore();

const responsiveView = reactive({
    selected: "100%",
    types: [
        {
            name: "Fullscreen",
            size: "100%",
            icon: useCdnUrl("/images/desktop_icon.svg"),
        },
        {
            name: "Desktop",
            size: "1873px",
            icon: useCdnUrl("/images/desktop_icon.svg"),
        },
        {
            name: "Laptop",
            size: "1319px",
            icon: useCdnUrl("/images/laptop_icon.svg"),
        },
        {
            name: "Tablet",
            size: "992px",
            icon: useCdnUrl("/images/tablet_icon.svg"),
        },
        {
            name: "Mobile",
            size: "400px",
            icon: useCdnUrl("/images/mobile_icon.svg"),
        },
    ],
});

function setResponsiveView(selectedTypeSize) {
    responsiveView.selected = selectedTypeSize;
}

function togglePageBuilderControls() {
    campaignDataStore.showPageBuilderControls =
        !campaignDataStore.showPageBuilderControls;
}
</script>

<style lang="scss" scoped>
.prizific-responsive-view-control {
    position: relative;

    &__panel {
        position: sticky;
        width: 100%;
        height: 64px;
        top: 0;
        backdrop-filter: saturate(180%) blur(20px);
        background-color: #212529;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
        padding-left: 82px;
        z-index: 9999;

        .btn {
            display: flex;
            align-items: flex-end;
            flex-flow: column;
            height: 100%;
            font-size: 11px;
            line-height: 1;
            gap: 0.35rem;
            justify-content: center;
            font-family: "Poppins" !important;
            border-radius: 0;

            .material-icons-outlined {
                font-size: 32px;
            }
        }
    }

    &__body {
        margin: 0 auto;
        transition: width 0.35s ease-in-out;
    }
}
</style>
