<template>
    <div :style="newPasswordFormStyle">
        <el-form
            id="newPasswordForm"
            ref="newPasswordForm"
            :style="newPasswordFormMergedStyle"
            :rules="rules"
            :model="newPasswordForm"
            label-position="top"
            @keydown.enter.prevent="submitNewPasswordForm"
            status-icon
        >
            <div class="row">
                <div class="col-xs-12 col-md-4 offset-md-4 text-center">
                    <!-- Password -->
                    <el-form-item prop="password" label="Új jelszó">
                        <PasswordInput v-model="newPasswordForm.password" />
                    </el-form-item>
                    <!-- Password end -->

                    <!-- Submit button -->
                    <el-button
                        class="submit-btn"
                        @click="submitNewPasswordForm"
                        @keyup.space="submitNewPasswordForm"
                        :loading="newPasswordForm.submitButtonClicked"
                    >
                        Mentés
                    </el-button>
                    <!-- Submit button end -->
                </div>
            </div>
        </el-form>
    </div>
</template>

<script setup lang="ts">
import { ElForm, ElFormItem, ElInput, ElCheckbox } from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";

interface Props {
    component: object;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["change"]);

const newPasswordFormRef = ref<FormInstance>();
const router = useRouter();

// const newPasswordComponent = props.component[0];
const newPasswordComponent = props.component[0];
// const feedbackModalsComponent = props.component[1];

const newPasswordForm = reactive({
    password: "",
    submitButtonClicked: false,
});

const rules = reactive<FormRules>({
    password: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
        {
            required: true,
            pattern: /^(?=.{6,})(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*\d+).*$/,
            message: "Hiba a jelszóba kérjük javítsd",
            trigger: "change",
        },
    ],
});

// Computed form and form items style
const newPasswordFormStyle = computed(() => {
    return getStyle(newPasswordComponent.style, "newPasswordFormBackground");
});
const newPasswordFormItemLabelStyle = computed(() => {
    return getStyle(newPasswordComponent.style, "newPasswordFormItemLabel");
});
const newPasswordFormItemInputStyle = computed(() => {
    return getStyle(newPasswordComponent.style, "newPasswordFormItemInput");
});
const newPasswordFormSubmitButtonStyle = computed(() => {
    return getStyle(newPasswordComponent.style, "newPasswordFormSubmitButton");
});
const newPasswordFormSubmitButtonHoverStyle = computed(() => {
    return getStyle(
        newPasswordComponent.style,
        "newPasswordFormSubmitButtonHover"
    );
});

const newPasswordFormMergedStyle = computed(() => {
    return {
        "--new-password-form-label-color":
            newPasswordFormItemLabelStyle.value[EProperty.COLOR],
        "--new-password-form-font-size":
            newPasswordFormItemLabelStyle.value[EProperty.FONT_SIZE],
        "--new-password-form-input-border-radius":
            newPasswordFormItemInputStyle.value[EProperty.BORDER_RADIUS],
        "--new-password-form-submit-color":
            newPasswordFormSubmitButtonStyle.value[EProperty.COLOR],
        "--new-password-form-submit-font-size":
            newPasswordFormSubmitButtonStyle.value[EProperty.FONT_SIZE],
        "--new-password-form-submit-background-color":
            newPasswordFormSubmitButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--new-password-form-submit-border-radius":
            newPasswordFormSubmitButtonStyle.value[EProperty.BORDER_RADIUS],
        "--new-password-form-submit-color-hover":
            newPasswordFormSubmitButtonHoverStyle.value[EProperty.COLOR],
        "--new-password-form-submit-background-color-hover":
            newPasswordFormSubmitButtonHoverStyle.value[
                EProperty.BACKGROUND_COLOR
            ],
    };
});

/**
 * Handle new password form submit
 */
async function submitNewPasswordForm() {
    await newPasswordFormRef.value?.validate((valid, fields) => {
        if (valid) {
            console.log("submit!");
        } else {
            console.log("error submit!", fields);
        }
    });
}

/**
 * Go to homepage
 */
function goToHome() {
    router.push({
        path: useDynamicLink(""),
    });
}

/**
 * Go to homepage
 */
function goToLogin() {
    router.push({
        path: useDynamicLink("/#belepes"),
    });
}
</script>

<style lang="scss">
#newPasswordForm {
    .el-form-item__label {
        color: var(--new-password-form-label-color) !important;
    }

    .el-form-item__label::before {
        content: "" !important;
    }

    .el-input__wrapper {
        border-radius: var(--new-password-form-input-border-radius);
    }

    .el-form-item__error {
        color: white;
        background-color: #e44f60;
        border-radius: 4px;
        padding: 2px 4px;
        margin-top: 5px;
        right: 0;
        left: initial;

        &::before {
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #e44f60;
            width: 0;
            height: 0;
            display: block;
            content: "";
            top: -5px;
            right: 12px;
        }
    }

    .submit-btn {
        border: 0;
        white-space: normal;
        word-wrap: break-word;
        color: var(--new-password-form-submit-color);
        border-radius: var(--new-password-form-submit-border-radius);
        background-color: var(--new-password-form-submit-background-color);

        &:hover {
            color: var(--new-password-form-submit-color-hover);
            background-color: var(
                --new-password-form-submit-background-color-hover
            );
        }

        &.is-disabled {
            background: grey !important;
            color: white !important;
        }
    }
}
</style>
