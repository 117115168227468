import { usePagesStore } from "~~/store/pages";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";





export function useActualPage(forcePageType?: EComponents) {
    const pageStore = usePagesStore();

    if (!forcePageType) {
        return pageStore.getPage(useActualPageType())
    } else {
        return pageStore.getPage(forcePageType)
    }
}
