import Page from "~/entities/PrizificPageBuilder/Page";

import { navbar } from "~/entities/pages/shared/sections/Navbar/Navbar";
import { contact } from "~/entities/pages/shared/sections/Contact/Contact";
import { footer } from "~/entities/pages/shared/sections/Footer/Footer";
import { SubPageSetup } from "./SubPageSetup";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { cookieSettings } from "~/entities/pages/shared/sections/CookieSettings/CookieSettings";
import {
    editProfileBackground,
    editProfileDeleteButtonBackground,
    editProfileDeleteButtonBackgroundHover,
    editProfileDeleteButtonBorderRadius,
    editProfileDeleteButtonFontSize,
    editProfileDeleteButtonPadding,
    editProfileDeleteButtonTextColor,
    editProfileDeleteButtonTextColorHover,
    editProfileGoToButtonBackground,
    editProfileGoToButtonBackgroundHover,
    editProfileGoToButtonBorderRadius,
    editProfileGoToButtonFontSize,
    editProfileGoToButtonPadding,
    editProfileGoToButtonTextColor,
    editProfileGoToButtonTextColorHover,
    editProfileTitleColor,
    editProfileTitleFontSize,
} from "~/entities/pages/edit-profile/sections/EditProfile/EditProfileProperties";
import { editProfile } from "~~/entities/pages/edit-profile/sections/EditProfile/EditProfile";

export class DefaultEditProfilePageSetup extends SubPageSetup {
    protected page;
    protected name = "Edit profile";

    constructor() {
        super();

        this.page = new Page(
            "-",
            "Edit profile",
            EComponents.EDIT_PROFILE_PAGE,
            [
                editProfileBackground,
                editProfileTitleColor,
                editProfileTitleFontSize,
                editProfileGoToButtonPadding,
                editProfileGoToButtonFontSize,
                editProfileGoToButtonBackground,
                editProfileGoToButtonTextColor,
                editProfileGoToButtonBorderRadius,
                editProfileGoToButtonBackgroundHover,
                editProfileGoToButtonTextColorHover,
                editProfileDeleteButtonPadding,
                editProfileDeleteButtonFontSize,
                editProfileDeleteButtonBackground,
                editProfileDeleteButtonTextColor,
                editProfileDeleteButtonBorderRadius,
                editProfileDeleteButtonBackgroundHover,
                editProfileDeleteButtonTextColorHover,
            ],
            "",
            [navbar, editProfile, contact, footer, cookieSettings],
            {}
        );
    }
}
