<template>
    <!-- Hero image -->
    <section class="position-relative">
        <div class="hero-text" :style="heroStyleObject">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <h1
                            v-if="section && section.sectionTitleRaw"
                            v-html="section.sectionTitleRaw"
                        ></h1>
                        <p
                            v-if="section && section.sectionParagraphRaw"
                            v-html="section.sectionParagraphRaw"
                        ></p>
                    </div>
                </div>
            </div>
        </div>
        <picture>
            <source
                :srcset="heroImageXXLWebp"
                media="(min-width: 1920px)"
                type="image/webp"
            />
            <source
                :srcset="heroImageXLWebp"
                media="(min-width: 1400px)"
                type="image/webp"
            />
            <source
                :srcset="heroImageLWebp"
                media="(min-width: 1200px)"
                type="image/webp"
            />
            <source
                :srcset="heroImageMWebp"
                media="(min-width: 992px)"
                type="image/webp"
            />
            <source
                :srcset="heroImageSWebp"
                media="(min-width: 768px)"
                type="image/webp"
            />
            <source
                :srcset="heroImageXSWebp"
                media="(min-width: 576px)"
                type="image/webp"
            />
            <img class="img-fluid w-100" :src="heroImageXSPng" alt="" />
        </picture>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
    <!-- Hero image end -->
</template>

<script setup lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { ImageFormat, useImageMediaUrl } from "~~/composables/useImageMedia";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { EImageType } from "~~/enums/PrizificPageBuilder/EImageType";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { IImg } from "~~/interfaces/PrizificPageBuilder/IImg";
import { ISection } from "~~/interfaces/PrizificPageBuilder/ISection";
const campaignDataStore = useCampaignDataStore();
const runtime = useRuntimeConfig();

interface Props {
    section: IComponent<EComponents> & ISection;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Create hero inline style object
 * @returns {Object} styleObject
 */
const heroStyleObject = computed(() => {
    return getStyle(props.section.style, "heroSection");
});

const heroImageXSPng = computed(() => {
    const image = props.section.images?.filter(
        (image: IImg) => image.imageType === EImageType.EXTRA_SMALL
    );
    return `${
        image[0]?.src
            ? useImageMediaUrl(image[0]?.src, ImageFormat.PNG, 576)
            : useCdnUrl("/images/hero-image-placeholder.png")
    }`;
});

const heroImageXXLWebp = computed(() => {
    const image = props.section.images?.filter(
        (image: IImg) => image.imageType === EImageType.EXTRA_EXTRA_LARGE
    );
    return `${
        image[0]?.src
            ? useImageMediaUrl(image[0]?.src, ImageFormat.WEBP, 1920)
            : useCdnUrl("/images/hero-image-placeholder.png")
    }`;
});

const heroImageXLWebp = computed(() => {
    const image = props.section.images?.filter(
        (image: IImg) => image.imageType === EImageType.EXTRA_LARGE
    );
    return `${
        image[0].src
            ? useImageMediaUrl(image[0]?.src, ImageFormat.WEBP, 1400)
            : useCdnUrl("/images/hero-image-placeholder.png")
    }`;
});

const heroImageLWebp = computed(() => {
    const image = props.section.images?.filter(
        (image: IImg) => image.imageType === EImageType.LARGE
    );
    return `${
        image[0]?.src
            ? useImageMediaUrl(image[0]?.src, ImageFormat.WEBP, 1200)
            : useCdnUrl("/images/hero-image-placeholder.png")
    }`;
});

const heroImageMWebp = computed(() => {
    const image = props.section.images?.filter(
        (image: IImg) => image.imageType === EImageType.MEDIUM
    );
    return `${
        image[0]?.src
            ? useImageMediaUrl(image[0]?.src, ImageFormat.WEBP, 992)
            : useCdnUrl("/images/hero-image-placeholder.png")
    }`;
});

const heroImageSWebp = computed(() => {
    const image = props.section.images?.filter(
        (image: IImg) => image.imageType === EImageType.SMALL
    );
    return `${
        image[0]?.src
            ? useImageMediaUrl(image[0]?.src, ImageFormat.WEBP, 768)
            : useCdnUrl("/images/hero-image-placeholder.png")
    }`;
});

const heroImageXSWebp = computed(() => {
    const image = props.section.images?.filter(
        (image: IImg) => image.imageType === EImageType.EXTRA_SMALL
    );
    return `${
        image[0]?.src
            ? useImageMediaUrl(image[0]?.src, ImageFormat.WEBP, 576)
            : useCdnUrl("/images/hero-image-placeholder.png")
    }`;
});
</script>

<style lang="scss" scoped>
.hero-text {
    position: absolute;
    width: 100%;
    top: 6rem;
}

.hero-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    min-height: 219px;
    background-color: #fdfcf9;

    .hero-text-wrapper {
        position: relative;
        margin-top: 2rem;

        h1 {
            position: relative;
            margin-bottom: 0;
            margin-top: 0;
        }

        .hero-text {
            position: relative;
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }
}

@media (min-width: 768px) {
    .hero-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        min-height: 419px;

        .hero-text-wrapper {
            position: relative;
            margin-top: 2rem;

            h1 {
                position: relative;
                font-weight: 800;
                margin-bottom: 0;
            }

            .hero-text {
                position: relative;
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 0;
            }
        }
    }
}
</style>
