<template>
    <section id="user-consenting" class="user-consenting">
        <div class="py-10" :style="backgroundStyle">
            <div class="container">
                <div class="col-12 col-md-10 ms-auto me-auto">
                    <!--<h2 class="text-center mb-5" v-html="section?.sectionTitle"></h2>
                    <div class="text-center mb-5">
                        <button class="el-button submit-btn" @click="redirectUserToLoginAndConsent">Continue to accept
                            terms</button>
                    </div>-->

                    <iframe
                        id="loginFrame"
                        :style="{ height: `${loginCustomization.height}px` }"
                        :src="authorizationUrl"
                        class="w-100 login-iframe"
                        frameBorder="0"
                        allowtransparency="true"
                    ></iframe>

                    <!--<div class="text-center mb-5">
                        <small class="">Just 1 more step to upload your code</small>
                    </div>-->
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { getStyle } from "../../composables/styleHelper";
import { useAuthStore } from "@/store/user/auth";
import { EHiveLoginText } from "@/enums/PrizificPageBuilder/EHiveLoginText";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { ISection } from "~~/interfaces/PrizificPageBuilder/ISection";
import { ELoginFormItem } from "~~/enums/PrizificPageBuilder/ELoginFormItem";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";
import { useCampaignDataStore } from "@/store/campaign/campaignData";

interface Props {
    section: IComponent<EComponents> & ISection & any;
    editable: boolean;
}
const authorizationUrl = ref("");

const authStore = useAuthStore();
const campaignDataStore = useCampaignDataStore();

const loginCustomization = reactive({
    guestTitle: "-",
    guestDescription: "-",
    loggedInTitle: "-",
    loggedInDescription: "-",
    height: "400",
});

async function iframeConsentEventListener(event: any) {
    if (event.data == "auth-state-changed") {
        await authStore.getMe();
    }

    if (
        typeof event.data === "string" &&
        event.data.startsWith("consent-height-changes:")
    ) {
        loginCustomization.height = event.data.replace(
            "consent-height-changes:",
            ""
        );
    }
}

onMounted(async () => {
    makeUrlHiveAuthUrl();

    // In parent app
    // Bind a message event listener to window object
    window.addEventListener("message", iframeConsentEventListener);
});

onUnmounted(async () => {
    window.removeEventListener("message", iframeConsentEventListener);
});

async function redirectUserToLoginAndConsent() {
    window.location.replace(authorizationUrl.value);
}

/**
 * Define props
 */
const props = defineProps<Props>();

// Computed section, form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "userConsentingBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "userConsentingTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "userConsentingParagraph");
});
const sectionPrimaryColorStyle = computed(() => {
    return getStyle(props.section.style, "userConsentingPrimaryColor");
});
const sectionSecondaryColorStyle = computed(() => {
    return getStyle(props.section.style, "userConsentingSecondaryColor");
});
const formBackgroundStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "formBackground");
});
const toggleButtonStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "toggleButton");
});
const toggleButtonActiveStyle = computed(() => {
    return getStyle(
        props.section.getComponents()[0].style,
        "toggleButtonActive"
    );
});
const formItemLabelStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "formItemLabel");
});
const formItemInputStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "formItemInput");
});
const formCheckboxStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "formCheckbox");
});
const formSubmitButtonStyle = computed(() => {
    return getStyle(props.section.getComponents()[0].style, "formSubmitButton");
});
const formSubmitButtonHoverStyle = computed(() => {
    return getStyle(
        props.section.getComponents()[0].style,
        "formSubmitButtonHover"
    );
});

const loginInputEmailTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[0],
        ELoginFormItem.EMAIL
    );
});
const loginInputPasswordTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[0],
        ELoginFormItem.PASSWORD
    );
});

const registrationInputFirstNameTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.FIRSTNAME
    );
});
const registrationInputLastNameTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.LASTNAME
    );
});
const registrationInputEmailTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.EMAIL
    );
});
const registrationInputPhoneTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.PHONE_NUMBER
    );
});
const registrationInputZipTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.ZIP
    );
});
const registrationInputCityTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.CITY
    );
});
const registrationInputAddressTexts = computed(() => {
    return getFormItemText(
        props.section.getComponents()[0].getComponents()[1],
        ERegistrationFormItem.ADDRESS
    );
});

const imageXs = `url('${useMediaUrl(props.section.images[5]?.src)}')`;
const imageSm = `url('${useMediaUrl(props.section.images[4]?.src)}')`;
const imageMd = `url('${useMediaUrl(props.section.images[3]?.src)}')`;
const imageLg = `url('${useMediaUrl(props.section.images[2]?.src)}')`;
const imageXl = `url('${useMediaUrl(props.section.images[1]?.src)}')`;
const imageXxl = `url('${useMediaUrl(props.section.images[0]?.src)}')`;

/**
 * Section and all component style
 */
const userConsentStyle = reactive({
    html: { overflow: "hidden" },
    sectionBackground: backgroundStyle,
    sectionTitle: sectionTitleStyle,
    sectionParagraph: sectionParagraphStyle,
    sectionPrimaryColor: sectionPrimaryColorStyle,
    sectionSecondaryColor: sectionSecondaryColorStyle,
    formBackground: formBackgroundStyle,
    toggleButton: toggleButtonStyle,
    toggleButtonActive: toggleButtonActiveStyle,
    formItemLabel: formItemLabelStyle,
    formItemInput: formItemInputStyle,
    formItemCheckbox: formCheckboxStyle,
    formSubmitButton: formSubmitButtonStyle,
    formSubmitButtonHover: formSubmitButtonHoverStyle,
    loginEmail: loginInputEmailTexts,
    loginPassword: loginInputPasswordTexts,
    registrationFirstName: registrationInputFirstNameTexts,
    registrationLastName: registrationInputLastNameTexts,
    registrationEmail: registrationInputEmailTexts,
    registrationPhoneNumber: registrationInputPhoneTexts,
    registrationZip: registrationInputZipTexts,
    registrationCity: registrationInputCityTexts,
    registrationAddress: registrationInputAddressTexts,
    loginSectionTitle: removeParagraph(
        props.section.getComponents()[0]?.getComponents()[2]?.textRaw
    ),
    loginSectionParagraph: removeParagraph(
        props.section.getComponents()[0]?.getComponents()[3]?.textRaw
    ),
    registrationSectionTitle: removeParagraph(
        props.section.getComponents()[0]?.getComponents()[4]?.textRaw
    ),
    registrationSectionParagraph: removeParagraph(
        props.section.getComponents()[0]?.getComponents()[5]?.textRaw
    ),
    acceptUserConsentSectionTitle: removeParagraph(
        props.section.getComponents()[0]?.getComponents()[6]?.textRaw
    ),
    acceptUserConsentSectionParagraph: removeParagraph(
        props.section.getComponents()[0]?.getComponents()[7]?.textRaw
    ),
    imageXs: `url('${useMediaUrl(props.section.images[5]?.src)}')`,
    imageSm: `url('${useMediaUrl(props.section.images[4]?.src)}')`,
    imageMd: `url('${useMediaUrl(props.section.images[3]?.src)}')`,
    imageLg: `url('${useMediaUrl(props.section.images[2]?.src)}')`,
    imageXl: `url('${useMediaUrl(props.section.images[1]?.src)}')`,
    imageXxl: `url('${useMediaUrl(props.section.images[0]?.src)}')`,
});

function removeParagraph(text: any): any {
    if (text && typeof text == "string") {
        return text.replaceAll("<p>", "").replaceAll("</p>", "");
    }
    return text;
}

watch(
    () => [props.section.style, props.section.getComponents()],
    () => {
        //console.log("It's changed!");
        makeUrlHiveAuthUrl();
    }
);

async function makeUrlHiveAuthUrl() {
    authorizationUrl.value = await authStore.oauth2LoginStart(
        campaignDataStore.campaignUUId,
        userConsentStyle as any
    );
}

/**
 * Helper function to get readable authorizationUrl
 * @param url
 */
function getUrlParams(url: string) {
    if (!url) {
        return;
    }
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const result: any[] = [];

    params.forEach((value, name) => {
        result.push({ name: name, value: value });
    });

    return result;
}
</script>

<style lang="scss">
.user-consenting {
    background-image: v-bind("imageXs");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}
.login-iframe {
    min-height: 500px;
    overflow: hidden;

    html {
        overflow: hidden;
    }
}
</style>
