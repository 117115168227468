import Page from "~/entities/PrizificPageBuilder/Page";

import { navbar } from "~/entities/pages/shared/sections/Navbar/Navbar";
import { contact } from "~/entities/pages/shared/sections/Contact/Contact";
import { footer } from "~/entities/pages/shared/sections/Footer/Footer";
import { SubPageSetup } from "./SubPageSetup";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { cookieSettings } from "~/entities/pages/shared/sections/CookieSettings/CookieSettings";
import { editPassword } from "~~/entities/pages/edit-password/sections/EditPassword/EditPassword";
import {
    editPasswordBackground,
    editPasswordTitleColor,
    editPasswordTitleFontSize,
    editPasswordGoToButtonPadding,
    editPasswordGoToButtonFontSize,
    editPasswordGoToButtonBackground,
    editPasswordGoToButtonTextColor,
    editPasswordGoToButtonBorderRadius,
    editPasswordGoToButtonBackgroundHover,
    editPasswordGoToButtonTextColorHover,
} from "~/entities/pages/edit-password/sections/EditPassword/EditPasswordProperties";

export class DefaultEditPasswordPageSetup extends SubPageSetup {
    protected page;
    protected name = "Edit password";

    constructor() {
        super();

        this.page = new Page(
            "-",
            "Edit password",
            EComponents.EDIT_PASSWORD_PAGE,
            [
                editPasswordBackground,
                editPasswordTitleColor,
                editPasswordTitleFontSize,
                editPasswordGoToButtonPadding,
                editPasswordGoToButtonFontSize,
                editPasswordGoToButtonBackground,
                editPasswordGoToButtonTextColor,
                editPasswordGoToButtonBorderRadius,
                editPasswordGoToButtonBackgroundHover,
                editPasswordGoToButtonTextColorHover,
            ],
            "",
            [navbar, editPassword, contact, footer, cookieSettings],
            {}
        );
    }
}
