<template>
    <div class="email-with-correction-tag">
        <el-tag
            v-if="emailTag.show"
            class="email-corrected-tag"
            @click="acceptCorrectedEmail()"
            @close="emailTag.show = false"
            type="info"
            closable
        >
            {{ emailTag.correctedEmail }}
        </el-tag>
        <el-form-item prop="email" label="Email">
            <el-input
                ref="email"
                type="email"
                @input="handleEmailInput($event)"
                @focus="handleEmailInput(emailValue)"
                v-model="emailValue"
            ></el-input>
        </el-form-item>
    </div>
</template>
<script setup lang="ts">
import { ElForm, ElFormItem, ElInput, ElTag } from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import * as mistyep from "mistyep";

interface Props {
    modelValue: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue"]);

const emailInputRef = ref<FormInstance>();

const emailTag = reactive({
    show: false,
    correctedEmail: "",
});

/**
 * Computed model value
 */
const emailValue = computed({
    get: () => {
        if (props.modelValue != null) {
            return props.modelValue;
        } else {
            return "";
        }
    },
    set: (value) => {
        if (value != null) {
            emit("update:modelValue", value);
        } else {
            emit("update:modelValue", "");
        }
    },
});

function handleEmailInput(value: string) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (emailPattern.test(value)) {
        const emailInput = value;

        const correctedEmail = mistyep.email(emailInput, {
            customDomains: ["citromail", "freemail"],
            customTLDs: ["com", "hu"],
        });

        if (emailInput !== correctedEmail) {
            emailTag.show = true;
            emailTag.correctedEmail = correctedEmail;
            console.log(emailTag);
        }
    } else {
        emailTag.show = false;
    }
}

function acceptCorrectedEmail() {
    emit("update:modelValue", emailTag.correctedEmail);
    emailTag.show = false;
}
</script>
<style lang="scss" scoped>
.email-with-correction-tag {
    position: relative;

    .email-corrected-tag {
        position: absolute;
        top: -2px;
        left: 22%;
        cursor: pointer;
        transform: translateX(-50%);
    }
}
</style>
