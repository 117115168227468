<script lang="ts" setup>
import moment from "moment";
import { ECodeUploadFormItem } from "~~/enums/PrizificPageBuilder/ECodeUploadFormItem";
import ApCodeDatePicker from "../../APCodeDatePicker/ApCodeDatePicker.vue";
import ApCodeInput from "../../APCodeInput/ApCodeInput.vue";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import { FormInstance } from "element-plus";
import { EPurchaseType } from "~~/enums/Campaign/EPurchaseType";
import { useCodeUploadStore } from "~~/store/components/codeUpload/codeUpload";

const campaignDataStore = useCampaignDataStore();
const codeUploadStore = useCodeUploadStore();
const router = useRouter();

interface Props {
    component: Array<any>;
    codeUploadFormMergedStyle: any;
}
const dynamicFieldValue = reactive<{ [key: string]: any }>({
    dynamicFields: {},
});

const codeUploadFormRef = ref<FormInstance | null>(null);
/**
 * Define props
 */
const props = defineProps<Props>();
const codeUploadComponent = props.component[0];

const inputApCodeTexts = computed(() => {
    return getFormItemText(codeUploadComponent, ECodeUploadFormItem.AP_CODE);
});
const inputPurchaseDateApCodeTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.PURCHASE_DATE
    );
});
const inputPurchaseTimeTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.PURCHASE_TIME
    );
});
const inputReceiptNumberTexts = computed(() => {
    return getFormItemText(
        codeUploadComponent,
        ECodeUploadFormItem.RECEIPT_NUMBER
    );
});

const emit = defineEmits(["highlight-change"]);

function onFocus(item: string) {
    emit("highlight-change", item);
}

watch(
    () => codeUploadStore.codeUploadForm.timeOfPurchase,
    (newValue) => {
        if (newValue.length === 5) {
            try {
                const currentTime = moment().format("HHmm");
                const selectedDate = moment(
                    codeUploadStore.codeUploadForm.dateOfPurchase
                ).format("YYYYMMDD");

                const currentDay = moment().format("YYYYMMDD");
                if (
                    moment(selectedDate).isValid() &&
                    !(selectedDate < currentDay) &&
                    parseInt(newValue.replace(":", "")) > parseInt(currentTime)
                ) {
                    const timeString =
                        currentTime.toString().slice(0, 2) +
                        ":" +
                        currentTime.toString().slice(2);

                    codeUploadStore.codeUploadForm.timeOfPurchase = timeString;
                }
            } catch (e) {
                console.error("TimeOfPurchase Moment Error", e);
            }
        }
    }
);

const promoProductsDialog = ref(false);

function submit(formEl: FormInstance | undefined) {
    if (!formEl) return;

    formEl.validate(async (valid: boolean) => {
        if (valid) {
            try {
                const { data, error } = await codeUploadStore.codeUpload(
                    codeUploadStore.codeUploadForm
                );

                if (
                    error.value &&
                    error.value.data &&
                    error.value.data.errorType
                ) {
                    codeUploadStore.codeUploadErrorHandle(
                        error.value.data.errorType
                    );
                } else if (error.value) {
                    throw error;
                }

                console.log("Winner URL", useDynamicLink("/nyertel/"));
                console.log("Data", data.value);

                if (
                    data.value.uploadedCode.winnerUuid !== undefined &&
                    data.value.uploadedCode.notifyImmediately
                ) {
                    if (
                        campaignDataStore.campaignUUId ==
                            "a46bdb28-0c9b-463b-a48e-b2e78acd71b8" ||
                        campaignDataStore.campaignUUId ==
                            "cc601dc3-716a-4cb3-a994-14d3ec111761"
                    ) {
                        router.push({
                            path: useDynamicLink(
                                "/sorsolas/" + data.value.uploadedCode.id
                            ),
                        });
                    } else {
                        router.push({
                            path: useDynamicLink(
                                "/nyertel/" + data.value.uploadedCode.winnerUuid
                            ),
                        });
                    }
                } else {
                    //Redirect anyway
                    if (
                        campaignDataStore.campaignUUId ==
                            "a46bdb28-0c9b-463b-a48e-b2e78acd71b8" ||
                        campaignDataStore.campaignUUId ==
                            "cc601dc3-716a-4cb3-a994-14d3ec111761"
                    ) {
                        router.push({
                            path: useDynamicLink(
                                "/sorsolas/" + data.value.uploadedCode.id
                            ),
                        });
                    } else {
                        codeUploadStore.codeUploadSuccess.apCodeNotWin = true;
                    }
                }
            } catch (e) {
                console.error("Error while upload code", e);
            }
        } else {
            console.log("error submit!");
        }
    });
}

watch(
    dynamicFieldValue,
    (newVal) => {
        codeUploadStore.codeUploadForm.promotionProductsId =
            newVal?.promotionProductsId;
        codeUploadStore.codeUploadForm.placeOfPurchase =
            newVal?.placeOfPurchase;
        codeUploadStore.codeUploadForm.receiptImage = newVal?.receiptImage;
        codeUploadStore.codeUploadForm.dynamicFields = newVal?.dynamicFields;
    },
    {
        deep: true,
        immediate: true,
    }
);

const formValid = ref<boolean>(false);

function validate() {
    if (!codeUploadFormRef.value || !codeUploadFormRef.value.fields) {
        return;
    }
    const errors = [];
    for (let field of codeUploadFormRef.value.fields) {
        const hasRule = field.rules && field?.rules.length > 0;
        if (
            field.validateState == "error" ||
            (hasRule && field.validateState == "")
        ) {
            errors.push({ name: field.label, valid: false });
        }
    }
    console.log("errors", errors, errors.length);

    formValid.value = errors.length == 0;
}

function resetForm() {
    codeUploadFormRef.value?.resetFields();
}

defineExpose({ resetForm });
</script>

<template>
    <el-form
        class="codeUploadForm"
        ref="codeUploadFormRef"
        :style="codeUploadFormMergedStyle"
        label-position="top"
        :model="codeUploadStore.codeUploadForm"
        :scroll-to-error="true"
        @keydown.enter.prevent="submit"
        status-icon
        @validate="validate"
    >
        <!--:rules="rules" -->

        <CodeUploadFormsDynamicFileds
            v-model="dynamicFieldValue"
            :entryType="EPurchaseType.AP_CODE"
            :codeUploadComponent="codeUploadComponent"
            :rawComponent="component"
        ></CodeUploadFormsDynamicFileds>

        <div class="text-center">
            <el-button
                :class="[
                    'submit-btn',
                    {
                        'opacity-0':
                            codeUploadStore.codeUploadForm.dynamicFormIsLoading,
                    },
                    {
                        'is-disabled':
                            codeUploadStore.codeUploadForm
                                .submitButtonClicked || !formValid,
                    },
                ]"
                @click="submit(codeUploadFormRef)"
                :loading="codeUploadStore.codeUploadForm.submitButtonClicked"
            >
                Kódfeltöltés
            </el-button>
        </div>
    </el-form>
</template>
