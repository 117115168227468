<template>
    <div class="vote-item-list">
        <VoteAlert
            v-if="authStore.isAuthenticated && voteStore.voteable"
            message="Ma már leadtad a szavazatod, holnap újra megteheted!"
        />
        <div class="row gap-0 gap-md-5 justify-content-center flex-fill">
            <template
                v-for="(voteItem, index) in voteStore.voteList"
                :key="index"
            >
                <VoteItem
                    :id="voteItem.id"
                    :image="voteItem.image"
                    :title="voteItem.title"
                    :description="voteItem.description"
                    :link="voteItem.link"
                    :talentEnum="voteItem.talentEnum"
                    :videoUrl="voteItem.videoUrl"
                />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "~~/store/user/auth";
import { useVoteStore } from "~~/store/components/vote/vote";

const authStore = useAuthStore();
const voteStore = useVoteStore();
</script>

<style lang="scss" scoped></style>
