<template>
    <section id="editPassword">
        <div class="h-fix py-10" :style="backgroundStyle">
            <div class="container">
                <h2
                    class="text-center mb-5"
                    :style="sectionTitleStyle"
                    v-html="section.sectionTitle"
                ></h2>

                <EditPasswordForm :component="section.components" />

                <div class="text-center">
                    <el-button
                        :style="editPasswordGoToButtonMergedStyle"
                        class="btn-link mt-4"
                        @click="goToCodeUpload"
                        link
                    >
                        Vissza a kódfeltöltéshez
                    </el-button>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { getStyle } from "../../composables/styleHelper";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";

interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();
const router = useRouter();

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "editPasswordBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "editPasswordTitle");
});

const editPasswordGoToButtonStyle = computed(() => {
    return getStyle(props.section.style, "editPasswordGoToButton");
});
const editPasswordGoToButtonHoverStyle = computed(() => {
    return getStyle(props.section.style, "editPasswordGoToButtonHover");
});

const editPasswordGoToButtonMergedStyle = computed(() => {
    return {
        "--edit-profile-go-to-button-padding":
            editPasswordGoToButtonStyle.value[EProperty.PADDING],
        "--edit-profile-go-to-button-color":
            editPasswordGoToButtonStyle.value[EProperty.COLOR],
        "--edit-profile-go-to-button-font-size":
            editPasswordGoToButtonStyle.value[EProperty.FONT_SIZE],
        "--edit-profile-go-to-button-background-color":
            editPasswordGoToButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--edit-profile-go-to-button-border-radius":
            editPasswordGoToButtonStyle.value[EProperty.BORDER_RADIUS],
        "--edit-profile-go-to-button-color-hover":
            editPasswordGoToButtonHoverStyle.value[EProperty.COLOR],
        "--edit-profile-go-to-button-background-color-hover":
            editPasswordGoToButtonHoverStyle.value[EProperty.BACKGROUND_COLOR],
    };
});

/**
 * Go to code uplaod
 */
function goToCodeUpload() {
    router.push({
        path: useDynamicLink(""),
        hash: "#kodfeltoltes",
    });
}
</script>

<style lang="scss">
#editPassword {
    .btn-link {
        border: 0;
        text-decoration: none;
        padding: var(--edit-profile-go-to-button-padding);
        color: var(--edit-profile-go-to-button-color);
        font-size: var(--edit-profile-go-to-button-font-size);
        background-color: var(--edit-profile-go-to-button-background-color);
        border-radius: var(--edit-profile-go-to-button-border-radius);

        &:hover {
            color: var(--edit-profile-go-to-button-color-hover);
            background-color: var(
                --edit-profile-go-to-button-background-color-hover
            );
        }
    }
}
</style>
