<template>
    <section class="pt-4 pb-4" :style="backgroundStyle">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 text-center">
                    <h2
                        class="position-relative d-block fw-900 font-italic pb-2 mt-4 text-center"
                        :style="sectionTitleStyle"
                        v-html="section?.sectionTitle"
                    >
                    </h2>
                    <div :style="sectionParagraphStyle" v-html="section?.sectionParagraph">
                        
                    </div>
                </div>
            </div>

            <template v-for="component in section.getComponents()">
                <ImageText
                    :component="(component as IComponent<EComponents> & IImageText)"
                />
            </template>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>
<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { IImageText } from "~~/interfaces/PrizificPageBuilder/IImageText";
import { ISection } from "~~/interfaces/PrizificPageBuilder/ISection";

interface Props {
    section: IComponent<EComponents> & ISection;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

// Computed section style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "imageTextContentBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "imageTextContentTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "imageTextContentParagraph");
});
</script>
<style lang="scss" scoped></style>
