import FeedbackPageMessage from "~~/entities/PrizificPageBuilder/sections/FeedbackPageMessage";
import { EComponents } from "@/enums/PrizificPageBuilder/EComponents";
import {
    feedbackPageFontFamily,
    feedbackPageMessageBackground,
    feedbackPageMessageTitleColor,
    feedbackPageMessageTitleFontSize,
    feedbackPageMessageParagraphColor,
    feedbackPageMessageParagraphFontSize,
} from "./FeedbackPageMessageProperties";

export const thankYouFeedbackPageMessage = new FeedbackPageMessage(
    "-",
    "Thank you",
    [
        feedbackPageFontFamily,
        feedbackPageMessageBackground,
        feedbackPageMessageTitleColor,
        feedbackPageMessageTitleFontSize,
        feedbackPageMessageParagraphColor,
        feedbackPageMessageParagraphFontSize,
    ],
    "",
    [],
    "Már a célegyenesben vagyunk! :)",
    "Kollegáink ellenőrzik a pályázatod, és ha minden rendben, hamarosan kapcsolatba lépünk veled a nyeremény átvételének részleteivel.",
    "Már a célegyenesben vagyunk! :)",
    "Kollegáink ellenőrzik a pályázatod, és ha minden rendben, hamarosan kapcsolatba lépünk veled a nyeremény átvételének részleteivel."
);
