<template>
    <div class="vote-item-more ms-auto me-auto ms-lg-0 me-lg-0">
        <div class="vote-item-more__body">
            <img
                class="vote-item-more__image"
                width="352"
                :src="useCdnUrl(image)"
                alt=""
            />
            <div class="vote-item-more__name px-5 mt-3">{{ name }}</div>
            <div class="row px-5 mt-3">
                <div class="col-6 text-color-blue">Születési idő:</div>
                <div class="col-6 text-color-pink">
                    {{ birthDate }}
                </div>
            </div>
            <div class="row px-5 mt-1">
                <div class="col-6 text-color-blue">Város:</div>
                <div class="col-6 text-color-pink">
                    {{ city }}
                </div>
            </div>
            <div class="row px-5 mt-1">
                <div class="col-6 text-color-blue">Sportág:</div>
                <div class="col-6 text-color-pink">
                    {{ sport }}
                </div>
            </div>
            <div class="row px-5 mt-1">
                <div class="col-6 text-color-blue">Egyesület neve:</div>
                <div class="col-6 text-color-pink">
                    {{ association }}
                </div>
            </div>
            <div class="row px-5 mt-1">
                <div class="col-6 text-color-blue">Sport tapasztalat:</div>
                <div class="col-6 text-color-pink">
                    {{ sportExperience }}
                </div>
            </div>
            <!-- <div class="row align-items-center px-5 mt-5">
                <div class="col-6 text-color-blue">
                    Esély a fődíj elnyerésére:
                </div>
                <div class="col-6 text-color-pink text-center">
                    <el-progress
                        class="vote-progress"
                        :width="100"
                        type="circle"
                        :stroke-width="8"
                        color="#2A3E86"
                        :percentage="percentage"
                    >
                        <template #default="{ percentage }">
                            <span class="percentage-value"
                                >{{ percentage }}%</span
                            >
                        </template>
                    </el-progress>
                </div>
            </div> -->
        </div>
        <!-- <button
            :style="`${
                yourTalent(id) || !voteable ? 'opacity: 1' : 'opacity: 0'
            }`"
            @click="voteStore.vote(id)"
            class="btn btn-vote"
            :disabled="!authStore.isAuthenticated || voteable"
        >
            {{ yourTalent(id) && voteable ? "Ma rá szavaztam" : "Rá szavazok" }}
        </button> -->
        <button
            @click="
                voteStore.vote(id);
                handleClickWithoutLoggedIn();
            "
            class="btn btn-vote"
            :class="[
                'btn btn-vote',
                { disabled: !authStore.isAuthenticated || voteable },
            ]"
        >
            Rá szavazok
        </button>

        <PrizificToaster
            :duration="3000"
            :visible="registrationToaster"
            message="Regisztrálj be, hogy te is szavazhass a kedvenc tehetségedre!"
            @close="(val: boolean)=>registrationToaster=val"
        />
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useVoteStore } from "~~/store/components/vote/vote";
import { useAuthStore } from "~~/store/user/auth";

const authStore = useAuthStore();
const voteStore = useVoteStore();
const { voteable, yourTalent } = storeToRefs(voteStore);

interface Props {
    id: string;
    image: string;
    name: string;
    birthDate: string;
    city: string;
    sport: string;
    association: string;
    sportExperience: string;
    percentage: number;
}

/**
 * Define props
 */
const props = defineProps<Props>();
const registrationToaster = ref<boolean>(false);

function handleClickWithoutLoggedIn() {
    if (!authStore.isAuthenticated) {
        registrationToaster.value = true;
    }
}
</script>

<style lang="scss">
.vote-progress {
    .el-progress-circle__track {
        stroke: #ebf8f9 !important;
    }
}
.vote-item-more {
    display: flex;
    flex-direction: column;
    margin-top: 68px;
    width: 352px;
    height: auto;
    min-height: 600px;
    max-height: 760px;
    border-radius: 24px;
    //background-color: #e3007e;
    font-family: "Cocogoose";

    &__image {
        margin-top: -68px;
    }

    &__body {
        height: 100%;
        font-size: 18px;
        border-radius: 0;
        padding-bottom: 32px;
        background-color: #ebf8f9;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
    }

    &__name {
        font-size: 32px;
        color: #e3007e;
    }

    .btn-vote {
        color: white;
        font-size: 20px;
        background: transparent;
        width: 100%;
        padding: 16px 8px;
        margin-top: auto;
        border-radius: 0px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        background-color: #e3007e;

        &:active,
        &:focus {
            outline: none;
            border: 1px solid #e3007e;
            color: white;
        }

        &.disabled {
            background-color: #948f8f;
            border: 1px solid #948f8f;
            color: white;
            pointer-events: all;
        }
    }
}

.text-color-blue {
    color: #2a3e86;
}
.text-color-pink {
    color: #e3007e;
}

.percentage-value {
    font-size: 24px;
    color: #e3007e;
}
</style>
