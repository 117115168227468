<template>
    <div :style="loginFormStyle">
        <el-form
            id="loginForm"
            ref="loginFormRef"
            :style="loginFormMergedStyle"
            :rules="rules"
            :model="loginForm"
            label-position="top"
            @keydown.enter.prevent="submitLoginForm"
            status-icon
        >
            <!-- Email -->
            <el-form-item prop="email" label="Email">
                <el-input
                    ref="email"
                    type="email"
                    v-model="loginForm.email"
                ></el-input>
            </el-form-item>
            <!-- Email end -->

            <!-- Password -->
            <el-form-item prop="password" label="Jelszó">
                <el-input
                    ref="password"
                    type="password"
                    v-model="loginForm.password"
                    show-password
                ></el-input>
            </el-form-item>
            <!-- Password end -->

            <!-- Forgot password -->
            <el-link
                class="forgot-password-link"
                @click="loginForm.showForgottenPasswordDialog = true"
                :underline="false"
            >
                Elfelejtetted a jelszavad?
            </el-link>

            <DialogForgottenPassword
                :show="loginForm.showForgottenPasswordDialog"
                :component="forgotPasswordModalComponent"
                footerBtnText="Elküldöm"
                title="Elfelejtetted a jelszavad?"
                description="Kérjük, add meg a regisztrációkor megadott e-mail címedet!
Ide küldjük majd a jelszóváltoztatáshoz szükséges információkat."
                @closed="loginForm.showForgottenPasswordDialog = false"
            />
            <!-- Forgot password end -->

            <!-- Stay logged in checkbox -->
            <el-form-item class="mt-4" prop="marketing">
                <el-checkbox v-model="loginForm.stayLoggedIn">
                    <span>Bejelentkezve maradok</span>
                </el-checkbox>
            </el-form-item>
            <!-- Stay logged in checkbox end -->

            <!-- Submit button -->
            <div class="d-flex w-100">
                <el-button
                    class="submit-btn ms-auto me-auto"
                    @click="submitLoginForm"
                    @keyup.space="submitLoginForm"
                    :loading="loginForm.submitButtonClicked"
                >
                    Belépés
                </el-button>
            </div>
            <!-- Submit button end -->
        </el-form>
    </div>
</template>

<script setup lang="ts">
import { useLoginRegistrationStore } from "~~/store/components/loginRegistration/loginRegistration";
import {
    ElForm,
    ElFormItem,
    ElInput,
    ElCheckbox,
    ElNotification,
} from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";

const loginRegistrationStore = useLoginRegistrationStore();

interface Props {
    component: object;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["change"]);

const loginFormRef = ref<FormInstance>();

const loginComponent = props.component[0];
const forgotPasswordModalComponent = props.component[1];
// const feedbackModalsComponent = props.component[1];

const loginForm = reactive({
    email: "",
    password: "",
    stayLoggedIn: false,
    showForgottenPasswordDialog: false,
    submitButtonClicked: false,
});

const rules = reactive<FormRules>({
    email: [
        {
            required: true,
            message: "Kötelező mező",
            trigger: "blur",
        },
        {
            required: true,
            message: "Kötelező mező",
            trigger: "change",
        },
    ],
    password: [
        {
            required: true,
            message: "Kötelező mező",
            trigger: "blur",
        },
        {
            required: true,
            message: "Kötelező mező",
            trigger: "change",
        },
    ],
});

// Computed form and form items style
const loginFormStyle = computed(() => {
    return getStyle(loginComponent.style, "loginFormBackground");
});
const loginFormItemLabelStyle = computed(() => {
    return getStyle(loginComponent.style, "loginFormItemLabel");
});
const loginFormItemInputStyle = computed(() => {
    return getStyle(loginComponent.style, "loginFormItemInput");
});
const loginFormCheckboxStyle = computed(() => {
    return getStyle(loginComponent.style, "loginFormCheckbox");
});
const loginFormSubmitButtonStyle = computed(() => {
    return getStyle(loginComponent.style, "loginFormSubmitButton");
});
const loginFormSubmitButtonHoverStyle = computed(() => {
    return getStyle(loginComponent.style, "loginFormSubmitButtonHover");
});

const loginFormMergedStyle = computed(() => {
    return {
        "--login-form-label-color":
            loginFormItemLabelStyle.value[EProperty.COLOR],
        "--login-form-font-size":
            loginFormItemLabelStyle.value[EProperty.FONT_SIZE],
        "--login-form-input-border-radius":
            loginFormItemInputStyle.value[EProperty.BORDER_RADIUS],
        "--login-form-checkbox-active-color":
            loginFormCheckboxStyle.value[EProperty.COLOR],
        "--login-form-checkbox-background-color":
            loginFormCheckboxStyle.value[EProperty.BACKGROUND_COLOR],
        "--login-form-checkbox-border-radius":
            loginFormCheckboxStyle.value[EProperty.BORDER_RADIUS],
        "--login-form-submit-color":
            loginFormSubmitButtonStyle.value[EProperty.COLOR],
        "--login-form-submit-font-size":
            loginFormSubmitButtonStyle.value[EProperty.FONT_SIZE],
        "--login-form-submit-background-color":
            loginFormSubmitButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--login-form-submit-border-radius":
            loginFormSubmitButtonStyle.value[EProperty.BORDER_RADIUS],
        "--login-form-submit-color-hover":
            loginFormSubmitButtonHoverStyle.value[EProperty.COLOR],
        "--login-form-submit-background-color-hover":
            loginFormSubmitButtonHoverStyle.value[EProperty.BACKGROUND_COLOR],
    };
});

/**
 * Handle login submit
 */
async function submitLoginForm() {
    loginForm.submitButtonClicked = true;

    await loginFormRef.value?.validate(async (valid, fields) => {
        if (valid) {
            try {
                const { data, error } =
                    await loginRegistrationStore.loginEndUser({
                        campaignId: 0,
                        email: loginForm.email,
                        password: loginForm.password,
                    });

                if (error.value) {
                    ElNotification({
                        title: "Sikertelen bejelentkezés",
                        message: "Helytelen e-mail-cím vagy jelszó!",
                        position: "bottom-left",
                        duration: 0,
                        type: "error",
                    });
                } else {
                    // reloadNuxtApp();
                    // window.location.reload();

                    useCookie("navbarButton").value = `{"isRegistered":true}`;
                    loginRegistrationStore.isRegistered = true;
                }
            } catch (error) {
                console.error(error);
            }

            loginForm.submitButtonClicked = false;
        } else {
            console.log("error submit!", fields);
            loginForm.submitButtonClicked = false;
        }
    });
}
</script>

<style lang="scss">
#loginForm {
    .el-form-item__label {
        color: var(--login-form-label-color) !important;
    }

    .el-form-item__label::before {
        content: "" !important;
    }

    .el-input__wrapper {
        border-radius: var(--login-form-input-border-radius);
    }

    .el-checkbox {
        display: flex;
        align-items: center;
        height: auto !important;
    }

    .forgot-password-link {
        color: var(--login-form-label-color) !important;
    }

    .el-checkbox__label {
        white-space: normal;
        word-wrap: break-word;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        color: var(--login-form-label-color) !important;

        p {
            margin-bottom: 0;
        }
    }

    .el-checkbox__inner {
        width: 24px;
        height: 24px;
        border-radius: 0.35rem;

        &::after {
            border-width: 3px;
            width: 5px;
            height: 12px;
            left: 7px;
            top: 2px;
            transition: initial;
            border-color: var(--login-form-checkbox-color) !important;
        }
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
        color: var(--login-form-label-color) !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: var(
            --login-form-checkbox-background-color
        ) !important;
        border-color: var(--login-form-checkbox-background-color) !important;
    }

    .el-link {
        color: var(--login-form-label-color);
    }

    .el-form-item__error {
        color: white;
        background-color: #e44f60;
        border-radius: 4px;
        padding: 2px 4px;
        margin-top: 5px;
        right: 0;
        left: initial;

        &::before {
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #e44f60;
            width: 0;
            height: 0;
            display: block;
            content: "";
            top: -5px;
            right: 12px;
        }
    }

    .submit-btn {
        border: 0;
        white-space: normal;
        word-wrap: break-word;
        color: var(--login-form-submit-color);
        border-radius: var(--login-form-submit-border-radius);
        background-color: var(--login-form-submit-background-color);

        &:hover {
            color: var(--login-form-submit-color-hover);
            background-color: var(--login-form-submit-background-color-hover);
        }

        &.is-disabled {
            background: grey !important;
            color: white !important;
        }
    }
}
</style>
