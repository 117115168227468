import Page from "~/entities/PrizificPageBuilder/Page";

import { navbar } from "~/entities/pages/shared/sections/Navbar/Navbar";
import { draw } from "~/entities/pages/draw/sections/Draw/Draw";
import { contact } from "~/entities/pages/shared/sections/Contact/Contact";
import { footer } from "~/entities/pages/shared/sections/Footer/Footer";
import { SubPageSetup } from "./SubPageSetup";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { cookieSettings } from "~/entities/pages/shared/sections/CookieSettings/CookieSettings";

export class DefaultDrawPageSetup extends SubPageSetup {
    protected page;
    protected name = "Draw Page";

    constructor() {
        super();

        this.page = new Page(
            "-",
            "Draw",
            EComponents.DRAW_PAGE,
            [],
            "",
            [navbar, draw, contact, footer, cookieSettings],
            {}
        );
    }
}
