<template>
    <div class="vote-item text-center">
        <img
            style="width: 100%; max-width: 100%"
            :src="
                image
                    ? useCdnUrl(image)
                    : useCdnUrl('/images/mogyi_minion/vote-placeholder.webp')
            "
            alt=""
        />
        <div>
            <div class="vote-item__title">
                {{ title }}
            </div>
            <div class="vote-item__description">
                {{ description }}
            </div>

            <button
                @click="
                    voteStore.vote(id);
                    handleClickWithoutLoggedIn();
                "
                :class="[
                    'btn btn-vote',
                    { disabled: !authStore.isAuthenticated || voteable },
                ]"
            >
                Szavazok
            </button>
        </div>

        <PrizificToaster
            :duration="3000"
            :visible="registrationToaster"
            :message="campaignDataStore.campaignUUId == '118ef046-ee9f-4d37-9062-7788cd336c2d'? 'Regisztrálj be, hogy te is szavazhass a kedvenc Minyonodra!' : 'Regisztrálj be, hogy te is szavazhass a kedvenc tehetségedre!'"
            @close="(val: boolean)=>registrationToaster=val"
        />
    </div>
</template>

<script setup lang="ts">
import { CircleClose } from "@element-plus/icons-vue";
import { storeToRefs } from "pinia";
import { TalentsTab, useVoteStore } from "~~/store/components/vote/vote";
import { useAuthStore } from "~~/store/user/auth";
import PrizificToaster from "~~/components/PrizificToaster/PrizificToaster.vue";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
const campaignDataStore = useCampaignDataStore();

const authStore = useAuthStore();
const voteStore = useVoteStore();
const { voteable, yourTalent } = storeToRefs(voteStore);

interface Props {
    id: string;
    image: string;
    title: string;
    description: string;
    videoUrl?: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();
const registrationToaster = ref<boolean>(false);

function handleClickWithoutLoggedIn() {
    if (!authStore.isAuthenticated) {
        registrationToaster.value = true;
    }
}
</script>

<style lang="scss" scoped>
.vote-item {
    position: relative;
    width: 320px;
    margin-top: 32px;
    border-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    padding: 0;

    &__img {
        width: 100%;
        transition: filter 0.15s ease-out;
    }

    &__link {
        position: absolute;
        display: flex;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        font-size: 14px;
        z-index: 1;
        text-decoration: none;
        transform: translate(-50%, -50%);
    }

    &__title {
        color: #0e3456;
        font-size: 20px;
        font-weight: bold;
        margin-top: 32px;
    }

    &__description {
        font-size: 16px;
        line-height: 1;
        font-weight: normal;
        color: #0e3456;
        margin-bottom: 32px;
    }
}

.btn-vote {
    color: white;
    font-size: 16px;
    background-color: #0e3456;
    border: 1px solid #0e3456;
    padding: 8px 24px;
    border-radius: 20px;
    font-weight: 300;
    display: block;
    width: auto;
    margin: 0 auto;
    transition: background-color 0.15s ease-out;

    &:hover {
        background-color: #0e3456;
        border-color: #0e3456;
        transition: background-color 0.15s ease-out, border-color 0.15s ease-out;
    }

    &.disabled {
        color: white;
        background-color: #948f8f;
        border: 1px solid #948f8f;
        pointer-events: all;
    }
}
</style>
