<template>
    <div id="password" class="w-100">
        <el-input
            ref="passwordInputRef"
            type="password"
            v-model="passwordValue"
            @focus="passwordInput.inputPasswordInFocus = true"
            @blur="passwordInput.inputPasswordInFocus = false"
            show-password
        ></el-input>
        <transition name="fade-helper">
            <div
                v-if="passwordInput.inputPasswordInFocus"
                class="password-bubble"
            >
                <div class="mb-2">Használj legalább</div>
                <div class="d-flex align-items-center gap-2">
                    <span
                        :class="[
                            'material-icons-outlined',
                            {
                                'text-success':
                                    checkPasswordHasUpparcaseCharacter,
                            },
                            {
                                'text-warning':
                                    !checkPasswordHasUpparcaseCharacter,
                            },
                        ]"
                    >
                        {{
                            checkPasswordHasUpparcaseCharacter
                                ? "check_circle"
                                : "radio_button_unchecked"
                        }}
                    </span>

                    1 NAGY betűt
                </div>
                <div class="d-flex align-items-center gap-2">
                    <span
                        :class="[
                            'material-icons-outlined',
                            {
                                'text-success':
                                    checkPasswordHasLowercaseCharacter,
                            },
                            {
                                'text-warning':
                                    !checkPasswordHasLowercaseCharacter,
                            },
                        ]"
                    >
                        {{
                            checkPasswordHasLowercaseCharacter
                                ? "check_circle"
                                : "radio_button_unchecked"
                        }}
                    </span>

                    1 kis betűt
                </div>
                <div class="d-flex align-items-center gap-2">
                    <span
                        :class="[
                            'material-icons-outlined',
                            {
                                'text-success': checkPasswordHasNumberCharacter,
                            },
                            {
                                'text-warning':
                                    !checkPasswordHasNumberCharacter,
                            },
                        ]"
                    >
                        {{
                            checkPasswordHasNumberCharacter
                                ? "check_circle"
                                : "radio_button_unchecked"
                        }}
                    </span>

                    1 számot
                </div>
            </div>
        </transition>
        <transition name="fade-helper">
            <div
                v-if="
                    passwordLength && passwordLength < passwordInput.minLength
                "
                class="word-limit-text"
            >
                Még
                <span class="word-limit-number">
                    {{ passwordHowManyCharactersAreNeed }}
                </span>
                karakter
            </div>
        </transition>
    </div>
</template>
<script setup lang="ts">
import { ElForm, ElFormItem, ElInput, ElCheckbox } from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";

interface Props {
    modelValue: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue"]);

const passwordInputRef = ref<FormInstance>();

const passwordInput = reactive({
    password: "",
    minLength: 6,
    inputPasswordInFocus: false,
});

/**
 * Computed model value
 */
const passwordValue = computed({
    get: () => {
        if (props.modelValue != null) {
            return props.modelValue;
        } else {
            return "";
        }
    },
    set: (value) => {
        if (value != null) {
            emit("update:modelValue", value);
        } else {
            emit("update:modelValue", "");
        }
    },
});

/**
 * Password length
 * @returns {number}
 */
const passwordLength = computed((): number => {
    return passwordValue ? passwordValue.value.length : 0;
});

/**
 * How many characters are need in password
 * @returns {number}
 */
const passwordHowManyCharactersAreNeed = computed((): number => {
    return passwordInput.minLength && passwordValue.value.length
        ? passwordInput.minLength - passwordValue.value.length
        : 0;
});

/**
 * Password has uppercase character
 * @returns {boolean}
 */
const checkPasswordHasUpparcaseCharacter = computed((): boolean => {
    const passwordHasCapitalizeCharacter =
        passwordValue.value.match(/([A-Z])\W*/);
    return passwordHasCapitalizeCharacter ? true : false;
});

/**
 * Password has lowercase character
 * @returns {boolean}
 */
const checkPasswordHasLowercaseCharacter = computed((): boolean => {
    const passwordHasCapitalizeCharacter =
        passwordValue.value.match(/([a-z])\W*/);
    return passwordHasCapitalizeCharacter ? true : false;
});

/**
 * Password has number character
 * @returns {boolean}
 */
const checkPasswordHasNumberCharacter = computed((): boolean => {
    const passwordHasCapitalizeCharacter =
        passwordValue.value.match(/([0-9])\W*/);
    return passwordHasCapitalizeCharacter ? true : false;
});
</script>
<style lang="scss" scoped>
#password {
    .word-limit-text {
        position: absolute;
        transform: translate(-50%);
        left: 50%;
        font-size: 12px;
        height: 16px;
        text-align: center;

        .word-limit-number {
            color: var(--password-word-limit-color);
            font-weight: bold;
        }

        &.error {
            color: var(--password-word-limit-error-color);
            transform: initial;
        }
    }

    .password-bubble {
        position: absolute;
        z-index: 2;
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        max-width: 211px;
        left: 50%;
        line-height: 1.25;
        top: calc(0px - 100px);
        transform: translateX(-50%);
        background-color: white;
        border: 1px solid white;
        font-size: 12px;

        &::after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid white;
        }

        &::before {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            bottom: -9px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 9px solid white;
        }

        .material-icons-outlined {
            font-size: 16px;
        }
    }
}

.fade-helper-enter-active,
.fade-helper-leave-active {
    transition: opacity 0.5s;
}
.fade-helper-enter,
.fade-helper-leave-to {
    opacity: 0;
}
</style>
