import Page from "~/entities/PrizificPageBuilder/Page";

import { navbar } from "~/entities/pages/shared/sections/Navbar/Navbar";
import { heroImage } from "~/entities/pages/home/sections/HeroImage/HeroImage";
import { prizes } from "~/entities/pages/home/sections/Prizes/Prizes";
import { timeInterval } from "~/entities/pages/home/sections/TimeInterval/TimeInterval";
import { gameSteps } from "~/entities/pages/home/sections/GameSteps/GameSteps";
import { promoProducts } from "~/entities/pages/home/sections/PromoProducts/PromoProducts";
import { faqs } from "~~/entities/pages/home/sections/Faqs/Faqs";
import { codeUpload } from "~/entities/pages/home/sections/CodeUpload/CodeUpload";
import { login } from "~/entities/pages/home/sections/Login/Login";
import { registration } from "~/entities/pages/home/sections/Registration/Registration";
import { contact } from "~/entities/pages/shared/sections/Contact/Contact";
import { footer } from "~/entities/pages/shared/sections/Footer/Footer";
import { ageGate } from "~/entities/pages/age-gate/sections/AgeGate/AgeGate";
import { winnerFeedbackPageMessage } from "~/entities/pages/winner/sections/PageMessage/FeedbackPageMessage";
import { yourPrize } from "~/entities/pages/winner/sections/YourPrize/YourPrize";
import { codeUploadInformation } from "~/entities/pages/winner/sections/CodeUploadInformation/CodeUploadInformation";
import { recipeUpload } from "~/entities/pages/winner/sections/RecipeUpload/RecipeUpload";
import { shippingAddress } from "~/entities/pages/winner/sections/ShippingAddress/ShippingAddress";
import { SubPageSetup } from "./SubPageSetup";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { cookieSettings } from "~~/entities/pages/shared/sections/CookieSettings/CookieSettings";

export class DefaultAgeGatePageSetup extends SubPageSetup {
    protected page;
    protected name = "Age Gate";

    constructor() {
        super();

        this.page = new Page(
            "-",
            "Age Gate",
            EComponents.AGE_GATE_PAGE,
            [],
            "",
            [ageGate, cookieSettings],
            {}
        );
    }
}
