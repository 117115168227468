<template>
    <section>
        <div
            class="py-5 overflow-hidden contact-us-wrapper"
            :style="[backgroundStyleObject]"
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2 text-center">
                        <h2
                            class="fw-bold px-4"
                            :style="titleStyleObject"
                            v-if="section.sectionTitleRaw"
                            v-html="section.sectionTitleRaw"
                        ></h2>
                        <div class="d-inline-block form-check mt-3">
                            <input
                                v-model="showContactBlock"
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="contactCheckbox"
                            />

                            <label
                                class="form-check-label"
                                for="contactCheckbox"
                            >
                                Hozzájárulok, hogy a Borsodi Sörgyár Kft. az
                                általam választott elérhetőségen kérésemre
                                felvilágosítást adjon (<a
                                    :href="campaign.privacyPolicy"
                                    target="_blank"
                                    >Adatkezelési szabályzat</a
                                >)
                            </label>
                        </div>
                        <div v-if="showContactBlock" class="mt-4">
                            A játékkal kapcsolatos kérdéseiddel, valamint
                            további felvilágosításért keresd
                            ügyfélszolgálatunkat a promóció időtartama alatt
                            <a href="mailto:info@staropramenjatek.hu">
                                info@staropramenjatek.hu
                            </a>
                            címen!
                        </div>

                        <div class="mt-4">
                            Promóció időtartama:
                            <strong>2024. 08. 01. - 2024. 09. 19.</strong>
                        </div>
                        <div class="mt-4">Köszönjük, ha megosztod!</div>
                        <div
                            class="fb-share-button mt-4"
                            data-href="https://staropramenjatek.hu"
                            data-layout="button"
                            data-size="large"
                        >
                            <a
                                target="_blank"
                                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fstaropramenjatek.hu%2F&amp;src=sdkpreparse"
                                class="fb-xfbml-parse-ignore btn-contact"
                            >
                                Megosztás
                            </a>
                        </div>
                        <div class="mt-4">
                            <small>
                                Kérünk, ügyelj arra, hogy 18 éven aluliak ne
                                lássák a megosztásod!
                            </small>
                        </div>
                    </div>
                    <!-- <a
                            class="d-inline-block fw-bold text-decoration-none text-uppercase mt-0 mb-5 mb-md-0 mt-md-6"
                            :style="buttonStyleObject"
                            :href="`mailto:${campaign.contactEmail}`"
                            v-html="contactButtonTextRaw"
                        ></a> -->
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { getStyle } from "../../composables/styleHelper";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
export default {
    props: {
        section: {
            required: true,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        const campaignDataStore = useCampaignDataStore();
        const runtime = useRuntimeConfig();

        return {
            campaign: {
                contactEmail: campaignDataStore.campaignData.contactEmail,
                privacyPolicy: useMediaUrl(
                    campaignDataStore.campaignData.privacyPolicyPdfFile
                ),
            },
            showContactBlock: false,
            imageXs: `url('${useMediaUrl(this.section.images[0]?.src ?? "")}')`,
            imageSm: `url('${useMediaUrl(this.section.images[1]?.src ?? "")}')`,
            imageMd: `url('${useMediaUrl(this.section.images[2]?.src ?? "")}')`,
            imageLg: `url('${useMediaUrl(this.section.images[3]?.src ?? "")}')`,
            imageXl: `url('${useMediaUrl(this.section.images[4]?.src ?? "")}')`,
            imageXxl: `url('${useMediaUrl(
                this.section.images[5]?.src ?? ""
            )}')`,
        };
    },
    computed: {
        /**
         * Get background style
         * @returns {any} object
         */
        backgroundStyleObject(): any {
            return getStyle(this.section?.style ?? [], "contactBackground");
        },

        /**
         * Get background-image style
         * @returns {any} object
         */
        sectionBackgroundImage() {
            return this.section?.src
                ? {
                      backgroundImage: `url(${useMediaUrl(
                          this.section?.src ?? ""
                      )})`,
                  }
                : "";
        },

        /**
         * Get title style
         * @returns {any} object
         */
        titleStyleObject(): any {
            return getStyle(this.section?.style ?? "", "contactTitle");
        },

        /**
         * Get paragraph style
         * @returns {any} object
         */
        paragraphStyleObject(): any {
            return getStyle(this.section?.style ?? "", "contactParagraph");
        },

        /**
         * Get contact checkbox style
         */
        checkboxStyleObject(): any {
            return getStyle(this.section?.style ?? "", "contactCheckbox");
        },

        /**
         * Get button style
         * @returns {any} object
         */
        buttonStyleObject(): any {
            return getStyle(
                this.section.components[0]?.style ?? [],
                "contactSubmitButton"
            );
        },

        contactMergedStyle(): any {
            return {
                "--contact-checkbox-active-color":
                    this.checkboxStyleObject[EProperty.COLOR],
                "--contact-checkbox-background-color":
                    this.checkboxStyleObject[EProperty.BACKGROUND_COLOR],
                "--contact-checkbox-border-radius":
                    this.checkboxStyleObject[EProperty.BORDER_RADIUS],
            };
        },

        /**
         * Get button text
         * @returns {string}
         */
        contactButtonText() {
            return this.section.components[0]?.text ?? "";
        },
        contactButtonTextRaw() {
            return this.section.components[0]?.textRaw ?? "";
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-us-wrapper {
    color: #114634;
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }

    a {
        color: #114634;

        &:hover {
            color: #114634;
        }
    }

    .btn-contact {
        color: #faf1d1 !important;
        padding: 4px 24px;
        border-radius: 24px;
        font-size: 18px;
        margin-top: 2rem;
        background-color: #114634;
        text-decoration: none;
    }

    .form-check-label {
        cursor: pointer;
    }

    .form-check-input {
        position: relative;
        top: -5px;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        background-color: transparent;
        border: 2px solid #114634;
        cursor: pointer;

        &:checked {
            background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23114634' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
    }
}
</style>
