<template>
    <li
        v-if="component?.text || component?.src"
        class="mt-4"
        :style="liStyleObject"
    >
        <img v-if="component?.src" :src="useImageMediaUrl(component?.src)" alt="" />
        <div v-html="component?.text"></div>
    </li>
</template>

<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { IListItem } from "~~/interfaces/PrizificPageBuilder/IListItem";

const runtime = useRuntimeConfig();

/**
 * Props interface
 */
interface Props {
    component: IComponent<EComponents.LIST_ITEM> & IListItem;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Compute li inline style object
 * @returns {Object} styleObject
 */
const liStyleObject = computed(() => {
    let style = [];

    style = props.component.style.map((item, index) => {
        if (item.belongsTo === "step-text-color") {
            return {
                [item.property]: item.value,
            };
        } else if (item.belongsTo === "step-icrement-width") {
            return {
                "--ol-number-width": `${item.value}${
                    item.unit ? item.unit : ""
                }`,
            };
        } else if (item.belongsTo === "step-icrement-height") {
            return {
                "--ol-number-height": `${item.value}${
                    item.unit ? item.unit : ""
                }`,
            };
        } else if (item.belongsTo === "step-icrement-background") {
            return {
                "--ol-number-background": item.value,
            };
        } else if (item.belongsTo === "step-icrement-color") {
            return {
                "--ol-number-color": item.value,
            };
        } else if (item.belongsTo === "step-icrement-border-radius") {
            return {
                "--ol-number-border-radius": `${item.value}${
                    item.unit ? item.unit : ""
                }`,
            };
        }
    });

    return style;
});
</script>

<style lang="scss" scoped>
li {
    position: relative;
    counter-increment: number;

    img {
        position: absolute;
        top: -0.3rem;
        width: var(--ol-number-width);
        left: calc(-24px - var(--ol-number-width));
    }

    &::before {
        opacity: 0;
        position: absolute;
        display: inline-flex;
        left: calc(-24px - var(--ol-number-width));
        top: -0.3rem;
        margin-right: 1.75rem;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        min-width: var(--ol-number-width);
        min-height: var(--ol-number-height);
        content: counter(number) ". ";
        color: var(--ol-number-color);
        background: var(--ol-number-background);
        border-radius: var(--ol-number-border-radius);
    }
}
</style>
