<template>
    <div class="collapse-card" :style="cardStyleObject">
        <div
            class="collapse-card__header collapsed d-flex align-items-center py-2 pr-2"
            data-bs-toggle="collapse"
            :data-bs-target="'#' + index"
        >
            <h4 class="fw-bold pe-2 mb-0" :style="headingStyleObject">
                {{ header }}
            </h4>
            <div class="ms-auto">
                <span class="icon-collapsed" :style="iconStyleObject">
                    <span class="material-icons"> add </span>
                </span>
                <span class="icon-collapse" :style="iconStyleObject">
                    <span class="material-icons"> remove </span>
                </span>
            </div>
        </div>
        <div class="collapse" :id="index">
            <div
                class="collapse-card__body"
                v-html="body"
                :style="bodyStyleObject"
            ></div>
        </div>
    </div>
</template>

<script lang="ts">
import { getStyle } from "../../composables/styleHelper";

export default {
    data() {
        return {};
    },
    props: {
        header: {
            type: String,
            required: false,
            default: "",
        },
        body: {
            type: String,
            required: false,
            default: "",
        },
        index: {
            type: String,
            required: true,
        },
        section: Object,
    },
    computed: {
        /**
         * Get card style
         * @returns {Object} styleObject
         */
        cardStyleObject(): Object {
            return getStyle(this.section.style, "faqsCard");
        },

        /**
         * Get card title style
         * @returns {Object} styleObject
         */
        headingStyleObject(): Object {
            return getStyle(this.section.style, "faqsCardTitle");
        },

        /**
         * Get card body style
         * @returns {Object} styleObject
         */
        bodyStyleObject(): Object {
            return getStyle(this.section.style, "faqsCardBody");
        },

        /**
         * Get card icon style
         * @returns {Object} styleObject
         */
        iconStyleObject(): Object {
            return getStyle(this.section.style, "faqsCardIcon");
        },
    },
};
</script>

<style lang="scss" scoped>
.collapse-card {
    border-bottom: 1px solid;

    &__header {
        cursor: pointer;

        .icon-collapse {
            display: inline-block;
        }
        .icon-collapsed {
            display: none;
        }

        &.collapsed {
            .icon-collapse {
                display: none;
            }
            .icon-collapsed {
                display: inline-block;
            }
        }
    }

    &__body {
        padding: 0.5rem 0;
    }
}
</style>
