import Page from "~/entities/PrizificPageBuilder/Page";

import { navbar } from "~/entities/pages/shared/sections/Navbar/Navbar";
import { contact } from "~/entities/pages/shared/sections/Contact/Contact";
import { footer } from "~/entities/pages/shared/sections/Footer/Footer";
import { SubPageSetup } from "./SubPageSetup";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { thankYouFeedbackPageMessage } from "~/entities/pages/thank-you/sections/FeedbackPageMessage/FeedbackPageMessage";
import { cookieSettings } from "~~/entities/pages/shared/sections/CookieSettings/CookieSettings";

export class DefaultThankYouPageSetup extends SubPageSetup {
    protected page;
    protected name = "Thank you";

    constructor() {
        super();

        this.page = new Page(
            "-",
            "Thank you",
            EComponents.THANK_YOU_PAGE,
            [],
            "",
            [
                navbar,
                thankYouFeedbackPageMessage,
                contact,
                footer,
                cookieSettings,
            ],
            {}
        );
    }
}
