<template>
    <section id="newPassword">
        <div class="h-fix py-10" :style="backgroundStyle">
            <div class="container">
                <h2 class="text-center mb-5" :style="sectionTitleStyle">
                    {{ section.sectionTitle }}
                </h2>
                <div
                    class="description text-center mb-5"
                    :style="sectionParagraphStyle"
                >
                    {{ section.sectionParagraph }}
                </div>

                <NewPasswordForm :component="section.components" />
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { getStyle } from "../../composables/styleHelper";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";

interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "newPasswordBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "newPasswordTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "newPasswordParagraph");
});
</script>
