<template>
    <footer>
        <div
            class="overflow-hidden footer-wrapper pb-6 pb-sm-0"
            :style="[backgroundStyleObject]"
        >
            <div class="container" :style="{ minHeight: '119px' }">
                <div
                    :style="{ minHeight: '119px' }"
                    class="d-flex flex-column flex-lg-row justify-content-center align-items-center text-center text-lg-start align-items-stretch pb-6"
                >
                    <div
                        class="d-flex flex-column ms-auto me-auto me-lg-0 ms-lg-0 mt-6"
                    >
                        <ul class="nav flex-row mt-2 gap-3 px-2">
                            <template
                                v-for="(component, index) in documentComponents"
                                :key="index"
                            >
                                <NavItem :component="component" />
                            </template>

                            <!-- <template
                                v-if="
                                    campaignData.uuid ==
                                        'a46bdb28-0c9b-463b-a48e-b2e78acd71b8' ||
                                    campaignData.uuid ==
                                        'cc601dc3-716a-4cb3-a994-14d3ec111761'
                                "
                            >
                                <div id="consent_blackbar"></div>
                                <div
                                    id="teconsent"
                                    :style="cookieSettingsStyleObject"
                                ></div>
                            </template> -->

                            <a
                                :style="cookieSettingsStyleObject"
                                class="cookie-consent-dialog-open-button"
                                @click="
                                    cookieSettings.setActivePage('settings');
                                    cookieSettings.showCookieDialog();
                                "
                                type="button"
                            >
                                Cookie beállítások
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </footer>
</template>

<script lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import NavItem from "../../entities/PrizificPageBuilder/components/NavItem";
import { IComponent } from "../../interfaces/PrizificPageBuilder/IComponent";
import { getStyle } from "../../composables/styleHelper";
import { useCookieSettingsDialogStore } from "@/store/components/cookieSettingsDialog/cookieSettingsDialog";

export default {
    props: {
        section: {
            required: true,
            type: Object,
        },
        editable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        const cookieSettingsDialogStore = useCookieSettingsDialogStore();
        const campaignDataStore: any = useCampaignDataStore();
        const runtime = useRuntimeConfig();

        return {
            campaignDataStore: campaignDataStore,
            campaignData: campaignDataStore.campaignData,
            cookieSettings: cookieSettingsDialogStore,
            runtime: runtime,
            socialLinks: [{}],
            imageXs: `url('${useMediaUrl(this.section.images[0]?.src) ?? ""}')`,
            imageSm: `url('${useMediaUrl(this.section.images[1]?.src) ?? ""}')`,
            imageMd: `url('${useMediaUrl(this.section.images[2]?.src) ?? ""}')`,
            imageLg: `url('${useMediaUrl(this.section.images[3]?.src) ?? ""}')`,
            imageXl: `url('${useMediaUrl(this.section.images[4]?.src) ?? ""}')`,
            imageXxl: `url('${
                useMediaUrl(this.section.images[5]?.src) ?? ""
            }')`,
        };
    },
    computed: {
        /**
         * Get background style
         * @returns {any} object
         */
        backgroundStyleObject(): any {
            return getStyle((this.section as any)?.style, "footerBackground");
        },

        /**
         * Get background-image style
         * @returns {any} object
         */
        sectionBackgroundImage() {
            return this.section?.src
                ? {
                      backgroundImage: `url(${useMediaUrl(this.section?.src)})`,
                  }
                : "";
        },

        /**
         * Get cookie settings button style
         * @returns {any} object
         */
        cookieSettingsStyleObject(): any {
            return getStyle((this.section as any)?.components[3]?.style);
        },

        /**
         * Get footer documents components
         * @returns {Array<IComponent<NavItem>}
         */
        documentComponents(): Array<IComponent<NavItem>> {
            let components: any[] = [];

            (this.section as any).components.forEach(
                (item: any, index: any) => {
                    if (
                        item.title == "Játékszabályzat" &&
                        this.campaignData?.rulesPdfFile &&
                        this.campaignData?.rulesPdfFile != "" &&
                        this.campaignData?.rulesPdfFile != null
                    ) {
                        item.to = useMediaUrl(this.campaignData.rulesPdfFile);
                        components.push(item);
                    } else if (
                        item.title == "Adatvédelmi nyilatkozat" &&
                        this.campaignData?.privacyPolicyPdfFile &&
                        this.campaignData?.privacyPolicyPdfFile != "" &&
                        this.campaignData?.privacyPolicyPdfFile != null
                    ) {
                        item.to = useMediaUrl(
                            this.campaignData.privacyPolicyPdfFile
                        );
                        components.push(item);
                    } else if (
                        item.title == "Cookie tájékoztató" &&
                        this.campaignData?.cookiePolicyPdfFile &&
                        this.campaignData?.cookiePolicyPdfFile != "" &&
                        this.campaignData?.cookiePolicyPdfFile != null
                    ) {
                        item.to = useMediaUrl(
                            this.campaignData.cookiePolicyPdfFile
                        );
                        components.push(item);
                    } else if (
                        item.title == "Felhasználási feltételek" &&
                        this.campaignData?.termsOfUsePdfFile &&
                        this.campaignData?.termsOfUsePdfFile != "" &&
                        this.campaignData?.termsOfUsePdfFile != null
                    ) {
                        item.to = useMediaUrl(
                            this.campaignData.termsOfUsePdfFile
                        );
                        components.push(item);
                    } else if (
                        item.title == "Jogi nyilatkozat" &&
                        this.campaignData?.legalNoticePdfFile &&
                        this.campaignData?.legalNoticePdfFile != "" &&
                        this.campaignData?.legalNoticePdfFile != null
                    ) {
                        item.to = useMediaUrl(
                            this.campaignData.legalNoticePdfFile
                        );
                        components.push(item);
                    }
                }
            );

            return components;
        },
    },
};
</script>

<style lang="scss" scoped>
footer {
    .nav-item {
        justify-content: center;

        @media (min-width: 768px) {
            justify-content: flex-start;
        }
    }
}

.cookie-consent-dialog-open-button {
    font-size: 13px;
    color: white;
    text-decoration: none;
}

.powered-by {
    font-family: "Poppins";
    font-size: 13px;
}

.footer-wrapper {
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}
</style>
