<template>
    <li class="nav-item d-flex align-items-center">
        <a
            v-if="component.link"
            target="_blank"
            :class="['nav-link', component.classNames]"
            :style="navItemStyleObject"
            :href="useDynamicLink(component.to)"
            @mouseover="toggleStyle($event, true)"
            @mouseleave="toggleStyle($event, false)"
        >
            <!-- <div
                v-if="component.icon"
                :style="iconSize"
                v-html="component.icon?.replace('data:image/svg+xml;utf8,', '')"
            ></div> -->
            <Icon
                v-if="component.icon"
                :style="iconSize"
                :name="`uil:${component.icon}`"
            />

            <span v-html="component.text"></span>
        </a>

        <NuxtLink
            v-else-if="
                component.to !== '#kodfeltoltes' ||
                (campaignDataStore.isCampaignRunning &&
                    campaignDataStore.campaignUUId !==
                        runtimeConfig.mogyiCampaignUUId)
            "
            aria-current="page"
            :to="
                component.to === '#kodfeltoltes' &&
                campaignDataStore.isRegistrationRequired &&
                !authStore.isAuthenticated
                    ? useDynamicLink('/#user-consenting')
                    : useDynamicLink(component.to)
            "
            :class="['nav-link', component.classNames]"
            :style="navItemStyleObject"
            @mouseover="toggleStyle($event, true)"
            @mouseleave="toggleStyle($event, false)"
            @click="onePageNavigation(component.to)"
        >
            <div
                v-if="component.icon"
                :style="iconSize"
                v-html="component.icon?.replace('data:image/svg+xml;utf8,', '')"
            ></div>
            <span v-html="component.text"></span>
        </NuxtLink>

        <NuxtLink
            v-else-if="
                component.to !== '#kodfeltoltes' ||
                (campaignDataStore.isCampaignRunning &&
                    campaignDataStore.campaignUUId ==
                        runtimeConfig.mogyiCampaignUUId)
            "
            aria-current="page"
            :to="
                component.to === '#kodfeltoltes' &&
                campaignDataStore.isRegistrationRequired &&
                !authStore.isAuthenticated
                    ? useDynamicLink(hashForActiveSection)
                    : useDynamicLink(component.to)
            "
            :class="['nav-link', component.classNames]"
            :style="navItemStyleObject"
            @mouseover="toggleStyle($event, true)"
            @mouseleave="toggleStyle($event, false)"
            @click="onePageNavigation(component.to)"
        >
            <div
                v-if="component.icon"
                :style="iconSize"
                v-html="component.icon?.replace('data:image/svg+xml;utf8,', '')"
            ></div>
            <span v-html="component.text"></span>
        </NuxtLink>
    </li>
</template>

<script setup lang="ts">
import { useAuthStore } from "~~/store/user/auth";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { INavItem } from "~~/interfaces/PrizificPageBuilder/INavItem";
import { getStyle } from "../../composables/styleHelper";
import { useLoginRegistrationStore } from "~~/store/components/loginRegistration/loginRegistration";
import { storeToRefs } from "pinia";
import { INavItemWithThreeState } from "~~/interfaces/PrizificPageBuilder/INavItemWithThreeState";

const authStore: any = useAuthStore();
const campaignDataStore = useCampaignDataStore();
const runtimeConfig = useRuntimeConfig();
const loginRegistrationStore = useLoginRegistrationStore();

const { hashForActiveSection } = storeToRefs(loginRegistrationStore);

interface Props {
    component: IComponent<EComponents> & INavItem;
}

/**
 * Define props
 */
const props = defineProps<Props>();
const route = useRoute();

const data = reactive({
    document: null as any,
    window: null as any,
});

onMounted(() => {
    data.document = document;
    data.window = window;
});

/**
 * Create nav item inline style object
 * @returns {Object} styleObject
 */
const navItemStyleObject = computed(() => {
    let style: any[] = [];

    if (props.component.style.length) {
        style = props.component.style.map((item) => {
            if (item.state === "default") {
                return {
                    [item.property]: `${item.value}${
                        item.unit ? item.unit : ""
                    }`,
                };
            }
        });
    }

    return style;
});

/**
 * Get icon size
 */
const iconSize = computed(() => {
    return getStyle(props.component.style, "iconSize");
});

//TODO: remove toggleStyle and add newer like in Prizes
/**
 * Handle mouseover/mouseleave
 * @param {MouseEvent} event
 * @param {Boolean} isHover
 */
function toggleStyle(event: MouseEvent, isHover: Boolean) {
    let target = event.target as HTMLButtonElement;

    if (isHover) {
        props.component.style.forEach((item) => {
            if (item.state === "hover") {
                target.style[item.property] = `${item.value}${
                    item.unit ? item.unit : ""
                }`;
            }
        });
    } else {
        props.component.style.forEach((item) => {
            if (item.state === "default") {
                target.style[item.property] = `${item.value}${
                    item.unit ? item.unit : ""
                }`;
            }
        });
    }
}

/**
 * One page navigation
 */
async function onePageNavigation(id: string) {
    /*
    const yOffset = data.document.getElementById("navbar").offsetHeight;
    if (
        id === "#kodfeltoltes" &&
        route.path !== "/" &&
        campaignDataStore.isRegistrationRequired &&
        !authStore.isAuthenticated
    ) {
        await navigateTo({ path: "/", hash: `#user-consenting` });
    } else if (
        id === "#kodfeltoltes" &&
        route.path === "/" &&
        campaignDataStore.isRegistrationRequired &&
        !authStore.isAuthenticated
    ) {
        const y =
            data.document
                .getElementById("user-consenting")
                .getBoundingClientRect().top +
            data.window.pageYOffset -
            yOffset;
        data.window.scrollTo({ top: y, behavior: "smooth" });
    } else if (id.charAt(0) == "#") {
        await navigateTo({ path: "/", hash: `${id}` });
    } else {
        await navigateTo(id);
    }*/
}
</script>
<style lang="scss" scoped>
.nav-link {
    position: relative;
    line-height: 1;
    padding: 0;
    cursor: pointer;
    color: transparent; //TODO: CONNECT WITH PAGE BUILDER
    transition: color 0.25 ease-in-out, background 0.25 ease-in-out;

    &.underline {
        &:hover {
            &::after {
                position: absolute;
                content: "";
                width: calc(100% - 16px);
                height: 3px;
                background-color: #facc6b;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
</style>
