<template>
    <!-- Time interval -->
    <section>
        <div class="time-interval" :style="prizeWrapperStyleObject">
            <div class="container">
                <div class="d-flex justify-content-center align-items-center">
                    <div
                        class="text-center py-2 fw-bold"
                        v-html="timeIntervalText"
                    ></div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
    <!-- Time interval end -->
</template>

<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { ITimeInterval } from "~~/interfaces/PrizificPageBuilder/ITimeInterval";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { IProperty } from "~~/interfaces/PrizificPageBuilder/IProperty";

const campaignDataStore = useCampaignDataStore();

/**
 * Props interface
 */
interface Props {
    section: IComponent<EComponents> & any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Create time interval wrapper style object
 * @returns {Object} styleObject
 */
const prizeWrapperStyleObject = computed(() => {
    return getStyle(props.section.style);
});

/**
 * Get time interval text
 * @returns {string}
 */
const timeIntervalText = computed(() => {
    if (campaignDataStore.isCampaignRunning) {
        return props.section.activeCampaignTextRaw; // Campaign is running
    } else if (campaignDataStore.isCampaignBeforeStart) {
        return props.section.beforeCampaignTextRaw; // Campaign before start
    } else if (campaignDataStore.isCampaignEnd) {
        return props.section.afterCampaignTextRaw; // Campaign ended
    }
});
</script>

<style lang="scss" scoped>
.time-interval {
    min-height: 32px;

    p {
        margin-bottom: 0 !important;
    }
}
</style>
