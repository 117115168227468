<template>
    <el-select
        v-model="selectValue"
        placeholder="Select font"
        @change="handleSelectChange"
        filterable
    >
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        />
    </el-select>
</template>

<script lang="ts" setup>
import { emit } from "vue";
import { storeToRefs } from "pinia";
import { useCampaignDataStore } from "@/store/campaign/campaignData";

const campaignDataStore: any = useCampaignDataStore();
const { campaignFonts } = storeToRefs(campaignDataStore);

interface Props {
    modelValue: string | number;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue", "change"]);

const selectValue = computed({
    get: () => {
        if (props.modelValue != null) {
            return props.modelValue;
        } else {
            return "";
        }
    },
    set: (value) => {
        if (value != null) {
            emit("update:modelValue", value);
        } else {
            emit("update:modelValue", "");
        }
    },
});

const options = campaignFonts;

function handleSelectChange() {
    emit("change");
}
</script>
