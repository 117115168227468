<template>
    <input
        class="cleave"
        ref="cleaveRef"
        v-model="cleaveInputValue"
        inputmode="numeric"
        maxlength="5"
    />
</template>

<script setup lang="ts">
import Cleave from "cleave.js";

/**
 * Props interface
 */
interface Props {
    modelValue: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue"]);

/**
 * Init refs
 */
const cleaveRef = ref(null);

/**
 * Computed model value
 */
const cleaveInputValue = computed({
    get: () => {
        if (props.modelValue != null) {
            return props.modelValue;
        } else {
            return "";
        }
    },
    set: (value) => {
        if (value != null) {
            emit("update:modelValue", value);
        } else {
            emit("update:modelValue", "");
        }
    },
});

onMounted(() => {
    new Cleave(cleaveRef.value, {
        time: true,
        timePattern: ["h", "m"],
    });
});
</script>

<style lang="scss" scoped>
.cleave {
    position: absolute;
    text-align: center;
    z-index: 1;
    border-radius: 8px;
    border: 2px solid transparent;
    padding: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: var(--code-upload-form-input-text-color);

    &:focus {
        border: 0;
        outline: none;
    }
}
</style>
