<template>
    <section id="editProfile">
        <div class="h-fix py-10" :style="backgroundStyle">
            <div class="container">
                <h2 class="text-center mb-5" :style="sectionTitleStyle" v-html="section.sectionTitle"></h2>

                <EditProfileForm :component="section.components" />

                <div class="text-center">
                    <el-button :style="editProfileGoToButtonMergedStyle" class="btn-link mt-4" link
                        @click="goToCodeUpload">
                        Vissza a kódfeltöltéshez
                    </el-button>
                </div>
                <div class="text-center">
                    <el-button class="btn-delete mt-6" :style="editProfileDeleteButtonMergedStyle"
                        @click="confirmProfileDeleteDialogRef = true">
                        Profil törlése
                    </el-button>
                </div>
            </div>
        </div>

        <!-- User Delete Confirm Dialog start-->
        <Dialog :show="confirmProfileDeleteDialogRef" :title="'Biztosan tölöd a profilod?'"
            :description="'Ez a művelet nem visszavonható!'" :footerBtnText="'Törlés'"
            @closed="confirmProfileDeleteDialogRef = false" @btnClicked="deleteProfile" />
        <!-- User Delete Confirm Dialog end -->

        <Dialog :show="notDeletableDialog" :title="'A profilod nem törölhető, kérlek keresd ügyfélszolgálatunkat!'"
            :description="'Amennyiben nyerményed átadás előtt van, vagy aktív sorsolásban részt veszel sajnálatosan a profilodat nem tudjuk törölni! Ebben az esetben ügyfélszolgálatunkat keresd!'"
            @closed="notDeletableDialog = false" />

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { useLoginRegistrationStore } from "~~/store/components/loginRegistration/loginRegistration";
import { getStyle } from "../../composables/styleHelper";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";
import { useAuthStore } from "~~/store/user/auth";

interface Props {
    section: any;
    editable: boolean;
}

const router = useRouter();
const profileStore = useLoginRegistrationStore();
const authStore = useAuthStore();
/**
 * Define props
 */
const props = defineProps<Props>();
const confirmProfileDeleteDialogRef = ref<boolean>(false);
const notDeletableDialog = ref<boolean>(false);

/**
 * Handle click on profile delete button
 */
async function deleteProfile() {

    try {
        await profileStore.deleteEndUser();


        confirmProfileDeleteDialogRef.value = false;
        await authStore.logOut();
        router.push({
            path: useDynamicLink(""),
        });
    } catch (e: any) {
        confirmProfileDeleteDialogRef.value = false;
        if (e.statusCode == 409) {
            notDeletableDialog.value = true;
            console.log("Not Deletable");
        }
    }

}



/**
 * Go to code uplaod
 */
function goToCodeUpload() {
    router.push({
        path: useDynamicLink(""),
        hash: "#kodfeltoltes",
    });
}

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "editProfileBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "editProfileTitle");
});

const editProfileGoToButtonStyle = computed(() => {
    return getStyle(props.section.style, "editProfileGoToButton");
});
const editProfileGoToButtonHoverStyle = computed(() => {
    return getStyle(props.section.style, "editProfileGoToButtonHover");
});

const editProfileDeleteButtonStyle = computed(() => {
    return getStyle(props.section.style, "editProfileDeleteButton");
});
const editProfileDeleteButtonHoverStyle = computed(() => {
    return getStyle(props.section.style, "editProfileDeleteButtonHover");
});

const editProfileGoToButtonMergedStyle = computed(() => {
    return {
        "--edit-profile-go-to-button-padding":
            editProfileGoToButtonStyle.value[EProperty.PADDING],
        "--edit-profile-go-to-button-color":
            editProfileGoToButtonStyle.value[EProperty.COLOR],
        "--edit-profile-go-to-button-font-size":
            editProfileGoToButtonStyle.value[EProperty.FONT_SIZE],
        "--edit-profile-go-to-button-background-color":
            editProfileGoToButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--edit-profile-go-to-button-border-radius":
            editProfileGoToButtonStyle.value[EProperty.BORDER_RADIUS],
        "--edit-profile-go-to-button-color-hover":
            editProfileGoToButtonHoverStyle.value[EProperty.COLOR],
        "--edit-profile-go-to-button-background-color-hover":
            editProfileGoToButtonHoverStyle.value[EProperty.BACKGROUND_COLOR],
    };
});

const editProfileDeleteButtonMergedStyle = computed(() => {
    return {
        "--edit-profile-delete-button-padding":
            editProfileDeleteButtonStyle.value[EProperty.PADDING],
        "--edit-profile-delete-button-color":
            editProfileDeleteButtonStyle.value[EProperty.COLOR],
        "--edit-profile-delete-button-font-size":
            editProfileDeleteButtonStyle.value[EProperty.FONT_SIZE],
        "--edit-profile-delete-button-background-color":
            editProfileDeleteButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--edit-profile-delete-button-border-radius":
            editProfileDeleteButtonStyle.value[EProperty.BORDER_RADIUS],
        "--edit-profile-delete-button-color-hover":
            editProfileDeleteButtonHoverStyle.value[EProperty.COLOR],
        "--edit-profile-delete-button-background-color-hover":
            editProfileDeleteButtonHoverStyle.value[EProperty.BACKGROUND_COLOR],
    };
});
</script>

<style lang="scss">
#editProfile {
    .btn-link {
        border: 0;
        text-decoration: none;
        padding: var(--edit-profile-go-to-button-padding);
        color: var(--edit-profile-go-to-button-color);
        font-size: var(--edit-profile-go-to-button-font-size);
        background-color: var(--edit-profile-go-to-button-background-color);
        border-radius: var(--edit-profile-go-to-button-border-radius);

        &:hover {
            color: var(--edit-profile-go-to-button-color-hover);
            background-color: var(--edit-profile-go-to-button-background-color-hover);
        }
    }

    .btn-delete {
        border: 0;
        padding: var(--edit-profile-delete-button-padding);
        color: var(--edit-profile-delete-button-color);
        font-size: var(--edit-profile-delete-button-font-size);
        background-color: var(--edit-profile-delete-button-background-color);
        border-radius: var(--edit-profile-delete-button-border-radius);

        &:hover {
            color: var(--edit-profile-delete-button-color-hover);
            background-color: var(--edit-profile-delete-button-background-color-hover);
        }
    }
}
</style>
