/**
 * Generates an embedded YouTube video URL based on the input URL.
 *
 * @param url The URL of the YouTube video
 * @returns The embedded YouTube video URL
 */
export const useYoutubeEmbedUrl = (url: string) => {
    const youtubeVideoId = url.match(
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]+)/
    )?.[1];

    if (!youtubeVideoId) {
        return;
    }

    const youtubeEmbedUrl = `https://www.youtube.com/embed/${youtubeVideoId}`;

    return youtubeEmbedUrl;
};
