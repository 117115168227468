<template>
    <section>
        <div class="h-fix py-10" :style="backgroundStyle">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3">
                        <h2 class="text-center mb-5" :style="sectionTitleStyle">
                            {{ section.sectionTitle }}
                        </h2>
                        <div
                            class="description text-center mb-5"
                            :style="sectionParagraphStyle"
                        >
                            {{ section.sectionParagraph }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { getStyle } from "../../composables/styleHelper";

interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "feedbackPageMessageBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "feedbackPageMessageTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "feedbackPageMessageParagraph");
});
</script>

<style lang="scss"></style>
