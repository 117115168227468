<template>
    <section>
        <div class="h-fix py-10" :style="backgroundStyle">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3">
                        <h2 class="text-center mb-5" :style="sectionTitleStyle">
                            {{ section.sectionTitle }}
                        </h2>
                        <div
                            class="description text-center mb-5"
                            :style="sectionParagraphStyle"
                        >
                            {{ section.sectionParagraph }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div v-loading.fullscreen.lock="true"></div> -->

        <!-- Activation time expired dialog -->
        <Dialog
            :show="responseDialogs.activationTimeExpiredDialog"
            :title="activationTimeExpiredModal.title"
            :description="activationTimeExpiredModal.description"
            :footerBtnText="activationTimeExpiredModal.buttonRight"
            @closed="responseDialogs.activationTimeExpiredDialog = false"
            @btnClicked="responseDialogs.activationTimeExpiredDialog = false"
        />
        <!-- Activation time expired dialog end -->

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { getStyle } from "../../composables/styleHelper";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";

interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const feedbackModalsComponent = props.section.components[0].components[0];

const responseDialogs = reactive({
    activationTimeExpiredDialog: false,
});

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "feedbackPageMessageBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "feedbackPageMessageTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "feedbackPageMessageParagraph");
});

// Computed feedback modals text
const activationTimeExpiredModal = computed(() => {
    return getGroupText(feedbackModalsComponent, EModal.ACTIVATIONTIMEEXPIRED);
});
</script>

<style lang="scss"></style>
