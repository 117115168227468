<template>
    <!-- Game steps -->
    <section
        class="text-button-section pt-7 pb-7"
        :style="[wrapperStyleObject]"
    >
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-1">
                    <h2
                        class="position-relative d-block fw-900 font-italic pb-2"
                        :style="titleWrapperStyleObject"
                        v-html="section.sectionTitleRaw"
                    ></h2>
                    <div
                        class="mt-3 mt-md-5"
                        :style="paragraphWrapperStyleObject"
                        v-html="section.sectionParagraphRaw"
                    ></div>

                    <a
                        class="d-inline-block fw-bold text-decoration-none mt-4 mb-5 mb-md-0 mt-md-6 cursor-pointer user-select-none"
                        :style="buttonStyle"
                        :href="button?.href"
                        v-html="button?.text"
                        role="button"
                    ></a>
                </div>
                <div class="col-10 offset-1 col-md-5 offset-md-0">
                    <img
                        class="img-fluid user-select-none"
                        :src="
                            useImageMediaUrl(
                                componentImage,
                                ImageFormat.WEBP,
                                500
                            )
                        "
                        alt=""
                    />
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
    <!-- Game steps end -->
</template>

<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";

/**
 * Props interface
 */
interface Props {
    section: IComponent<EComponents> & any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Create wrapper inline style object
 * @returns {Object} styleObject
 */
const wrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "wrapper");
});

const imageXs = `url('${useMediaUrl(props.section.images[0]?.src)}')`;
const imageSm = `url('${useMediaUrl(props.section.images[1]?.src)}')`;
const imageMd = `url('${useMediaUrl(props.section.images[2]?.src)}')`;
const imageLg = `url('${useMediaUrl(props.section.images[3]?.src)}')`;
const imageXl = `url('${useMediaUrl(props.section.images[4]?.src)}')`;
const imageXxl = `url('${useMediaUrl(props.section.images[5]?.src)}')`;

/**
 * Create title inline style object
 * @returns {Object} styleObject
 */
const titleWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "title");
});

/**
 * Create paragraph inline style object
 * @returns {Object} styleObject
 */
const paragraphWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "paragraph");
});

const button = computed(() => {
    return props.section.components[0];
});

const buttonStyle = computed(() => {
    return getStyle(props.section.components[0].style, "button");
});

const componentImage = computed(() => {
    return props.section.components[1]?.src;
});

const componentImageStyle = computed(() => {
    return getStyle(props.section.components[1].style, "image");
});
</script>

<style lang="scss">
.text-button-section {
    min-height: 56px;
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}
</style>
