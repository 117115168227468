<template>
    <div class="d-flex align-items-center ms-auto">
        <span class="update-progress-title">Saved:</span>
        <el-icon
            :class="[
                'ms-2',
                {
                    'spinner-animation':
                        pageStore.updateInProgress ===
                        EUpdateProgress.IN_PROGRESS,
                },
                {
                    'text-success':
                        pageStore.updateInProgress === EUpdateProgress.SUCCESS,
                },
                {
                    'text-danger':
                        pageStore.updateInProgress === EUpdateProgress.FAILED,
                },
            ]"
        >
            <Loading
                v-if="
                    pageStore.updateInProgress === EUpdateProgress.IN_PROGRESS
                "
            />
            <Check
                v-if="pageStore.updateInProgress === EUpdateProgress.SUCCESS"
            />
            <Close
                v-if="pageStore.updateInProgress === EUpdateProgress.FAILED"
            />
        </el-icon>
    </div>
</template>

<script setup lang="ts">
import { ElIcon } from "element-plus";
import { Loading, Check, Close } from "@element-plus/icons-vue";
import { usePagesStore, EUpdateProgress } from "@/store/pages";

/**
 * Use page store
 */
const pageStore = usePagesStore();
</script>

<style lang="scss" scoped>
.update-progress-title {
    font-size: 13px;
}
.spinner-animation {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
