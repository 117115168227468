<template>
    <div :style="winnersListMergedStyle">
        <div class="winners-list" v-for="(data, index) in list" :key="index">
            <template v-for="(monthGroup, monthName) in data" :key="monthName">
                <div v-if="monthGroup.length" class="col-12">
                    <div
                        class="month-name text-capitalize fw-bold text-center mt-3"
                    >
                        {{ monthName }}
                    </div>
                </div>

                <div class="col-12 pb-3 pt-3 winners-daily-col text-center">
                    <div
                        v-for="(winners, winnersIndex) in monthGroup"
                        :key="winnersIndex"
                        :class="[
                            'winners-list-item d-flex flex-row flex-nowrap align-items-center gap-2 mt-2 ms-auto me-auto',
                            { 'main-prize': selectedWinnersType == 'main' },
                        ]"
                    >
                        <div
                            v-if="selectedWinnersType != 'main'"
                            class="d-flex flex-column flex-nowrap gap-0"
                        >
                            <template
                                v-for="(
                                    winner, winnerKey, winnerIndex
                                ) in winners"
                            >
                                <div
                                    v-if="
                                        (winnerIndex == 0 ||
                                            winnerIndex == 1) &&
                                        winner &&
                                        winner !== '' &&
                                        winner !== 'DRAWED'
                                    "
                                    :key="winnerKey"
                                    :class="[
                                        'text-center',

                                        {
                                            'date-font-size': winnerIndex === 1,
                                        },
                                    ]"
                                >
                                    <template
                                        v-if="
                                            campaignDataStore.campaignUUId ==
                                                runtimeConfig.lavazzaCampaignUUId &&
                                            selectedWinnersType == 'weekly'
                                        "
                                    >
                                        <span v-if="winnerIndex == 1">
                                            Garantált nyeremény
                                        </span>
                                    </template>
                                    <template v-else>
                                        {{ winner.length ? winner : "" }}
                                    </template>
                                </div>
                            </template>
                        </div>

                        <div class="d-flex flex-column flex-nowrap gap-0">
                            <template
                                v-for="(
                                    winner, winnerKey, winnerIndex
                                ) in winners"
                            >
                                <div
                                    v-if="
                                        (winnerIndex == 2 ||
                                            winnerIndex == 3) &&
                                        winner &&
                                        winner !== '' &&
                                        winner !== 'DRAWED' &&
                                        winner.name !== ''
                                    "
                                    :key="winnerKey"
                                    :class="[
                                        { 'fw-bold': winnerIndex === 2 },
                                        { dot: winnerIndex === 2 },
                                        { 'opacity-75': winnerIndex === 3 },
                                    ]"
                                >
                                    <template v-if="winnerIndex === 2">
                                        <div
                                            v-for="(name, nameIndex) in winner"
                                            :key="nameIndex"
                                        >
                                            {{ name }}
                                        </div>
                                    </template>
                                    {{
                                        winner.length && winnerIndex !== 2
                                            ? winner
                                            : ""
                                    }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { ElForm, ElFormItem, ElInput, ElCheckbox } from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { EPrizeType } from "~~/enums/Campaign/EPrizeType";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";

interface Props {
    component: object;
    list: object;
    selectedWinnersType: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();
const runtimeConfig = useRuntimeConfig();
const campaignDataStore = useCampaignDataStore();

// Computed form and form items style
const winnersListStyle = computed(() => {
    //
    return getStyle(props.component[0]?.style ?? [], "winnersListItem");
});

console.log(props.component[0]);

//console.log(winnersListStyle.value);

const winnersListMergedStyle = computed(() => {
    return {
        "--winners-list-month-name-color":
            winnersListStyle.value[EProperty.COLOR],
        "--winners-list-item-color": winnersListStyle.value[EProperty.COLOR],
        "--winners-list-item-background-color":
            winnersListStyle.value[EProperty.BACKGROUND_COLOR],
        "--winners-list-item-border-color":
            winnersListStyle.value[EProperty.BORDER_COLOR],
        "--winners-list-item-border-width":
            winnersListStyle.value[EProperty.BORDER_WIDTH],
        "--winners-list-item-border-radius":
            winnersListStyle.value[EProperty.BORDER_RADIUS],
        "--winners-list-item-padding":
            winnersListStyle.value[EProperty.PADDING],
    };
});
</script>

<style lang="scss">
.winners-list {
    .month-name {
        color: var(--winners-list-month-name-color);
    }

    .winners-list-item {
        max-width: 320px;
        min-width: 320px;
        border-style: solid;
        font-size: 13px;
        padding: var(--winners-list-item-padding);
        color: var(--winners-list-item-color);
        border-width: var(--winners-list-item-border-width);
        border-color: var(--winners-list-item-border-color);
        border-radius: var(--winners-list-item-border-radius);
        background-color: var(--winners-list-item-background-color);

        & > div {
            &:nth-child(1) {
                min-width: 64px;
                max-width: 64px;
                text-align: center;
            }

            &:nth-child(2) {
                min-width: calc(100% - 64px);
                max-width: calc(100% - 64px);
                text-align: left;
                padding-left: 0.75rem;
                border-left: 1px solid var(--winners-list-item-color);
            }
        }

        &.main-prize {
            & > div {
                min-width: 100%;
                max-width: 100%;
                width: 100%;
                text-align: left;
            }
        }

        .date-font-size {
            font-size: 11px;
        }
    }
}
</style>
