<template>
    <div v-if="page">
        <client-only>
            <UploadedCodeProgress
                v-if="
                    (campaignDataStore.campaignUUId ==
                        'a46bdb28-0c9b-463b-a48e-b2e78acd71b8' ||
                        campaignDataStore.campaignUUId ==
                            'cc601dc3-716a-4cb3-a994-14d3ec111761') &&
                    authStore.isAuthenticated &&
                    route.name == 'index'
                "
            />
        </client-only>
        <!-- <PrizificPageBuilderPerformance /> -->
        <!-- <PrizificPageBuilderUserDebug /> -->
        <PrizificResponsiveViewControl>
            <draggable
                v-model="data.componentsArray"
                handle=".handle"
                group="section"
                ghost-class="ghost"
                animation="200"
                :disabled="!campaignDataStore.isPageBuilder"
                item-key="element.id"
                @change="dragChange"
                @start="data.drag = true"
                @end="data.drag = false"
            >
                <template #item="{ element, index }">
                    <div
                        :class="[
                            {
                                'position-relative':
                                    element.type !== EComponents.NAVBAR,
                            },
                            {
                                'position-sticky sticky-top':
                                    element.type === EComponents.NAVBAR,
                            },
                            {
                                'navbar-page-builder':
                                    campaignDataStore.isPageBuilder,
                            },
                        ]"
                    >
                        <div
                            v-if="campaignDataStore.isPageBuilder"
                            :class="[
                                'draggable-control',
                                {
                                    'z-1021':
                                        element.type === EComponents.NAVBAR,
                                },
                                {
                                    'd-none':
                                        !campaignDataStore.showPageBuilderControls,
                                },
                            ]"
                        >
                            <span class="order-number">{{ index + 1 }}.</span>
                            <span class="handle">
                                <Icon
                                    name="radix-icons:drag-handle-dots-2"
                                    size="24"
                                />
                            </span>
                            <PrizificPageBuilderRemoveSection
                                :section="element"
                                :page="page"
                            />
                        </div>

                        <div
                            v-if="campaignDataStore.isPageBuilder"
                            :class="[
                                {
                                    'd-none':
                                        !campaignDataStore.showPageBuilderControls,
                                },
                            ]"
                        >
                            <PrizificPageBuilderAddSection
                                :page="page"
                                :section="element"
                                :position="index"
                            />
                        </div>

                        <Navbar
                            v-if="element.type === EComponents.NAVBAR"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <MobileNavbar
                            v-if="element.type === EComponents.NAVBAR"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <HeroImage
                            v-else-if="element.type === EComponents.HERO_IMAGE"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <Prizes
                            v-else-if="element.type === EComponents.PRIZES"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <PrizesByType
                            v-else-if="
                                element.type === EComponents.PRIZES_BY_TYPE
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <PrizesGroupByType
                            v-else-if="
                                element.type ===
                                EComponents.PRIZES_GROUP_BY_TYPE
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <PrizesMinion
                            v-else-if="
                                element.type === EComponents.PRIZES_MINION
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <PrizesMinionWeeklyAndDaily
                            v-else-if="
                                element.type ===
                                EComponents.PRIZES_MINION_WEEKLY_DAILY
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <Draw
                            v-else-if="element.type === EComponents.DRAW"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <DiscountCoupons
                            v-else-if="
                                element.type === EComponents.DISCOUNT_COUPONS
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <ClientOnly
                            v-else-if="element.type === EComponents.VOTE"
                        >
                            <VoteSection
                                :section="element"
                                :editable="campaignDataStore.isPageBuilder"
                            />
                        </ClientOnly>

                        <ClientOnly
                            v-else-if="
                                element.type === EComponents.VOTE_DEFAULT
                            "
                        >
                            <VoteDefault
                                :section="element"
                                :editable="campaignDataStore.isPageBuilder"
                            />
                        </ClientOnly>

                        <TimeInterval
                            v-else-if="
                                element.type === EComponents.TIME_INTERVAL
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <GameSteps
                            v-else-if="element.type === EComponents.GAME_STEPS"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <GameStepSzentkiralyi
                            v-else-if="
                                element.type ===
                                EComponents.GAME_STEPS_SZENTKIRALYI
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <PromoProducts
                            v-else-if="
                                element.type === EComponents.PROMO_PRODUCTS
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <Faqs
                            v-else-if="element.type === EComponents.FAQS"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <Login
                            v-else-if="
                                element.type === EComponents.LOGIN &&
                                campaignDataStore.isRegistrationRequired &&
                                !campaignDataStore.isHiveConnection &&
                                !isAuthenticated &&
                                loginRegistrationStore.activeComponent ===
                                    EComponents.LOGIN
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <Registration
                            v-else-if="
                                element.type === EComponents.REGISTRATION &&
                                campaignDataStore.isRegistrationRequired &&
                                !campaignDataStore.isHiveConnection &&
                                !isAuthenticated &&
                                loginRegistrationStore.activeComponent ===
                                    EComponents.REGISTRATION
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <Contact
                            v-else-if="element.type === EComponents.CONTACT"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <ContactWithPrivacyPolicy
                            v-else-if="
                                element.type ===
                                EComponents.CONTACT_WITH_PRIVACY_POLICY
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <ContactPrivacyPolicy
                            v-else-if="
                                element.type ===
                                EComponents.CONTACT_PRIVACY_POLICY
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <ActivateRegistrationFeedbackPageMessage
                            v-else-if="
                                element.type ===
                                EComponents.ACTIVATE_REGISTRATION_FEEDBACK_PAGE_MESSAGE
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <ThankYouFeedbackPageMessage
                            v-else-if="
                                element.type ===
                                EComponents.THANK_YOU_FEEDBACK_PAGE_MESSAGE
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <WeAreSorryFeedbackPageMessage
                            v-else-if="
                                element.type ===
                                EComponents.WE_ARE_SORRY_FEEDBACK_PAGE_MESSAGE
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <NewPassword
                            v-else-if="
                                element.type === EComponents.NEW_PASSWORD
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <EditPassword
                            v-else-if="
                                element.type === EComponents.EDIT_PASSWORD
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <EditProfile
                            v-else-if="
                                element.type === EComponents.EDIT_PROFILE
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <Winners
                            v-else-if="element.type === EComponents.WINNERS"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <WinnerFeedbackPageMessage
                            v-else-if="
                                element.type ===
                                EComponents.WINNER_FEEDBACK_PAGE_MESSAGE
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <YourPrize
                            v-else-if="element.type === EComponents.YOUR_PRIZE"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <CodeUploadInformation
                            v-else-if="
                                element.type ===
                                EComponents.CODE_UPLOAD_INFORMATION
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <RecipeUpload
                            v-else-if="
                                element.type === EComponents.RECIPE_UPLOAD &&
                                shippingFromStore.prize?.imageRequiredOnUpload
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <ShippingAddress
                            v-else-if="
                                element.type === EComponents.SHIPPING_ADDRESS
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <UploadedCodes
                            v-else-if="
                                element.type === EComponents.UPLOADED_CODES
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <AgeGate
                            v-else-if="element.type === EComponents.AGE_GATE"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <Footer
                            v-else-if="element.type === EComponents.FOOTER"
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <FooterSmall
                            v-else-if="
                                element.type === EComponents.FOOTER_SMALL
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <ImageTextContent
                            v-else-if="
                                element.type === EComponents.IMAGE_TEXT_CONTENT
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                        />

                        <CookieSettingsDialog
                            v-else-if="
                                element.type === EComponents.COOKIE_SETTINGS
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                            :page="page"
                        />

                        <YoutubeVideoSection
                            v-else-if="
                                element.type === EComponents.YOUTUBE_VIDEO
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                            :page="page"
                        />

                        <TextButtonSection
                            v-else-if="
                                element.type === EComponents.TEXT_BUTTON_SECTION
                            "
                            :section="element"
                            :editable="campaignDataStore.isPageBuilder"
                            :page="page"
                        />
                        <client-only
                            v-else-if="element.type === EComponents.MAPS"
                        >
                            <MapSection
                                :section="element"
                                :editable="campaignDataStore.isPageBuilder"
                                :page="page"
                            />
                        </client-only>

                        <Transition
                            name="custom-classes-transition"
                            enter-active-class="animate__animated animate__fadeIn animate__faster"
                            leave-active-class="animate__animated animate__fadeOut animate__faster"
                        >
                            <template
                                v-if="
                                    (element.type === EComponents.CODE_UPLOAD &&
                                        campaignDataStore.isCampaignRunning &&
                                        !campaignDataStore.isRegistrationRequired) ||
                                    (element.type === EComponents.CODE_UPLOAD &&
                                        campaignDataStore.isCampaignRunning &&
                                        campaignDataStore.isRegistrationRequired &&
                                        isAuthenticated)
                                "
                            >
                                <CodeUpload
                                    :section="element"
                                    :editable="campaignDataStore.isPageBuilder"
                                />
                            </template>
                        </Transition>

                        <Transition
                            name="custom-classes-transition"
                            enter-active-class="animate__animated animate__fadeIn animate__faster"
                            leave-active-class="animate__animated animate__fadeOut animate__faster"
                        >
                            <template
                                v-if="
                                    element.type ===
                                        EComponents.USER_CONSENTING &&
                                    ((campaignDataStore.isRegistrationRequired &&
                                        campaignDataStore.isCampaignRunning) ||
                                        campaignDataStore.isPageBuilder) &&
                                    !authStore.isAuthenticated
                                "
                            >
                                <UserConsenting
                                    :section="element"
                                    :editable="campaignDataStore.isPageBuilder"
                                />
                            </template>
                        </Transition>
                    </div>
                </template>
            </draggable>
        </PrizificResponsiveViewControl>
    </div>
</template>

<script setup lang="ts">
import { usePagesStore } from "@/store/pages";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { useLoginRegistrationStore } from "~~/store/components/loginRegistration/loginRegistration";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IPage } from "~~/interfaces/PrizificPageBuilder/IPage";
import { useAuthStore } from "@/store/user/auth";
import { storeToRefs } from "pinia";
import VoteSection from "../VoteSection/VoteSection.vue";
import VoteDefault from "../VoteDefault/VoteDefault.vue";
import DiscountCoupons from "../DicountCoupons/DiscountCoupons.vue";
import FooterSmall from "../FooterSmall/FooterSmall.vue";
import { useShippingFromStore } from "~~/store/components/shippingFrom/shippingFrom";

const authStore: any = useAuthStore();
const pageStore: any = usePagesStore();
const campaignDataStore: any = useCampaignDataStore();
const loginRegistrationStore: any = useLoginRegistrationStore();
const { isAuthenticated } = storeToRefs(authStore);
const shippingFromStore = useShippingFromStore();
const route = useRoute();

interface Props {
    page: IPage<EComponents>;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const data = reactive({
    drag: false,
    componentsArray: [] as any[],
});

if (props.page) {
    data.componentsArray = props.page.getComponents();
}

watch(
    () => props.page,
    (page) => {
        data.componentsArray = page.getComponents();
    }
);

function dragChange(event: any) {
    console.log("Order changed: ", event);

    pageStore.reOrderComponents(event.moved.element, {
        newIndex: event.moved.newIndex,
        oldIndex: event.moved.oldIndex,
    });
}
</script>

<style lang="scss">
.ghost {
    opacity: 0.25;
    background-color: #e4f6fb;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.draggable-control {
    display: flex;
    position: absolute;
    top: 50%;
    left: 1rem;
    z-index: 2;
    align-items: center;
    transform: translateY(-50%);

    .handle {
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        margin-left: 0.5rem;
        justify-content: center;
        background-color: #ffffffb3;
        backdrop-filter: saturate(50%) blur(8px);
        box-shadow: 2px 4px 12px rgb(0 0 0 / 14%);
    }

    .order-number {
        min-width: 24px;
        font-weight: bold;
        border-radius: 5px;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.7019607843);
        backdrop-filter: saturate(50%) blur(8px);
        box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.14);
    }
}

.z-1021 {
    z-index: 1021;
}

.navbar-page-builder {
    top: 0px;
}
</style>
