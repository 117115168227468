<template>
    <div class="mt-8 mb-5">
        <div class="row">
            <div class="col-12 col-lg-4 offset-lg-1 text-center">
                <img
                    v-if="component.imageContent"
                    :style="imageStyle"
                    class="img-fluid"
                    :src="useMediaUrl(component.imageContent)"
                    alt=""
                />
                <el-skeleton
                    v-else
                    :style="['width: 100%; overflow:hidden;', imageStyle]"
                >
                    <template #template>
                        <el-skeleton-item
                            variant="image"
                            style="width: 100%; height: 256px"
                        />
                    </template>
                </el-skeleton>
            </div>
            <div class="col-12 col-lg-6 mt-4 mt-lg-0">
                <p
                    :style="descriptionStyle"
                    v-html="component.descriptionContent"
                ></p>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ElSkeleton, ElSkeletonItem } from "element-plus";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { IImageText } from "~~/interfaces/PrizificPageBuilder/IImageText";

const runtime = useRuntimeConfig();


interface Props {
    component: IComponent<EComponents> & IImageText;
}

/**
 * Define props
 */
const props = defineProps<Props>();

// Computed component style
const imageStyle = computed(() => {
    return getStyle(props.component.style, "imageTextImage");
});

const descriptionStyle = computed(() => {
    return getStyle(props.component.style, "imageTextDescription");
});
</script>
<style lang="scss" scoped></style>
