<template>
    <div class="quill-wrapper">
        <QuillEditor
            ref="quillEditorRef"
            v-model:content="quillValue"
            contentType="html"
            :options="data.quillOptions"
        />
    </div>
</template>

<script setup lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { storeToRefs } from "pinia";

const campaignDataStore: any = useCampaignDataStore();
const { campaignBrandColors } = storeToRefs(campaignDataStore);

/**
 * Props interface
 */
interface Props {
    modelValue: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue", "change"]);

const quillEditorRef = ref();

const toolbarTextColor = ["", "#00000"];
campaignBrandColors.value.forEach((brandColor: string) => {
    toolbarTextColor.push(brandColor);
});

/**
 * Computed model value
 */
const quillValue = computed({
    get: () => {
        if (props.modelValue != null) {
            return props.modelValue;
        } else {
            return "";
        }
    },
    set: (value) => {
        if (value != null) {
            emit("update:modelValue", value);
            emit("change", value);
        } else {
            emit("update:modelValue", "");
            emit("change", value);
        }
    },
});

const data = reactive({
    quillOptions: {
        modules: {
            toolbar: {
                container: [
                    // [{ params: ["", "Kampány kezdete", "Kampány vége"] }],
                    [
                        "bold",
                        "italic",
                        "underline",
                        "link",
                        { color: toolbarTextColor },
                    ],
                ],
            },
            // params: [
            //     { name: "Kampány kezdete", tag: "*|CAMPAIGN:START|*" },
            //     { name: "Kampány vége", tag: "*|CAMPAIGN:END|*" },
            // ],
        },
        theme: "snow",
    },
});

onMounted(() => {
    // We need to manually supply the HTML content of our custom dropdown list
    // const pasteParamItems = Array.prototype.slice.call(
    //     document.querySelectorAll(".ql-params .ql-picker-item")
    // );
    // pasteParamItems.forEach((item) => (item.textContent = item.dataset.value));
    // const qlParamsLength = document.querySelectorAll(
    //     ".ql-params .ql-picker-label"
    // ).length;
    // const selectIconSvg =
    //     '<svg viewBox="0 0 18 18"> <polygon class="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon> <polygon class="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon> </svg>';
    // for (let index = 0; index < qlParamsLength; index++) {
    //     document.querySelectorAll(".ql-params .ql-picker-label")[
    //         index
    //     ].innerHTML = `Változó paraméterek beszúrása ${selectIconSvg}`;
    // }
});
</script>

<style lang="scss">
.ql-editor {
    line-height: 1.6 !important;
}

.quill-wrapper {
    background-color: white;
}

.tag-param {
    color: white;
    padding: 2px 0;
    border-radius: 3px;
    white-space: nowrap;
    border-left: 1px solid white;
    border-right: 1px solid white;
    background-color: #3a89a2;
}

.ql-snow {
    // .ql-picker-label {
    //     display: flex;
    //     align-items: center;
    //     line-height: 1;
    //     padding-right: 24px !important;

    //     &:hover {
    //         color: #3a89a2 !important;

    //         .ql-stroke {
    //             stroke: #3a89a2 !important;
    //         }
    //     }
    // }

    // .ql-expanded {
    //     .ql-picker-label {
    //         border-color: white !important;
    //         color: #3a89a2 !important;

    //         .ql-stroke {
    //             stroke: #3a89a2 !important;
    //         }
    //     }
    // }

    .ql-color-picker {
        .ql-picker-item {
            border: 1px solid #d1d5db;
        }
    }

    .ql-picker-options {
        padding: 4px 0;
    }

    .ql-picker-item {
        padding: 0 16px;
        line-height: 32px;

        &:first-child {
            display: none;
        }

        &:hover {
            color: #3a89a2 !important;
            background-color: #ebf3f6;
        }
    }

    .ql-formats {
        margin-bottom: 0 !important;

        .ql-params {
            font-weight: bold;
            color: #3a89a2;
        }

        button {
            padding: 0px 5px !important;

            &:hover,
            &.ql-active {
                color: #3a89a2 !important;

                .ql-stroke {
                    stroke: #3a89a2 !important;
                }
            }
        }

        &:last-child {
            margin-right: 0 !important;

            button {
                font-weight: bold;
                width: auto !important;
                color: #3a89a2;
                font-size: 15px;
            }
        }
    }
}
</style>
