<template>
    <section>
        <div :style="backgroundStyle">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6 offset-lg-3 py-3 text-center">
                        <div :style="informationBoxStyle">
                            <h2 class="fw-900 mb-4" :style="sectionTitleStyle" v-if="section.sectionTitleRaw"
                                v-html="section.sectionTitleRaw">

                            </h2>
                            <!-- AP CODE -->
                            <div class="d-flex align-items-center justify-content-center">
                                <span class="fw-bold">AP kód</span>
                                <span class="d-inline-flex align-items-center" v-if="shippingFromStore.uploadedCode">
                                    <img class="ms-2 me-2" :src="useCdnUrl('/images/ap-icon.jpg')"
                                        alt="ap icon image" />
                                    {{ shippingFromStore.uploadedCode.code }}
                                </span>
                            </div>
                            <!-- AP CODE end -->

                            <!-- Transaction number -->
                            <div class="d-flex align-items-center justify-content-center mt-2"
                                v-if="shippingFromStore.uploadedCode">
                                <span class="fw-bold"
                                    v-if="shippingFromStore.uploadedCode && shippingFromStore.uploadedCode.transactionNumber">
                                    Tranzakciószám: </span>
                                <span class="ms-2"> {{ shippingFromStore.uploadedCode.transactionNumber }} </span>
                            </div>

                            <div class="d-flex align-items-center justify-content-center mt-2"
                                v-if="shippingFromStore.uploadedCode">
                                <span class="fw-bold"
                                    v-if="shippingFromStore.uploadedCode && shippingFromStore.uploadedCode.receiptNumber">
                                    Nyugtaszám: </span>
                                <span class="ms-2"> {{ shippingFromStore.uploadedCode.receiptNumber }} </span>
                            </div>


                            <!-- Transaction number end -->

                            <!-- Invoice number -->
                            <div class="d-flex align-items-center justify-content-center mt-2"
                                v-if="shippingFromStore.uploadedCode && shippingFromStore.uploadedCode.invoiceNumber">
                                <span class="fw-bold"> Számlaszám: </span>
                                <span class="ms-2">
                                    {{ shippingFromStore.uploadedCode.invoiceNumber }}
                                </span>
                            </div>
                            <!-- Invoice number end -->

                            <!-- Time of purchase -->
                            <div class="d-flex align-items-center justify-content-center mt-2"
                                v-if="shippingFromStore.uploadedCode && shippingFromStore.uploadedCode.receiptNumber">
                                <span class="fw-bold"> Vásárlás ideje </span>
                                <span class="ms-2">{{ shippingFromStore.uploadedCode.timeOfPurchase }}</span>
                            </div>
                            <!-- Time of purchase end -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { getStyle } from "../../composables/styleHelper";
import { useShippingFromStore } from "@/store/components/shippingFrom/shippingFrom";


const shippingFromStore = useShippingFromStore();


interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "codeUploadInformation");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "codeUploadInformationTitle");
});
const informationBoxStyle = computed(() => {
    return getStyle(props.section.style, "codeUploadInformationBox");
});
</script>

<style lang="scss"></style>
