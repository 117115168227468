import { defineStore } from "pinia";

export const usePurchasedPromotionProductStore = defineStore(
    "purchasedPromotionProduct",
    {
        state: () => {
            return {
                searching: false,
                searchKeyword: "",
                afterSearchPromoProducts: [],
                selectedPromoProducts: [],
                selectedPromoProductsId: [],
                promoProducts: [],
            };
        },
        getters: {},
        actions: {},
    }
);
