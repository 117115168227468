<template>
    <div :style="registrationFormMergedStyle">
        <el-form
            id="registrationForm"
            ref="registrationFormRef"
            class="mt-3"
            :model="registrationForm"
            :rules="rules"
            label-position="top"
            @keydown.enter.prevent="registrationFormSubmit"
            status-icon
        >
            <div class="row">
                <div class="col-lg-6">
                    <!-- Lastname -->
                    <el-form-item prop="lastname" label="Vezetéknév">
                        <el-input
                            ref="lastname"
                            type="text"
                            v-model="registrationForm.lastname"
                        ></el-input>
                    </el-form-item>
                    <!-- Lastname end -->
                </div>

                <div class="col-lg-6">
                    <!-- Firstname -->
                    <el-form-item prop="firstname" label="Keresztnév">
                        <el-input
                            ref="firstname"
                            type="text"
                            v-model="registrationForm.firstname"
                        ></el-input>
                    </el-form-item>
                    <!-- Firstname end -->
                </div>
            </div>

            <!-- Email -->
            <EmailCorrectedInput v-model="registrationForm.email" />
            <!-- Email end -->

            <!-- Password -->
            <el-form-item
                prop="password"
                :label="
                    inputPasswordTexts ? inputPasswordTexts.label : 'Jelszó'
                "
            >
                <PasswordInput v-model="registrationForm.password" />
            </el-form-item>
            <!-- Password end -->

            <!-- TODO: New Solution Not tested Privacy Policy checkbox -->
            <!-- <el-form-item
                v-if="privacyPolicyCheckbox?.checkboxShow"
                prop="privacyPolicy"
            >
                <el-checkbox v-model="registrationForm.privacyPolicy">
                    <span v-html="privacyPolicyCheckbox?.checkboxText"></span>
                </el-checkbox>
            </el-form-item> -->
            <!-- Privacy Policy checkbox end -->

            <!-- TODO: New Solution Not tested Edm subscription checkbox -->
            <!-- <el-form-item
                v-if="edmCheckbox.checkboxShow"
                prop="edmSubscription"
            >
                <el-checkbox v-model="registrationForm.edmSubscription">
                    <span v-html="edmCheckbox.checkboxText"></span>
                </el-checkbox>
            </el-form-item> -->
            <!-- Edm subscription checkbox end -->

            <!-- TODO: New Solution Not tested Age restrictgion checkbox -->
            <!-- <el-form-item
                v-if="overeighteenCheckbox.checkboxShow"
                prop="ageRestriction"
            >
                <el-checkbox v-model="registrationForm.ageRestriction">
                    <span v-html="overeighteenCheckbox.checkboxText"></span>
                </el-checkbox>
            </el-form-item> -->
            <!-- Age restrictgion checkbox end -->

            <!-- Edm subscription checkbox -->
            <el-form-item
                prop="edmSubscription"
                v-if="
                    campaignDataStore.campaignUUId ==
                        'a46bdb28-0c9b-463b-a48e-b2e78acd71b8' ||
                    campaignDataStore.campaignUUId ==
                        'cc601dc3-716a-4cb3-a994-14d3ec111761'
                "
            >
                <el-checkbox v-model="registrationForm.edmSubscription">
                    <span>
                        Hozzájárulok, hogy a Borsodi Sörgyár Kft. az általam
                        megadott teljes nevemet és e-mail címemet a
                        promócióival, ajánlataival kapcsolatos elektronikus
                        marketing üzenetek küldése céljából kezelje
                        <a
                            href="https://staropramenjatek.hu/apiimagemanagerms/staro-tokeletes-moziest-adatkezelesi.pdf"
                            >Adatkezelési szabályzat</a
                        >. (Opcionális)
                    </span>
                </el-checkbox>
            </el-form-item>
            <!-- Edm subscription checkbox end -->

             <!-- Privacy Policy checkbox -->
             <el-form-item prop="privacyPolicy">
                <el-checkbox v-model="registrationForm.privacyPolicy">
                    <span>
                        Figyelmesen elolvastam és kifejezetten elfogadom a
                        <a
                            :href="
                                useMediaUrl(
                                    campaignDataStore.campaignData?.rulesPdfFile
                                )
                            "
                            >Részvételi- és Játékszabályzatot</a
                        >
                        és az
                        <a
                            :href="
                                useMediaUrl(
                                    campaignDataStore.campaignData
                                        ?.privacyPolicyPdfFile
                                )
                            "
                            >Általános Adatkezelési Tájékoztatót. *</a
                        >
                    </span>
                </el-checkbox>
            </el-form-item>
            <!-- Privacy Policy checkbox end -->



            <el-form-item
                v-if="
                    campaignDataStore.campaignUUId ==
                        'a46bdb28-0c9b-463b-a48e-b2e78acd71b8' ||
                    campaignDataStore.campaignUUId ==
                        'f1e9a390-ea7b-46ff-a19c-a562ce9307a9'
                "
                prop="ageRestriction"
            >
                <el-checkbox v-model="registrationForm.ageRestriction">
                    <span> Elmúltam 18 éves </span>
                </el-checkbox>
            </el-form-item>

            <!-- Submit button -->
            <div class="d-flex w-100">
                <el-button
                    class="submit-btn ms-auto me-auto"
                    @click="registrationFormSubmit"
                    @keyup.space="registrationFormSubmit"
                    :loading="registrationForm.submitButtonClicked"
                >
                    Regisztrálok
                </el-button>
            </div>
            <!-- Submit button end -->
        </el-form>
    </div>
</template>
<script setup lang="ts">
import { useLoginRegistrationStore } from "~~/store/components/loginRegistration/loginRegistration";
import {
    ElForm,
    ElFormItem,
    ElInput,
    ElCheckbox,
    ElTag,
    ElNotification,
} from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { EProperty } from "~/enums/PrizificPageBuilder/EProperty";

import * as mistyep from "mistyep";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import { ERegistrationFormItem } from "~~/enums/PrizificPageBuilder/ERegistrationFormItem";

const loginRegistrationStore = useLoginRegistrationStore();
const campaignDataStore = useCampaignDataStore();

interface Props {
    component: object;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["change"]);

const registrationFormRef = ref<FormInstance>();

const registrationForm = reactive({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    rePassword: "",
    stayLoggedIn: false,
    privacyPolicy: false,
    edmSubscription: 0,
    ageRestriction: false,
    subscribeToNewsletter: false,
    submitButtonClicked: false,
});

const emailTag = reactive({
    show: false,
    correctedEmail: "",
});

const registrationComponent = props.component[0];
const privacyPolicyCheckbox = registrationComponent.components[2];
const edmCheckbox = registrationComponent.components[3];
const overeighteenCheckbox = registrationComponent.components[4];

const rules = reactive<FormRules>({
    firstname: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
    ],
    lastname: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
    ],
    phone: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
    ],
    privacyPolicy: [
        {
            type: "enum",
            enum: ["true"],
            required: true,
            message:
                "Kérlek, fogadd el az adatvédelmi nyilatkozatot és a játékszabályzatot",
            trigger: "change",
            transform: (value) => value.toString(),
        },
    ],
    ageRestriction: [
        {
            type: "enum",
            enum: ["true"],
            required: true,
            message: "Kérjük erősítsd meg, hogy elmultál 18 éves",
            trigger: "change",
            transform: (value) => value.toString(),
        },
    ],
    email: [
        {
            required: true,
            message: "Kötelező mező!",
            trigger: ["blur", "change"],
        },
        {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
            message: "Hiba az email címben!",
            trigger: ["blur", "change"],
        },
    ],
    password: [
        {
            required: true,
            message: "Kötelező mező",
            trigger: "blur",
        },
        {
            required: true,
            pattern: /^(?=.{6,})(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*\d+).*$/,
            message: "A jelszó nem megfelelő",
            trigger: "blur",
        },
        {
            required: true,
            pattern: /^(?=.{6,})(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*\d+).*$/,
            message: "A jelszó nem megfelelő",
            trigger: "change",
        },
    ],
});

// Computed form and form items style
const registrationFormStyle = computed(() => {
    return getStyle(registrationComponent.style, "registrationFormBackground");
});
const registrationFormItemLabelStyle = computed(() => {
    return getStyle(registrationComponent.style, "registrationFormItemLabel");
});
const registrationFormItemInputStyle = computed(() => {
    return getStyle(registrationComponent.style, "registrationFormItemInput");
});
const registrationFormCheckboxStyle = computed(() => {
    return getStyle(registrationComponent.style, "registrationFormCheckbox");
});
const registrationFormSubmitButtonStyle = computed(() => {
    return getStyle(
        registrationComponent.style,
        "registrationFormSubmitButton"
    );
});
const registrationFormSubmitButtonHoverStyle = computed(() => {
    return getStyle(
        registrationComponent.style,
        "registrationFormSubmitButtonHover"
    );
});
const registrationFormItemToggleButtonStyle = computed(() => {
    return getStyle(
        registrationComponent.style,
        "registrationFormItemToggleButton"
    );
});
const registrationFormItemToggleButtonActiveStyle = computed(() => {
    return getStyle(
        registrationComponent.style,
        "registrationFormItemToggleButtonActive"
    );
});

const registrationFormMergedStyle = computed(() => {
    return {
        "--registration-form-label-color":
            registrationFormItemLabelStyle.value[EProperty.COLOR],
        "--registration-form-font-size":
            registrationFormItemLabelStyle.value[EProperty.FONT_SIZE],
        "--registration-form-input-border-radius":
            registrationFormItemInputStyle.value[EProperty.BORDER_RADIUS],
        "--registration-form-checkbox-active-color":
            registrationFormCheckboxStyle.value[EProperty.COLOR],
        "--registration-form-checkbox-background-color":
            registrationFormCheckboxStyle.value[EProperty.BACKGROUND_COLOR],
        "--registration-form-checkbox-border-radius":
            registrationFormCheckboxStyle.value[EProperty.BORDER_RADIUS],
        "--registration-form-submit-color":
            registrationFormSubmitButtonStyle.value[EProperty.COLOR],
        "--registration-form-submit-font-size":
            registrationFormSubmitButtonStyle.value[EProperty.FONT_SIZE],
        "--registration-form-submit-background-color":
            registrationFormSubmitButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--registration-form-submit-border-radius":
            registrationFormSubmitButtonStyle.value[EProperty.BORDER_RADIUS],
        "--registration-form-submit-color-hover":
            registrationFormSubmitButtonHoverStyle.value[EProperty.COLOR],
        "--registration-form-submit-background-color-hover":
            registrationFormSubmitButtonHoverStyle.value[
                EProperty.BACKGROUND_COLOR
            ],
    };
});

const checkboxEdmSubText = computed(() => {
    return getFormItemText(
        registrationComponent,
        ERegistrationFormItem.EDM_SUBSCRIPTION
    )?.checkboxText;
});

/**
 * Handle registraton submit
 */
async function registrationFormSubmit() {
    await registrationFormRef.value?.validate(async (valid, fields) => {
        if (valid) {
            const { data, error } = await loginRegistrationStore.createEndUser({
                email: registrationForm.email,
                password: registrationForm.password,
                firstname: registrationForm.firstname,
                lastname: registrationForm.lastname,
                edmSubscription: registrationForm.edmSubscription,
            });

            if (error.value) {
                ElNotification({
                    title: "Sikertelen regisztráció",
                    message: "Az email foglalt vagy nem megfelelő!",
                    position: "bottom-left",
                    duration: 0,
                    type: "error",
                });
            } else {
                registrationFormRef.value?.resetFields();
                loginRegistrationStore.setActiveComponent(EComponents.LOGIN);

                useCookie("navbarButton").value = `{"isRegistered":true}`;
                loginRegistrationStore.isRegistered = true;

                ElNotification({
                    title: "Kérjük, aktiváld a regisztrációdat",
                    message:
                        "A regisztrációd sikeres volt. Kérjük, aktiváld az e-mail címedre küldött linkre kattintva.",
                    position: "bottom-left",
                    duration: 0,
                    type: "success",
                });
            }

            registrationForm.submitButtonClicked = false;
        } else {
            console.log("error submit!", fields);
        }
    });
}
</script>
<style lang="scss">
#registrationForm {
    .el-form-item__label {
        color: var(--registration-form-label-color) !important;
    }

    .el-form-item__label::before {
        content: "" !important;
    }

    .el-input__wrapper {
        border-radius: var(--registration-form-input-border-radius);
    }

    .el-checkbox {
        display: flex;
        align-items: center;
        height: auto !important;
    }

    .el-checkbox__label {
        white-space: normal;
        word-wrap: break-word;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        color: var(--registration-form-label-color) !important;

        p {
            margin-bottom: 0;
        }

        a {
            color: var(--registration-form-label-color) !important;
        }
    }

    .el-checkbox__inner {
        width: 24px;
        height: 24px;
        border-radius: 0.35rem;

        &::after {
            border-width: 3px;
            width: 5px;
            height: 12px;
            left: 7px;
            top: 2px;
            transition: initial;
            border-color: var(--registration-form-checkbox-color) !important;
        }
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
        color: var(--registration-form-label-color) !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: var(
            --registration-form-checkbox-background-color
        ) !important;
        border-color: var(
            --registration-form-checkbox-background-color
        ) !important;
    }

    .el-link {
        color: var(--registration-form-label-color);
    }

    .el-form-item__error {
        color: white;
        background-color: #e44f60;
        border-radius: 4px;
        padding: 2px 4px;
        margin-top: 5px;
        right: 0;
        left: initial;

        &::before {
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #e44f60;
            width: 0;
            height: 0;
            display: block;
            content: "";
            top: -5px;
            right: 12px;
        }
    }

    .submit-btn {
        border: 0;
        white-space: normal;
        word-wrap: break-word;
        color: var(--registration-form-submit-color);
        border-radius: var(--registration-form-submit-border-radius);
        background-color: var(--registration-form-submit-background-color);

        &:hover {
            color: var(--registration-form-submit-color-hover);
            background-color: var(
                --registration-form-submit-background-color-hover
            );
        }

        &.is-disabled {
            background: grey !important;
            color: white !important;
        }
    }
}
</style>
