export enum ECodeUploadErrorType {
    AP_CODE_CONFLICT = "APCodeConflict",
    AP_CODE_EXHAUSTED = "APCodeExhausted",
    AP_CODE_SHORT_TIME_GATE = "APCodeShortTimeGate",
    AP_CODE_CONFLICT_ITSELF = "APCodeConflictItself",
    COUPON_CODE_CONFLICT_ITSELF = "CouponCodeConflictItself",
    NOT_ACTIVATED_USER = "UserHasNotBeenActivatedException",
    COUPON_CODE_NOT_EXISTS='CouponCodeNotExist',
    COUPON_CODE_CONFLICT='CouponCodeConflict',
}
