<template>
    <section v-if="showFaqs" id="faq">
        <div
            class="pt-5 pb-8 faq-wrapper overflow-hidden"
            :style="[backgroundStyleObject]"
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <h2
                            class="fw-900 font-italic mt-5 mb-7 text-center"
                            :style="titleStyleObject"
                            v-if="section.sectionTitleRaw"
                            v-html="section.sectionTitleRaw"
                        ></h2>

                        <div class="row">
                            <div
                                :class="{
                                    'col-sm-6 offset-md-3': !faqMoreThanSix,
                                    'col-sm-6': faqMoreThanSix,
                                }"
                            >
                                <CollapseCard
                                    v-for="(faq, index) in faqFirstColumn"
                                    :key="faq.id"
                                    :index="'first-column-' + index"
                                    :header="faq.question"
                                    :body="faq.answer"
                                    :section="section"
                                    cardBorderWidth="1px"
                                />
                            </div>

                            <template v-if="faqMoreThanSix">
                                <div class="col-sm-6">
                                    <CollapseCard
                                        v-for="(faq, index) in faqSecondColumn"
                                        :key="faq.id"
                                        :index="'second-column-' + index"
                                        :header="faq.question"
                                        :body="faq.answer"
                                        :section="section"
                                        cardBorderWidth="1px"
                                    />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { getStyle } from "../../composables/styleHelper";

const campaignDataStore = useCampaignDataStore();
const runtime = useRuntimeConfig();

interface Props {
    section: IComponent<EComponents> & any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Get background style
 * @returns {Array<any>} styleObject
 */
const backgroundStyleObject = computed(() => {
    return getStyle(props.section.style, "faqsBackground");
});

/**
 * Create faq background-image inline style object
 */
const sectionBackgroundImage = computed(() => {
    return props.section?.src
        ? {
              backgroundImage: `url(${useMediaUrl(props.section.src)})`,
          }
        : "";
});

const imageXs = `url('${useMediaUrl(props.section.images[0].src)}')`;
const imageSm = `url('${useMediaUrl(props.section.images[1].src)}')`;
const imageMd = `url('${useMediaUrl(props.section.images[2].src)}')`;
const imageLg = `url('${useMediaUrl(props.section.images[3].src)}')`;
const imageXl = `url('${useMediaUrl(props.section.images[4].src)}')`;
const imageXxl = `url('${useMediaUrl(props.section.images[5].src)}')`;

/**
 * Get title style
 * @returns {Array<any>} styleObject
 */
const titleStyleObject = computed(() => {
    return getStyle(props.section.style, "faqsTitle");
});

/**
 * Show faqs
 */
const showFaqs = computed(() => {
    return campaignDataStore.campaignData?.faq;
});

/**
 * Check faq elements more than six
 */
const faqMoreThanSix = computed(() => {
    return campaignDataStore.campaignFaqs?.length > 6;
});

/**
 * Create faq first column
 */
const faqFirstColumn = computed(() => {
    return faqMoreThanSix.value
        ? campaignDataStore.campaignFaqs.filter(
              (faq, index) =>
                  index <
                  (campaignDataStore.campaignFaqs?.length % 2 == 0
                      ? (campaignDataStore.campaignFaqs?.length - 1) / 2
                      : (campaignDataStore.campaignFaqs?.length + 1) / 2)
          )
        : campaignDataStore.campaignFaqs;
});

/**
 * Create faq second column
 */
const faqSecondColumn = computed(() => {
    return campaignDataStore.campaignFaqs.filter(
        (faq, index) =>
            index >
            (campaignDataStore.campaignFaqs?.length % 2 == 0
                ? (campaignDataStore.campaignFaqs?.length - 1) / 2
                : campaignDataStore.campaignFaqs?.length / 2)
    );
});
</script>

<style lang="scss">
.faq-wrapper {
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}
</style>
