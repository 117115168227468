<template>
    <!-- Dialog -->
    <client-only>
        <el-dialog
            class="prizific-omnia-dialog"
            :modelValue="show"
            :show-close="false"
            @closed="handleOnClosed()"
            destroy-on-close
            append-to-body
        >
            <template #header="{ close }">
                <div @click="close" class="icon-close d-inline-block float-end">
                    <el-icon :size="32"><CircleClose /></el-icon>
                </div>
            </template>
            <div>
                <div class="text-center">
                    <h3
                        style="color: #8c734b; font-size: 24px"
                        class="text-center px-4 py-2 fw-bold"
                        v-html="title"
                    ></h3>
                    <img v-if="image" :src="image" alt="" />
                </div>
                <div
                    style="color: #8c734b"
                    class="dialog-body d-flex justify-content-center fw-bold pt-4"
                >
                    <div v-html="description"></div>
                </div>
                <div class="text-end">
                    <el-button
                        v-if="footerBtnLeftText"
                        type="info"
                        class="mt-3"
                        @click="handleLeftButtonClick()"
                    >
                        {{ footerBtnLeftText }}
                    </el-button>
                    <el-button
                        v-if="footerBtnText"
                        type="primary"
                        class="mt-3"
                        style="
                            font-size: 14px;
                            padding: 12px 14px;
                            border-radius: 5px;
                            color: #ffffff;
                            border-color: #8c734b;
                            background-color: #8c734b;
                        "
                        @click="handleRightButtonClick()"
                    >
                        {{ footerBtnText }}
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </client-only>
    <!-- Dialog end -->
</template>

<script lang="ts">
import { getStyle } from "../../composables/styleHelper";
import { ElDialog, ElButton, ElIcon } from "element-plus";
import { CircleClose } from "@element-plus/icons-vue";

export default {
    components: {
        ElDialog,
        ElButton,
        ElIcon,
        CircleClose,
    },
    emits: ["closed", "btnClicked", "btnLeftClicked"],
    props: {
        show: Boolean,
        title: String,
        image: String,
        description: String,
        footerBtnText: String,
        footerBtnLeftText: String,
        component: Object,
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        handleOnClosed() {
            this.$emit("closed", false);
        },
        handleRightButtonClick() {
            this.$emit("btnClicked", "");
        },
        handleLeftButtonClick() {
            this.$emit("btnLeftClicked", "");
        },
    },
};
</script>

<style lang="scss">
.prizific-omnia-dialog {
    min-width: 320px;
    font-family: "dynamic-font-39" !important;

    a {
        color: #ae2126 !important;
        text-decoration: none !important;
    }

    .icon-close {
        cursor: pointer;
    }
}
</style>
