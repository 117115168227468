<template>
    <li
        :class="[
            'mobile-navbar-item',
            'd-flex',
            'flex-column',
            'align-items-center',
            'justify-content-center',
            'text-center',
            'w-100',
            { 'need-animation': needAnimation },
            className,
        ]"
    >
        <template v-if="type === 'helper'">
            <div
                @click="handleClick"
                class="d-flex flex-column align-items-center justify-content-center helper-menu"
            >
                <span
                    ref="mobileNavbarItemIcon"
                    class="material-icons-outlined mobile-navbar-item-icon"
                >
                    {{ iconName }}
                </span>
                <span
                    ref="mobileNavbarItemName"
                    class="mobile-navbar-item-name"
                >
                    {{ name }}
                </span>
            </div>
        </template>

        <NuxtLink
            v-else
            aria-current="page"
            class="d-flex flex-column align-items-center justify-content-center"
            :to="useDynamicLink(navUrl as string)"
            @click.native="handleClickNav(navUrlId, navUrl)"
        >
            <span
                ref="mobileNavbarItemIcon"
                class="material-icons-outlined mobile-navbar-item-icon"
            >
                {{ iconName }}
            </span>
            <span ref="mobileNavbarItemName" class="mobile-navbar-item-name">
                {{ name }}
            </span>
        </NuxtLink>
    </li>
</template>

<script setup lang="ts">
interface Props {
    iconName: string;
    name: string;
    className: any;
    navUrlId?: string;
    navUrl?: string;
    type?: string;
    needAnimation?: boolean;
    playAnimation?: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const mobileNavbarItemIcon = ref();
const mobileNavbarItemName = ref();

/**
 * Define emits
 */
const emit = defineEmits(["click"]);

/**
 * Helper function to open mobile menu
 */
function handleClick() {
    emit("click");
}

function handleClickNav(
    navUrlId: string | undefined,
    navUrl: string | undefined
) {
    emit("click", { id: navUrlId, url: navUrl });
}

watch(
    () => props.playAnimation,
    (newVal, oldVal) => {
        if (newVal) {
            mobileNavbarItemIcon.value.classList.add("play");
            mobileNavbarItemName.value.classList.add("play");
        }
    }
);
</script>

<style lang="scss" scoped>
$animation-time: 0.35s;
.mobile-navbar-item {
    display: block;
    position: relative;
    color: var(--mobile-navbar-text-color);
    font-size: 11px;

    &.active {
        &::after {
            position: absolute;
            bottom: 0;
            content: "";
            height: 3px;
            width: 100%;
            background-color: var(--mobile-navbar-text-hover-color);
        }
    }

    .helper-menu {
        cursor: pointer;
    }

    a {
        cursor: pointer;
        text-decoration: none;
        color: var(--mobile-navbar-text-color);
    }

    &.need-animation {
        .mobile-navbar-item-icon {
            opacity: 0;
            transform: translateY(100px);

            &.play {
                animation-name: fadeInUp;
                animation-duration: $animation-time;
                animation-delay: 0;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }

        .mobile-navbar-item-name {
            opacity: 0;
            transform: translateY(100px);

            &.play {
                animation-name: fadeInUp;
                animation-duration: $animation-time;
                animation-delay: 0;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity $animation-time ease-in-out,
            transform $animation-time ease-in-out;
    }

    to {
        opacity: 1;
        transform: translateY(0);
        transition: opacity $animation-time ease-in-out,
            transform $animation-time ease-in-out;
    }
}
</style>
