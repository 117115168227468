import Page from "~/entities/PrizificPageBuilder/Page";

import { navbar } from "~/entities/pages/shared/sections/Navbar/Navbar";
import { contact } from "~/entities/pages/shared/sections/Contact/Contact";
import { footer } from "~/entities/pages/shared/sections/Footer/Footer";
import { SubPageSetup } from "./SubPageSetup";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { winnerFeedbackPageMessage } from "~/entities/pages/winner/sections/PageMessage/FeedbackPageMessage";
import { yourPrize } from "~/entities/pages/winner/sections/YourPrize/YourPrize";
import { codeUploadInformation } from "~/entities/pages/winner/sections/CodeUploadInformation/CodeUploadInformation";
import { recipeUpload } from "~/entities/pages/winner/sections/RecipeUpload/RecipeUpload";
import { shippingAddress } from "~/entities/pages/winner/sections/ShippingAddress/ShippingAddress";
import { cookieSettings } from "~~/entities/pages/shared/sections/CookieSettings/CookieSettings";

export class DefaultWinnerPageSetup extends SubPageSetup {
    protected page;
    protected name = "Winner";

    constructor() {
        super();

        this.page = new Page(
            "-",
            "Winner",
            EComponents.WINNER_PAGE,
            [],
            "",
            [
                navbar,
                winnerFeedbackPageMessage,
                yourPrize,
                codeUploadInformation,
                recipeUpload,
                shippingAddress,
                contact,
                footer,
                cookieSettings,
            ],
            {}
        );
    }
}
