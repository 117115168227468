<template>
    <transition name="fade">
        <div v-show="visible" class="prizific-toaster">
            <VoteAlert
                role="alert"
                @mouseenter="clearTimer"
                @mouseleave="startTimer"
                :message="message"
            />
        </div>
    </transition>
</template>
<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useTimeoutFn } from "@vueuse/core";

interface Props {
    duration: number;
    visible: boolean;
    message: string;
}

const props = defineProps<Props>();
const emits = defineEmits(["close"]);

const visible = ref(false);
let timer: (() => void) | undefined = undefined;

function startTimer() {
    if (props.duration > 0) {
        ({ stop: timer } = useTimeoutFn(() => {
            if (visible.value) close();
        }, props.duration));
    }
}

function clearTimer() {
    timer?.();
}

function close() {
    visible.value = false;
    emits("close", false);
}

watch(
    () => props.visible,
    (newVal) => {
        console.log("Toaster new value:", newVal);
        if (newVal) {
            visible.value = newVal;
            startTimer();
        }
    }
);
</script>
<style lang="scss">
.prizific-toaster {
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 32px);
    z-index: 9999;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
