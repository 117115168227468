<template>
    <div class="buttons-swiper overflow-hidden">
        <div ref="mobileHL" class="mobile-helper-left d-block d-sm-none"></div>
        <div ref="mobileHR" class="mobile-helper-right d-block d-sm-none"></div>
        <!-- Swiper -->
        <swiper
            :modules="modules"
            :breakpoints="data.breakpoints"
            :pagination="data.pagination"
            :navigation="data.navigation"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
        >
            <swiper-slide v-for="(button, index) in buttons" :key="index">
                <div
                    :class="[
                        'd-flex flex-column justify-content-center align-items-center swiper-button',
                        { active: button.value == modelValue },
                    ]"
                >
                    <div
                        class="fw-bold w-100 text-center"
                        @click="setActiveButton(button.value)"
                    >
                        {{ button.name }}
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <!-- Swiper end -->
    </div>
</template>

<script setup lang="ts">
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { useVibrate } from "@vueuse/core";

const runtime = useRuntimeConfig();

interface Props {
    buttons: Array<any>;
    modelValue: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const mobileHL = ref();
const mobileHR = ref();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue"]);

/**
 * Set active button
 */
const setActiveButton = (clickedButton: string) => {
    emit("update:modelValue", clickedButton);
};

const onSwiper = (e: any) => {
    if (e.activeIndex > 0) {
        mobileHL.value.style.opacity = 1;
    } else {
        mobileHL.value.style.opacity = 0;
    }

    if (e.activeIndex < props.buttons.length - 2) {
        mobileHR.value.style.opacity = 1;
    } else {
        mobileHR.value.style.opacity = 0;
    }
};
const onSlideChange = (e: any, a: any) => {
    if (e.activeIndex > 0) {
        mobileHL.value.style.opacity = 1;
    } else {
        mobileHL.value.style.opacity = 0;
    }

    if (e.activeIndex < props.buttons.length - 2) {
        mobileHR.value.style.opacity = 1;
    } else {
        mobileHR.value.style.opacity = 0;
    }
};
const modules = [Navigation, Pagination, A11y];

const data = reactive({
    breakpoints: {
        1024: {
            slidesPerView:
                props.buttons?.length > 1 ? props.buttons?.length : 1,
            spaceBetween: 0,
            centeredSlides: false,
        },
        768: {
            slidesPerView:
                props.buttons?.length > 1 ? props.buttons?.length : 1,
            spaceBetween: 0,
            centeredSlides: false,
        },
        640: {
            slidesPerView:
                props.buttons?.length > 1 ? props.buttons?.length : 1,
            spaceBetween: 0,
            centeredSlides: false,
        },
        320: {
            slidesPerView: props.buttons?.length > 1 ? 2 : 1,
            spaceBetween: 0,
            centeredSlides: false,
        },
    },
});
</script>

<style lang="scss">
.buttons-swiper {
    position: relative;
    // padding: 0 6px;

    .mobile-helper-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 100%;
        z-index: 2;
        opacity: 0;
        background: linear-gradient(to right, white, transparent);
    }
    .mobile-helper-right {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
        z-index: 2;
        opacity: 0;
        background: linear-gradient(to right, transparent, white);
    }

    .swiper-wrapper {
        // padding-top: 6px;
        // padding-bottom: 6px;
    }

    .swiper-slide {
        width: 150px;
    }

    .swiper-button-disabled {
        display: none !important;
    }

    .swiper-button {
        cursor: pointer;
        min-height: 40px;
        color: var(--toggle-button-color);
        background-color: var(--toggle-button-background-color);

        &.active {
            color: var(--toggle-button-active-color);
            border-radius: var(--toggle-button-border-radius);
            background-color: var(--toggle-button-active-background-color);
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        &::after {
            font-weight: bold;
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            background-color: var(--swiper-pagination-bullet);

            &.swiper-pagination-bullet-active {
                background-color: var(--swiper-pagination-color-active);
            }
        }
    }
}
</style>
