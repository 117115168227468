<template>
    <div
        :class="[
            'prizific-page-builder-add-section__item',
            { unavailable: !available },
        ]"
    >
        <div class="prizific-page-builder-add-section__overlay">
            <div class="prizific-page-builder-add-section__overlay__header">
                {{ title }}
                <div
                    class="prizific-page-builder-add-section__overlay__header__buttons ms-auto"
                >
                    <client-only>
                        <el-tooltip
                            :content="`Add section before ${sectionTitle}`"
                            effect="prizific"
                            placement="bottom"
                        >
                            <button
                                class="prizific-template-builder_btn"
                                @click="handleClickBefore()"
                            >
                                <span class="material-icons"
                                    >arrow_drop_up</span
                                >
                            </button>
                        </el-tooltip>
                    </client-only>
                    <client-only>
                        <el-tooltip
                            :content="`Add section after ${sectionTitle}`"
                            effect="prizific"
                            placement="bottom"
                        >
                            <button
                                class="prizific-template-builder_btn"
                                @click="handleClickAfter()"
                            >
                                <span class="material-icons"
                                    >arrow_drop_down</span
                                >
                            </button>
                        </el-tooltip>
                    </client-only>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>
<script setup lang="ts">
import { ElTooltip } from "element-plus";
import { ENewSectionPosition } from "@/enums/PrizificPageBuilder/ENewSectionPosition";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";

interface Props {
    title: string;
    sectionTitle: string;
    sectionType: EComponents;
    available: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["clickBefore", "clickAfter"]);

/**
 * Handle click before
 */
function handleClickBefore() {
    emit("clickBefore", ENewSectionPosition.BEFORE, props.sectionType);
}

/**
 * Handle click after
 */
function handleClickAfter() {
    emit("clickAfter", ENewSectionPosition.AFTER, props.sectionType);
}
</script>
<style lang="scss" scoped>
.prizific-page-builder-add-section {
    &__item {
        position: relative;
        padding: 8px;
        border-radius: 8px;
        margin-bottom: 16px;
        overflow: hidden;
        border: 1px solid #e8ebf0;
        transition: border-color 0.15s ease-in-out;

        &:hover {
            cursor: pointer;
            border-color: #d1d5db;
            transition: border-color 0.15s ease-in-out;

            .prizific-page-builder-add-section__overlay {
                background-color: #d1d5db;
                transition: background-color 0.15s ease-in-out;
            }

            .prizific-page-builder-add-section__overlay__header__buttons {
                display: flex;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.unavailable {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__overlay {
        padding: 3px 6px;
        margin: -8px -8px 0 -8px;
        margin-bottom: 12px;
        background-color: #e8ebf0;
        transition: background-color 0.15s ease-in-out;

        &__header {
            display: flex;
            font-size: 13px;

            &__buttons {
                display: none;

                .prizific-template-builder_btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    width: 18px;
                    height: 18px;
                    color: #212529;
                    margin-left: 3px;
                    border-radius: 50%;
                    border: 1px solid #212529;
                    background-color: transparent;

                    .material-icons {
                        width: 18px;
                        height: 18px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
</style>
