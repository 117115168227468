<template>
    <section class="overflow-hidden">
        <div
            class="background-heart py-4"
            :style="`background-color: #ffc9e6; background-image: url(${useCdnUrl(
                '/images/szentkiralyi/prize-bg.svg'
            )}); background-repeat:no-repeat;`"
        >
            <div class="container">
                <div class="row">
                    <div
                        class="col-12 col-md-10 offset-md-1 offset-xxl-2 d-flex"
                    >
                        <div class="d-flex ms-auto ms-md-0 me-auto">
                            <div
                                class="prize-for-vote d-flex flex-column flex-md-row gap-4 gap-md-4 gap-xxl-4"
                            >
                                <img
                                    class="prize-img"
                                    width="412"
                                    height="196"
                                    :src="
                                        useCdnUrl(
                                            '/images/szentkiralyi/prize.webp'
                                        )
                                    "
                                    alt=""
                                />
                                <div
                                    class="prize-for-vote-text mt-auto mb-auto d-flex"
                                >
                                    <div>
                                        <NuxtLink
                                            :to="useDynamicLink('#szavazz')"
                                            ><strong>SZAVAZZ</strong></NuxtLink
                                        >
                                        a tehetségekre<br />
                                        és vegyél részt<br />
                                        a
                                        <NuxtLink
                                            :to="
                                                useDynamicLink('#jatek-menete')
                                            "
                                            ><strong
                                                >NYEREMÉNYJÁTÉKBAN</strong
                                            ></NuxtLink
                                        >
                                        is!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="background: #ebf8f9" class="py-12">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <h2 class="text-center">Nézd meg a kampányvideót</h2>
                        <div style="margin: 0 auto; margin-top: 48px">
                            <!-- <video
                                style="width: 100%; border-radius: 32px"
                                controls
                                src="https://szentkiralyi-videos.s3.eu-west-2.amazonaws.com/szentkiralyi-2024-talent-program-TVC-PS-v5_1.mp4"
                            ></video> -->

                            <iframe
                                class="szentkiralyi-yt-video"
                                style="width: 100%; border-radius: 32px"
                                src="https://www.youtube.com/embed/lEr1Zabw024?si=fwjQaou6cDZohCz0"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen
                            ></iframe>
                        </div>
                        <p
                            style="color: #26406b; font-family: 'Montserrat'"
                            class="text-center mt-8"
                        >
                            A sporttehetségek elkötelezett támogatójaként
                            elindult a szavazás a Szentkirályi Tehetségprogram
                            dobogósaira! Az idei évtől a program nagykövete az
                            olimpiai bajnok tornász, Berki Krisztián, aki a
                            díjnyertes ásványvízmárkával közösen arra törekszik,
                            hogy előremozdítsa egy sikerre vágyó, fiatal
                            sportoló karrierjét. Az esélyesek közé három
                            szerencsés fiatal került be, akiket Berki Krisztián
                            mellett további olimpikon sportolókból, szakértőkből
                            és a Szentkirályi képviselőiből álló szakmai zsűri
                            választott ki.
                        </p>
                        <p
                            style="color: #26406b; font-family: 'Montserrat'"
                            class="text-center mt-4"
                        >
                            <strong
                                >A program abszolút győztesét most Ti
                                szavazhatjátok meg</strong
                            >, aki elnyeri a Szentkirályi
                            <strong>kétmillió forintos hozzájárulás</strong>át.
                            <br />A <strong>második helyezet</strong>tet ezen
                            felül <strong>1 millió forint</strong>tal, míg a
                            <strong>harmadik helyezet</strong>tet
                            <strong>500 ezer forint</strong>tal
                            <strong>támogatja a Szentkirályi.</strong>
                        </p>
                        <img
                            class="mt-8 img-fluid"
                            :src="useCdnUrl('/images/szentkiralyi/prizes.webp')"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>

        <div id="szavazz" class="py-9">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <h2 class="text-center">Tehetségek</h2>

                        <VoteList />
                    </div>
                </div>
            </div>
        </div>

        <div id="talents" class="pt-6 pb-12">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <div class="affix-container">
                            <el-affix
                                class="w-100"
                                target=".affix-container"
                                :offset="getAffixOffset"
                            >
                                <div class="vote-tab-wrapper">
                                    <NuxtLink
                                        @click="
                                            voteStore.setActiveTab(
                                                TalentsTab.NAGY_NAPSUGAR
                                            )
                                        "
                                        :class="[
                                            'btn btn-vote-tab',
                                            {
                                                active:
                                                    voteStore.activeTab ==
                                                    TalentsTab.NAGY_NAPSUGAR,
                                            },
                                        ]"
                                        :to="useDynamicLink('#talents')"
                                    >
                                        Nagy Napsugár
                                    </NuxtLink>
                                    <NuxtLink
                                        @click="
                                            voteStore.setActiveTab(
                                                TalentsTab.KRISZT_SAROLTA
                                            )
                                        "
                                        :class="[
                                            'btn btn-vote-tab',
                                            {
                                                active:
                                                    voteStore.activeTab ==
                                                    TalentsTab.KRISZT_SAROLTA,
                                            },
                                        ]"
                                        :to="useDynamicLink('#talents')"
                                    >
                                        Kriszt Sarolta
                                    </NuxtLink>
                                    <NuxtLink
                                        @click="
                                            voteStore.setActiveTab(
                                                TalentsTab.ZEMAN_ZOE_ZORA
                                            )
                                        "
                                        :class="[
                                            'btn btn-vote-tab',
                                            {
                                                active:
                                                    voteStore.activeTab ==
                                                    TalentsTab.ZEMAN_ZOE_ZORA,
                                            },
                                        ]"
                                        :to="useDynamicLink('#talents')"
                                    >
                                        Zemán Zóra Zoé
                                    </NuxtLink>
                                </div>
                            </el-affix>

                            <VoteSectionKnowMoreAboutMeList />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            style="max-width: 1024px"
            width="92%"
            v-model="voteStore.modal"
            :show-close="false"
            destroy-on-close
            append-to-body
            center
        >
            <template #header="{ close }">
                <div
                    @click="close"
                    class="icon-close d-inline-block float-end mb-3"
                    style="color: #e3007e; margin-right: -16px"
                >
                    <el-icon :size="32">
                        <CircleClose />
                    </el-icon>
                </div>
            </template>
            <div>
                <div class="text-center">
                    <h3
                        class="text-center px-4 py-2 fw-bold"
                        style="color: #2a3e86"
                    >
                        Köszönjük, hogy szavaztál, várunk vissza holnap is!
                    </h3>
                </div>
            </div>
            <div class="text-center">
                <el-button
                    style="
                        background-color: #e3007e;
                        color: white;
                        border: 0 !important;
                    "
                    type="primary"
                    class="mt-3"
                    @click="voteStore.hideModal"
                >
                    Bezárás
                </el-button>
            </div>
        </el-dialog>

        <!-- Prizific page builder tool -->
        <!-- <PrizificPageBuilderTool v-if="editable" :section="section" /> -->
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { CircleClose } from "@element-plus/icons-vue";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
import { useVoteStore, TalentsTab } from "~~/store/components/vote/vote";
import { useAuthStore } from "~~/store/user/auth";

const runtimeConfig = useRuntimeConfig();
const campaignDataStore: any = useCampaignDataStore();
const voteStore = useVoteStore();
const authStore = useAuthStore();

interface Props {
    section: IComponent<EComponents>;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

if (authStore.isAuthenticated) {
    voteStore.getVoted();
} else {
    voteStore.votePercentage();
}

const getAffixOffset = computed(() => {
    return document.getElementById("navbar")?.offsetHeight
        ? document.getElementById("navbar")?.offsetHeight
        : document.getElementById("mobile-navbar")?.offsetHeight;
});

onMounted(() => {
    console.log("VoteSection");
    if (
        campaignDataStore.campaignUUId == runtimeConfig.szentkiralyiCampaignUUId
    ) {
        voteStore.getVoted();
        voteStore.votePercentage();
    }

    if (authStore.isAuthenticated) {
        voteStore.getVoted();
    } else {
        voteStore.votePercentage();
    }
});
</script>

<style lang="scss" scoped>
h2 {
    font-family: "Cocogoose";
    color: #2a3e86;
    font-size: 40px;
    font-weight: normal;
}

.background-heart {
    background-size: cover;
    background-position: center;

    @media (min-width: 576px) {
        background-size: cover;
        background-position: center;
    }

    @media (min-width: 768px) {
    }

    @media (min-width: 992px) {
    }

    @media (min-width: 1200px) {
    }

    @media (min-width: 1400px) {
        background-size: auto;
        background-position: center;
    }
}

.prize-for-vote {
    .prize-img {
        width: 320px;
        height: 105px;
    }

    .prize-for-vote-text {
        font-family: "Cocogoose";
        font-size: 24px;
        line-height: 40px;
        color: #2a3e86;

        strong {
            color: #e3007e;
            text-decoration: underline;
        }
    }

    @media (min-width: 576px) {
        .prize-for-vote-text {
            font-size: 24px;
            line-height: 40px;
        }
        .prize-img {
            width: 320px;
            height: 105px;
        }
    }

    @media (min-width: 768px) {
        .prize-img {
            width: 332px;
            height: 110px;
        }
    }

    @media (min-width: 992px) {
        .prize-img {
            width: 462px;
            height: 157px;
        }
    }

    @media (min-width: 1200px) {
        .prize-img {
            width: 458px;
            height: 156px;
        }
    }

    @media (min-width: 1400px) {
        .prize-img {
            width: 593px;
            height: 196px;
        }

        .prize-for-vote-text {
            font-size: 32px;
            line-height: 48px;
        }
    }
}

.vote-tab-wrapper {
    width: 100%;
    padding: 8px 0;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: saturate(180%) blur(12px);

    .btn-vote-tab {
        border: 0;
        font-size: 12px;
        padding: 16px 8px;
        border-radius: 8px;
        line-height: 1;
        color: #2a3e86;

        &.active {
            color: #e3007e;
            background-color: #ebf8f9;
        }
    }

    @media (min-width: 576px) {
        .btn-vote-tab {
            font-size: 14px;
            padding: 24px 16px;
            border-radius: 16px;
        }
    }

    @media (min-width: 768px) {
        .btn-vote-tab {
            font-size: 16px;
            padding: 24px 16px;
            border-radius: 16px;
        }
    }

    @media (min-width: 992px) {
        .btn-vote-tab {
            font-size: 16px;
            padding: 24px 16px;
            border-radius: 16px;
        }
    }

    @media (min-width: 1200px) {
        .btn-vote-tab {
            font-size: 24px;
            padding: 32px 24px;
            border-radius: 16px;
        }
    }

    @media (min-width: 1400px) {
        .btn-vote-tab {
            font-size: 24px;
            padding: 32px 24px;
            border-radius: 16px;
        }
    }
}

.szentkiralyi-yt-video {
    height: 320px;

    @media (min-width: 576px) {
        height: 290px;
    }

    @media (min-width: 768px) {
        height: 324px;
    }

    @media (min-width: 992px) {
        height: 436px;
    }

    @media (min-width: 1200px) {
        height: 521px;
    }

    @media (min-width: 1400px) {
        height: 605px;
    }
}
</style>
