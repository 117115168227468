<template>
    <!-- Recipe block -->
    <div class="d-none d-lg-block">
        <div class="text-center" :style="codeUploadFormExampleStyle">
            Kérjük, add meg a blokkodon bekeretezett kódot!
        </div>
        <div :class="['recipe-image mt-5']">
            <img
                class="img-fluid"
                :src="useCdnUrl('/images/ap_code.jpg')"
                alt="Receipt sample"
            />
            <div
                :class="['highlight', props.highlight]"
                :style="codeUploadFormExampleHighlightStyle"
            ></div>
        </div>
    </div>
    <!-- Recipe block end -->
</template>

<script setup lang="ts">
/**
 * Props interface
 */
interface Props {
    component: object;
    highlight: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const codeUploadFormExampleStyle = computed(() => {
    return getStyle((props.component as any).style, "codeUploadFormExample");
});

const codeUploadFormExampleHighlightStyle = computed(() => {
    return getStyle(
        (props.component as any).style,
        "codeUploadFormExampleHighlight"
    );
});
</script>

<style lang="scss" scoped>
.recipe-image {
    position: relative;

    .highlight {
        display: none;
        position: absolute;
        border-radius: 1rem;
        border: 3px solid #e44f60;
        transition: all ease-in-out 0.25s;
        // border: 3px solid var(--code-upload-recipe-higlight-border-color);

        &.apcode {
            display: block;
            width: 45%;
            height: 14%;
            bottom: 20%;
            left: 48%;
            transform: translateX(-50%);
        }

        &.date {
            display: block;
            width: 33%;
            height: 12%;
            bottom: 42%;
            left: 4%;
        }

        &.time {
            display: block;
            width: 19%;
            height: 12%;
            bottom: 42.3%;
            left: 77%;
        }

        &.receipt-number {
            display: block;
            width: 30%;
            height: 12%;
            bottom: 52%;
            left: 65%;
        }

        &.nav-code {
            display: block;
            width: 19%;
            height: 12%;
            bottom: 31.5%;
            left: 66.5%;
        }
    }
}
</style>
