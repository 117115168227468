<template>
    <div class="vote-item text-center">
        <img
            style="width: 100%; max-width: 100%"
            :src="useCdnUrl(image)"
            alt=""
        />
        <!-- <div class="vote-item__gradient-bg">
            <img class="vote-item__img" :src="useCdnUrl(image)" alt="" />
            <NuxtLink class="vote-item__link" :to="useDynamicLink(link)">
                <span class="btn btn-more">Ismerj meg jobban</span>
            </NuxtLink>
        </div> -->
        <div
            style="
                border: 1px solid #d7d7d7;
                border-radius: 32px;
                margin-top: -44px;
                padding-top: 44px;
            "
        >
            <div class="vote-item__title">
                {{ title }}
            </div>
            <div class="vote-item__description">
                {{ description }}
            </div>
            <!-- <button
            v-if="yourTalent(id) || !voteable"
            @click="voteStore.vote(id)"
            class="btn btn-vote"
            :disabled="!authStore.isAuthenticated || voteable"
        >
            {{ yourTalent(id) && voteable ? "Ma rá szavaztam" : "Szavazok" }}
        </button> -->
            <div style="gap: 2px" class="d-flex">
                <div
                    class="w-50 vote-item__button"
                    @click="dialogShortVideo = true"
                >
                    <svg
                        class="icon"
                        width="13"
                        height="15"
                        viewBox="0 0 13 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 12.8204C1.00359 13.0288 1.06422 13.2322 1.17529 13.4085C1.28636 13.5848 1.44363 13.7273 1.63 13.8204C1.81611 13.9256 2.02624 13.9809 2.24 13.9809C2.45376 13.9809 2.6639 13.9256 2.85 13.8204L11.5 8.47042C11.6882 8.37975 11.847 8.2378 11.9582 8.0609C12.0693 7.884 12.1282 7.67933 12.1282 7.47042C12.1282 7.26151 12.0693 7.05684 11.9582 6.87994C11.847 6.70304 11.6882 6.56109 11.5 6.47042L2.85 1.16042C2.6639 1.05526 2.45376 1 2.24 1C2.02624 1 1.81611 1.05526 1.63 1.16042C1.44363 1.25361 1.28636 1.39609 1.17529 1.5724C1.06422 1.7487 1.00359 1.95208 1 2.16042V12.8204Z"
                            stroke="#E3007E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    Bemutatkozó kisvideó
                </div>
                <NuxtLink
                    @click="voteStore.setActiveTab(talentEnum)"
                    class="w-50 vote-item__button"
                    :to="useDynamicLink(link)"
                >
                    <svg
                        class="icon"
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.6923 3.76923H2.38462C1.61992 3.76923 1 4.38915 1 5.15384V17.6154C1 18.3801 1.61992 19 2.38462 19H10.6923C11.457 19 12.0769 18.3801 12.0769 17.6154V5.15384C12.0769 4.38915 11.457 3.76923 10.6923 3.76923Z"
                            stroke="#E3007E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M3.76904 7.23077H9.3075"
                            stroke="#E3007E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M3.76904 10.6923H9.3075"
                            stroke="#E3007E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M3.76904 14.1539H6.53827"
                            stroke="#E3007E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M4.46143 1H13.4614C13.8286 1 14.1809 1.14588 14.4405 1.40554C14.7001 1.66521 14.846 2.01739 14.846 2.38462V15.5385"
                            stroke="#E3007E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    Ismerj meg jobban
                </NuxtLink>
            </div>
            <button
                @click="
                    voteStore.vote(id);
                    handleClickWithoutLoggedIn();
                "
                :class="[
                    'btn btn-vote',
                    { disabled: !authStore.isAuthenticated || voteable },
                ]"
            >
                Szavazok
            </button>
        </div>

        <PrizificToaster
            :duration="3000"
            :visible="registrationToaster"
            :message="campaignDataStore.campaignUUId == '118ef046-ee9f-4d37-9062-7788cd336c2d'? 'Regisztrálj be, hogy te is szavazhass a kedvenc Minyonodra!' : 'Regisztrálj be, hogy te is szavazhass a kedvenc tehetségedre!'"
            @close="(val: boolean)=>registrationToaster=val"
        />

        <el-dialog
            style="max-width: 1024px"
            width="92%"
            v-model="dialogShortVideo"
            :show-close="false"
            destroy-on-close
            append-to-body
            center
        >
            <template #header="{ close }">
                <div
                    @click="close"
                    class="icon-close d-inline-block float-end mb-3"
                    style="color: #e3007e; margin-right: -16px"
                >
                    <el-icon :size="32">
                        <CircleClose />
                    </el-icon>
                </div>
            </template>

            <!-- <video
                style="max-width: 100%"
                controls
                autoplay
                auto
                :src="videoUrl"
            ></video> -->

            <iframe
                class="youtube-video"
                type="text/html"
                style="width: 100%"
                :src="`https://www.youtube.com/embed/${videoUrl}?autoplay=1&enablejsapi=1`"
                allow="autoplay"
                frameborder="0"
            ></iframe>
        </el-dialog>
    </div>
</template>

<script setup lang="ts">
import { CircleClose } from "@element-plus/icons-vue";
import { storeToRefs } from "pinia";
import { TalentsTab, useVoteStore } from "~~/store/components/vote/vote";
import { useAuthStore } from "~~/store/user/auth";
import PrizificToaster from "~~/components/PrizificToaster/PrizificToaster.vue";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";
const campaignDataStore = useCampaignDataStore();

const authStore = useAuthStore();
const voteStore = useVoteStore();
const { voteable, yourTalent } = storeToRefs(voteStore);

interface Props {
    id: string;
    image: string;
    title: string;
    description: string;
    link: string;
    talentEnum: TalentsTab;
    videoUrl?: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const dialogShortVideo = ref<boolean>(false);
const registrationToaster = ref<boolean>(false);

function handleClickWithoutLoggedIn() {
    if (!authStore.isAuthenticated) {
        registrationToaster.value = true;
    }
}
</script>

<style lang="scss" scoped>
.vote-item {
    position: relative;
    font-family: "Cocogoose";
    width: 320px;
    margin-top: 78px;
    border-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    padding: 0;

    &__button {
        background-color: #ebf8f9;
        color: #2a3e86;
        font-size: 14px;
        text-decoration: none;
        padding: 0 48px;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        cursor: pointer;
        gap: 12px;
        transition: color 0.15s ease-out;

        .icon {
            path {
                transition: stroke 0.15s ease-out;
            }
        }

        &:hover {
            .icon {
                path {
                    stroke: #3998d0;
                    transition: stroke 0.15s ease-out;
                }
            }

            text-decoration: underline;
            color: #3998d0;
            transition: color 0.15s ease-out;
        }
    }

    &__gradient-bg {
        position: relative;
        border-radius: 24px;
        height: 222px;
        background: linear-gradient(25deg, #2b3686, #3998d0);

        &:before {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 24px;
            z-index: 1;
            opacity: 0;
            background: linear-gradient(0, #e3007e 40%, #e3007d00 100%);
            transition: opacity 0.15s ease-out;
        }

        &:hover {
            img {
                position: relative;
                z-index: 0;
                filter: saturate(0);
                transition: filter 0.15s ease-out;
            }

            &:before {
                opacity: 0.3;
                transition: opacity 0.15s ease-out;
            }

            .btn-more {
                opacity: 1;
                transition: opacity 0.15s ease-out;
            }
        }
    }

    &__img {
        width: 100%;
        margin-top: -78px;
        transition: filter 0.15s ease-out;
    }

    &__link {
        position: absolute;
        display: flex;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        font-size: 14px;
        z-index: 1;
        text-decoration: none;
        transform: translate(-50%, -50%);
    }

    &__title {
        color: #2a3e86;
        font-size: 32px;
        font-weight: 300;
        margin-top: 32px;
    }

    &__description {
        font-size: 20px;
        line-height: 1;
        font-weight: 300;
        color: #e3007e;
        margin-bottom: 32px;
    }

    @media (min-width: 576px) {
        width: 320px;
        margin-top: 40px;

        &__gradient-bg {
            height: 140px;
        }

        &__img {
            margin-top: -40px;
        }
    }

    @media (min-width: 768px) {
        width: 320px;
        margin-top: 55px;

        &__gradient-bg {
            height: 200px;
        }

        &__img {
            margin-top: -55px;
        }
    }

    @media (min-width: 992px) {
        width: 245px;

        &__button {
            padding: 0 24px;
        }

        &__gradient-bg {
            height: 264px;
        }

        &__img {
            margin-top: -88px;
        }
    }

    @media (min-width: 1200px) {
        width: 286px;
        margin-top: 64px;

        &__button {
            padding: 0 32px;
        }

        &__gradient-bg {
            height: 327px;
        }

        &__img {
            margin-top: -88px;
        }
    }

    @media (min-width: 1400px) {
        width: 336px;

        &__button {
            padding: 0 48px;
        }

        &__gradient-bg {
            height: 352px;
        }
    }
}

.youtube-video {
    height: 320px;

    @media (min-width: 576px) {
        height: 276px;
    }

    @media (min-width: 768px) {
        height: 480px;
    }

    @media (min-width: 992px) {
        height: 553px;
    }

    @media (min-width: 1200px) {
        height: 554px;
    }

    @media (min-width: 1400px) {
        height: 554px;
    }
}

.btn-more {
    color: #2a3e86;
    font-size: 14px;
    font-weight: bold;
    background-color: #ffc9e6;
    margin: auto;
    opacity: 0;
    font-weight: 300;
    transition: opacity 0.15s ease-out;

    &:focus,
    &:active {
        border: 1px solid #ffc9e6;
        color: #2a3e86;
        background-color: #ffc9e6;
    }
}

.btn-vote {
    color: white;
    font-size: 20px;
    background-color: #e3007e;
    border: 1px solid #e3007e;
    padding: 8px 24px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    height: 69px;
    font-weight: 300;
    display: block;
    width: 100%;
    transition: background-color 0.15s ease-out;

    &:hover {
        background-color: #2a3e86;
        border-color: #2a3e86;
        transition: background-color 0.15s ease-out, border-color 0.15s ease-out;
    }

    &.disabled {
        color: white;
        background-color: #948f8f;
        border: 1px solid #948f8f;
        pointer-events: all;
    }
}
</style>
