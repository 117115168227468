<template>
    <client-only>
        <el-dialog
            class="promo-products-dialog prizific-dialog"
            :modelValue="show"
            :show-close="false"
            @open="handleOnOpen()"
            @closed="handleOnClosed()"
            align-center
            destroy-on-close
        >
            <template #header="{ close }">
                <div
                    @click="close"
                    class="icon-close d-inline-block float-end"
                    :style="closeIconStyleObject"
                >
                    <el-icon :size="32">
                        <CircleClose />
                    </el-icon>
                </div>
            </template>
            <div>
                <div class="text-center">
                    <h3
                        class="text-center px-4 mb-3 fw-bold"
                        :style="headingStyleObject"
                    >
                        {{ title }}
                    </h3>
                </div>
                <!-- <div
                    class="dialog-body text-center fw-bold mt-4"
                    :style="paragraphStyleObject"
                >
                    {{ description }}
                </div> -->
                <div v-loading="PurchasedPromotionProductStore.searching">
                    <div class="row">
                        <div class="col-12 col-xl-6 offset-xl-3">
                            <el-form-item class="mb-5">
                                <el-input
                                    placeholder="Keresés a termékek között"
                                    type="text"
                                    suffix-icon="el-icon-search"
                                    v-model="
                                        PurchasedPromotionProductStore.searchKeyword
                                    "
                                    @input="searchInPromoProducts()"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>

                    <div
                        :style="selectedPromoProductMergedStyle"
                        ref="promoProductListRef"
                        class="row promo-products-list"
                        v-infinite-scroll="load"
                        :infinite-scroll-disabled="disabled"
                        :infinite-scroll-distance="400"
                    >
                        <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4"
                            v-for="promoProduct in promoProductList.list"
                            :key="promoProduct.id"
                        >
                            <div
                                @click="togglePromoProduct(promoProduct)"
                                :class="[
                                    'promo-card d-flex flex-column position-relative',
                                    {
                                        selected: isPromoProductSelected(
                                            promoProduct.id
                                        ),
                                    },
                                ]"
                            >
                                <div
                                    v-if="isPromoProductSelected(promoProduct)"
                                    class="promo-card-selected"
                                >
                                    Kiválasztva
                                </div>
                                <div class="promo-card-image text-center">
                                    <img
                                        class="img-fluid"
                                        :src="
                                            useMediaUrl(promoProduct.imageUrl)
                                        "
                                        alt=""
                                    />
                                </div>
                                <div
                                    class="promo-card-title text-center fw-bold"
                                >
                                    {{ promoProduct.name }}
                                </div>
                                <div
                                    v-if="isPromoProductSelected(promoProduct)"
                                    class="promo-card-quantity-holder"
                                ></div>
                            </div>
                            <div
                                v-if="isPromoProductSelected(promoProduct)"
                                class="promo-card-quantity"
                            >
                                <el-input-number
                                    v-model="promoProduct.quantity"
                                    :min="0"
                                    @change="handleQuantityChange(promoProduct)"
                                />
                            </div>
                        </div>

                        <div
                            class="text-center"
                            v-if="PurchasedPromotionProductStore.searching"
                        >
                            Betöltés...
                        </div>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="text-center">
                    <el-button
                        type="primary"
                        class="mt-1"
                        :style="rightButtonStyleObject"
                        @click="handleButtonClick()"
                    >
                        Kiválasztás
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </client-only>
</template>

<script setup lang="ts">
import debounce from "lodash.debounce";
import { CircleClose } from "@element-plus/icons-vue";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { usePurchasedPromotionProductStore } from "@/store/components/purchasedPromotionProduct/purchasedPromotionProduct";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";

const runtime = useRuntimeConfig();
const campaignDataStore = useCampaignDataStore();
const PurchasedPromotionProductStore = usePurchasedPromotionProductStore();

interface Props {
    title: string;
    description: string;
    show: boolean;
    component: Array<IComponent<EComponents>>;
}
const props = defineProps<Props>();

const promoProductListRef = ref();
const promoProductList = reactive({
    list: [],
    lastSlice: 0,
});

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue", "close"]);

const preSelectPromoProducts = reactive({
    data: [] as any[],
});

const disabled = computed(() => {
    return (
        promoProductList.list.length >=
        PurchasedPromotionProductStore.afterSearchPromoProducts.length
    );
});

onMounted(() => {
    initList();
});

const load = debounce(function () {
    PurchasedPromotionProductStore.searching = true;

    setTimeout(() => {
        const list =
            PurchasedPromotionProductStore.afterSearchPromoProducts.slice(
                promoProductList.lastSlice,
                promoProductList.lastSlice + 10
            );

        list.forEach((item) => {
            promoProductList.list.push(item);
        });

        promoProductList.lastSlice += 10;

        PurchasedPromotionProductStore.searching = false;
    }, 1500);
}, 300);

/**
 * Search in promo products
 */
const searchInPromoProducts = debounce(function () {
    PurchasedPromotionProductStore.searching = true;
    PurchasedPromotionProductStore.afterSearchPromoProducts = [];
    promoProductList.list = [];
    promoProductList.lastSlice = 0;

    PurchasedPromotionProductStore.promoProducts.forEach(
        (promoProduct: any) => {
            if (
                promoProduct.name
                    .toLowerCase()
                    .includes(
                        PurchasedPromotionProductStore.searchKeyword.toLowerCase()
                    )
            ) {
                PurchasedPromotionProductStore.afterSearchPromoProducts.push(
                    promoProduct
                );
            }
        }
    );

    if (promoProductListRef.value) {
        promoProductListRef.value.scrollTop = 0;
    }
    load();
    PurchasedPromotionProductStore.searching = false;
}, 500);

function handleQuantityChange(promoProduct) {
    if (promoProduct.quantity == 0) {
        togglePromoProduct(promoProduct);
    }
}

/**
 * Toggle selection on promo product
 */
function togglePromoProduct(togglePromoProduct: any) {
    if (
        !preSelectPromoProducts.data.find(
            (selectedPromoProduct: any) =>
                selectedPromoProduct.id === togglePromoProduct.id
        )
    ) {
        togglePromoProduct.quantity = 1;
        preSelectPromoProducts.data.push(togglePromoProduct);
    } else {
        preSelectPromoProducts.data = preSelectPromoProducts.data.filter(
            (selectedPromoProduct: any) =>
                selectedPromoProduct.id !== togglePromoProduct.id
        );
    }
}

/**
 * Helper function to check promo product was selected
 * @param {object} promoProduct
 * @return {boolean}
 */
function isPromoProductSelected(promoProduct: any): boolean | undefined {
    return preSelectPromoProducts.data.find(
        (selectedPromoProduct: any) =>
            selectedPromoProduct.id === promoProduct.id
    );
}

/**
 * Handle select button click
 */
function handleButtonClick() {
    PurchasedPromotionProductStore.selectedPromoProductsId = [];

    PurchasedPromotionProductStore.selectedPromoProducts =
        preSelectPromoProducts.data;

    preSelectPromoProducts.data.forEach((item: any) =>
        PurchasedPromotionProductStore.selectedPromoProductsId.push(item.id)
    );

    emit(
        "update:modelValue",
        PurchasedPromotionProductStore.selectedPromoProducts
    );
    emit("close");
}

/**
 * On dialog close reset input
 */
function handleOnClosed() {
    PurchasedPromotionProductStore.searchKeyword = "";
    searchInPromoProducts();
    emit("close");

    initList();
}

/**
 * On dialog open reset selected
 */
function handleOnOpen() {
    preSelectPromoProducts.data = JSON.parse(
        JSON.stringify(PurchasedPromotionProductStore.selectedPromoProducts)
    );

    initList();
}

/**
 * Helper function if we have selected a promotional product,
 * we will put it at the top of the list.
 *
 * @param selectedArray
 * @param array
 */
function reOrdering(selectedArray: any[], array: any[]) {
    const selectedIds = new Set(selectedArray.map((item) => item.id));
    const selectedElements = array.filter((item) => selectedIds.has(item.id));
    const remainingElements = array.filter((item) => !selectedIds.has(item.id));

    return selectedElements.concat(remainingElements) ?? [];
}

/**
 * Initializing purchased promotion products
 */
function initList() {
    PurchasedPromotionProductStore.afterSearchPromoProducts = reOrdering(
        preSelectPromoProducts.data,
        campaignDataStore.campaignPromotions
    );

    PurchasedPromotionProductStore.promoProducts = reOrdering(
        preSelectPromoProducts.data,
        campaignDataStore.campaignPromotions
    );

    PurchasedPromotionProductStore.afterSearchPromoProducts = reOrdering(
        preSelectPromoProducts.data,
        campaignDataStore.campaignPromotions
    );
}

const closeIconStyleObject = computed(() => {
    return;
});
const headingStyleObject = computed(() => {
    return;
});
const paragraphStyleObject = computed(() => {
    return;
});
const leftButtonStyleObject = computed(() => {
    return;
});
const rightButtonStyleObject = computed(() => {
    return;
});

const selectedPromoProductStyleObject = computed(() => {
    return getStyle(
        props.component[0]?.getComponents()[5]?.style,
        "modalPurchasePromotionProducts"
    );
});

const selectedPromoProductMergedStyle = computed(() => {
    return {
        "--selected-promo-product-background-color":
            selectedPromoProductStyleObject.value[EProperty.BACKGROUND_COLOR] ??
            "#D64393FF",
        "--selected-promo-product-text-color":
            selectedPromoProductStyleObject.value[EProperty.COLOR],
        "--selected-promo-product-border-color":
            selectedPromoProductStyleObject.value[EProperty.BORDER_COLOR] ??
            "#D64393FF",
    };
});
</script>

<style lang="scss">
.icon-close {
    cursor: pointer;
}

.promo-products-dialog {
    h3 {
        display: inline-block;
        position: relative;

        p {
            position: relative;
            width: 100%;
            margin-bottom: 0;
            word-break: keep-all;
        }
    }

    .dialog-title {
        font-size: 18px;
    }

    .el-dialog__footer {
        padding: 10px 20px 15px;
    }

    .submit-btn {
        &.is-disabled {
            background: grey !important;
            color: white !important;
        }
    }

    .el-dialog__header {
        padding: 10px 20px 32px;
    }

    .el-dialog__body {
        padding: 32px 32px 0;
        word-break: keep-all !important;
    }

    .el-dialog__headerbtn .el-dialog__close {
        display: none;
    }

    .icon-close {
        cursor: pointer;
        font-size: 32px;
    }

    a {
        color: var(--el-promo-products-dialog-body-link-color);
        text-decoration: none;
    }

    .dialog-body {
        padding: 32px 0;
        margin-bottom: 1rem;
        word-break: keep-all !important;

        p {
            margin-bottom: 0 !important;
        }
    }

    .el-form-item__label {
        font-weight: bold;
        color: black !important;
    }

    .el-input__inner {
        border-width: 2px;
        border-radius: 10px;
    }

    .el-input.is-active,
    .el-input__inner:focus {
        border-color: var(--selected-promo-product-border-color);
    }

    .el-input__icon {
        color: var(--selected-promo-product-text-color);
    }

    .el-button--primary {
        text-transform: uppercase;
        // border: 0;
        // border-radius: 10px;
        font-weight: bold;
        white-space: normal;
        word-break: keep-all;
        // background: linear-gradient(to right, #372768, #372768, #372768);

        &:hover {
            // background: linear-gradient(
            //     90deg,
            //     rgb(231, 83, 15),
            //     rgb(248, 177, 33)
            // ) !important;
            // background: linear-gradient(to right, #372768, #372768, #372768);
        }
    }

    .el-button--info {
        text-transform: uppercase;
        border-radius: 10px;
        font-weight: bold;
        color: var(--selected-promo-product-text-color) !important;
        border: 1px solid var(--selected-promo-product-border-color) !important;
    }

    .el-form-item {
        display: flex;
        flex-direction: column;

        .el-form-item__label {
            justify-content: center;
        }

        @media (min-width: 768px) {
            flex-direction: row;

            .el-form-item__label {
                justify-content: flex-end;
            }
        }
    }

    .promo-products-list {
        row-gap: 1.25rem;
        height: 420px;
        overflow: auto;

        .promo-card {
            cursor: pointer;
            padding: 0.5rem;
            border-radius: 8px;
            border: 2px solid var(--selected-promo-product-border-color);
            height: 100%;
            color: black;

            &.selected {
                border-color: var(--selected-promo-product-border-color);
            }

            .promo-card-selected {
                position: absolute;
                user-select: none;
                top: 0;
                left: 0;
                width: 100%;
                padding: 0.25rem 0;
                text-align: center;
                color: #f7f3e8;
                font-weight: bold;
                font-size: 14px;
                background-color: var(
                    --selected-promo-product-background-color
                );
            }

            .promo-card-image {
                margin-top: auto;
                margin-bottom: auto;
            }

            .promo-card-title {
                margin-top: 0.25rem;
                user-select: none;
            }

            .promo-card-quantity-holder {
                height: 48px;
                background: var(--selected-promo-product-background-color);
                margin: 0.25rem -0.55rem -0.5rem;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        .promo-card-quantity {
            position: relative;
            z-index: 2;
            margin-top: -38px;
            text-align: center;

            .el-input__wrapper {
                box-shadow: initial !important;
                background: transparent;
            }

            .el-input__inner {
                border-color: var(--selected-promo-product-border-color);
                border-style: solid;
                color: var(--selected-promo-product-background-color);
                font-weight: bold;
                background: white;
            }

            .el-input-number__decrease,
            .el-input-number__increase {
                color: white;
                font-weight: bold;
                border: 2px solid white;
                background: transparent;
            }

            .el-input-number__decrease {
                border-radius: 50% !important;
            }

            .el-input-number__increase {
                border-radius: 50% !important;
            }
        }
    }
}
</style>
