<template>
    <client-only>
        <el-dialog class="dialog prizific-dialog cookie-dialog" :modelValue="cookieSettingsDialogStore.cookieConsent.show"
            @closed="handleOnClosed()" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
            destroy-on-close>
            <!-- <template #header="{ close }">
                <div
                    @click="close"
                    class="icon-close d-inline-block float-end"
                    :style="closeIconStyleObject"
                >
                    <el-icon :size="32"><CircleClose /></el-icon>
                </div>
            </template> -->

            <div>
                <template v-for="(page, index) in data.pages">
                    <div v-if="page.name ===
                        cookieSettingsDialogStore.getCookieConsentActivePage
                        " class="d-flex justify-content-center align-items-center" :key="index">
                        <div v-if="page.navigate.length" @click="
                            cookieSettingsDialogStore.setActivePage(
                                page.navigate
                            )
                            " class="py-2 mb-2 me-auto fs-4 page-navigation">
                            <i class="el-icon-arrow-left"></i>
                        </div>
                        <h3 :style="cookieSettingsTitleStyle" :class="[
                            'text-center px-4 py-2 fw-bold me-auto',
                            {
                                'ms-auto':
                                    cookieSettingsDialogStore.getCookieConsentActivePage ==
                                    'intro',
                            },
                        ]">
                            {{ page.title }}
                        </h3>
                    </div>
                </template>

                <div v-if="cookieSettingsDialogStore.getCookieConsentActivePage ==
                    'intro'
                    " class="mt-3" :style="cookieSettingsParagraphStyle ?? {}">
                    <div class="fw-bold fs-6">
                        Ezen a weboldalon sütiket használunk a felhasználói
                        élmény javítása, a hirdetések testreszabása valamint a
                        navigáció megkönnyítése érdekében.
                    </div>
                    <div class="fw-bold fs-6 mt-2">
                        Bővebb információ itt:
                        <a class="cursor-pointer text-decoration-underline" :href="useMediaUrl(campaignDataStore.campaignData.legalNoticePdfFile)
                            " target="_blank">
                            Cookie szabályzat
                        </a>
                    </div>
                    <div class="d-flex flex-column flex-lg-row align-items-center justify-content-end gap-4 mt-5">
                        <el-button type="primary" :style="leftButtonStyleObject" @click="
                            cookieSettingsDialogStore.setActivePage(
                                'settings'
                            )
                            ">
                            Beállítások
                        </el-button>
                        <el-button type="primary" :style="rightButtonStyleObject" @click="
                            cookieSettingsDialogStore.approveAllCookie()
                            ">
                            Összes elfogadása
                        </el-button>
                    </div>
                </div>

                <div v-if="cookieSettingsDialogStore.getCookieConsentActivePage ==
                    'settings'
                    " class="d-flex flex-column gap-3 mt-3">
                    <div class="d-flex align-items-center gap-2">
                        <div class="fw-bold fs-6">
                            Alapvető, működéshez szükséges sütik
                        </div>
                        <div class="ms-auto fs-6">Mindig aktív</div>
                    </div>
                    <div class="d-flex align-items-center gap-2">
                        <div class="fw-bold fs-6">Statisztikai sütik</div>
                        <el-switch class="ms-auto" v-model="data.cookieSettings.statistics" active-color="#13ce66"
                            inactive-color="#e0e0e0">
                        </el-switch>
                    </div>
                    <div class="d-flex align-items-center gap-2">
                        <div class="fw-bold fs-6">Marketing sütik</div>
                        <el-switch class="ms-auto" v-model="data.cookieSettings.marketing" active-color="#13ce66"
                            inactive-color="#e0e0e0">
                        </el-switch>
                    </div>
                    <div class="fw-bold fs-6">
                        Bővebb információ itt:
                        <a class="cursor-pointer text-decoration-underline" :href="useMediaUrl(campaignDataStore.campaignData.legalNoticePdfFile)
                            " target="_blank">
                            Cookie tájékoztató
                        </a>
                    </div>
                    <div class="d-flex flex-column flex-lg-row align-items-center justify-content-end mt-5 gap-4">
                        <el-button class="order-2 order-lg-1" type="text" :style="leftButtonStyleObject" @click="
                            cookieSettingsDialogStore.approveSelectedCookies(
                                data.cookieSettings
                            )
                            ">
                            Kiválasztottak elfogadása
                        </el-button>
                        <el-button class="order-1 order-lg-2" type="primary" :style="rightButtonStyleObject" @click="
                            cookieSettingsDialogStore.approveAllCookie()
                            ">
                            Összes elfogadása
                        </el-button>
                    </div>
                </div>

                <div v-if="cookieSettingsDialogStore.getCookieConsentActivePage ==
                    'consent'
                    ">
                    <div class="cookie-consent">Consent text</div>
                </div>
            </div>
        </el-dialog>

        <!-- Prizific page builder tool -->
        <PrizificPageBuilderTool v-if="editable" :section="section" :page="page" />
        <!-- Prizific page builder tool end -->
    </client-only>
</template>

<script setup lang="ts">
import { ElDialog, ElButton, ElIcon } from "element-plus";
import { CircleClose } from "@element-plus/icons-vue";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { useCookieSettingsDialogStore } from "@/store/components/cookieSettingsDialog/cookieSettingsDialog";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { ICookieSettings } from "~~/interfaces/PrizificPageBuilder/ICookieSettings";

const runtime = useRuntimeConfig();
const campaignDataStore = useCampaignDataStore();
const cookieSettingsDialogStore = useCookieSettingsDialogStore();

interface Props {
    page?: any;
    section: IComponent<EComponents> & ICookieSettings;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["closed"]);

const data = reactive({
    cookieSettings: {},
    pages: [
        { title: "Cookie beállítások", name: "intro", navigate: "" },
        { title: "Cookie beállítások", name: "settings", navigate: "intro" },
        { title: "Cookie beállítások", name: "consent", navigate: "settings" },
    ],
});

onMounted(() => {
    //console.log(cookieSettingsDialogStore.getCookieConsent);
    data.cookieSettings = cookieSettingsDialogStore.getCookieConsent;
});

/**
 * Handle dialog on closed
 */
function handleOnClosed() {
    emit("closed", false);
    cookieSettingsDialogStore.cookieConsent.show = false;
    cookieSettingsDialogStore.setAnalytics();
}

// Computed cookie settings modal style
const closeIconStyleObject = computed(() => {
    return getStyle(props.section.style, "cookieSettingsClose");
});
const cookieSettingsTitleStyle = computed(() => {
    return getStyle(props.section.style, "cookieSettingsTitle");
});
const cookieSettingsParagraphStyle = computed(() => {
    return getStyle(props.section.style, "cookieSettingsParagraph");
});
const leftButtonStyleObject = computed(() => {
    return getStyle(props.section.style, "modalLeftButton");
});
const rightButtonStyleObject = computed(() => {
    return getStyle(props.section.style, "modalRightButton");
});
</script>

<style lang="scss">
.dialog {
    h3 {
        position: relative;

        p {
            position: relative;
            margin-bottom: 0;
            word-break: keep-all;
        }
    }

    .el-dialog__footer {
        padding: 10px 20px 50px;
    }

    .el-dialog__header {
        padding: 10px 20px 32px;
    }

    .el-dialog__body {
        padding: 32px;
        word-break: keep-all;
    }

    .el-dialog__headerbtn .el-dialog__close {
        display: none;
    }

    .icon-close {
        cursor: pointer;
        font-size: 32px;
    }

    .page-navigation {
        cursor: pointer;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    a {
        color: var(--el-dialog-body-link-color);
        text-decoration: none;
    }

    .dialog-body {
        padding: 32px 0;
        margin-bottom: 1rem;
        word-break: keep-all !important;

        p {
            margin-bottom: 0 !important;
        }
    }

    .cookie-consent {
        max-height: 230px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 1rem;
    }

    .el-button--primary {
        font-weight: 400;
        white-space: normal;
        word-break: keep-all;
    }

    .el-button--info {
        font-weight: 300;
        white-space: normal;
    }

    .submit-btn {
        &.is-disabled {
            background: grey !important;
            color: white !important;
        }
    }

    .el-dialog {
        width: 90%;
        background: white !important;

        @media (min-width: 768px) {
            width: 50%;

            .el-dialog__body {
                padding: 16px 64px 32px;
                word-break: keep-all;
            }
        }
    }
}

.cookie-dialog {
    .el-dialog__header {
        display: none;
    }
}
</style>
