import { defineStore } from "pinia";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";

export const useImageUploadStore = defineStore("imageUpload", {
    state: () => {
        return {};
    },
    getters: {},
    actions: {
        async upload(uploadData: any) {
            const campaignDataStore = useCampaignDataStore();


            let formData = new FormData();
            formData.append("file", uploadData.file);
            formData.append("public", uploadData.isPublic ? "1" : "0");
            formData.append("campaignId", campaignDataStore.campaignId);
            formData.append("campaignUuid", campaignDataStore.campaignUUId);
            //Proxy hacking only for szentkirályi
            //if (campaignDataStore.campaignUUId == "cca63d5c-c47c-45be-8a3f-3c98e0037b7f") {
            //    const { data, error } = await useDirectApiFetch(`/imagemanagerms/upload`, {
            //        key: 'image-upload',
            //        method: "POST",
            //        body: formData,
            //    });
            //    if (error.value == null) {
            //        return data.value.file;
            //    }
            //} else {
                //TODO: only use this after szentkirályi!
                const { data, error } = await useApiFetch(`/imagemanagerms/upload`, {
                    key: 'image-upload' + Math.random(),
                    method: "POST",
                    body: formData,
                });

                if (error.value == null) {
                    return data.value.file;
                }
            //}

        },
    },
});
