<template>
    <!-- Prizes -->

    <section id="nyeremenyek">
        <div class="prizes overflow-hidden">
            <div>
                <div class="bg-white py-4 px-2 text-center">
                    <h2>VÁLASZTHATÓ FŐNYEREMÉNY</h2>
                    <div
                        class="d-flex flex-column flex-md-row justify-content-center gap-3 gap-md-13"
                    >
                        <div v-for="mainPrize in mainPrizes">
                            <div class="d-flex flex-column mt-3">
                                <img
                                    class="img-fluid prize-img"
                                    :src="mainPrize.image"
                                    alt=""
                                />
                                <div class="prize-name">
                                    {{ mainPrize.name }}
                                </div>

                                <div
                                    class="prize-modal-button"
                                    @click="handleDialogs(mainPrize.id, true)"
                                >
                                    Részletek
                                </div>

                                <DialogForOmnia
                                    :show="showDialog(mainPrize.id)"
                                    :title="mainPrize.modalTitle"
                                    :image="mainPrize.image"
                                    :description="mainPrize.modalDescription"
                                    footerBtnText="Bezár"
                                    @closed="handleDialogs(mainPrize.id, false)"
                                    @btnClicked="
                                        handleDialogs(mainPrize.id, false)
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="pictures-are-illustrations">
                        A képek illusztrációk.
                    </div>
                </div>

                <div class="bg-white py-5 px-2 text-center drop-shadow">
                    <h3>VÁLASZTHATÓ HETI NYEREMÉNY</h3>
                    <div
                        class="d-flex flex-column flex-md-row justify-content-center gap-3 gap-md-13"
                    >
                        <div v-for="weeklyPrize in weeklyPrizes">
                            <div class="d-flex flex-column mt-3">
                                <img
                                    class="img-fluid prize-img"
                                    :src="weeklyPrize.image"
                                    alt=""
                                />
                                <div class="prize-name">
                                    {{ weeklyPrize.name }}
                                </div>

                                <div
                                    class="prize-modal-button"
                                    @click="handleDialogs(weeklyPrize.id, true)"
                                >
                                    Részletek
                                </div>

                                <DialogForOmnia
                                    :show="showDialog(weeklyPrize.id)"
                                    :title="weeklyPrize.modalTitle"
                                    :image="weeklyPrize.image"
                                    :description="weeklyPrize.modalDescription"
                                    footerBtnText="Bezár"
                                    @closed="
                                        handleDialogs(weeklyPrize.id, false)
                                    "
                                    @btnClicked="
                                        handleDialogs(weeklyPrize.id, false)
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="pictures-are-illustrations">
                        A képek illusztrációk.
                    </div>
                </div>

                <div class="bg-white py-4 px-2 text-center">
                    <h3>NAPI OMNIA AJÁNDÉKCSOMAG</h3>
                    <div
                        class="d-flex flex-column flex-md-row justify-content-center gap-3 gap-md-13"
                    >
                        <div v-for="dailyPrize in dailyPrizes">
                            <div class="d-flex flex-column mt-3">
                                <img
                                    class="img-fluid prize-img"
                                    :src="dailyPrize.image"
                                    alt=""
                                />
                                <div
                                    class="prize-name"
                                    v-html="dailyPrize.name"
                                ></div>

                                <div
                                    class="prize-modal-button"
                                    @click="handleDialogs(dailyPrize.id, true)"
                                >
                                    Részletek
                                </div>

                                <DialogForOmnia
                                    :show="showDialog(dailyPrize.id)"
                                    :title="dailyPrize.modalTitle"
                                    :image="dailyPrize.image"
                                    :description="dailyPrize.modalDescription"
                                    footerBtnText="Bezár"
                                    @closed="
                                        handleDialogs(dailyPrize.id, false)
                                    "
                                    @btnClicked="
                                        handleDialogs(dailyPrize.id, false)
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="pictures-are-illustrations">
                        A képek illusztrációk.
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <!-- <PrizificPageBuilderTool v-if="editable" :section="section" /> -->
        <!-- Prizific page builder tool end -->
    </section>
    <!-- Prizes end -->
</template>

<script setup lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";

const runtime = useRuntimeConfig();
const campaignDataStore: any = useCampaignDataStore();

interface Props {
    section: IComponent<EComponents>;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();
const mainPrizes = ref([
    {
        id: 1,
        name: "Lombházas hétvége",
        image: useCdnUrl("/images/prizes/main-1.png"),
        modalTitle:
            "Lombházas hétvége: 500 000 Ft értékű utalvány, az alábbi választható helyszínek egyikére",
        modalDescription: `
            -Treestyle Zebegény (<a href='https://treestylezebegeny.hu' target="_blank">https://treestylezebegeny.hu</a>)<br/>
            -Woodhouses Noszvaj (<a href='https://woodhouses.hu' target="_blank">https://woodhouses.hu</a>)
        `,
    },
    {
        id: 2,
        name: "Tokaji szüretelős hétvége",
        image: useCdnUrl("/images/prizes/main-2.png"),
        modalTitle: "Tokaji szüretelős hétvége: 500 000 Ft értékű utalvány",
        modalDescription: `
            -Angyal Borászat Rátka (<a href='https://angyalboraszat.hu' target="_blank">https://angyalboraszat.hu</a>)
        `,
    },
    {
        id: 3,
        name: "E-bike túra",
        image: useCdnUrl("/images/prizes/main-3.png"),
        modalTitle: "E-bike túra a Káli-medencében: 500 000 Ft értékű utalvány",
        modalDescription: `
            -Zen Garden Resort Zánka (<a href='https://www.zengardenresort.hu/szallastipusok' target="_blank">https://www.zengardenresort.hu/szallastipusok</a>)
        `,
    },
]);
const weeklyPrizes = ref([
    {
        id: 4,
        name: "Páros fürdő",
        image: useCdnUrl("/images/prizes/weekly-1.png"),
        modalTitle:
            "20 000 Ft értékű utalvány<br/> az alábbi listából kiválasztott fürdőbe",
        modalDescription: `
            -Budapest, Rudas Gyógyfürdő<br/>
            -Budapest, Aquaworld<br/>
            -Miskolctapolca Barlangfürdő<br/>
            -Eger Termálfürdő<br/>
            -Demjén Termálfürdő<br/>
            -Hajdúszoboszló Hungarospa, Aqua Palace<br/>
            -Debrecen Aquaticum<br/>
            -Gyulai Várfürdő<br/>
            -Szeged, Napfényfürdő Aquapolis<br/>
            -Harkányfürdő<br/>
            -Hévíz<br/>
            -Zalakaros, Fürdőkomplexum<br/>
            -Bükfürdő<br/>
            -Sárvár Gyógy- és Wellnessfürdő<br/>
            -Pápa Várkertfürdő<br/>
        `,
    },
    {
        id: 5,
        name: "Prémium mozi",
        image: useCdnUrl("/images/prizes/weekly-2.png"),
        modalTitle:
            "20 000 Ft értékű utalvány<br/> az alábbi listából kiválasztott prémium moziba",
        modalDescription: `
            -Etele Cinema Premium<br/>
            -Cinema City VIP
        `,
    },
    {
        id: 6,
        name: "Kiállítás belépő",
        image: useCdnUrl("/images/prizes/weekly-3.png"),
        modalTitle: "19 900 Ft értékű Feldobox Kultúra csomag",
        modalDescription: `
            -<a href='https://www.feldobox.hu/termekek/szabadido/kultura' target="_blank">https://www.feldobox.hu/termekek/szabadido/kultura</a>   
        `,
    },
]);
const dailyPrizes = ref([
    {
        id: 7,
        name: "Őrölt-pörkölt Omnia<br/> válogatás és csésze szett",
        image: useCdnUrl("/images/prizes/daily-1.png"),
        modalTitle: "Őrölt-pörkölt Omnia<br/> válogatás és csésze szett",
        modalDescription: `
        -1 csomag D·E Omnia Classic 500 g őrölt-pörkölt kávé<br/>
        -1 csomag D·E Omnia Silk 250 g őrölt-pörkölt kávé<br/>
        -1 csomag D·E Omnia Evening 250 g őrölt-pörkölt kávé<br/>
        -1 csomag Omnia Intense 250 g őrölt-pörkölt kávé<br/>
        -1 csomag Omnia Gold 250 g őrölt-pörkölt kávé<br/>
        -1 csomag Omnia Barista Ed. Cr. Int. 225 g őrölt-pörkölt kávé<br/>
        -Duplafalú, boroszilikát üvegből készült eszpresszó csésze szett 2 db csészével (90 ml) bambusz alátéttel
        `,
    },
    {
        id: 8,
        name: "Omnia kapszula válogatás<br/> és csésze szett",
        image: useCdnUrl("/images/prizes/daily-2.png"),
        modalTitle: "Omnia kávékapszula válogatás<br/> és csésze szett",
        modalDescription: `
        -2 csomag Omnia Espresso Classico 20 db kávékapszula<br/>
        -1 csomag Omnia Colombia 20 db kávékapszula<br/>
        -1 csomag Omnia Espresso Crema 20 db kávékapszula<br/>
        -Duplafalú, boroszilikát üvegből készült eszpresszó csésze szett 2 db csészével (90 ml) bambusz alátéttel
        `,
    },
    {
        id: 9,
        name: "Omnia szemes kávé<br/> válogatás és csésze szett",
        image: useCdnUrl("/images/prizes/daily-3.png"),
        modalTitle: "Omnia szemes kávé<br/> válogatás és csésze szett",
        modalDescription: `
        -1 csomag D·E Omnia Classic 1000 g szemes kávé<br/>
        -1 csomag D·E Omnia Barista Cremoso 900 g szemes kávé<br/>
        -Duplafalú, boroszilikát üvegből készült eszpresszó csésze szett 2 db csészével (90 ml) bambusz alátéttel
        `,
    },
]);

const data = reactive({
    prizeDialogShow: [
        {
            id: 1,
            value: false,
        },
        {
            id: 2,
            value: false,
        },
        {
            id: 3,
            value: false,
        },
        {
            id: 4,
            value: false,
        },
        {
            id: 5,
            value: false,
        },
        {
            id: 6,
            value: false,
        },
        {
            id: 7,
            value: false,
        },
        {
            id: 8,
            value: false,
        },
        {
            id: 9,
            value: false,
        },
    ] as any[],
});

/**
 * Helper function to get dialog state value
 * @param {number} id
 */
function showDialog(id: string | number) {
    let dialogValue;

    data.prizeDialogShow.forEach((prizeDialog: any) => {
        if (prizeDialog.id == id) {
            dialogValue = prizeDialog.value;
        }
    });

    return dialogValue;
}

/**
 * Helper function to set dialog state value
 * @param {number} id
 * @param {boolean} value
 */
function handleDialogs(id: string | number, value: boolean) {
    data.prizeDialogShow.forEach((prizeDialog: any) => {
        if (prizeDialog.id == id) {
            prizeDialog.value = value;
        }
    });
}
</script>

<style lang="scss" scoped>
.prizes {
    font-family: "dynamic-font-39";
    background-color: #ffffff;

    h2 {
        color: #ae2126;
        font-weight: bold;
        font-size: 20px;
    }

    h3 {
        color: #ae2126;
        font-weight: bold;
        font-size: 15px;
    }

    .prize-img {
        max-width: 150px;
        margin: 0 auto;
    }
    .prize-name {
        font-size: 12px;
        font-weight: bold;
        color: #8c734b;
        margin-top: 10px;
    }

    .prize-modal-button {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 11px;
        color: #ae2126;
        cursor: pointer;
        margin-top: 0.35rem;
    }

    .drop-shadow {
        filter: drop-shadow(0px 0px 5px rgba(#000000, 15%));
    }
}

.pictures-are-illustrations {
    font-size: 10px;
    color: #ae2126;
    margin-top: 17px;
}
</style>
