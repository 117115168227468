export const ApCodeInput = (newValue: string, oldValue: string): string => {
    newValue = newValue ? newValue.toUpperCase() : "";
    oldValue = oldValue ? oldValue.toUpperCase() : "";

    if (
        newValue.match(/^[a-zA-Z\d](\d{1,8})?$/g) ||
        (newValue.match(/^(\d{1,8})?$/g) && newValue.length)
    ) {
        return newValue;
    }

    if (!newValue.length) {
        return "";
    }

    return oldValue;
};

export const ExtraCodeInput = (newValue: string, oldValue: string): string => {
    newValue = newValue ? newValue.toUpperCase() : "";
    oldValue = oldValue ? oldValue.toUpperCase() : "";

    if (
        newValue.match(/^[a-zA-Z\d]{1,10}?$/g) ||
        (newValue.match(/^[a-zA-Z\d]{1,10}?$/g) && newValue.length)
    ) {
        return newValue;
    }

    if (!newValue.length) {
        return "";
    }

    return oldValue;
};
