<template>
    <div class="prizific-page-builder-file-input">
       

        <!-- Thumbnail image or icon-->
        <div :class="[
            'el-upload-list__item-thumbnail',
            { 'no-thumbnail': !imageValue },
        ]">
            <div v-if="!imageValue" class="placeholder-text">
                PLACEHOLDER<br />
                IMAGE
            </div>

            <span v-if="!imageValue" class="no-thumbnail-icon">
                <i class="el-icon-picture-outline"></i>
            </span>
            
            <img v-if="imageValue" class="image-picker-img" :src="useImageMediaUrl(imageValue)" alt="" />

            <div v-if="imageValue" class="prizific-page-builder-file-input-clear">
                <span @click="handleRemove()" class="prizific-page-builder-file-input-clear-icon">
                    <el-icon :size="24">
                        <Delete />
                    </el-icon>
                </span>
            </div>
        </div>
        <!-- Thumbnail image or icon end-->

        <!-- Texts -->
        <label class="prizific-page-builder-file-input-label">
            Change
            <input ref="fileInput" type="file" @change="onFileChange" />
        </label>
        <!-- Texts end-->
    </div>
</template>

<script setup lang="ts">
import { ElIcon } from "element-plus";
import { Delete } from "@element-plus/icons-vue";
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { useImageUploadStore } from "@/store/components/imageUpload/imageUpload";
import { ImageFormat, useImageMediaUrl } from "~~/composables/useImageMedia";

const campaignDataStore: any = useCampaignDataStore();
const imageUploadStore: any = useImageUploadStore();
const runtime = useRuntimeConfig();

/**
 * Props interface
 */
interface Props {
    modelValue: string;
    public: Boolean;
}

/**
 *  File interface
 */
interface IFile {
    id: number;
    filename: string;
    originalFilename: string;
    isPublic: boolean;
    campaignId: number;
    campaignUuid: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue", "change", "error"]);

const imageUploaderRef = ref();

/**
 * Computed model value
 */
const imageValue = computed({
    get: () => {
        if (props.modelValue != null) {
            return props.modelValue;
        } else {
            return "";
        }
    },
    set: (value) => {
        if (value != null) {
            emit("update:modelValue", value);
            emit("change", value);
        } else {
            emit("update:modelValue", "");
            emit("change", value);
        }
    },
});


/**
 * Handle file remove
 */
function handleRemove() {
    emit("change", "");
    emit("update:modelValue", "");
}

/**
 * Handle input file when is change
 */
async function onFileChange(event: any) {
    const eventFile = event?.target?.files[0];

    if (eventFile) {
        try {
            const imageFile: IFile = await imageUploadStore.upload({
                file: eventFile,
                isPublic: props.public !== undefined ? props.public : false,
                campaignId: campaignDataStore.campaignData?.id,
                campaignUuid: campaignDataStore.campaignData?.uuid,
            });
            console.log("imageFile", imageFile);
            emit("update:modelValue", imageFile.originalFilename);
            emit("change", imageFile.originalFilename);
        } catch (error) {
            emit("error", error);
        }
    }
}
</script>

<style lang="scss" scoped>
.prizific-page-builder-file-input {
    display: flex;
    align-items: center;

    .el-upload-list__item-thumbnail {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        min-width: 100px;
        min-height: 100px;
        overflow: hidden;
        border-radius: 0.25rem;
        border: 1px solid transparent;

        &.no-thumbnail {
            align-items: center;
            justify-content: center;
            background-color: #878787;
            border: 1px solid var(--el-text-color-regular);

            .placeholder-text {
                color: white;
                line-height: 1.3;
                font-size: 11px;
                font-weight: 400;
                text-align: center;
            }
        }

        .no-thumbnail-icon {
            font-size: 24px;
        }

        .image-picker-img {
            width: 100px;
            height: 100px;
            object-fit: contain;
        }

        .prizific-page-builder-file-input-clear {
            position: absolute;
            opacity: 0;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            color: white;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.5);
            transition: opacity 0.25s ease-in-out;

            .prizific-page-builder-file-input-clear-icon {
                font-size: 24px;
                cursor: pointer;
            }
        }

        &:hover {
            .prizific-page-builder-file-input-clear {
                opacity: 1;
                transition: opacity 0.25s ease-in-out;
            }
        }
    }

    .prizific-page-builder-file-input-label {
        cursor: pointer;
        display: inline-block;
        margin-left: 1rem;

        .prizific-page-builder-file-input-label-title {
            font-size: 16px;
            font-weight: bold;
            color: black;
        }

        .prizific-page-builder-file-input-label-title,
        .prizific-page-builder-file-input-label-desc {
            line-height: 24px;
            margin-left: 1rem;
        }

        input[type="file"] {
            display: none;
        }
    }
}
</style>
