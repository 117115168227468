<script setup lang="ts">
import { useUploadedCodesStore } from "~~/store/components/uploadedCodes/uploadedCodes";
import { getStyle } from "../../composables/styleHelper";
import { useShippingFromStore } from "~~/store/components/shippingFrom/shippingFrom";

interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "drawBackground");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "drawTitle");
});
const sectionParagraphStyle = computed(() => {
    return getStyle(props.section.style, "drawParagraph");
});

const notWin = ref(false);

const videoPlayerRef = ref();
const playing = ref(false);

const videoByWin = ref("");
const videoByWinMobile = ref("");

const uploadedCodeStore = useUploadedCodesStore();
const shippingFromStore = useShippingFromStore();
const route = useRoute();
const router = useRouter();
const winnerUuid = ref<string | null>(null);

try {
    const res = await uploadedCodeStore.getUploadedCodeById(
        route.params.id as number
    ).catch((error) => {
        console.log("Not available", error.statusCode);

        if (error && error.statusCode == 404) {
            console.log("Redirect!")
            router.push({
                path: useDynamicLink(""),
            });
        }

        throw error;
    });


    if (res.uploadedCode.winnerUuid) {
        winnerUuid.value = res.uploadedCode.winnerUuid;
    }

    const prize = await shippingFromStore.getPrizeByUploadedCode(
        res.uploadedCode.id
    );

    if (prize?.id == 227 || prize?.id == 180) {
        videoByWin.value =
            "https://staro.s3.eu-west-2.amazonaws.com/staro_anim_desktop_netflix.mp4";
        videoByWinMobile.value =
            "https://staro.s3.eu-west-2.amazonaws.com/staro_anim_mobile_netflix.mp4";
    } else if (prize?.id == 181) {
        videoByWin.value =
            "https://staro.s3.eu-west-2.amazonaws.com/staro_anim_desktop_foodora.mp4";
        videoByWinMobile.value =
            "https://staro.s3.eu-west-2.amazonaws.com/staro_anim_mobile_foodora.mp4";
    } else {
        videoByWin.value =
            "https://staro.s3.eu-west-2.amazonaws.com/staro_anim_desktop_nem_nyert.mp4";
        videoByWinMobile.value =
            "https://staro.s3.eu-west-2.amazonaws.com/staro_anim_mobile_nem_nyert.mp4";
    }

} catch (e) {
    console.error("e", e);
    videoByWin.value =
        "https://staro.s3.eu-west-2.amazonaws.com/staro_anim_desktop_nem_nyert.mp4";
}

//watch(error, () => {
//    if (error.value && error.value.statusCode == 404) {
//        router.push({
//            path: useDynamicLink(""),
//        });
//    }
//});




function videoEnd() {
    console.log("Video done!");
    if (winnerUuid.value == null) {
        notWin.value = true;
    } else {
        setTimeout(() => {


            router.push({
                path: useDynamicLink("/nyertel/" + winnerUuid.value),
            });
        }, 3000)
    }
}

function videoIsLoaded() {

}

function startVideo() {
    console.log("Video Start!");
    videoPlayerRef.value.play();

    playing.value = true;
}
</script>

<template>
    <section>
        <div class="h-fix py-10" :style="backgroundStyle">
            <div class="container-fluid px-0">
                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3">
                        <h2 class="text-center mb-5" :style="sectionTitleStyle" v-if="section.sectionTitleRaw"
                            v-html="section.sectionTitleRaw"></h2>
                        <div class="description text-center mb-5" :style="sectionParagraphStyle"
                            v-if="section.sectionParagraphRaw" v-html="section.sectionParagraphRaw"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <video muted v-if="videoByWin" style="width: 100%" ref="videoPlayerRef" @ended="videoEnd"
                            @loadeddata="videoIsLoaded" playsinline autoplay>
                            <source :src="videoByWin" type="video/mp4" />
                            Your browser does not support the videos, please
                            wait and everything will happen
                        </video>
                    </div>
                </div>

                <div class="row" v-if="!notWin">
                    <div class="col-12 text-center">
                        <button @click="startVideo" :disabled="playing" class="btn btn-start">
                            Forogjon a film!
                        </button>
                    </div>
                </div>

                <div class="row" v-if="notWin">
                    <div class="col-12 text-center">
                        <div style="max-width:984px;margin:auto; margin-top:30px">
                            <p :style="sectionTitleStyle">Sajnos nem nyertél napi nyereményt, de a heti nyereményünkre
                                még esélyes vagy. Sőt, összesen
                                3 kupont tudsz megszerezni, amikor nem nyertél napi nyereményt. Csak kattints alul a
                                “Kuponok” gombra, és válaszd ki a kuponodat!
                            </p>
                            <p :style="sectionParagraphStyle" style="margin-top:30px; margin-bottom:30px">
                                Amennyiben nem érted el a napi 2 feltöltési limitedet, akár próbálkozhatsz is még a napi
                                nyereményért.
                                És ne feledd, minden egyes feltöltéssel nőnek az esélyeid a heti nyereményünkre is!
                            </p>
                            <a :href="useDynamicLink('/#kedvezmenykuponok')" class="btn btn-coupons">
                                Kuponok
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<style lang="scss">
.btn-start {
    color: #faf1d1 !important;
    padding: 4px 24px;
    border-radius: 24px;
    font-size: 18px;
    margin-top: 2rem;
    background-color: #114634;
    text-decoration: none;
    border: solid 1px #faf1d1;

    &:hover {
        border: solid 1px #faf1d1;
        background-color: #387963;
    }
}

.btn-coupons {
    border-radius: 24px;
    background-color: #faf1d1;
    border: solid 1px #faf1d1;
    color: #114634;

    &:hover {
        border: solid 1px #faf1d1;
        background-color: #c4bca1;
    }
}
</style>
