import Page from "~/entities/PrizificPageBuilder/Page";

import { navbar } from "~/entities/pages/shared/sections/Navbar/Navbar";
import { contact } from "~/entities/pages/shared/sections/Contact/Contact";
import { footer } from "~/entities/pages/shared/sections/Footer/Footer";
import { SubPageSetup } from "./SubPageSetup";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { winners } from "~/entities/pages/winners/sections/Winners/Winners";
import { cookieSettings } from "~~/entities/pages/shared/sections/CookieSettings/CookieSettings";

export class DefaultWinnersPageSetup extends SubPageSetup {
    protected page;
    protected name = "Winners";

    constructor() {
        super();

        this.page = new Page(
            "-",
            "Winners",
            EComponents.WINNERS_PAGE,
            [],
            "",
            [navbar, winners, contact, footer, cookieSettings],
            {}
        );
    }
}
