<template>
    <!-- Dialog -->
    <client-only>
        <el-dialog
            class="prizific-dialog"
            :modelValue="show"
            :show-close="false"
            @closed="handleOnClosed()"
            destroy-on-close
            append-to-body
            center
        >
            <template #header="{ close }">
                <div
                    @click="close"
                    class="icon-close d-inline-block float-end"
                    :style="closeIconStyleObject"
                >
                    <el-icon :size="32"><CircleClose /></el-icon>
                </div>
            </template>
            <div>
                <div class="text-center">
                    <h3
                        class="text-center px-4 py-2 fw-bold"
                        :style="headingStyleObject"
                        v-html="title"
                    ></h3>
                </div>

                <el-form
                    id="forgottenPasswordForm"
                    ref="forgottenPasswordForm"
                    :model="forgottenPasswordForm"
                    :rules="rules"
                    :style="formLabelStyleObject"
                    @keyup.native.enter="submitForgottenPasswordForm()"
                    @submit.prevent.native="checkEnter"
                    status-icon
                >
                    <div class="row">
                        <div class="col-xs-12 col-md-8 offset-md-2">
                            <el-form-item
                                label="Email cím"
                                prop="email"
                                status-icon
                            >
                                <el-input
                                    type="email"
                                    v-model="forgottenPasswordForm.email"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
                <div class="text-center">
                    <el-button
                        v-if="footerBtnLeftText"
                        type="info"
                        class="mt-3"
                        :style="leftButtonStyleObject"
                        @click="handleLeftButtonClick()"
                    >
                        {{ footerBtnLeftText }}
                    </el-button>
                    <el-button
                        v-if="footerBtnText"
                        type="primary"
                        class="mt-3"
                        :style="rightButtonStyleObject"
                        @click="submitForgottenPasswordForm"
                    >
                        {{ footerBtnText }}
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </client-only>
    <!-- Dialog end -->
</template>

<script lang="ts">
import { getStyle } from "../../composables/styleHelper";
import { ElDialog, ElButton, ElIcon, ElNotification } from "element-plus";
import { CircleClose } from "@element-plus/icons-vue";
import { useLoginRegistrationStore } from "../../store/components/loginRegistration/loginRegistration";
const registrationStore = useLoginRegistrationStore();

export default {
    components: {
        ElDialog,
        ElButton,
        ElIcon,
        CircleClose,
    },
    emits: ["closed", "btnClicked", "btnLeftClicked"],
    props: {
        show: Boolean,
        title: String,
        image: String,
        description: String,
        footerBtnText: String,
        footerBtnLeftText: String,
        component: Object,
    },
    data() {
        return {
            isMounted: false,
            forgottenPasswordForm: {
                email: "",
                submitButtonClicked: false,
            },
            rules: {
                email: [
                    {
                        required: true,
                        message: "Kérjük töltsd ki a mezőt",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        message: "Kérjük töltsd ki a mezőt",
                        trigger: "change",
                    },
                    {
                        pattern:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                        message: "Hiba az Email címbe kérjük javítsd",
                        trigger: "change",
                    },
                    {
                        pattern:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                        message: "Hiba az Email címbe kérjük javítsd",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    mounted() {
        this.isMounted = true;
    },
    computed: {
        closeIconStyleObject() {
            return getStyle(this.component?.style, "loginModalClose");
        },
        headingStyleObject() {
            return getStyle(this.component?.style, "loginModalTitle");
        },
        paragraphStyleObject() {
            return getStyle(this.component?.style, "loginModalDescription");
        },
        leftButtonStyleObject() {
            return getStyle(this.component?.style, "modalLeftButton");
        },
        rightButtonStyleObject() {
            return getStyle(this.component?.style, "modalRightButton");
        },
        /**
         * Check forgotten password form is valid
         */
        forgottenPasswordFormIsValid(): boolean {
            let formIsValid = false;

            if (
                this.isMounted &&
                this.dialogVisible &&
                this.forgottenPasswordForm.email !== ""
            ) {
                const errors = (
                    this.$refs.forgottenPasswordForm as HTMLFormElement
                ).fields.filter(
                    (field: any) =>
                        (field.validateState === "error" ||
                            field.validateState === "") &&
                        field.isRequired === true
                );

                formIsValid = !errors.length;
            }

            return formIsValid;
        },
    },
    methods: {
        handleOnClosed() {
            this.$emit("closed", false);
        },
        handleRightButtonClick() {
            this.$emit("btnClicked", "");
        },
        handleLeftButtonClick() {
            this.$emit("btnLeftClicked", "");
        },
        /**
         * Submit forgottend password form
         */
        submitForgottenPasswordForm() {
            (this.$refs.forgottenPasswordForm as HTMLFormElement).validate(
                async (valid: Boolean) => {
                    if (valid) {
                        this.$emit(
                            "btnClicked",
                            this.forgottenPasswordForm.email
                        );

                        this.forgottenPasswordForm.submitButtonClicked = true;
                        try {
                            const { data, error } =
                                await registrationStore.resetPasswordNotification(
                                    this.forgottenPasswordForm.email
                                );

                            if (error.value == null) {
                                (
                                    this.$refs
                                        .forgottenPasswordForm as HTMLFormElement
                                ).resetFields();

                                ElNotification({
                                    title: "A jelszó emlékeztetőt sikeresen elküldtük!",
                                    message:
                                        "Amennyiben a rendszerünkben szerepel ez az e-mail cím, kérlek ellenőrizd postafiókodat",
                                    position: "bottom-left",
                                    duration: 6000,
                                    type: "success",
                                });

                                this.$emit("closed", true);
                            }

                            if (error.value != null) {
                                ElNotification({
                                    title: "A jelszó emlékeztetőt nem sikerült elküldenünk!",
                                    message: "Kérjük próbáld meg újra később!",
                                    position: "bottom-left",
                                    duration: 6000,
                                    type: "error",
                                });
                            }
                        } catch (error) {
                            ElNotification({
                                title: "A jelszó emlékeztetőt nem sikerült elküldenünk!",
                                message: "Kérjük próbáld meg újra később!",
                                position: "bottom-left",
                                duration: 6000,
                                type: "error",
                            });
                        }
                    } else {
                        return false;
                    }

                    this.forgottenPasswordForm.submitButtonClicked = false;
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.prizific-dialog {
    min-width: 320px;
}

.icon-close {
    cursor: pointer;
}
</style>
