<template>
    <div :style="editProfileFormStyle">
        <div class="row">
            <div class="col-12 col-md-4 offset-md-4">
                <el-form
                    id="editProfileForm"
                    ref="editProfileFormRef"
                    :style="editProfileFormMergedStyle"
                    :model="editProfileForm"
                    :rules="rules"
                    label-position="top"
                    @keyup.enter.prevent="submitEditProfileForm"
                    status-icon
                >
                    <div class="row">
                        <div class="col-lg-6">
                            <!-- Lastname -->
                            <el-form-item prop="lastname" label="Vezetéknév">
                                <el-input
                                    ref="lastname"
                                    type="text"
                                    v-model="editProfileForm.lastname"
                                />
                            </el-form-item>
                            <!-- Lastname end -->
                        </div>

                        <div class="col-lg-6">
                            <!-- Firstname -->
                            <el-form-item prop="firstname" label="Keresztnév">
                                <el-input
                                    ref="firstname"
                                    type="text"
                                    v-model="editProfileForm.firstname"
                                />
                            </el-form-item>
                            <!-- Firstname end -->
                        </div>
                    </div>

                    <!-- Email -->
                    <el-form-item prop="email" label="Email">
                        <el-input
                            ref="email"
                            type="email"
                            v-model="editProfileForm.email"
                            disabled
                        />
                    </el-form-item>
                    <!-- Email end -->

                    <div class="text-center">
                        <!-- Submit button -->
                        <el-button
                            class="submit-btn"
                            @click="submitEditProfileForm"
                            @keyup.space="submitEditProfileForm"
                            :loading="editProfileForm.submitButtonClicked"
                        >
                            Mentés
                        </el-button>
                        <!-- Submit button end -->
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    ElForm,
    ElFormItem,
    ElInput,
    ElCheckbox,
    ElNotification,
} from "element-plus";
import type { FormInstance, FormRules } from "element-plus";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { useAuthStore } from "@/store/user/auth";
import { useLoginRegistrationStore } from "~~/store/components/loginRegistration/loginRegistration";

interface Props {
    component: any;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["change"]);
const router = useRouter();
const authStore = useAuthStore();

const editProfileFormRef = ref<FormInstance>();
const editProfileComponent = props.component[0];

const editProfileForm = reactive({
    lastname: authStore.user?.lastname,
    firstname: authStore.user?.firstname,
    email: authStore.user?.email,
    phone: "",
    edmSubscription: false,
    submitButtonClicked: false,
});

const rules = reactive<FormRules>({
    password: [
        {
            required: true,
            message: "Kérjük töltsd ki a mezőt",
            trigger: "blur",
        },
        {
            required: true,
            pattern: /^(?=.{6,})(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*\d+).*$/,
            message: "Hiba a jelszóba kérjük javítsd",
            trigger: "change",
        },
    ],
});

// Computed form and form items style
const editProfileFormStyle = computed(() => {
    return getStyle(editProfileComponent.style, "editProfileFormBackground");
});
const editProfileFormItemLabelStyle = computed(() => {
    return getStyle(editProfileComponent.style, "editProfileFormItemLabel");
});
const editProfileFormItemInputStyle = computed(() => {
    return getStyle(editProfileComponent.style, "editProfileFormItemInput");
});
const editProfileFormSubmitButtonStyle = computed(() => {
    return getStyle(editProfileComponent.style, "editProfileFormSubmitButton");
});
const editProfileFormSubmitButtonHoverStyle = computed(() => {
    return getStyle(
        editProfileComponent.style,
        "editProfileFormSubmitButtonHover"
    );
});

const editProfileFormMergedStyle = computed(() => {
    return {
        "--edit-profile-form-label-color":
            editProfileFormItemLabelStyle.value[EProperty.COLOR],
        "--edit-profile-form-font-size":
            editProfileFormItemLabelStyle.value[EProperty.FONT_SIZE],
        "--edit-profile-form-input-border-radius":
            editProfileFormItemInputStyle.value[EProperty.BORDER_RADIUS],
        "--edit-profile-form-submit-color":
            editProfileFormSubmitButtonStyle.value[EProperty.COLOR],
        "--edit-profile-form-submit-font-size":
            editProfileFormSubmitButtonStyle.value[EProperty.FONT_SIZE],
        "--edit-profile-form-submit-background-color":
            editProfileFormSubmitButtonStyle.value[EProperty.BACKGROUND_COLOR],
        "--edit-profile-form-submit-border-radius":
            editProfileFormSubmitButtonStyle.value[EProperty.BORDER_RADIUS],
        "--edit-profile-form-submit-color-hover":
            editProfileFormSubmitButtonHoverStyle.value[EProperty.COLOR],
        "--edit-profile-form-submit-background-color-hover":
            editProfileFormSubmitButtonHoverStyle.value[
                EProperty.BACKGROUND_COLOR
            ],
    };
});

/**
 * Handle edit Profile form submit
 */
async function submitEditProfileForm() {
    await editProfileFormRef.value?.validate(async (valid, fields) => {
        const profileStore = useLoginRegistrationStore();
        if (valid) {
            const { data, error } = await profileStore.updateMe({
                lastname: editProfileForm.lastname,
                firstname: editProfileForm.firstname,
            });

            if (error.value !== null) {
                ElNotification({
                    title: "Sikertelen profil módosítás",
                    message: "Sikertelen profil módosítá",
                    position: "bottom-left",
                    duration: 5000,
                    type: "error",
                });
            } else {
                ElNotification({
                    title: "Sikeres profil módosítás",
                    message: "Sikeres profil módosítás",
                    position: "bottom-left",
                    duration: 5000,
                    type: "success",
                });
            }
        } else {
            console.log("error submit!", fields);
        }
    });
}
</script>

<style lang="scss">
#editProfileForm {
    .el-form-item__label {
        color: var(--edit-profile-form-label-color) !important;
        font-size: var(--edit-profile-form-font-size) !important;
    }

    .el-form-item__label::before {
        content: "" !important;
    }

    .el-input__wrapper {
        border-radius: var(--edit-profile-form-input-border-radius);
    }

    .el-form-item__error {
        color: white;
        background-color: #e44f60;
        border-radius: 4px;
        padding: 2px 4px;
        margin-top: 5px;
        right: 0;
        left: initial;

        &::before {
            position: absolute;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #e44f60;
            width: 0;
            height: 0;
            display: block;
            content: "";
            top: -5px;
            right: 12px;
        }
    }

    .submit-btn {
        border: 0;
        white-space: normal;
        word-wrap: break-word;
        color: var(--edit-profile-form-submit-color);
        border-radius: var(--edit-profile-form-submit-border-radius);
        background-color: var(--edit-profile-form-submit-background-color);

        &:hover {
            color: var(--edit-profile-form-submit-color-hover);
            background-color: var(
                --edit-profile-form-submit-background-color-hover
            );
        }

        &.is-disabled {
            background: grey !important;
            color: white !important;
        }
    }
}
</style>
