<template>
    <div>
        <VoteAlert
            v-if="authStore.isAuthenticated && voteStore.voteable"
            message="Ma már leadtad a szavazatod, holnap újra megteheted!"
        />
        <template v-for="(talent, index) in voteStore.talents" :key="index">
            <div
                v-if="talent.talentEnum == voteStore.activeTab"
                :id="talent.talentId"
                class="d-flex flex-column flex-lg-row gap-5 pt-8"
            >
                <VoteItemMore
                    :id="talent.id"
                    :image="talent.image"
                    :name="talent.name"
                    :birthDate="talent.birthDate"
                    :city="talent.city"
                    :sport="talent.sport"
                    :association="talent.association"
                    :sportExperience="talent.sportExperience"
                    :percentage="talent.percentage"
                />

                <div class="mt-3 mt-md-9">
                    <div class="list-title">Céljaim:</div>
                    <VoteSectionMyGoals :text="talent.myGoals" />
                    <div class="list-title">Meghatározó sporteredmények:</div>
                    <VoteSectionList
                        :list="talent.sportResults"
                        :columns="2"
                        color="#2A3E86"
                    />
                    <div class="row mt-6">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                            <div class="list-title">Fontosabb állomások:</div>
                            <VoteSectionList
                                :list="talent.importantStations"
                                :columns="1"
                                color="#2A3E86"
                            />
                        </div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                            <div class="list-title">
                                Anyagi támogatás felhasználása:
                            </div>
                            <VoteSectionList
                                :list="talent.financialSupport"
                                :columns="1"
                                color="#2A3E86"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "~~/store/user/auth";
import { useVoteStore } from "~~/store/components/vote/vote";

const authStore = useAuthStore();
const voteStore = useVoteStore();
</script>

<style lang="scss" scoped>
.list-title {
    font-family: "Cocogoose";
    color: #e3007e;
    margin-bottom: 24px;
    margin-top: 32px;
    font-size: 20px;

    &:first-child {
        margin-top: 0;
    }
}
</style>
