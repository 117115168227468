<template>
    <!-- Prizes -->

    <section id="nyeremenyek">
        <div
            class="position-relative main-prize py-4 pb-10"
            style="background-color: #f5d940"
        >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2
                            style="color: #0e3456"
                            class="text-center fw-bold text-uppercase py-5"
                        >
                            Főnyeremény
                        </h2>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <div class="row justify-content-center">
                            <template
                                v-for="(
                                    prize, index
                                ) in campaignDataStore.campaignPrizes"
                                :key="index"
                            >
                                <template v-if="prize.type == 'jackpot'">
                                    <div
                                        class="col-md-6 col-xl-6 my-3"
                                        v-for="item in prize.prizeVariants"
                                        :key="item.id"
                                    >
                                        <div
                                            class="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start"
                                        >
                                            <div
                                                style="color: #444444"
                                                class="text-center text-sm-start px-3 mb-2"
                                            >
                                                <picture>
                                                    <source
                                                        :srcset="
                                                            useImageMediaUrl(
                                                                item.imageUrl,
                                                                ImageFormat.WEBP,
                                                                prizeImageStyle
                                                                    ? parseInt(
                                                                          prizeImageStyle
                                                                      )
                                                                    : 150,
                                                                prizeImageStyle
                                                                    ? parseInt(
                                                                          prizeImageStyle
                                                                      )
                                                                    : 150
                                                            )
                                                        "
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        class="prize-image"
                                                        :src="
                                                            item.imageUrl
                                                                ? useImageMediaUrl(
                                                                      item.imageUrl,
                                                                      ImageFormat.PNG,
                                                                      prizeImageStyle
                                                                          ? parseInt(
                                                                                prizeImageStyle
                                                                            )
                                                                          : 150,
                                                                      prizeImageStyle
                                                                          ? parseInt(
                                                                                prizeImageStyle
                                                                            )
                                                                          : 150
                                                                  )
                                                                : useCdnUrl(
                                                                      '/images/150x150-image-placeholder.png'
                                                                  )
                                                        "
                                                        alt=""
                                                    />
                                                </picture>
                                            </div>
                                            <div
                                                class="text-center text-sm-start px-0"
                                            >
                                                <h4
                                                    class="fw-bold text-uppercase"
                                                    :style="prizeNameStyle"
                                                >
                                                    {{ item.name }}
                                                </h4>
                                                <div
                                                    class="py-3 mb-0"
                                                    v-if="item.description"
                                                    :style="
                                                        prizeDescriptionStyle
                                                    "
                                                    v-html="item.description"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="prizes py-4 overflow-hidden" :style="[sectionStyle]">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2
                            style="color: #f5d940"
                            class="text-center py-5"
                            v-html="section.sectionTitle"
                        ></h2>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div :class="['row d-block', 'd-sm-none']">
                    <div class="col-12 col-md-10 offset-md-1">
                        <div class="row justify-content-center">
                            <div
                                class="col-md-6 col-xl-4 my-3"
                                v-for="(
                                    prize, index
                                ) in campaignDataStore.campaignPrizes"
                                :key="index"
                            >
                                <template
                                    v-for="item in prize.prizeVariants"
                                    :key="item.id"
                                >
                                    <div
                                        class="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start"
                                    >
                                        <div
                                            class="text-center text-sm-start px-3 mb-2"
                                        >
                                            <picture
                                                v-if="parseInt(prizeImageStyle)"
                                            >
                                                <source
                                                    :srcset="
                                                        useImageMediaUrl(
                                                            item.imageUrl,
                                                            ImageFormat.WEBP,
                                                            parseInt(
                                                                prizeImageStyle
                                                            ),
                                                            parseInt(
                                                                prizeImageStyle
                                                            )
                                                        )
                                                    "
                                                    type="image/webp"
                                                />
                                                <img
                                                    class="prize-image"
                                                    :src="
                                                        item.imageUrl
                                                            ? useImageMediaUrl(
                                                                  item.imageUrl,
                                                                  ImageFormat.PNG,
                                                                  parseInt(
                                                                      prizeImageStyle
                                                                  ),
                                                                  parseInt(
                                                                      prizeImageStyle
                                                                  )
                                                              )
                                                            : useCdnUrl(
                                                                  '/images/150x150-image-placeholder.png'
                                                              )
                                                    "
                                                    alt=""
                                                />
                                            </picture>
                                            <picture v-else>
                                                <source
                                                    :srcset="
                                                        useImageMediaUrl(
                                                            item.imageUrl,
                                                            ImageFormat.WEBP,
                                                            150,
                                                            150
                                                        )
                                                    "
                                                    type="image/webp"
                                                />
                                                <img
                                                    class="prize-image"
                                                    :src="
                                                        item.imageUrl
                                                            ? useImageMediaUrl(
                                                                  item.imageUrl,
                                                                  ImageFormat.PNG,
                                                                  150,
                                                                  150
                                                              )
                                                            : useCdnUrl(
                                                                  '/images/150x150-image-placeholder.png'
                                                              )
                                                    "
                                                    alt=""
                                                />
                                            </picture>
                                            <!-- <img
                                                class="prize-image"
                                                :src="
                                                    item.imageUrl
                                                        ? useMediaUrl(
                                                              item.imageUrl,
                                                              150,
                                                              150
                                                          )
                                                        : useCdnUrl(
                                                              '/images/150x150-image-placeholder.png'
                                                          )
                                                "
                                                alt="Prize"
                                            /> -->
                                        </div>
                                        <div
                                            class="text-center text-sm-start px-0"
                                        >
                                            <div
                                                class="prize-type text-uppercase mb-2"
                                                :style="prizeTypeStyle"
                                            >
                                                {{ prizeTypeTitle(prize.type) }}
                                            </div>
                                            <h4
                                                class="fw-bold text-uppercase"
                                                :style="prizeNameStyle"
                                            >
                                                {{ item.name }}
                                            </h4>
                                            <div
                                                class="py-3 mb-0"
                                                v-if="item.description"
                                                :style="prizeDescriptionStyle"
                                                v-html="item.description"
                                            ></div>
                                            <!-- <div
                                                v-if="item.longDescription"
                                                class="text-decoration-none fw-bold text-uppercase"
                                                type="button"
                                                :style="prizeMoreStyle"
                                                @mouseover="
                                                    setStyle(
                                                        $event,
                                                        prizeMoreHoverStyle
                                                    )
                                                "
                                                @mouseleave="
                                                    setStyle(
                                                        $event,
                                                        prizeMoreStyle
                                                    )
                                                "
                                                @click="
                                                    handleDialogs(item.id, true)
                                                "
                                            >
                                                Részletek
                                            </div>
                                            <Dialog
                                                :show="showDialog(item.id)"
                                                :title="item.name"
                                                :image="
                                                    item.imageUrl
                                                        ? useMediaUrl(
                                                              item.imageUrl,
                                                              150,
                                                              150
                                                          )
                                                        : useCdnUrl(
                                                              '/images/150x150-image-placeholder.png'
                                                          )
                                                "
                                                :description="
                                                    item.longDescription
                                                "
                                                footerBtnText="Bezár"
                                                :component="modalComponent"
                                                @closed="
                                                    handleDialogs(
                                                        item.id,
                                                        false
                                                    )
                                                "
                                                @btnClicked="
                                                    handleDialogs(
                                                        item.id,
                                                        false
                                                    )
                                                "
                                            /> -->
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="prizes-swiper py-3 d-none d-sm-block">
                    <client-only>
                        <!-- Swiper -->
                        <swiper
                            :modules="modules"
                            :breakpoints="data.breakpoints"
                            :pagination="data.pagination"
                            :navigation="data.navigation"
                        >
                            <template
                                v-for="(
                                    prize, index
                                ) in campaignDataStore.campaignPrizes"
                                :key="index"
                            >
                                <template v-if="prize.type == 'week'">
                                    <swiper-slide
                                        v-for="item in prize.prizeVariants"
                                        :key="item.id"
                                    >
                                        <div
                                            class="d-flex flex-column flex-sm-row justify-content-center"
                                        >
                                            <div
                                                class="text-center text-sm-start px-3 mb-9 mb-lg-2"
                                            >
                                                <picture
                                                    v-if="
                                                        parseInt(
                                                            prizeImageStyle
                                                        )
                                                    "
                                                >
                                                    <source
                                                        :srcset="
                                                            useImageMediaUrl(
                                                                item.imageUrl,
                                                                ImageFormat.WEBP,
                                                                parseInt(
                                                                    prizeImageStyle
                                                                ),
                                                                parseInt(
                                                                    prizeImageStyle
                                                                )
                                                            )
                                                        "
                                                        type="image/webp"
                                                    />

                                                    <img
                                                        class="prize-image"
                                                        :src="
                                                            item.imageUrl
                                                                ? useImageMediaUrl(
                                                                      item.imageUrl,
                                                                      ImageFormat.PNG,
                                                                      parseInt(
                                                                          prizeImageStyle
                                                                      ),
                                                                      parseInt(
                                                                          prizeImageStyle
                                                                      )
                                                                  )
                                                                : useCdnUrl(
                                                                      '/images/150x150-image-placeholder.png'
                                                                  )
                                                        "
                                                        alt="Prize"
                                                    />
                                                </picture>
                                                <picture v-else>
                                                    <source
                                                        :srcset="
                                                            useImageMediaUrl(
                                                                item.imageUrl,
                                                                ImageFormat.WEBP,
                                                                150,
                                                                150
                                                            )
                                                        "
                                                        type="image/webp"
                                                    />

                                                    <img
                                                        class="prize-image"
                                                        :src="
                                                            item.imageUrl
                                                                ? useImageMediaUrl(
                                                                      item.imageUrl,
                                                                      ImageFormat.PNG,
                                                                      150,
                                                                      150
                                                                  )
                                                                : useCdnUrl(
                                                                      '/images/150x150-image-placeholder.png'
                                                                  )
                                                        "
                                                        alt="Prize"
                                                    />
                                                </picture>
                                            </div>
                                            <div
                                                class="text-center text-sm-start px-0"
                                            >
                                                <!-- <div
                                                    class="prize-type text-uppercase mb-2"
                                                    :style="prizeTypeStyle"
                                                >
                                                    {{
                                                        prizeTypeTitle(
                                                            prize.type
                                                        )
                                                    }}
                                                </div> -->
                                                <h4
                                                    class="fw-bold text-uppercase"
                                                    :style="prizeNameStyle"
                                                >
                                                    {{ item.name }}
                                                </h4>
                                                <div
                                                    class="py-3 mb-0"
                                                    v-if="item.description"
                                                    :style="
                                                        prizeDescriptionStyle
                                                    "
                                                    v-html="item.description"
                                                ></div>
                                                <!-- <div
                                                    v-if="item.longDescription"
                                                    class="text-decoration-none fw-bold text-uppercase"
                                                    type="button"
                                                    :style="prizeMoreStyle"
                                                    @mouseover="
                                                        setStyle(
                                                            $event,
                                                            prizeMoreHoverStyle
                                                        )
                                                    "
                                                    @mouseleave="
                                                        setStyle(
                                                            $event,
                                                            prizeMoreStyle
                                                        )
                                                    "
                                                    @click="
                                                        handleDialogs(
                                                            item.id,
                                                            true
                                                        )
                                                    "
                                                >
                                                    Részletek
                                                </div> -->
                                            </div>
                                        </div>
                                    </swiper-slide>
                                </template>
                            </template>

                            <!-- Swiper prev/next buttons -->
                            <div
                                class="swiper-button-prev d-none d-lg-block"
                                :style="swiperArrowColorStyleObject"
                            ></div>
                            <div
                                class="swiper-button-next d-none d-lg-block"
                                :style="swiperArrowColorStyleObject"
                            ></div>
                            <!-- Swiper prev/next buttons end -->

                            <!-- Swiper pagination -->
                            <div
                                :style="swiperPaginationStyleObject"
                                class="swiper-pagination d-block d-lg-none"
                            ></div>
                            <!-- Swiper pagination end -->
                        </swiper>
                        <!-- Swiper end -->
                    </client-only>
                </div>
                <div class="text-center small pt-10" style="color: #f5d940">
                    A képek illusztrációk
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <PrizificPageBuilderTool v-if="editable" :section="section" />
        <!-- Prizific page builder tool end -->
    </section>
    <!-- Prizes end -->
</template>

<script setup lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { EPrizeType } from "~~/enums/Campaign/EPrizeType";
import { getStyle } from "../../composables/styleHelper";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import PrizesMinion from "~~/entities/PrizificPageBuilder/sections/PrizesMinion";

const runtime = useRuntimeConfig();
const campaignDataStore: any = useCampaignDataStore();

interface Props {
    section: IComponent<EComponents> | any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();
const modules = [Navigation, Pagination, A11y];
const data = reactive({
    prizeDialogShow: [] as any[],
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
    },
    breakpoints: {
        1199: {
            slidesPerView: 3,
            spaceBetween: 10,
            centeredSlides: false,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 10,
            centeredSlides: true,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 10,
            centeredSlides: true,
        },
        320: {
            slidesPerView: 2,
            spaceBetween: 10,
            centeredSlides: true,
        },
    },
    prizeVariantSum: 0,
});

onMounted(() => {
    if (campaignDataStore.campaignPrizes) {
        campaignDataStore.campaignPrizes.forEach((prize: any) => {
            prize.prizeVariants.forEach((prizeVariant: any) => {
                data.prizeDialogShow.push({
                    id: prizeVariant.id,
                    value: false,
                });

                data.prizeVariantSum += 1;
            });
        });
    }

    if (data.prizeVariantSum > 3) {
        data.breakpoints[1199].slidesPerView = 3;
        data.breakpoints[1199].centeredSlides = false;
    }
});

const sectionBackgroundImage = computed(() => {
    return {
        backgroundImage: `url(${useMediaUrl(props.section?.src)})`,
    };
});

const imageXs = `url('${useMediaUrl(props.section.images[0]?.src)}')`;
const imageSm = `url('${useMediaUrl(props.section.images[1]?.src)}')`;
const imageMd = `url('${useMediaUrl(props.section.images[2]?.src)}')`;
const imageLg = `url('${useMediaUrl(props.section.images[3]?.src)}')`;
const imageXl = `url('${useMediaUrl(props.section.images[4]?.src)}')`;
const imageXxl = `url('${useMediaUrl(props.section.images[5]?.src)}')`;

const sectionStyle = computed(() => {
    return getStyle(props.section.style, "prizeSection");
});

const prizeTypeStyle = computed(() => {
    return getStyle(props.section.style, "prizeType");
});

const prizeNameStyle = computed(() => {
    return getStyle(props.section.style, "prizeName");
});

const prizeDescriptionStyle = computed(() => {
    return getStyle(props.section.style, "prizeDescription");
});

const prizeImageStyle = computed(() => {
    return getStyle(props.section.style, "prizeImage")?.width.replace("px", "");
});

const prizeMoreStyle = computed(() => {
    return getStyle(props.section.style, "prizeMore");
});

const prizeMoreHoverStyle = computed(() => {
    return getStyle(props.section.style, "prizeMoreHover");
});

const modalComponent = computed(() => {
    return props.section
        .getComponents()
        .find(
            (component: IComponent<EComponents>) =>
                component.getType() == EComponents.MODAL
        );
});

const isMoreThanTwoPrize = computed(() => {
    return campaignDataStore.campaignPrizes?.length > 2;
});

/**
 * Get swiper arrow style
 * @returns {Array<any>} styleObject
 */
const swiperArrowColorStyleObject = computed(() => {
    const prizeSwiperArrow = getStyle(props.section.style, "prizeMore");

    const style = {
        color: prizeSwiperArrow.color,
    };

    return style;
});

/**
 * Get promo products pagination style
 * @returns {Object} styleObject
 */
const swiperPaginationStyleObject = computed(() => {
    const prizeSwiperPagination = getStyle(props.section.style, "prizeMore");

    const style = {
        "--swiper-pagination-color": prizeSwiperPagination?.color,
    };

    return style;
});

/**
 * Helper function for get prize type title from prizeType
 * @return {string}
 */
function prizeTypeTitle(prizeType: string) {
    let prizeTypeText = "";

    const runtimeConfig = useRuntimeConfig();

    if (
        campaignDataStore.campaignUUId ==
            runtimeConfig.ferfipromoCampaignUUId &&
        prizeType == EPrizeType.MAIN
    ) {
        prizeTypeText = "Nyerőidőpontos";
    } else if (prizeType === EPrizeType.MAIN) {
        prizeTypeText = "Főnyeremény";
    } else if (prizeType === EPrizeType.MONTHLY) {
        prizeTypeText = "Havi nyeremény";
    } else if (prizeType === EPrizeType.WEEKLY) {
        prizeTypeText = "Heti nyeremény";
    } else if (prizeType === EPrizeType.DAILY) {
        prizeTypeText = "Napi nyeremény";
    }

    return prizeTypeText;
}

/**
 * Helper function to set dialog state value
 * @param {number} id
 * @param {boolean} value
 */
function handleDialogs(id: string, value: boolean) {
    data.prizeDialogShow.forEach((prizeDialog: any) => {
        if (prizeDialog.id === id) {
            prizeDialog.value = value;
        }
    });
}

/**
 * Helper function to get dialog state value
 * @param {number} id
 */
function showDialog(id: string) {
    let dialogValue;

    data.prizeDialogShow.forEach((prizeDialog) => {
        if (prizeDialog.id === id) {
            dialogValue = prizeDialog.value;
        }
    });

    return dialogValue;
}
</script>

<style lang="scss" scoped>
.prizes {
    // .prize-image {
    //     max-width: 150px;
    // }
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}

#nyeremenyek {
    h2 {
        font-family: "HelveticaNeue";
        font-weight: 700;
        font-size: 24px;
        @media (min-width: 576px) {
            font-size: 30px;
        }

        @media (min-width: 768px) {
            font-size: 40px;
        }

        @media (min-width: 992px) {
            font-size: 80px;
        }

        @media (min-width: 1200px) {
            font-size: 90px;
        }

        @media (min-width: 1400px) {
            font-size: 90px;
        }
    }
}

.prizes-swiper {
    min-height: 56px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: v-bind("imageXs");

    .swiper-wrapper {
        padding-bottom: 24px;
    }

    .swiper-button-disabled {
        display: none !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
        &::after {
            font-weight: bold;
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            // background-color: var(--swiper-pagination-bullet);
            background-color: #f5d940 !important;

            &.swiper-pagination-bullet-active {
                // background-color: var(--swiper-pagination-color-active);
                background-color: #f5d940 !important;
            }
        }
    }
}
</style>
