<template>
    <div
        ref="prizificPageBuilderRemoveSection"
        :class="['prizific-page-builder-remove-section']"
    >
        <!-- Add section button -->
        <client-only>
            <el-tooltip
                :content="`Remove ${sectionTitle}`"
                effect="prizific"
                placement="bottom"
            >
                <div
                    :class="['prizific-page-builder-remove-section__open']"
                    @click="handleClickOnRemoveSection()"
                >
                    <span class="material-icons">delete</span>
                </div>
            </el-tooltip>
        </client-only>
        <!-- Add section button end -->
    </div>
</template>

<script lang="ts" setup>
import { ElTooltip } from "element-plus";
import { EComponents } from "~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~/interfaces/PrizificPageBuilder/IComponent";
import { usePagesStore } from "~/store/pages";
import { IPage } from "~~/interfaces/PrizificPageBuilder/IPage";

const pageStore: any = usePagesStore();

interface Props {
    page: IComponent<EComponents>;
    section: IComponent<EComponents>;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const prizificPageBuilderRemoveSection = ref(null);

function handleClickOnRemoveSection() {
    pageStore.removeComponent(props.section, props.page.getType());
}

/**
 * Return with section title
 */
const sectionTitle = computed(() => {
    return props.section?.title;
});
</script>
<style lang="scss" scoped>
.prizific-page-builder-remove-section {
    margin-left: 16px;
    display: flex;
    min-width: 40px;
    max-width: 40px;
    border-radius: 50%;
    flex-direction: column;

    &__open {
        display: flex;
        width: 40px;
        height: 40px;
        cursor: pointer;
        color: #6e6e73;
        user-select: none;
        margin-left: auto;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: #ffffffb3;
        backdrop-filter: saturate(50%) blur(8px);
        box-shadow: 2px 4px 12px rgb(0 0 0 / 14%);
        transition: color 0.2s ease-in-out, transform 0.25s ease-in-out;
    }
}
</style>
