<template>
    <!-- Prizes -->

    <section id="nyeremenyek">
        <div class="prizes py-4 overflow-hidden" :style="[sectionStyle]">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <h2
                            :style="[
                                {
                                    fontSize: '24px',
                                    ...titleWrapperStyleObject,
                                },
                            ]"
                            class="position-relative d-block fw-900 font-italic pb-2 mt-4 text-center"
                            v-html="section.sectionTitleRaw"
                        ></h2>
                    </div>
                </div>
            </div>
            <div class="prize-container container">
                <div
                    :class="[
                        'row d-block',
                        { 'd-sm-none': isMoreThanTwoPrize },
                    ]"
                >
                    <div class="col-12 col-md-10 offset-md-1">
                        <div class="row justify-content-center">
                            <div
                                class="col-md-6 col-xl-4 my-3"
                                v-for="item in data.prizes"
                                :key="item.id"
                            >
                                <div
                                    style="max-width: 320px; margin: 0 auto"
                                    class="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start"
                                >
                                    <div
                                        class="text-center text-sm-start px-3 mb-2"
                                    >
                                        <div
                                            class="prize-type mb-2"
                                            :style="prizeTypeStyle"
                                        >
                                            {{ item.type }}
                                        </div>
                                        <picture>
                                            <source
                                                :srcset="item.image"
                                                type="image/webp"
                                            />
                                            <img
                                                class="prize-image"
                                                :src="
                                                    item.image
                                                        ? item.image
                                                        : useCdnUrl(
                                                              '/images/150x150-image-placeholder.png'
                                                          )
                                                "
                                                alt=""
                                            />
                                        </picture>
                                        <!-- <img
                                                class="prize-image"
                                                :src="
                                                    item.imageUrl
                                                        ? useMediaUrl(
                                                              item.imageUrl,
                                                              150,
                                                              150
                                                          )
                                                        : useCdnUrl(
                                                              '/images/150x150-image-placeholder.png'
                                                          )
                                                "
                                                alt="Prize"
                                            /> -->
                                    </div>
                                    <div class="text-center text-sm-start px-0">
                                        <h4
                                            class="fw-bold"
                                            :style="prizeNameStyle"
                                        >
                                            {{ item.name }}
                                        </h4>
                                        <div
                                            class="py-3 mb-0"
                                            v-if="item.description"
                                            :style="prizeDescriptionStyle"
                                            v-html="item.description"
                                        ></div>
                                        <div
                                            v-if="item.longDescription"
                                            style="
                                                border: 1px solid #faf1d1;
                                                border-radius: 24px;
                                            "
                                            class="d-inline-block px-4 text-decoration-none fw-bold mt-3"
                                            type="button"
                                            :style="prizeMoreStyle"
                                            @mouseover="
                                                setStyle(
                                                    $event,
                                                    prizeMoreHoverStyle
                                                )
                                            "
                                            @mouseleave="
                                                setStyle($event, prizeMoreStyle)
                                            "
                                            @click="
                                                handleDialogs(item.id, true)
                                            "
                                        >
                                            Részletek
                                        </div>
                                        <Dialog
                                            :show="showDialog(item.id)"
                                            :title="item.name"
                                            :image="
                                                item.image
                                                    ? useMediaUrl(
                                                          item.image,
                                                          150,
                                                          150
                                                      )
                                                    : useCdnUrl(
                                                          '/images/150x150-image-placeholder.png'
                                                      )
                                            "
                                            :description="item.longDescription"
                                            footerBtnText="Bezár"
                                            :component="modalComponent"
                                            @closed="
                                                handleDialogs(item.id, false)
                                            "
                                            @btnClicked="
                                                handleDialogs(item.id, false)
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="isMoreThanTwoPrize"
                    class="prizes-swiper py-3 d-none d-sm-block"
                >
                    <client-only>
                        <!-- Swiper -->
                        <swiper
                            :modules="modules"
                            :breakpoints="data.breakpoints"
                            :pagination="data.pagination"
                            :navigation="data.navigation"
                        >
                            <swiper-slide
                                v-for="item in data.prizes"
                                :key="item.id"
                                class="pb-7"
                            >
                                <div
                                    style="max-width: 226px; margin: 0 auto"
                                    class="d-flex flex-column justify-content-center"
                                >
                                    <div
                                        class="prize-type text-center mb-2"
                                        :style="{
                                            ...prizeTypeStyle,
                                            minHeight: '48px',
                                        }"
                                    >
                                        {{ item.type }}
                                    </div>
                                    <div class="text-center px-3 mb-3 mb-lg-2">
                                        <picture>
                                            <source
                                                :srcset="item.image"
                                                type="image/webp"
                                            />

                                            <img
                                                class="prize-image"
                                                :src="
                                                    item.image
                                                        ? item.image
                                                        : useCdnUrl(
                                                              '/images/150x150-image-placeholder.png'
                                                          )
                                                "
                                                alt="Prize"
                                            />
                                        </picture>
                                    </div>
                                    <div class="text-center px-0">
                                        <h4
                                            class="fw-bold"
                                            :style="{
                                                ...prizeNameStyle,
                                                minHeight: '60px',
                                            }"
                                        >
                                            {{ item.name }}
                                        </h4>
                                        <div
                                            class="py-3 mb-0"
                                            v-if="item.description"
                                            :style="prizeDescriptionStyle"
                                            v-html="item.description"
                                        ></div>
                                    </div>
                                    <div class="text-center">
                                        <div
                                            v-if="item.longDescription"
                                            style="
                                                border: 1px solid #faf1d1;
                                                border-radius: 24px;
                                            "
                                            class="d-inline-block px-4 text-decoration-none fw-bold mt-3"
                                            type="button"
                                            :style="prizeMoreStyle"
                                            @mouseover="
                                                setStyle(
                                                    $event,
                                                    prizeMoreHoverStyle
                                                )
                                            "
                                            @mouseleave="
                                                setStyle($event, prizeMoreStyle)
                                            "
                                            @click="
                                                handleDialogs(item.id, true)
                                            "
                                        >
                                            részletek
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>

                            <!-- Swiper prev/next buttons -->
                            <div
                                class="swiper-button-prev d-none d-lg-block"
                                :style="swiperArrowColorStyleObject"
                            ></div>
                            <div
                                class="swiper-button-next d-none d-lg-block"
                                :style="swiperArrowColorStyleObject"
                            ></div>
                            <!-- Swiper prev/next buttons end -->

                            <!-- Swiper pagination -->
                            <div
                                :style="swiperPaginationStyleObject"
                                class="swiper-pagination d-block d-lg-none"
                            ></div>
                            <!-- Swiper pagination end -->
                        </swiper>
                        <!-- Swiper end -->
                    </client-only>
                </div>
            </div>
            <div class="container">
                <div
                    class="text-center"
                    :style="paragraphWrapperStyleObject"
                    v-html="section.sectionParagraphRaw"
                ></div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <PrizificPageBuilderTool v-if="editable" :section="section" />
        <!-- Prizific page builder tool end -->
    </section>
    <!-- Prizes end -->
</template>

<script setup lang="ts">
import { useCampaignDataStore } from "@/store/campaign/campaignData";
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { EPrizeType } from "~~/enums/Campaign/EPrizeType";
import { getStyle } from "../../composables/styleHelper";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const runtime = useRuntimeConfig();
const campaignDataStore: any = useCampaignDataStore();

interface Props {
    section: IComponent<EComponents>;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();
const modules = [Navigation, Pagination, A11y];
const data = reactive({
    prizeDialogShow: [] as any[],
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
    },
    breakpoints: {
        1199: {
            slidesPerView: 4,
            spaceBetween: 10,
            centeredSlides: false,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 10,
            centeredSlides: true,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 10,
            centeredSlides: true,
        },
        320: {
            slidesPerView: 2,
            spaceBetween: 10,
            centeredSlides: true,
        },
    },
    prizeVariantSum: 0,
    prizes: [
        {
            id: "1",
            type: "Hetente",
            name: "2 db Everdure Kiln pizzasütő, 2 db Samsung smart UHD LED TV",
            description: "",
            longDescription:
                "Samsung UHD LED TV egymilliárd színnel, 4 K-s Crystal Processorral garantált kritálytiszta képpel, Q-Symphony hangzással és számos okos funkciüval gondoskodik a tökéletes otthoni moziélményről.<br/><br/> Az Everdure Kiln pizzasüvel pedig a házimozizás alatt elfogyasztott tökéletes pizza készül. Ez a prémium minőségű pizzasütő ugyanis többek között a forgózsámolynak és az U-alakú égőnek is köszönhetően mindössze 2 perc alatt kitűnő pizzát készít.",
            image: useCdnUrl("/images/staropramen/heti.webp"),
        },
        {
            id: "2",
            type: "Naponta",
            name: "1 db 15 000 Ft értékű Netflix vagy Foodora utalvány",
            description: "",
            longDescription:
                "Foodore és Netflix utalvány: A Foodora és Netflix utalványokkal ízlésednek megfelelően választhatsz a különböző ételek, vagy filmek és sorozatok között. Nyereményjátékunk során lehetőséged van mindkettőt megnyerni - de egy nap csak az egyikre van szükséged.",
            image: useCdnUrl("/images/staropramen/napi.webp"),
        },
        {
            id: "3",
            type: "Extra nyeremény",
            name: "VIP moziélmény és találkozás Csőre Gáborral*",
            description: "",
            longDescription:
                "VIP moziélmény: Vidd el 14 barátodat egy különleges moziélményre az Aréna Pláza VIP mozitermébe! A vetítés előtt fél órával korlátlan étel- és italfogyasztást biztosítunk az aktuális svédasztalos kínálatból, illetve feltankolhattok a moziélményhez elengedhetetlen nachosból, popcornból és üdítőből. A vetítés előtt ráadásul lehetőséget biztosítunk egy 20 perces közönségtalálkozóra Csőre Gáborral.",
            image: useCdnUrl("/images/staropramen/extra.webp"),
        },
        {
            id: "4",
            type: "Azonnali nyeremények a pubokban",
            name: "Korsó Staropramen, mozijegy, pizzatálaló, pizzavágó",
            description: "",
            longDescription:
                "Pubban kapott sorsjegyeddel azonnali nyereményként bambusz pizzatálalót, fanyelű, rozsdamentes acélból készült pizzavágót, vagy egy főre szóló Cinema City mozijegyet nyerhetsz.",
            image: useCdnUrl("/images/staropramen/azonnali.webp"),
        },
    ],
});

onMounted(() => {
    // if (campaignDataStore.campaignPrizes) {
    //     campaignDataStore.campaignPrizes.forEach((prize: any) => {
    //         prize.prizeVariants.forEach((prizeVariant: any) => {
    //             data.prizeDialogShow.push({
    //                 id: prizeVariant.id,
    //                 value: false,
    //             });

    //             data.prizeVariantSum += 1;
    //         });
    //     });
    // }

    data.prizes.forEach((prize) => {
        data.prizeDialogShow.push({
            id: prize.id,
            value: false,
        });

        data.prizeVariantSum += 1;
    });

    if (data.prizeVariantSum > 3) {
        data.breakpoints[1199].slidesPerView = 4;
        data.breakpoints[1199].centeredSlides = false;
    }
});

/**
 * Create game steps title inline style object
 * @returns {Object} styleObject
 */
const titleWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "title");
});

/**
 * Create game steps paragraph inline style object
 * @returns {Object} styleObject
 */
const paragraphWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "paragraph");
});

const sectionBackgroundImage = computed(() => {
    return {
        backgroundImage: `url(${useMediaUrl(props.section?.src)})`,
    };
});

const imageXs = `url('${useMediaUrl(props.section.images[0]?.src)}')`;
const imageSm = `url('${useMediaUrl(props.section.images[1]?.src)}')`;
const imageMd = `url('${useMediaUrl(props.section.images[2]?.src)}')`;
const imageLg = `url('${useMediaUrl(props.section.images[3]?.src)}')`;
const imageXl = `url('${useMediaUrl(props.section.images[4]?.src)}')`;
const imageXxl = `url('${useMediaUrl(props.section.images[5]?.src)}')`;

const sectionStyle = computed(() => {
    return getStyle(props.section.style, "prizeSection");
});

const prizeTypeStyle = computed(() => {
    return getStyle(props.section.style, "prizeType");
});

const prizeNameStyle = computed(() => {
    return getStyle(props.section.style, "prizeName");
});

const prizeDescriptionStyle = computed(() => {
    return getStyle(props.section.style, "prizeDescription");
});

const prizeMoreStyle = computed(() => {
    return getStyle(props.section.style, "prizeMore");
});

const prizeMoreHoverStyle = computed(() => {
    return getStyle(props.section.style, "prizeMoreHover");
});

const modalComponent = computed(() => {
    return props.section
        .getComponents()
        .find(
            (component: IComponent<EComponents>) =>
                component.getType() == EComponents.MODAL
        );
});

const isMoreThanTwoPrize = computed(() => {
    return data.prizes?.length > 2;
});

/**
 * Get swiper arrow style
 * @returns {Array<any>} styleObject
 */
const swiperArrowColorStyleObject = computed(() => {
    const prizeSwiperArrow = getStyle(props.section.style, "prizeMore");

    const style = {
        color: prizeSwiperArrow.color,
    };

    return style;
});

/**
 * Get promo products pagination style
 * @returns {Object} styleObject
 */
const swiperPaginationStyleObject = computed(() => {
    const prizeSwiperPagination = getStyle(props.section.style, "prizeMore");

    const style = {
        "--swiper-pagination-color": prizeSwiperPagination?.color,
    };

    return style;
});

/**
 * Helper function for get prize type title from prizeType
 * @return {string}
 */
function prizeTypeTitle(prizeType: string) {
    let prizeTypeText = "";

    const runtimeConfig = useRuntimeConfig();

    if (
        campaignDataStore.campaignUUId ==
            runtimeConfig.ferfipromoCampaignUUId &&
        prizeType == EPrizeType.MAIN
    ) {
        prizeTypeText = "Nyerőidőpontos";
    } else if (prizeType === EPrizeType.MAIN) {
        prizeTypeText = "Főnyeremény";
    } else if (prizeType === EPrizeType.MONTHLY) {
        prizeTypeText = "Havi nyeremény";
    } else if (prizeType === EPrizeType.WEEKLY) {
        prizeTypeText = "Heti nyeremény";
    } else if (prizeType === EPrizeType.DAILY) {
        prizeTypeText = "Napi nyeremény";
    }

    return prizeTypeText;
}

/**
 * Helper function to set dialog state value
 * @param {number} id
 * @param {boolean} value
 */
function handleDialogs(id: string, value: boolean) {
    data.prizeDialogShow.forEach((prizeDialog: any) => {
        if (prizeDialog.id === id) {
            prizeDialog.value = value;
        }
    });
}

/**
 * Helper function to get dialog state value
 * @param {number} id
 */
function showDialog(id: string) {
    let dialogValue;

    data.prizeDialogShow.forEach((prizeDialog) => {
        if (prizeDialog.id === id) {
            dialogValue = prizeDialog.value;
        }
    });

    return dialogValue;
}
</script>

<style lang="scss" scoped>
.prize-container {
    @media (max-width: 1200px) {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}
.prizes {
    .prize-image {
        max-width: 192px;
    }
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}

.prizes-swiper {
    min-height: 56px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: v-bind("imageXs");

    .swiper-wrapper {
        padding-bottom: 24px;
    }

    .swiper-button-disabled {
        display: none !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
        &::after {
            font-weight: bold;
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            background-color: var(--swiper-pagination-bullet);

            &.swiper-pagination-bullet-active {
                background-color: var(--swiper-pagination-color-active);
            }
        }
    }
}
</style>
