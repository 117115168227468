<template>
    <section>
        <div :style="backgroundStyle">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3 py-3">
                        <div class="py-2 px-4">
                            <h3
                                class="mt-2"
                                :style="sectionTitleStyle"
                                v-if="section.sectionTitleRaw"
                                v-html="section.sectionTitleRaw"
                            ></h3>
                        </div>
                    </div>
                </div>

                <div class="row" :style="recipeUploadFormMergedStyle">
                    <div class="col-12 col-md-6 offset-md-3">
                        <div class="mt-2 prizific-file-text">
                            {{ recipeUploadTexts.label }}
                        </div>
                        <!-- Recipe uploader thumbnails -->
                        <div v-if="hasFile" class="row">
                            <div
                                v-for="(
                                    fileUrl, index
                                ) in recipeUpload.fileUrlsWithTypes"
                                :key="index"
                                class="col-xs-12 col-lg-4 prizific-file mt-3 mb-3"
                            >
                                <img
                                    v-if="fileUrl.type == 'image'"
                                    :src="fileUrl.url"
                                    alt=""
                                />
                                <!-- eslint-disable -->
                                <!-- <embed
                                    v-if="fileUrl.type == 'application'"
                                    :src="fileUrl.url"
                                >
                                   
                                        <noembed>
                                            <i
                                                v-if="
                                                    fileUrl.type ==
                                                    'application'
                                                "
                                                class="fa fa-file"
                                            ></i>
                                        </noembed>
                                </embed> -->
                                <!-- eslint-enable -->

                                <div
                                    @click="handleRemove(index)"
                                    class="prizific-file-input-clear-icon"
                                >
                                    <el-icon class="el-icon-minus" :size="24">
                                        <Minus />
                                    </el-icon>
                                </div>
                            </div>
                        </div>
                        <!-- Recipe uploader thumbnails end -->

                        <!-- Recipe uploader -->
                        <div class="prizific-file-input mt-2">
                            <div
                                :class="[
                                    'el-upload-list__item-thumbnail',
                                    {
                                        'no-thumbnail':
                                            !recipeUpload.fileUrlsWithTypes,
                                    },
                                    { disabled: disabledFileUploader },
                                ]"
                            >
                                <span class="no-thumbnail-icon">
                                    <el-icon :size="20">
                                        <Camera />
                                    </el-icon>
                                </span>
                                <div
                                    class="prizific-file-input-label-title ms-2"
                                >
                                    {{ recipeUploadTexts.placeholder }}
                                </div>
                            </div>

                            <!-- Texts -->
                            <label
                                :class="[
                                    'prizific-file-input-label',
                                    { disabled: disabledFileUploader },
                                ]"
                            >
                                <input
                                    ref="fileInput"
                                    type="file"
                                    @change="onFileChange"
                                    accept="image/png, image/jpeg, application/pdf"
                                    multiple
                                    :disabled="disabledFileUploader"
                                />
                            </label>
                            <!-- Texts end-->
                        </div>
                        <h4
                            v-if="
                                recipeUpload.errorMessage &&
                                recipeUpload.errorMessage.length > 0
                            "
                            class="error-message"
                        >
                            {{ recipeUpload.errorMessage }}
                        </h4>
                        <!-- Recipe uploader end -->

                        <!-- No recipe button -->
                        <el-button
                            :style="noRecipeButtonStyle"
                            class="float-end"
                            @click="noRecipeDialog = !noRecipeDialog"
                            v-if="noRecipeButtonComponent.textRaw"
                            v-html="noRecipeButtonComponent.textRaw"
                            link
                        >
                        </el-button>
                        <!-- No recipe button end -->
                    </div>
                </div>
            </div>
        </div>

        <!-- No recipe dialog -->
        <Dialog
            :show="noRecipeDialog"
            :title="noRecipeModal ? noRecipeModal.title : 'Title'"
            :description="
                noRecipeModal ? noRecipeModal.description : 'description'
            "
            :footerBtnText="
                noRecipeModal ? noRecipeModal.rightButton : 'rightButton'
            "
            :footerBtnLeftText="
                noRecipeModal ? noRecipeModal.leftButton : 'leftButton'
            "
            :component="props.section.components[2]"
            @closed="noRecipeDialog = false"
            @btnClicked="noRecipeDialog = false"
            @btnLeftClicked="cantShowTheRecipe()"
        />
        <!-- No recipe dialog end -->

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
</template>

<script setup lang="ts">
import { InputInstance, UploadFiles } from "element-plus";
import { getStyle } from "../../composables/styleHelper";
import { Camera, Minus } from "@element-plus/icons-vue";
import { EModal } from "~~/enums/PrizificPageBuilder/EModal";
import { EProperty } from "~~/enums/PrizificPageBuilder/EProperty";
import { ERecipeUploadFormItem } from "~~/enums/PrizificPageBuilder/ERecipeUploadFormItem";

import { useShippingFromStore } from "@/store/components/shippingFrom/shippingFrom";

const shippingFromStore = useShippingFromStore();

interface Props {
    section: any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();

const fileInput = ref();
const noRecipeDialog = ref(false);
const recipeUploadFormComponent = props.section.components[0];
const noRecipeButtonComponent = props.section.components[1];
const feedbackModalsComponent = props.section.components[2];

const recipeUpload = reactive({
    fileUrlsWithTypes: [] as any,
    files: [],
    formData: null as any,
    dialogNoRecipeVisible: false,
    errorMessage: "",
});

/**
 * Define emits
 */
const emit = defineEmits(["change", "missedReceipt"]);

/**
 * Remove selected file
 */
function handleRemove(index: any) {
    const fileList = new DataTransfer();
    recipeUpload.fileUrlsWithTypes.splice(index, 1);
    recipeUpload.files.splice(index, 1);
    recipeUpload.formData.delete(`file${index}`);

    if (recipeUpload.files?.length) {
        recipeUpload.files.forEach((item: File) => {
            const file = new File(["content"], item.name);
            fileList.items.add(file);
        });
    }

    fileInput.value.files = fileList.files;

    if (!recipeUpload.files.length) {
        recipeUpload.formData = null;
    }
    shippingFromStore.updateReceipts(recipeUpload.formData);
    emit("change", recipeUpload.formData);
}

/**
 * Handle input file when is change
 */
function onFileChange(event: any) {
    const eventFile = event.target.files;

    if (eventFile) {
        recipeUpload.files.push(...Array.from(eventFile));
        recipeUpload.fileUrlsWithTypes = [];
        recipeUpload.formData = new FormData();

        recipeUpload.files.forEach((file, index) => {
            if (
                file["type"] === "image/jpeg" ||
                file["type"] === "image/jpg" ||
                file["type"] === "image/png" ||
                file["type"] === "application/pdf"
            ) {
                if (recipeUpload.fileUrlsWithTypes.length + 1 <= 5) {
                    console.log("file", file);
                    recipeUpload.formData.append("file" + index, file);
                    let typeSplit = file["type"].split("/");

                    console.log("File type: ", typeSplit[0]);

                    recipeUpload.fileUrlsWithTypes.push({
                        type: typeSplit[0],
                        url: URL.createObjectURL(file),
                    });
                }
            } else {
                recipeUpload.errorMessage =
                    "A képek .jpg, .jpeg vagy .png formátumúak kell hogy legyenek, számla esetében pedig .pdf!";
            }
        });
        shippingFromStore.updateReceipts(recipeUpload.formData);
        emit("change", recipeUpload.formData);
    }
}

/**
 * Handle click cant show the recipe button in modal
 */
function cantShowTheRecipe() {
    emit("missedReceipt");
}

/**
 * Has any file
 * @returns {boolean}
 */
const hasFile = computed(() => {
    return recipeUpload.fileUrlsWithTypes.length;
});

/**
 * Disabled file uploader after 5 file
 * @returns {boolean}
 */
const disabledFileUploader = computed(() => {
    return recipeUpload.fileUrlsWithTypes.length == 5;
});

// Computed form and form items style
const backgroundStyle = computed(() => {
    return getStyle(props.section.style, "recipeUpload");
});
const sectionTitleStyle = computed(() => {
    return getStyle(props.section.style, "recipeUploadTitle");
});
const recipeUploadFormStyle = computed(() => {
    return getStyle(
        props.section.components[0].style,
        "recipeUploadFormBackground"
    );
});
const recipeUploadFormItemLabelStyle = computed(() => {
    return getStyle(
        props.section.components[0].style,
        "recipeUploadFormItemLabel"
    );
});
const recipeUploadFormItemInputStyle = computed(() => {
    return getStyle(
        props.section.components[0].style,
        "recipeUploadFormItemInput"
    );
});
const noRecipeButtonStyle = computed(() => {
    return getStyle(props.section.components[1].style, "noRecipeButton");
});
const recipeUploadFormMergedStyle = computed(() => {
    return {
        "--recipe-upload-form-label-color":
            recipeUploadFormItemLabelStyle.value[EProperty.COLOR],
        "--recipe-upload-form-label-font-size":
            recipeUploadFormItemLabelStyle.value[EProperty.FONT_SIZE],
        "--recipe-upload-form-input-color":
            recipeUploadFormItemInputStyle.value[EProperty.COLOR],
        "--recipe-upload-form-input-font-size":
            recipeUploadFormItemInputStyle.value[EProperty.FONT_SIZE],
        "--recipe-upload-form-input-border-radius":
            recipeUploadFormItemInputStyle.value[EProperty.BORDER_RADIUS],
        "--recipe-upload-form-input-border-color":
            recipeUploadFormItemInputStyle.value[EProperty.BORDER_COLOR],
        "--recipe-upload-form-input-border-width":
            recipeUploadFormItemInputStyle.value[EProperty.BORDER_WIDTH],
        "--recipe-upload-form-input-background-color":
            recipeUploadFormItemInputStyle.value[EProperty.BACKGROUND_COLOR],
    };
});

// Computed texts
const recipeUploadTexts = computed(() => {
    return getFormItemText(
        recipeUploadFormComponent,
        ERecipeUploadFormItem.RECIPE_UPLOAD
    );
});
const noRecipeModal = computed(() => {
    return getGroupText(props.section.components[2], EModal.NO_RECIPE_MODAL);
});
</script>

<style lang="scss">
.prizific-file-text {
    color: var(--recipe-upload-form-label-color);
    font-size: var(--recipe-upload-form-label-font-size);
}

.prizific-file-input {
    position: relative;
    display: flex;
    align-items: center;

    .el-upload-list__item-thumbnail {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 100px;
        min-height: 100px;
        overflow: hidden;
        z-index: 1;
        color: var(--recipe-upload-form-input-color);
        font-size: var(--recipe-upload-form-input-font-size);
        pointer-events: none;

        &.no-thumbnail {
            border: 1px solid;
        }

        &.disabled {
            filter: grayscale(100%);
        }

        .no-thumbnail-icon {
            font-size: 24px;
        }

        .prizific-file-input-clear {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.25);
            transition: opacity 0.25s ease-in-out;
        }
    }

    .prizific-file-input-label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        margin-left: 0.25rem;
        display: inline-block;
        z-index: 0;
        background-color: var(--recipe-upload-form-input-background-color);
        border-radius: var(--recipe-upload-form-input-border-radius);
        border-style: solid;
        border-color: var(--recipe-upload-form-input-border-color);
        border-width: var(--recipe-upload-form-input-border-width);
        color: var(--recipe-upload-form-label-color);
        font-size: var(--recipe-upload-form-label-font-size);

        .prizific-file-input-label-title {
            font-weight: bold;
        }

        .prizific-file-input-label-title,
        .prizific-file-input-label-desc {
            line-height: 24px;
            margin-left: 1rem;
        }

        input[type="file"] {
            display: none;
        }

        &.disabled {
            cursor: no-drop;
        }
    }
}

.error-message {
    margin-top: 10px;
}

.prizific-file {
    position: relative;

    img,
    embed {
        max-width: 100%;
        height: 200px;
        object-fit: contain;
    }

    embed {
        top: 8px;
    }

    img {
        margin: 0 auto;
        display: inherit;
    }

    .prizific-file-input-clear-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        font-size: 24px;
        line-height: 1;
        cursor: pointer;
        top: -10px;
        right: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 2px solid;
        color: var(--recipe-upload-form-label-color);
        border-color: var(--recipe-upload-form-label-color);
    }
}
</style>
