<template>
    <!-- Game steps -->
    <section
        id="jatek-menete"
        class="game-steps pt-5 pb-4"
        :style="[gameStepsWrapperStyleObject]"
    >
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1">
                    <h2
                        class="position-relative d-block fw-900 font-italic pb-2 mt-4 text-center"
                        :style="titleWrapperStyleObject"
                        v-html="section.sectionTitleRaw"
                    ></h2>

                    <ol class="mt-4" :style="listWrapperStyleObject">
                        <template
                            v-for="(
                                component, componentIndex
                            ) in section.components"
                            :key="componentIndex"
                        >
                            <ListItem :component="component">
                                <div v-html="component.textRaw"></div>
                            </ListItem>
                        </template>
                    </ol>

                    <div class="prizes-notification pb-5">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-md-8 offset-md-2">
                                    <div
                                        class="text-center fw-bold"
                                        :style="paragraphWrapperStyleObject"
                                        v-html="section.sectionParagraphRaw"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Prizific page builder tool -->
        <client-only>
            <PrizificPageBuilderTool v-if="editable" :section="section" />
        </client-only>
        <!-- Prizific page builder tool end -->
    </section>
    <!-- Game steps end -->
</template>

<script setup lang="ts">
import { EComponents } from "~~/enums/PrizificPageBuilder/EComponents";
import { IComponent } from "~~/interfaces/PrizificPageBuilder/IComponent";
import { IProperty } from "~~/interfaces/PrizificPageBuilder/IProperty";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";

const runtime = useRuntimeConfig();

/**
 * Props interface
 */
interface Props {
    section: IComponent<EComponents> & any;
    editable: boolean;
}

/**
 * Define props
 */
const props = defineProps<Props>();
const campaignDataStore = useCampaignDataStore();
const runtimeConfig = useRuntimeConfig();

/**
 * Create game steps wrapper inline style object
 * @returns {Object} styleObject
 */
const gameStepsWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "wrapper");
});

/**
 * Create game steps background-image inline style object
 */
const sectionBackgroundImage = computed(() => {
    return props.section?.src
        ? {
              backgroundImage: `url(${useMediaUrl(props.section?.src)})`,
          }
        : "";
});

const imageXs = `url('${useMediaUrl(props.section.images[0]?.src)}')`;
const imageSm = `url('${useMediaUrl(props.section.images[1]?.src)}')`;
const imageMd = `url('${useMediaUrl(props.section.images[2]?.src)}')`;
const imageLg = `url('${useMediaUrl(props.section.images[3]?.src)}')`;
const imageXl = `url('${useMediaUrl(props.section.images[4]?.src)}')`;
const imageXxl = `url('${useMediaUrl(props.section.images[5]?.src)}')`;
const gameStepTextColor = `${
    campaignDataStore.campaignUUId == runtimeConfig.mogyiCampaignUUId
        ? "white"
        : "initial"
}`;

/**
 * Create game steps list wrapper inline style object
 * @returns {Object} styleObject
 */
const listWrapperStyleObject = computed(() => {
    let style: any[] = [];

    props.section
        .getComponents()
        .forEach((component: IComponent<EComponents>) => {
            component.style.map((item: IProperty) => {
                if (
                    item.belongsTo === "step-icrement-width" ||
                    item.belongsTo === "step-increment-font-size"
                ) {
                    style.push(`${item.value}${item.unit ? item.unit : ""}`);
                }
            });
        });

    style
        .sort((a, b) => {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        })
        .reverse();

    style = [
        {
            "padding-left": `calc(24px + ${style[0]})`,
        },
    ];

    return style;
});

/**
 * Create game steps title inline style object
 * @returns {Object} styleObject
 */
const titleWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "title");
});

/**
 * Create game steps paragraph inline style object
 * @returns {Object} styleObject
 */
const paragraphWrapperStyleObject = computed(() => {
    return getStyle(props.section.style, "paragraph");
});
</script>

<style lang="scss" scoped>
.game-steps {
    min-height: 56px;
    background-image: v-bind("imageXs");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    p {
        margin-bottom: 0;
    }

    h2 {
        position: relative;

        p {
            position: relative;
            width: 100%;
            margin-bottom: 0;
        }
    }

    ol {
        position: relative;
        list-style: none;
        padding-left: calc(24px + var(--ol-number-width));
        counter-reset: number;

        li {
            color: v-bind(gameStepTextColor);
        }
    }

    @media (min-width: 576px) {
        background-image: v-bind("imageSm");
    }

    @media (min-width: 768px) {
        background-image: v-bind("imageMd");
    }

    @media (min-width: 992px) {
        background-image: v-bind("imageLg");
    }

    @media (min-width: 1200px) {
        background-image: v-bind("imageXl");
    }

    @media (min-width: 1400px) {
        background-image: v-bind("imageXxl");
    }
}

.prizes-notification {
    margin-top: 3rem;
    min-height: 56px;
}
</style>
