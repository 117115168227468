import { defineStore } from "pinia";
import { EPrizeType } from "~~/enums/Campaign/EPrizeType";
import { useCampaignDataStore } from "~~/store/campaign/campaignData";

export const useWinnersStore = defineStore("winnersStore", {
    state: () => {
        return {
            winnersDaily: [],
            winnersWeekly: [],
            winnersMonthly: [],
            winnersMain: [],
        };
    },
    getters: {
        /**
         * Get main winners from state
         *
         * @param state
         * @returns
         */
        winnersMainList(state) {
            let section: any = {};
            let winnerObject: any = {};
            let winnerList: any = {};
            let mainSection: any[] = [];
            let winnersArray: any[] = [];
            let winnersNameArray: any[] = [];

            if (state.winnersMain) {
                Object.keys(state.winnersMain).forEach((interval: any) => {
                    state.winnersMain[interval].forEach((item: any) => {
                        if (item.winners.length) {
                            winnerObject = {
                                day: item.meta.dayName,
                                date: `${item.meta.from} - ${item.meta.to}`,
                                name: [],
                                prize: "",
                                status: item.meta.status,
                            };

                            item.winners.forEach((winner: any) => {
                                winnersNameArray.push(winner.userName);
                                winnerObject.prize = winner.prizeName;
                            });

                            winnerObject.name = winnersNameArray;
                            mainSection.push(winnerObject);
                            winnersNameArray = [];
                        }
                    });

                    section = {};
                    section[interval] = mainSection;
                    winnersArray.push(section);
                    mainSection = [];
                });

                winnerList = winnersArray;
                winnersArray = [];
            }

            return state.winnersMain ? winnerList : {};
        },

        /**
         * Get monthly winners from state
         *
         * @param state
         * @returns
         */
        winnersMonthlyList(state) {
            let section: any = {};
            let winnerObject: any = {};
            let winnerList: any = {};
            let monthlySection: any[] = [];
            let winnersArray: any[] = [];
            let winnersNameArray: any[] = [];

            if (state.winnersMonthly) {
                Object.keys(state.winnersMonthly).forEach((interval: any) => {
                    state.winnersMonthly[interval].forEach((item: any) => {
                        if (item.winners.length) {
                            winnerObject = {
                                day: item.meta.dayName,
                                date: `${item.meta.from} - ${item.meta.to}`,
                                name: [],
                                prize: "",
                                status: item.meta.status,
                            };

                            item.winners.forEach((winner: any) => {
                                winnersNameArray.push(winner.userName);
                                winnerObject.prize = winner.prizeName;
                            });

                            winnerObject.name = winnersNameArray;
                            monthlySection.push(winnerObject);
                            winnersNameArray = [];
                        }
                    });

                    section = {};
                    section[interval] = monthlySection;
                    winnersArray.push(section);
                    monthlySection = [];
                });

                winnerList = winnersArray;
                winnersArray = [];
            }

            return state.winnersMonthly ? winnerList : {};
        },

        /**
         * Get weekly winners from state
         *
         * @param state
         * @returns
         */
        winnersWeeklyList(state) {
            let section: any = {};
            let winnerObject: any = {};
            let winnerList: any = {};
            let weeklySection: any[] = [];
            let winnersArray: any[] = [];
            let winnersNameArray: any[] = [];

            if (state.winnersWeekly) {
                Object.keys(state.winnersWeekly).forEach((interval: any) => {
                    state.winnersWeekly[interval].forEach((item: any) => {
                        if (item.winners.length) {
                            winnerObject = {
                                day: item.meta.dayName,
                                date: `${item.meta.from} - ${item.meta.to}`,
                                name: [],
                                prize: "",
                                status: item.meta.status,
                            };

                            item.winners.forEach((winner: any) => {
                                winnersNameArray.push(winner.userName);
                                winnerObject.prize = winner.prizeName;
                            });

                            winnerObject.name = winnersNameArray;
                            weeklySection.push(winnerObject);
                            winnersNameArray = [];
                        }
                    });

                    section = {};
                    section[interval] = weeklySection;
                    winnersArray.push(section);
                    weeklySection = [];
                });

                winnerList = winnersArray;
                winnersArray = [];
            }

            return state.winnersWeekly ? winnerList : {};
        },

        /**
         * Get daily winners from state
         *
         * @param state
         * @returns
         */
        winnersDailyList(state) {
            let section: any = {};
            let winnerObject: any = {};
            let winnerList: any = {};
            let dailySection: any[] = [];
            let winnersArray: any[] = [];
            let winnersNameArray: any[] = [];

            if (state.winnersDaily) {
                Object.keys(state.winnersDaily).forEach((interval: any) => {
                    state.winnersDaily[interval].forEach((item: any) => {
                        if (item.winners.length) {
                            winnerObject = {
                                day: item.meta.dayName,
                                date: item.meta.from,
                                name: [],
                                prize: "",
                                status: item.meta.status,
                            };

                            item.winners.forEach((winner: any) => {
                                winnersNameArray.push(winner.userName);
                                winnerObject.prize = winner.prizeName;
                            });

                            winnerObject.name = winnersNameArray;
                            dailySection.push(winnerObject);
                            winnersNameArray = [];
                        }
                    });

                    section = {};
                    section[interval] = dailySection;
                    winnersArray.push(section);
                    dailySection = [];
                });

                winnerList = winnersArray;
                winnersArray = [];
            }

            return state.winnersDaily ? winnerList : {};
        },
    },
    actions: {
        /**
         * Get winners
         *
         * @param param0
         * @param interval - ex.: day, week, month, main
         * @returns
         */
        async getWinners(interval: EPrizeType) {
            const campaignDataStore = useCampaignDataStore();

            const { data, error } = await useApiFetch(
                `/endusermanagerms/public/winners/${campaignDataStore.campaignId}/${interval}`,
                {
                    key: `winner-list-${interval}`,
                    method: "POST",
                }
            );

            if (interval === EPrizeType.DAILY) {
                this.winnersDaily = data.value;
            } else if (interval === EPrizeType.WEEKLY) {
                this.winnersWeekly = data.value;
            } else if (interval === EPrizeType.MONTHLY) {
                this.winnersMonthly = data.value;
            } else if (interval === EPrizeType.MAIN) {
                this.winnersMain = data.value;
            }

            if (error.value == null) {
                return error.value;
            }
        },
    },
});
