import { defineStore } from "pinia";
import { useCampaignDataStore } from "~/store/campaign/campaignData";
import { useAuthStore } from "~~/store/user/auth";

interface IUploadedCode {
    id: number,
    code: string,
    type: string,
    userName: string,
    email: string,
    uploadedAt: string,
    timeOfPurchase: string,
    status: any,
    navCode: string,
    isWinner: boolean,
    fullName: string,
    firstname: string,
    lastname: string,
    extraDetails: any,
    receiptNumber: string,
    transactionNumber: string,
    invoiceNumber: string,
    promotionIds: any
}

interface IUploadedCodeList {
    uploadedCodes: IUploadedCode[]
    meta: any,
    links: any
}





export const useUploadedCodesStore = defineStore("uploadedCodes", {
    state: (): IUploadedCodeList => {
        return {
            uploadedCodes: [],
            meta: undefined,
            links: undefined,
        };
    },
    getters: {
        getUploadedCodesFromState(state) {
            return state.uploadedCodes;
        },
    },
    actions: {
        async getUploadedCodes() {
            const authStore = useAuthStore();

            const campaignDataStore = useCampaignDataStore();

            const response = await usePiniaFetch<IUploadedCodeList>(
                `/endusermanagerms/entries/${campaignDataStore.campaignUUId}`,
                {
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${authStore.accessToken}`,
                    }
                }
            );

            this.uploadedCodes = response.uploadedCodes;
            this.meta = response.meta;
            this.links = response.links;

            return this.uploadedCodes;
        },
        /**
         * Return with the uploaded code by uniq uploaded code id
         * It's working only client
         */
        async getUploadedCodeById(uploadedCodeId: number) {
            const authStore = useAuthStore();

            return await usePiniaFetch<{ uploadedCode: { id: number, winnerUuid: string } }>(
                `/endusermanagerms/profile/my-code/${uploadedCodeId}`,
                {
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${authStore.accessToken}`,
                    },

                }
            );
        },
    },
});
