<script setup lang="ts">
import { useCodeUploadStore } from "~~/store/components/codeUpload/codeUpload";
import { Camera, Minus } from "@element-plus/icons-vue";

interface Props {
    modelValue: string;
}

/**
 * Define props
 */
const props = defineProps<Props>();

/**
 * Define emits
 */
const emit = defineEmits(["update:modelValue", "focus", "change"]);

const receiptImageValue = computed({
    get: () => {
        if (props.modelValue != null) {
            return props.modelValue;
        } else {
            return "";
        }
    },
    set: (value) => {
        if (value != null) {
            emit("update:modelValue", value);
        } else {
            emit("update:modelValue", "");
        }
    },
});

const codeUploadStore = useCodeUploadStore();

const fileInput = ref();
const recipeUpload = reactive({
    fileUrlsWithTypes: [] as any,
    files: [] as any,
    formData: null as any,
    errorMessage: "",
});

/**
 * Disabled file uploader after 5 file
 * @returns {boolean}
 */
const disabledFileUploader = computed(() => {
    return recipeUpload.fileUrlsWithTypes.length == 5;
});

/**
 * Has any file
 * @returns {boolean}
 */
const hasFile = computed(() => {
    return recipeUpload.fileUrlsWithTypes.length;
});

/**
 * Handle input file when is change
 */
function onFileChange(event: any) {
    const eventFile = event.target.files;

    if (eventFile) {
        recipeUpload.files.push(...Array.from(eventFile));
        recipeUpload.fileUrlsWithTypes = [];
        recipeUpload.formData = new FormData();
        console.log("itt a foreach?");
        recipeUpload.files.forEach((file: any, index: number) => {
            if (
                file["type"] === "image/jpeg" ||
                file["type"] === "image/jpg" ||
                file["type"] === "image/png" ||
                file["type"] === "application/pdf"
            ) {
                if (recipeUpload.fileUrlsWithTypes.length + 1 <= 5) {
                    console.log("file", file);
                    recipeUpload.formData.append("file" + index, file);
                    let typeSplit = file["type"].split("/");

                    console.log("File type: ", typeSplit[0]);

                    recipeUpload.fileUrlsWithTypes.push({
                        type: typeSplit[0],
                        url: URL.createObjectURL(file),
                    });
                }
            } else {
                recipeUpload.errorMessage =
                    "A képek .jpg, .jpeg vagy .png formátumúak kell hogy legyenek, számla esetében pedig .pdf!";
            }
        });
        codeUploadStore.updateReceipts(recipeUpload.formData);
        // codeUploadForm.receiptImage = recipeUpload.formData;
        receiptImageValue.value = recipeUpload.formData;
    }
    emit('change');
}

function handleRemove(index: any) {
    const fileList = new DataTransfer();
    recipeUpload.fileUrlsWithTypes.splice(index, 1);
    recipeUpload.files.splice(index, 1);
    recipeUpload.formData.delete(`file${index}`);

    if (recipeUpload.files?.length) {
        recipeUpload.files.forEach((item: File) => {
            const file = new File(["content"], item.name);
            fileList.items.add(file);
        });
    }

    fileInput.value.files = fileList.files;

    if (!recipeUpload.files.length) {
        recipeUpload.formData = null;
    }
    codeUploadStore.updateReceipts(recipeUpload.formData);
    // codeUploadForm.receiptImage = recipeUpload.formData;
    receiptImageValue.value = recipeUpload.formData;

    emit('change');
}

</script>

<template>
    <div class="prizific-file-input-wrapper">
        <div v-if="hasFile" class="row">
            <div v-for="(fileUrl, index) in recipeUpload.fileUrlsWithTypes" :key="index"
                class="col-xs-12 col-lg-4 prizific-file mt-3 mb-3">
                <img v-if="fileUrl.type == 'image'" :src="fileUrl.url" alt="" />

                <div @click="handleRemove(index)" class="prizific-file-input-clear-icon">
                    <el-icon class="el-icon-minus" :size="24">
                        <Minus />
                    </el-icon>
                </div>
            </div>
        </div>
        <div class="prizific-file-input mt-2">
            <div :class="[
                'el-upload-list__item-thumbnail ',
                {
                    'no-thumbnail': !recipeUpload.fileUrlsWithTypes,
                },
                { disabled: disabledFileUploader },
            ]">
                <span class="no-thumbnail-icon">
                    <el-icon :size="20">
                        <Camera />
                    </el-icon>
                </span>
                <div class="prizific-file-input-label-title py-2 px-3">
                    Blokk képének feltöltése
                </div>
            </div>

            <label :class="[
                'prizific-file-input-label',
                { disabled: disabledFileUploader },
            ]">
                <input ref="fileInput" type="file" @change="onFileChange"
                    accept="image/png, image/jpeg, application/pdf" multiple :disabled="disabledFileUploader" />
            </label>
        </div>
    </div>
</template>

<style lang="scss">
.prizific-file-input-wrapper {
    .receipt-image {
        .el-form-item__content {
            display: block;
        }
    }

    .prizific-file-text {
        color: var(--recipe-upload-form-label-color);
        font-size: var(--recipe-upload-form-label-font-size);
    }

    .prizific-file-input {
        position: relative;
        display: flex;
        align-items: center;

        .el-upload-list__item-thumbnail {
            position: relative;
            display: flex;
            width: 100%;
            min-width: 100px;
            min-height: 100px;
            overflow: hidden;
            z-index: 1;
            color: var(--code-upload-form-input-text-color);
            font-size: var(--recipe-upload-form-input-font-size);
            pointer-events: none;

            &.no-thumbnail {
                border: 1px solid;
            }

            &.disabled {
                filter: grayscale(100%);
            }

            .no-thumbnail-icon {
                font-size: 24px;
            }

            .prizific-file-input-clear {
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.25);
                transition: opacity 0.25s ease-in-out;
            }
        }

        .prizific-file-input-label {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            margin-left: 0;
            display: inline-block;
            z-index: 0;
            background-color: var(--code-upload-form-input-background-color);
            border-radius: var(--code-upload-form-input-border-radius);
            border-style: solid;
            border-color: var(--code-upload-form-input-border-color);
            border-width: var(--code-upload-form-input-border-width);
            font-size: var(--code-upload-form-input-font-size);

            .prizific-file-input-label-title {
                font-weight: bold;
            }

            .prizific-file-input-label-title,
            .prizific-file-input-label-desc {
                line-height: 24px;
            }

            input[type="file"] {
                display: none;
            }

            &.disabled {
                cursor: no-drop;
            }
        }
    }

    .error-message {
        margin-top: 10px;
    }

    .prizific-file {
        position: relative;

        img,
        embed {
            max-width: 100%;
            height: 200px;
            object-fit: contain;
        }

        embed {
            top: 8px;
        }

        img {
            margin: 0 auto;
            display: inherit;
        }

        .prizific-file-input-clear-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            font-size: 24px;
            line-height: 1;
            cursor: pointer;
            top: -10px;
            right: 0;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 2px solid;
            color: var(--code-upload-form-input-receipt-image-close-icon-color);
            border-color: var(--code-upload-form-input-receipt-image-close-icon-color);
        }
    }
}
</style>
